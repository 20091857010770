import AppCable from './app-cable'

$(document).ready(function() {
  if ($('meta[name=userId]').length === 0) return

  AppCable.createSubscription('PresenceChannel', {
    connected: function() {
      return this.appear()
    },
    disconnected: function() {
      return this.away()
    },
    rejected: function() {
      return this.away()
    },
    appear: function() {
      return this.perform('appear')
    },
    away: function() {
      return this.perform('away')
    },
    received: function(data) {
      var sessions = data['open_sessions']
      if (sessions > 0) {
        $('#alert-count').text(sessions)
        $('.menu-alerts').show()
      } else {
        $('.menu-alerts').hide()
      }
    },
  })
})
