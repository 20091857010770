let pageParams: Record<string, any> = {
  // l1Code: 'en' - examples
  // l2Code: 'de'
  // topicId: 1
  // unitId: 1
}

export const storePageParams = (currentPageParams: Record<string, any>) => {
  pageParams = currentPageParams || {}
}

export const usePageParams = () => {
  return pageParams
}
