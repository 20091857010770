import React from 'react'
import Countdown from 'react-countdown-now'

import { Flex, Box, Text } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import DigitBox from './DigitBox'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div />
  } else {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Text
          mb="2px"
          fontSize="sm"
          fontWeight="bold"
          css="text-transform: uppercase;"
        >
          {t('Offer Ends In')}
        </Text>
        <Flex>
          {days > 0 && (
            <Box>
              <DigitBox value={days} label={t('days')} />
            </Box>
          )}
          <Box ml="8px">
            <DigitBox value={hours} label={t('hours')} />
          </Box>
          <Box ml="8px">
            <DigitBox value={minutes} label={t('minutes')} />
          </Box>
          <Box ml="8px">
            <DigitBox value={seconds} label={t('seconds')} />
          </Box>
        </Flex>
      </Flex>
    )
  }
}

const Ticker = ({ timeTo, offerLabel, onExpire }) => {
  return <Countdown onComplete={onExpire} date={timeTo} renderer={renderer} />
}

export default Ticker
