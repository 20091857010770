export default {
  chatterbug: {
    cb_MMMD: 'D [de] MMM', // 8 de Mai
    cb_MMMDYYYY: 'D [de] MMM [de] YYYY', // 8 de Mai 2017
    cb_MMMYYYY: 'MMM YYYY', // Mai 2017
    cb_dddMMMD: 'ddd, D [de] MMM', // Mo., 8. Jan
    cb_ddddMMMD: 'dddd, D [de] MMM', // Montag, 8. Jan
    cb_hmma: 'HH:mm', // 15:00
    cb_MMMDYYYYhmma: 'D [de] MMM [de] YYYY HH:mm', // 8. Jan 2017 15:00
  },
}
