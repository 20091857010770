import { escape, isNumber } from 'lodash'
import Polyglot from 'node-polyglot'
// Formatting Reference: https://moment.github.io/luxon/docs/manual/formatting.html

export { DateTime } from 'luxon'

const wnd: any = window

const escapeValues = (args: Polyglot.InterpolationOptions) =>
  Object.keys(args).reduce((escapedValues, key) => {
    // do not escape `smart_count`, keep it a number.
    const val = key === 'smart_count' ? Number(args[key]) : escape(args[key])
    return {
      ...escapedValues,
      [key]: val,
    }
  }, {})

let polyglotFn: typeof t = wnd.Polyglot.t.bind(wnd.Polyglot)

document.addEventListener('readystatechange', () => {
  const i18nMarker = document.querySelector<HTMLMetaElement>(
    'meta[name="i18nMarker"]'
  )?.content

  if (i18nMarker?.length > 0) {
    polyglotFn = (msg, opts) => i18nMarker + wnd.Polyglot.t(msg, opts)
  }
})

function getTranslatedString(
  message: string,
  options?: number | Polyglot.InterpolationOptions
): string {
  return polyglotFn(message, options)
}

export const tDangerouslyUnescapedValues = getTranslatedString

export const t = (
  message: string,
  options?: number | Polyglot.InterpolationOptions
) =>
  getTranslatedString(
    message,
    isNumber(options) || !options ? options : escapeValues(options)
  )
