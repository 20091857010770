import { datajsJQuery } from 'src/lib/util'

function CardJudgment(element) {
  this.$el = $(element)
  this.$continueButton = datajsJQuery('card-study.continue-button')

  // Hide/show mnemonic section.
  // this.$el.find(".edit-mnemonic").click(this.showMnemonic.bind(this));
}

$.extend(CardJudgment.prototype, {
  showMnemonic: function() {
    // This is currently disabled. See the template
    this.$el.find('.answer-mnemonic').hide()
    this.$el.find('.edit-mnemonic').hide()
    this.$el
      .find('.mnemonic-input')
      .show()
      .find('textarea')
      .focus()
    return false
  },

  // Set the number of active stars in the Judgment section.
  //
  // count - the integer number of stars to set (0-4).
  setStars: function(count) {
    for (var i = 0; i <= count; i++) {
      this.$el.find('.card-judgment__star-' + i).addClass('on')
    }
  },

  // Show a correct answer.
  //
  // numStars  - The integer number (0-4) of stars to display on the page.
  // numClass  - The integer (0-4) for use in the display class.
  // desc      - The string description.
  showIncorrectAnswer: function(numStars, numClass, desc, explain) {
    this.setStars(numStars)
    this.$el.addClass('card-judgment_rating_' + numClass)

    this.$el
      .find('.card-judgment__failure-message')
      .text(desc)
      .show()
    this.$el.find('.card-judgment__failure-explanation').show()
    this.$el.find('.card-judgment__mnemonic').show()
    this.$el.find('.card-judgment__answer-text').show()
    this.$el.find('.card-judgment__actions').show()
    this.$el.find('.card-judgment__extra-actions').show()
    this.$el.find('.card-judgment__success-message').hide()
    this.show()
    this.$continueButton.hide()
  },

  // Show an incorrect answer.
  //
  // numStars  - The integer number (0-4) of stars to display on the page.
  // numClass  - The integer (0-4) for use in the display class.
  // msg       - The string message.
  showCorrectAnswer: function(numStars, numClass, msg) {
    this.setStars(numStars)
    this.$el.addClass('card-judgment_rating_' + numClass)
    this.$el
      .find('.card-judgment__success-message')
      .text(msg)
      .show()

    this.$el.find('.card-judgment__mnemonic').hide()
    this.$el.find('.card-judgment__answer-text').hide()
    this.$el.find('.card-judgment__actions').hide()
    this.$el.find('.card-judgment__extra-actions').hide()
    this.$el.find('.card-judgment__failure-message').hide()
    this.show()
    this.$continueButton.hide()
  },

  show: function() {
    this.$el.show()
    this.$continueButton.hide()
  },
})

$.fn.cardJudgment = function() {
  return $.map(this, function(element) {
    return new CardJudgment(element)
  })
}
