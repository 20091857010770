// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
 "octopus1": "rgba(248, 245, 254, 1)",
 "octopus2": "rgba(226, 215, 252, 1)",
 "octopus3": "rgba(204, 183, 245, 1)",
 "octopus4": "rgba(171, 138, 234, 1)",
 "dolphin1": "rgba(231, 253, 255, 1)",
 "dolphin2": "rgba(196, 238, 248, 1)",
 "dolphin3": "rgba(115, 218, 239, 1)",
 "dolphin4": "rgba(94, 204, 237, 1)",
 "jaguar1": "rgba(224, 224, 224, 1)",
 "jaguar2": "rgba(194, 194, 194, 1)",
 "jaguar3": "rgba(148, 148, 148, 1)",
 "jaguar4": "rgba(0, 0, 0, 1)",
 "frog1": "rgba(236, 254, 215, 1)",
 "frog2": "rgba(216, 254, 133, 1)",
 "frog3": "rgba(149, 206, 63, 1)",
 "frog4": "rgba(80, 177, 51, 1)",
 "dragonfly1": "rgba(218, 253, 246, 1)",
 "dragonfly2": "rgba(178, 252, 237, 1)",
 "dragonfly3": "rgba(115, 247, 219, 1)",
 "dragonfly4": "rgba(96, 208, 172, 1)",
 "baboon1": "rgba(252, 238, 238, 1)",
 "baboon2": "rgba(248, 210, 220, 1)",
 "baboon3": "rgba(243, 175, 194, 1)",
 "baboon4": "rgba(234, 61, 109, 1)",
 "flamingo1": "rgba(252, 237, 249, 1)",
 "flamingo2": "rgba(249, 219, 242, 1)",
 "flamingo3": "rgba(239, 140, 198, 1)",
 "flamingo4": "rgba(236, 103, 201, 1)",
 "bumblebee1": "rgba(254, 255, 217, 1)",
 "bumblebee2": "rgba(253, 241, 184, 1)",
 "bumblebee3": "rgba(251, 233, 113, 1)",
 "bumblebee4": "rgba(249, 220, 74, 1)",
 "camel1": "rgba(254, 247, 230, 1)",
 "camel2": "rgba(249, 222, 196, 1)",
 "camel3": "rgba(246, 196, 148, 1)",
 "camel4": "rgba(241, 155, 67, 1)",
 "ladybug1": "rgba(255, 232, 232, 1)",
 "ladybug2": "rgba(255, 210, 210, 1)",
 "ladybug3": "rgba(254, 176, 176, 1)",
 "ladybug4": "rgba(253, 66, 66, 1)",
 "gray99": "rgba(252, 252, 252, 1)",
 "gray95": "rgba(241, 242, 243, 1)",
 "gray88": "rgba(222, 224, 227, 1)",
 "gray80": "rgba(201, 203, 206, 1)",
 "gray60": "rgba(149, 152, 157, 1)",
 "gray46": "rgba(113, 116, 122, 1)",
 "blue97": "rgba(240, 249, 255, 1)",
 "blue90": "rgba(204, 232, 255, 1)",
 "blue70": "rgba(103, 185, 252, 1)",
 "blue42": "rgba(0, 111, 214, 1)",
 "blue30": "rgba(0, 80, 153, 1)",
 "pink97": "rgba(255, 240, 247, 1)",
 "pink51": "rgba(230, 28, 84, 1)",
 "yellow65": "rgba(244, 200, 86, 1)",
 "yellow92": "rgba(255, 246, 212, 1)",
 "green72": "rgba(172, 229, 137, 1)",
 "green36": "rgba(0, 176, 65, 1)",
 "white100": "rgba(255, 255, 255, 1)",
 "black0": "rgba(10, 10, 10, 1)",
 "black19": "rgba(48, 48, 48, 1)",
 "red97": "rgba(255, 242, 243, 1)",
 "red87": "rgba(253, 191, 196, 1)",
 "red68": "rgba(243, 103, 113, 1)",
 "red44": "rgba(204, 20, 33, 1)",
 "red22": "rgba(102, 10, 17, 1)"
}

export default colors;