import React from 'react'
import { ImageProps, Image } from '@chatterbug/aaron'
import { LanguageCode } from 'src/types'

export const SIZES = {
  l: {
    px: 46,
    borderWidth: 2,
  },
  m: {
    px: 32,
    borderWidth: 1,
  },
  middle: {
    px: 24,
    borderWidth: 1,
  },
  s: {
    px: 22,
    borderWidth: 1,
  },
  xs: {
    px: 18,
    borderWidth: 1,
  },
  xxs: {
    px: 14,
    borderWidth: 0,
  },
  xxxs: {
    px: 12,
    borderWidth: 0,
  },
}

const borderColors = {
  none: 'transparent',
  dark: '#000000',
  light: '#f3f3f3',
}

export interface FlagProps extends ImageProps {
  size?: keyof typeof SIZES
  borderColor?: 'none' | 'dark' | 'light'
  countryCode: LanguageCode | 'other'
  disabled?: boolean
}

const Flag: React.FC<FlagProps> = ({
  size = 'm',
  countryCode,
  borderColor = 'dark',
  disabled,
  ...others
}) => {
  const { px, borderWidth } = SIZES[size ?? 'm']

  return (
    <Image
      src={require(`./images/${countryCode}@2x.png`)}
      sx={{
        borderRadius: 999,
        filter: disabled ? 'grayscale(100%)' : 'none',
        width: px,
        height: px,
        border: `${borderWidth}px solid ${borderColors[borderColor]}`,
      }}
      {...others}
    />
  )
}

export default Flag
