import moment from 'moment'

import { bindMethods } from 'lib/util'
import Calendar from 'modules/calendar'
import DateUtil from 'lib/date-util'
import { t } from 'src/lib/i18n'
import NewPaymentMethod from 'modules/new-payment-method'

export default class LayoutCardsFinish {
  constructor(element) {
    bindMethods(this)

    const newPaymentEl = document.querySelector('.new-payment-method')
    // init the payment modal
    if (newPaymentEl) {
      const props = {
        paymentSource: 'additional',
        paymentMethods:
          newPaymentEl.getAttribute('data-sepa') === '1'
            ? ['card', 'iban']
            : undefined,
        stripePublishedKey: window.stripe_public_key,
        buttonText: t('Book Appointment'),
        onStripeResponse: (paymentId) => {
          window.eventTracking.track('live lesson', 'reservation')
          document.getElementById('new-source').setAttribute('value', paymentId)
          document.getElementById('appointment-book-form').submit()
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(newPaymentEl, { props })
    }

    this.element = element
    this.calendarEl = element.querySelector('.calendar')

    // load the calendar 2 days from now
    let targetDate = new Date()
    targetDate.setDate(targetDate.getDate() + 2)

    this.calendar = new Calendar(this.calendarEl, {
      onClickEvent: this.handleClickEvent.bind(this),
      onEventRender: this.handleEventRender.bind(this),
      defaultView: 'listDay',
      height: 'auto',
      minHeight: 300,
      selectable: false,
      selectHelper: false,
      editable: false,
      defaultDate: targetDate,
      firstDay: 1,
      events: {
        url: '/schedule/available_bookings',
        data: {
          more_notice: true,
        },
      },
    })
  }

  handleClickEvent(calEvent, jsEvent, view) {
    if (calEvent.start) {
      let start = moment(calEvent.start)
      let end = moment(calEvent.end)
      let formattedDate = `${DateUtil.format(
        start,
        'cb_ddddMMMD'
      )} ${DateUtil.format(start, 'cb_hmma')} - ${DateUtil.format(
        end,
        'cb_hmma'
      )}`
      if (calEvent.appointment_id) {
        $('#booking-appt-id').val(calEvent.appointment_id)
      }

      let tomorrow = moment().add(1, 'day')
      if (start.isBefore(tomorrow)) {
        $('#under-24h').show()
      } else {
        $('#under-24h').hide()
      }

      $('#booking-time').val(calEvent.start.unix())
      $('#booking-start-time').text(formattedDate)
      $('#booking-modal').modal('show')

      window.eventTracking.track('live lesson', 'planning')
    }
  }

  handleEventRender(event, element) {
    if (event.event_type) {
      element.addClass(`calendar__training_${event.event_type}`)
    }
    element.find('.fc-list-item-title').html(`<a href="#">${t('book now')}</a>`)
  }
}
