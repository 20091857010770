import {
  get,
  post,
  isSuccessStatus,
  bindMethods,
  serializeForm,
} from 'lib/util'
import LiveDate from 'src/components/LiveDate'
import Calendar from 'modules/calendar'
import PageAlert from 'src/components/PageAlert'
import { t } from 'src/lib/i18n'

export default class LayoutScheduleAvailability {
  constructor(element) {
    bindMethods(this)
    this.element = element
    this.calendarEl = element.querySelector('.calendar')
    const selector = '.l-schedule-availability'

    let backgroundType = element.getAttribute('data-background-availability')
    let calendar = (this.calendar = new Calendar(this.calendarEl, {
      onClickEvent: this.handleClickEvent,
      onCreateEvent: this.handleCreateEvent,
      onUpdateEvent: this.handleUpdateEvent,
      onEventRender: this.handleEventRender,
      onFinishFetch: this.handleFinishFetch,
      events: {
        url: '/schedule/feed',
        data: () => ({ background_type: backgroundType }),
      },
    }))

    this.showTutors = element.querySelector(`${selector}__show_tutors`)
    this.showStudents = element.querySelector(`${selector}__show_students`)
    this.toggleMessage = element.querySelector(`${selector}__toggle__message`)
    this.render({ backgroundType })

    this.showStudents
      ? this.showStudents.addEventListener('click', (e) => {
          backgroundType = 'students'
          this.calendar.refetchEvents()
          this.render({ backgroundType })
        })
      : null

    this.showTutors
      ? this.showTutors.addEventListener('click', (e) => {
          backgroundType = 'tutors'
          this.calendar.refetchEvents()
          this.render({ backgroundType })
        })
      : null

    $('#upcoming-availability').on(
      'click',
      '.schedule-upcoming-availability__date-range',
      function() {
        let date = $(this).attr('ref')
        calendar.gotoDate(date)
      }
    )
  }

  render(state) {
    let { backgroundType } = state

    let showingText =
      backgroundType === 'students'
        ? this.showStudents.rel
        : this.showTutors.rel
    this.toggleMessage.innerHTML = t(
      `Showing background availability of <strong>%{showingText}</strong>`,
      { showingText }
    )

    if (backgroundType === 'students') {
      $(this.showStudents).hide()
      $(this.showTutors).show()
    } else {
      $(this.showStudents).show()
      $(this.showTutors).hide()
    }
  }

  refreshAvailability(isUpdate = false) {
    this.updateUpcoming()
    // there's no need to completely re-render the calendar when updating cause the
    // calendar is already in the right state, users dragging availability around
    // during a refresh causes an exception.
    !isUpdate && this.calendar.refetchEvents()
  }

  showEditModalForEvent(eventId) {
    get(`item/${eventId}`).then(({ data }) => {
      $('#event-modal .modal-content').html(data)
      $('#event-modal').modal('show')
      this.addModalActions()
    })
  }

  updateUpcoming() {
    let upcomingUrl = $('#upcoming-availability').attr('ref')
    $('#upcoming-availability').load(upcomingUrl, () => {
      LiveDate.mountAll({}, $('#upcoming-availability')[0])
    })
  }

  addModalActions() {
    $('.submit-item-form').click((e) => {
      let actionType = $(e.target).val()
      let form = $('#item-edit-form')
      let dataStuff = form.serializeArray()
      dataStuff.push({ name: 'action_type', value: actionType })
      post(form.attr('action'), dataStuff).then(() => {
        $('#event-modal').modal('hide')
        this.refreshAvailability()
      })
      return false
    })
  }

  handleUpdateEvent(event, delta, revertFunc) {
    if (event.event_type !== 'schedule_item') {
      revertFunc()
      return
    }

    let range = clampDefaultDuration(event.start, event.end)
    let startTime = range.start.format()
    let endTime = range.end.format()
    let form = $('#item-update-form')
    let eventData = form.serializeArray()

    eventData.push({ name: 'event_id', value: event.event_id })
    eventData.push({ name: 'start_time', value: startTime })
    eventData.push({ name: 'end_time', value: endTime })

    post(form.attr('action'), eventData).then(({ data, statusCode }) => {
      if (statusCode === 404) {
        PageAlert.showAlert({
          type: 'warning',
          fixed: true,
          content: t('This block was already deleted'),
          onClose: () => this.refreshAvailability(),
        })
      } else if (isSuccessStatus(statusCode)) {
        this.refreshAvailability(true)
      } else {
        revertFunc()
        PageAlert.showAlert({
          type: 'warning',
          fixed: true,
          content: t('Changes could not be saved'),
        })
      }
    })
  }

  handleClickEvent(calEvent, jsEvent, view) {
    if (calEvent.event_type === 'schedule_item') {
      this.showEditModalForEvent(calEvent.event_id)
    }
  }

  handleCreateEvent(start, end) {
    let form = $('#add-event-form')
    let eventData = serializeForm(form)
    let range = clampDefaultDuration(start, end)
    eventData.start = range.start
    eventData.end = range.end
    post(form.attr('action'), eventData, 'json').then(({ data }) => {
      this.showEditModalForEvent(data.event_id)
      this.refreshAvailability()
    })
  }

  handleEventRender(event, element) {
    if (!event.id) element.addClass('calendar__event_pending')
    if (event.availability_type) {
      element.addClass(
        `calendar__avail-${event.availability_type}_${event.availability}`
      )
    }
  }

  handleFinishFetch() {
    // When the calendar is refreshed, we deselect any selections. There might
    // be a selection from when they created a new item.
    this.calendar.unselect()
  }
}

function clampDefaultDuration(start, end) {
  let minEndTime = start.clone().add(45, 'minutes')
  if (!end || end.isBefore(minEndTime)) end = minEndTime
  return { start, end }
}
