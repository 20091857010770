$(document).ready(function() {
  function $$(selector, context) {
    context = context || document
    var elements = context.querySelectorAll(selector)
    return Array.prototype.slice.call(elements)
  }

  $$('.pie').forEach(function(pie) {
    var p = parseFloat(pie.textContent)
    var NS = 'http://www.w3.org/2000/svg'
    var svg = document.createElementNS(NS, 'svg')
    var bgCircle = document.createElementNS(NS, 'circle')
    var circle = document.createElementNS(NS, 'circle')
    var title = document.createElementNS(NS, 'title')

    bgCircle.setAttribute('r', 16)
    bgCircle.setAttribute('cx', 16)
    bgCircle.setAttribute('cy', 16)
    bgCircle.setAttribute('class', 'bg')

    circle.setAttribute('r', 16)
    circle.setAttribute('cx', 16)
    circle.setAttribute('cy', 16)
    circle.setAttribute('stroke-dasharray', p + ' 100')
    circle.setAttribute('class', 'fg')

    svg.setAttribute('viewBox', '0 0 32 32')
    title.textContent = pie.textContent
    pie.textContent = ''
    svg.appendChild(title)
    svg.appendChild(bgCircle)
    svg.appendChild(circle)
    pie.appendChild(svg)
  })
})
