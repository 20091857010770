/* eslint-disable camelcase */
export default {
  CONTRACTION_MAP: {
    '&': ['y'],
  },

  CONTRACTION_CHARACTERS: /[\&]/,

  // Expand any contractions in the text into their non-contracted form.
  //
  // text - the string text to expand.
  //
  // Returns an array of possible expansions. Even if no expansions were made,
  // the return will be a one-element array with the original text.
  expandContractions: function(text) {
    let expansions = [text]

    // stop now unless contraction characters are present
    if (!this.CONTRACTION_CHARACTERS.test(text)) return expansions

    let text_lc = text.toLowerCase()

    for (const contraction in this.CONTRACTION_MAP) {
      // so long as contraction expansions don't contain contractions,
      // this is a safe check for usage of a contraction and faster
      if (text_lc.indexOf(contraction.toLowerCase()) < 0) continue

      let replacements = this.CONTRACTION_MAP[contraction]

      let replaceRe = new RegExp(contraction, 'gi')

      let new_expansions = []

      for (const expansion of expansions) {
        replacements.forEach((replacement) => {
          new_expansions.push(expansion.replace(replaceRe, replacement))
        })
      }

      expansions = new_expansions
    }

    expansions.unshift(text) // include original as first alternative

    return expansions
  },

  // Expand the text to allow for various properties.
  //
  // text           - the string text to expand.
  // type           - the string type. Possible values: "word" or "phrase".
  // features       - the collection of linguistic features. Includes part of speech.
  // isNative       - whether the text language is the native language of the user
  //
  // Returns an array of possible expansions. Even if no expansions were made,
  // the return will be a one-element array with the original text.
  expand: function(text, type, features, isNative) {
    let expansions = [text]

    if (type === 'phrase') {
      expansions = this.expandContractions(text)
    }

    return expansions
  },
}
