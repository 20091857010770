import _ from 'lodash'

import { bindMethods } from 'lib/util'

export default class PrivacySelector {
  constructor(element, options) {
    bindMethods(this)
    this.optionSelector = `${PrivacySelector.selector}__option`
    this.selectedClass = `${PrivacySelector.className}__option_selected`

    if (!element) return
    this.element = element
    this.options = _.defaults(options || {}, {
      onChange: null,
    })

    let $el = $(this.element)
    $el.on('click', this.optionSelector, this.handleClickOption)
  }

  handleClickOption(e) {
    let optionElement = $(e.target).closest(this.optionSelector)[0]
    let value = optionElement.getAttribute('data-value')
    this.options.onChange(value)
  }

  getOption(value) {
    return this.element.querySelector(
      `${this.optionSelector}[data-value="${value}"]`
    )
  }

  render(state) {
    if (!this.element) return
    let optionEl
    let { value } = state

    let elements = this.element.querySelectorAll('.' + this.selectedClass)
    for (let element of _.toArray(elements)) {
      element.classList.remove(this.selectedClass)
    }

    optionEl = this.getOption(value)
    if (optionEl) {
      optionEl.checked = true
      optionEl.classList.add(this.selectedClass)
    }
  }
}

PrivacySelector.className = 'privacy-selector'
PrivacySelector.selector = '.privacy-selector'
