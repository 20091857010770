import { EventEmitter } from 'events'

import _ from 'lodash'

import { get, post, isSuccessStatus } from 'lib/util'

// User preferences model
//
// It emits one event event: 'change'. Usage:
//
// Preferences.instance().then((prefs) => {
//   // pulls from local
//   let mobile = prefs.get(['notifications', 'upcoming_session', 'mobile'])
//
//   // sets local, sets remote, and refreshes the preferences
//   prefs.set(['notifications', 'upcoming_session', 'mobile'], true)
//
//   // A when something is set or refreshed
//   prefs.on('change', ({path, value}) => {
//     // Do things
//     // `path` will be null when the whole prefs object is updated
//   })
// })
export default class Preferences {
  static instance() {
    let instance = Preferences._instance
    if (instance && instance.loadingPromise) {
      // Created already and it's loading
      return instance.loadingPromise.then(() => instance)
    } else if (instance) {
      // Created and it's done loading
      return Promise.resolve(instance)
    } else {
      // Not created yet; create and load
      Preferences._instance = instance = new Preferences()
      return instance.refresh().then(() => instance)
    }
  }

  static on(event, handler) {
    return Preferences.instance().then((preferences) => {
      preferences.on('change', () => handler(preferences))
    })
  }

  constructor(prefs) {
    this.prefs = prefs || {}
    this.emitter = new EventEmitter()
  }

  get(path) {
    return _.get(this.prefs, path)
  }

  set(path, value) {
    let oldValue = this.get(path)
    _.set(this.prefs, path, value)
    if (!_.isEqual(value, oldValue)) this.emitChange({ path, value })
    return post(Preferences.updateURL, { path, value }, 'json').then(
      ({ data, status }) => {
        if (isSuccessStatus(status)) this.setAll(data)
      }
    )
  }

  setAll(prefs) {
    let didChange = !_.isEqual(this.prefs, prefs)
    this.prefs = prefs
    if (didChange) this.emitChange()
  }

  refresh() {
    this.loadingPromise = get(Preferences.getURL, 'json').then(
      ({ data, status }) => {
        if (isSuccessStatus(status)) {
          this.setAll(data)
          this.loadingPromise = null
        }
      }
    )
    return this.loadingPromise
  }

  emitChange(options) {
    options = options || {}
    this.emitter.emit('change', options)
  }

  on(event, listener) {
    this.emitter.on(event, listener)
  }

  off(event, listener) {
    this.emitter.removeListener(event, listener)
  }
}

Preferences.getURL = '/account/preferences'
Preferences.updateURL = '/account/preferences'
