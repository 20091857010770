import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6H14" stroke="black" strokeLinecap="round" />
      <path d="M4 12H20" stroke="black" strokeLinecap="round" />
      <path d="M4 18H20" stroke="black" strokeLinecap="round" />
    </svg>
  )
}
