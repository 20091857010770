/* eslint-disable no-new */
import _ from 'lodash'

// Tabs
//
// Example usage:
//
//     <section class="content-section" id="tabs">
//       <div class="content-card">
//         <div class="tabs tabs" data-target="#my-tabs">
//           <div class="tabs__tab">
//             <a href="javascript:void(0)">Tab 1</a>
//           </div>
//           <div class="tabs__tab">
//             <a href="javascript:void(0)">Tab 2</a>
//           </div>
//         </div>
//         <div id="my-tabs" class="tabs__content content-card__content">
//           <div>
//             Contents of first tab.
//           </div>
//           <div>
//             Contents of second tab.
//           </div>
//         </div>
//       </div>
//     </section>
//
// Once the above HTML is on a page, it will be automatically detected and
// enabled.
export default class Tabs {
  constructor(element, options) {
    this.element = element
    this.tabSelector = `${Tabs.selector}__tab`
    this.activeContentClass = `${Tabs.className}__content_active`
    this.activeContentSelector = `.${this.activeContentClass}`
    this.activeTabClass = `${Tabs.className}__tab_active`
    this.activeTabSelector = `.${this.activeTabClass}`

    this.tabs = element.querySelectorAll(this.tabSelector)

    this.tabLinkData = 'data-link-to-tab'
    this.tabLinkSelector = `[${this.tabLinkData}]`

    this.emulateJquery =
      (options && options.emulateJquery) ||
      element.getAttribute('data-emulate-jquery') !== null

    this.initTabs()
  }

  initTabs() {
    if (!this.getTabContents()) return null

    $(this.element).on(
      'click',
      `${this.tabSelector} a`,
      this.handleTabClick.bind(this)
    )

    let selectedTab =
      this.element.querySelector(this.activeTabSelector) ||
      this.element.querySelector(this.tabSelector)

    this.updateSelectedTab(selectedTab)
  }

  getTabContents() {
    let rootSelector = this.element.getAttribute('data-target')

    let tabSelector = this.element.getAttribute('data-tabs')

    this.tabsRoot = document.querySelector(rootSelector)
    if (!this.tabsRoot) return false

    if (!tabSelector) {
      this.tabContents = _.filter(
        this.tabsRoot.childNodes,
        (e) => e.tagName === 'DIV' || e.tagName === 'REACT-ELEMENT'
      )
    } else {
      this.tabContents = this.tabsRoot.querySelectorAll(tabSelector)
    }
    return this.tabContents.length
  }

  handleTabClick(e) {
    e.target.blur()
    this.updateSelectedTab(e.target.parentNode)
  }

  connectTabLinks(root) {
    $(root).on(
      'click',
      this.tabLinkSelector,
      this.handleTabLinkClick.bind(this)
    )
  }

  handleTabLinkClick(e) {
    e.target.blur()
    e.preventDefault()
    let tabId = e.target.getAttribute(this.tabLinkData)
    let tabEl = document.querySelector(`#${tabId}`)
    let selectedTabIndex = _.indexOf(this.tabContents, tabEl)
    this.render({ selectedTabIndex })
  }

  updateSelectedTab(tab) {
    let selectedTabIndex = _.indexOf(this.tabs, tab)
    this.render({ selectedTabIndex })
  }

  render(state) {
    let { selectedTabIndex } = state

    selectedTabIndex = _.clamp(selectedTabIndex || 0, 0, this.tabs.length)

    let currentTab = this.element.querySelector(this.activeTabSelector)
    if (currentTab) {
      if (this.emulateJquery) {
        $(currentTab).trigger('hide.bs.tab')
        currentTab.classList.remove(this.activeTabClass)
        $(currentTab).trigger('hidden.bs.tab')
      } else {
        currentTab.classList.remove(this.activeTabClass)
      }
    }

    const currentContent = this.tabsRoot.querySelector(
      this.activeContentSelector
    )
    if (currentContent) {
      currentContent.classList.remove(this.activeContentClass)
    }

    const newTab = this.tabs[selectedTabIndex]
    if (this.emulateJquery) {
      $(newTab).trigger('show.bs.tab')
    }
    newTab.classList.add(this.activeTabClass)

    // if contents are being loaded async, they may not exist yet
    if (this.tabContents[selectedTabIndex]) {
      this.tabContents[selectedTabIndex].classList.add(this.activeContentClass)
      if (this.emulateJquery) {
        $(newTab).trigger('shown.bs.tab')
      }
    }
  }
}

Tabs.className = 'tabs'
Tabs.selector = `.${Tabs.className}`

$(document).ready(() => {
  let elements = document.querySelectorAll(Tabs.selector)
  _.map(elements, (e) => {
    if (e.getAttribute('data-target') !== null) {
      e.tabs = new Tabs(e)
    }
  })
})
