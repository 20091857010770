import LegacyPopper from 'popper.js'

import { get, post, bindMethods } from 'lib/util'
import { t } from 'src/lib/i18n'

const sentenceSelector = '.l-reading__sentence'
const sentenceMarkSelector = `${sentenceSelector} a`
const sentenceTranslationSelector = `.l-reading__translation ${sentenceSelector}`
const clickableWordSelector = '.l-reading__word-translated'
const wordStudySelector = '.l-reading__word-add-study'

const sentenceHoverClass = 'l-reading__sentence__over'

const sentenceLookupsField = '#sentence-lookups'
const wordLookupsField = '#word-lookups'

export default class LayoutReading {
  constructor(element, { lookupWordUrl }) {
    bindMethods(this)
    this.$element = $(element)
    this.lookupWordUrl = decodeURI(lookupWordUrl || '')
    this.currentPopper = null
    this.initializeSentencePeers()
    this.initializeWordTranslations(clickableWordSelector)

    window.eventTracking.track('activity', 'start', 'reading')
  }

  initializeSentencePeers() {
    // Shade paired sentences when you hover over mark or translation block
    this.initializeSentenceTranslationHover(sentenceMarkSelector)
    this.initializeSentenceTranslationHover(sentenceTranslationSelector)

    // Reveal translation if you click sentence mark or on translation block
    this.$element.find(sentenceMarkSelector).click(this.handleRevealClick)
    this.$element
      .find(sentenceTranslationSelector)
      .click(this.handleRevealClick)
  }

  initializeSentenceTranslationHover(selector) {
    this.$element.find(selector).hover(
      function() {
        // in
        let ref = $(this)
          .closest(sentenceSelector)
          .attr('ref')
        $(`[ref=${ref}]`).addClass(sentenceHoverClass)
      },
      function() {
        // out
        let ref = $(this)
          .closest(sentenceSelector)
          .attr('ref')
        $(`[ref=${ref}]`).removeClass(sentenceHoverClass)
      }
    )
  }

  initializeWordTranslations(selector) {
    this.$element.find(selector).click(this.handleWordClick)

    $('body').on('click', `${wordStudySelector} a`, this.handleWordStudy)

    // body clicks not in popper should dismiss the popper
    $('body').mousedown(this.handleBodyClick)
  }

  killPopper() {
    if (this.currentPopper != null) {
      this.currentPopper.destroy()
      $(this.currentPopper._popper).remove()
      this.currentPopper = null
    }
  }

  createPopper(word, content) {
    this.killPopper()

    this.currentPopper = new LegacyPopper(
      word,
      { content: content, contentType: 'html' },
      { placement: 'bottom' }
    )

    return this.currentPopper
  }

  hasPopperFor(word) {
    return this.currentPopper != null && this.currentPopper._reference === word
  }

  handleWordClick(ev) {
    let word = ev.target

    if (this.hasPopperFor(word)) {
      this.killPopper()
      return
    }

    this.incrementInput(wordLookupsField)

    var popperContent = `
        <div id="popper-word">
        <div class="loader_spinner loader_dark"></div>
        </div>
      `
    this.createPopper(word, popperContent)

    let wordId = word.getAttribute('ref')
    if (wordId) {
      let url = this.lookupWordUrl.replace('{id}', wordId)
      get(url).then(({ status, data }) => {
        if (status !== 'success') {
          return
        }
        $('#popper-word').html(data)
      })
    }

    return false
  }

  handleWordStudy(ev) {
    ev.preventDefault()

    let wordId = ev.target.getAttribute('ref')
    let url = this.lookupWordUrl.replace('{id}', wordId)
    post(url, { study: 1 })

    $(wordStudySelector).html(`<b>${t('added')}</b>`)
    return false
  }

  handleBodyClick(ev) {
    if (
      ev.target.id === 'popper-word' ||
      $(ev.target).parents('#popper-word').length > 0 ||
      this.hasPopperFor(ev.target)
    ) {
      // we're doing stuff inside the popup, so just chillax
    } else {
      this.killPopper()
    }
  }

  revealSentence(ref) {
    let color = parseInt(ref) % 3
    $(`[ref=${ref}]`).addClass(`l-reading__sentence__shown-${color}`)

    this.incrementInput(sentenceLookupsField)
  }

  handleRevealClick(ev) {
    ev.preventDefault()
    let ref = $(ev.target)
      .closest(sentenceSelector)
      .attr('ref')
    this.revealSentence(ref)
    return false
  }

  incrementInput(selector) {
    let field = this.$element.find(selector)
    field.val(parseInt(field.val()) + 1)
  }
}

LayoutReading.selector = '.l-reading'
