import ChatPresenceMessage from 'modules/chat-presence-message'

export default class ChatPresenceAvatar extends ChatPresenceMessage {
  render(state) {
    let { presence } = state
    let { classList } = this.element
    classList.remove(
      ChatPresenceAvatar.awayClass,
      ChatPresenceAvatar.availableClass
    )
    if (presence === 'away') {
      classList.add(ChatPresenceAvatar.awayClass)
    } else if (presence === 'available') {
      classList.add(ChatPresenceAvatar.availableClass)
    }
  }
}

ChatPresenceAvatar.className = 'chat-presence-avatar'
ChatPresenceAvatar.selector = `.${ChatPresenceAvatar.className}`
ChatPresenceAvatar.awayClass = `${ChatPresenceAvatar.className}_away`
ChatPresenceAvatar.availableClass = `${ChatPresenceAvatar.className}_available`
