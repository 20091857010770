import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import { post } from 'src/lib/util'
import ButtonBar from 'src/components/ButtonBar/ButtonBar'
import LiveLesson from 'src/components/LiveLesson/LiveLesson'

const Confirm = ({ appointment, onSubmit }) => {
  return (
    <React.Fragment>
      {t('Do you really want to confirm this appointment?')}
      <br />
      <SC.LiveLesson>
        <LiveLesson {...appointment} />
      </SC.LiveLesson>
      <ButtonBar>
        <Button
          type="primary"
          disableWith={t('Confirming...')}
          onClick={() => {
            post(
              routes.appointment_modify(appointment.id, 'confirm'),
              {},
              'json'
            ).then(({ data }) => {
              onSubmit({ ...appointment, ...data.appointment })
            })
          }}
        >
          {t('Really Confirm')}
        </Button>
      </ButtonBar>
    </React.Fragment>
  )
}

const SC = {}
SC.LiveLesson = styled.div`
  font-weight: bold;
  margin: 10px;
`

Confirm.propTypes = {
  appointment: PropTypes.object.isRequired,
}

export default Confirm
