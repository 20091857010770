import _ from 'lodash'

import { bindMethods } from 'lib/util'

// Utility to find Term links and make them display the definition

export default class TermDisplay {
  constructor(element, options) {
    bindMethods(this)

    this.element = element
    this.$el = $(element)
    this.$el.detach().appendTo('body')

    this.options = _.defaults(options || {}, {})

    this.nativeLC = this.$el.data('native-lc') || ''
    this.learningLC = this.$el.data('learning-lc') || ''
    this.allTerms = (this.$el.data('all-terms') || '').split(';')
    this.newTerms = (this.$el.data('new-terms') || '').split(';')

    this.termLinkSelector = this.options.termLinkSelector || '.markdown__term'
    this.termModalSelector = '#term-modal'
    this.termModalContentSelector = `#{this.termModalSelector} .modal-body`

    this.showTermsDebounced = _.debounce(this.showTerms, 500, {
      leading: true,
      trailing: false,
    })

    this.$modal = null
    this.showingList = null
    this.showingIndex = 0

    this.enableTerms() // watch term links, and make them clickable (nested terms can appear in ajax modals)

    this.showTerms(this.newTerms) // pop all new terms immediately
  }

  termUrl(term) {
    return `/language/${this.nativeLC}/term/${term}?learning=${this.learningLC}`
  }

  handleTermClick(e) {
    e.preventDefault()
    let term = $(e.target)
      .closest(this.termLinkSelector)
      .attr('ref')
    if (term) this.fetchTerm(this.termUrl(term))
  }

  handleTermHelp(e) {
    if (
      (e.metaKey || e.ctrlKey) &&
      String.fromCharCode(e.which).toLowerCase() === 'h'
    ) {
      this.showTermsDebounced(this.allTerms)
    }
  }

  handleTermData(data) {
    if (this.$modal) return
    this.$el.html(data).show()
    this.$modal = this.$el.find(this.termModalSelector)
    this.$modal.modal()
    this.$modal.on(
      'hidden.bs.modal',
      function() {
        this.$modal = null
      }.bind(this)
    )
  }

  showTerms(termList) {
    // don't start a new list if there is one in progress
    if (this.showingList && this.showingIndex < this.showingList.length) return
    if (this.$modal) return

    // remove empty or null entries from term list
    this.showingList = termList.filter(function(n) {
      return !!n
    })
    this.showingIndex = 0
    if (!this.showingList) return

    this.showNextTerm()
  }

  showNextTerm() {
    if (this.showingIndex >= this.showingList.length) {
      if (this.$modal) this.$modal.off('hidden.bs.modal')
      return
    }

    let term = this.showingList[this.showingIndex]
    this.showingIndex += 1

    let url = this.termUrl(term)
    // more to go
    if (this.showingIndex < this.showingList.length) {
      url += '&next=1'
    }

    if (term) this.fetchTerm(url, this.chainTerms)
    else this.showNextTerm()
  }

  chainTerms(data) {
    this.handleTermData(data)
    this.$modal.on('hidden.bs.modal', this.showNextTerm)
  }

  enableTerms() {
    $(document).off('click', this.termLinkSelector)
    $(document).on('click', this.termLinkSelector, this.handleTermClick)
    if (this.allTerms.length > 0) $(document).on('keydown', this.handleTermHelp)
  }

  fetchTerm(url, callback) {
    callback = callback || this.handleTermData
    $.get(url, callback.bind(this))
  }
}

TermDisplay.className = 'term-display'
TermDisplay.selector = `.${TermDisplay.className}`
