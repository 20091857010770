import React from 'react'

export default ({ selected }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={selected ? 'black' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9563 13.8616C2.9563 13.3093 3.40401 12.8616 3.9563 12.8616H5.9563C6.50858 12.8616 6.9563 13.3093 6.9563 13.8616V20.8616H2.9563V13.8616Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9563 5.86157C9.9563 5.30929 10.404 4.86157 10.9563 4.86157H12.9563C13.5086 4.86157 13.9563 5.30929 13.9563 5.86157V20.8616H9.9563V5.86157Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9563 9.86157C16.9563 9.30929 17.404 8.86157 17.9563 8.86157H19.9563C20.5086 8.86157 20.9563 9.30929 20.9563 9.86157V20.8616H16.9563V9.86157Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
