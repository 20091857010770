import React from 'react'

export default () => {
  return (
    <svg
      style={{ borderRadius: '100px' }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="24"
        height="25"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.02271 1.3139H24.8607V25.0008H1.02271V1.3139L1.02271 1.3139Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.37485 10.3125C2.95564 3.96292 9.41654 0.0922868 15.8045 1.66351C22.1942 3.23402 26.0896 9.65314 24.5089 16.0017C22.9278 22.3502 16.468 26.2225 10.0787 24.6509C3.69027 23.0804 -0.207203 16.6609 1.37485 10.3125Z"
          fill="#4A58A4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3953 4.80796L16.6033 7.98213V1.88892C16.3416 1.80538 16.0758 1.72945 15.8049 1.66281C13.5566 1.10982 11.2995 1.23175 9.24926 1.89774V7.98285L4.47623 4.82151C3.38326 5.91568 2.49514 7.2326 1.89156 8.72435L3.15564 9.56164H1.58916C1.51051 9.80784 1.43851 10.0576 1.37513 10.3119C0.814399 12.5618 0.94254 14.8204 1.62556 16.8693H3.18685L1.9364 17.6976C2.54276 19.1456 3.43438 20.4657 4.5594 21.5742L9.24926 18.4681V24.4142C9.52081 24.5019 9.79737 24.5811 10.0791 24.6502C12.3159 25.2005 14.5613 25.0826 16.6033 24.4255V18.4681L21.3118 21.5866C22.4178 20.5044 23.3215 19.198 23.9417 17.7147L22.6652 16.8693H24.2577C24.3517 16.5854 24.4358 16.2958 24.5092 16.0011C25.0586 13.7942 24.9458 11.5789 24.2971 9.56164H22.6969L23.9856 8.7081C23.3912 7.25401 22.511 5.92551 21.3953 4.80796Z"
        fill="#F4F0ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5168 16.8693L22.8531 19.7348C23.1501 19.2929 23.4189 18.828 23.6563 18.342L21.4272 16.8692L18.5168 16.8693ZM24.6772 11.0835H15.0723V1.50708C13.6162 1.24294 12.1664 1.25464 10.7813 1.50924V11.0835H1.21107C0.950566 12.5303 0.968069 13.9704 1.22918 15.3457H10.7813V24.8003C12.237 25.0686 13.6867 25.0603 15.0723 24.8095V15.3457H24.6529C24.928 13.9004 24.9243 12.4604 24.6772 11.0835ZM3.02393 19.7204C3.3221 20.1655 3.65093 20.5906 4.00771 20.9929L9.24894 17.5294V16.8693H7.33847L3.02393 19.7204ZM22.8737 6.61425C22.5765 6.16814 22.2484 5.742 21.8923 5.33859L16.6031 8.83383V9.56171H18.4145L22.8737 6.61425ZM7.41584 9.56174L2.99114 6.63718C2.69683 7.08083 2.43029 7.54704 2.19562 8.03483L4.5053 9.56174H7.41584Z"
        fill="#E25A63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.8289C19.6274 24.8289 25 19.4946 25 12.9144C25 6.33427 19.6274 1 13 1C6.37258 1 1 6.33427 1 12.9144C1 19.4946 6.37258 24.8289 13 24.8289Z"
        stroke="black"
      />
    </svg>
  )
}
