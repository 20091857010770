import _ from 'lodash'

import Module from 'lib/module'
import ChatChannel from 'lib/actioncable/chat-channel'
import { t } from 'src/lib/i18n'

export default class ChatReadyHandshake extends Module {
  constructor(element, options) {
    super(element, options)
    this.element = element
    this.options = _.defaults({}, options, {
      enabled: null,
      onChangeState: () => {},
    })

    this.$el = $(element)
    this.button = this.$el.find('.chat-ready-handshake__start')
    this.sessionId = this.$el.attr('data-session-id')
    this.userRole = this.$el.attr('data-user-role')
    this.otherRole = this.userRole === 'student' ? 'teacher' : 'student'
    this.state = null

    if (this.sessionId) {
      this.connectChannelWithRetries(2)
    } else {
      console.error('No session id for ChatReadyHandshake!')
    }
    this.button.click(this.handleStartButtonClick)
    if (this.options.enabled != null) this.setEnabled(this.options.enabled)
  }

  handleConnectionTimeout(retries) {
    if (retries > 0) {
      console.log(
        'Action Cable was not able to connect due to timeout, it will retry now'
      )
      this.connectChannelWithRetries(retries - 1)
    } else {
      console.log('ActionCable connection retries exhausted')
      window.Sentry.withScope((scope) => {
        scope.setExtra({ session_id: this.sessionId })
        window.Sentry.captureException(
          new Error('ActionCable connection retries exhausted')
        )
      })
      this.setState('error')
    }
  }
  handleConnected() {
    clearTimeout(this.connectionTimeout)
    this.chatChannel.getClientReady()
  }
  connectChannelWithRetries(retries) {
    this.chatChannel = ChatChannel.get(this.sessionId)
    this.chatChannel.on('connect', this.handleConnected)
    this.chatChannel.on('clientReady', this.handleClientReady)
    this.chatChannel.on('getClientReady', this.handleClientReady)
    this.chatChannel.on('resetClientReady', this.handleClientReady)
    this.connectionTimeout = window.setTimeout(
      () => this.handleConnectionTimeout(retries),
      5000
    )
  }
  handleClientReady(data) {
    data = data || {}
    if (data.both) this.setState('both-ready')
    else if (data[this.userRole]) this.setState('me-ready')
    else if (data[this.otherRole]) this.setState('other-ready')
    else this.setState('none-ready')
  }
  handleStartButtonClick() {
    this.chatChannel.clientReady()
  }
  getState() {
    return this.state
  }
  setState(state) {
    this.state = state
    this.$el.removeClass(
      this.states
        .map(function(s) {
          return 'chat-ready-handshake_' + s
        })
        .join(' ')
    )
    this.$el.addClass('chat-ready-handshake_' + state)
    this.options.onChangeState(this.state)
  }
  setEnabled(enabled) {
    this.button.prop('disabled', !enabled)
    this.button.attr(
      'title',
      enabled
        ? ''
        : t('Please check your audio / video settings before continuing!')
    )
  }
}
ChatReadyHandshake.prototype.states = [
  'error',
  'none-ready',
  'other-ready',
  'me-ready',
  'both-ready',
]
ChatReadyHandshake.className = 'chat-ready-handshake'
ChatReadyHandshake.selector = `.${ChatReadyHandshake.className}`
