import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ButtonBar from 'src/components/ButtonBar/ButtonBar'
import LiveLesson from 'src/components/LiveLesson/LiveLesson'
import Markdown from 'src/components/Markdown/Markdown'
import { t, DateTime } from 'src/lib/i18n-react'
import { Button, Box } from '@chatterbug/aaron'

const LessThanHourAndHalf = () => (
  <div className="page-alert_inline alert alert-danger">
    {t(
      'Since there is less than %{num} minutes until this appointment, if you cancel now you will be charged as if you had taken the appointment.',
      { num: 90 }
    )}
  </div>
)

const LessThan24Hours = () => (
  <div className="page-alert_inline alert alert-danger">
    {t(
      'Since there is less than a day until this appointment, if you cancel now you will be charged half of a Live Lesson credit.'
    )}
  </div>
)

class Cancel extends React.Component {
  state = {
    reason: '',
  }

  handleChange = (event) => {
    this.setState({ reason: event.target.value })
  }

  render() {
    const {
      appointment,
      tutorConfirmed,
      isStudent,
      onCancelAppointment,
      ctaButtonType,
    } = this.props
    const { startTime, endTime } = appointment

    let Warning
    if (tutorConfirmed && isStudent) {
      const apppointmentTime = DateTime.fromISO(startTime)

      const { minutes } = apppointmentTime
        .diff(DateTime.local(), 'minutes')
        .toObject()

      if (minutes < 90) {
        Warning = LessThanHourAndHalf
      } else if (minutes < 24 * 60) {
        Warning = LessThan24Hours
      }
    }
    const linkAttributes = 'target="_blank" rel="noopener"'

    return (
      <React.Fragment>
        <p>{t('Do you really want to cancel this appointment?')}</p>
        <SC.LiveLesson>
          <LiveLesson
            languageCode={appointment.languageCode || appointment.language.code}
            start={startTime}
            finish={endTime}
            {...appointment}
          />
        </SC.LiveLesson>
        {Warning && <Warning />}
        <form>
          <p style={{ marginBottom: '16px' }}>
            <label htmlFor="reason">
              {t(
                "To help us make the system better, could you let us know why you can't make this time?"
              )}
            </label>
            <textarea
              rows="4"
              placeholder={t("I can't make it because...")}
              className="form-control"
              value={this.state.reason}
              onChange={this.handleChange}
            />
          </p>
        </form>

        <ButtonBar>
          <Button
            type={ctaButtonType || 'cta'}
            disableWith={t('Canceling...')}
            onClick={() => onCancelAppointment(this.state.reason, appointment)}
          >
            {t('Really Cancel')}
          </Button>
          <Markdown>
            {t(
              '*Important*: You cannot undo this cancellation. Before you cancel your upcoming Live Lesson, please inform yourself about our cancellation policy and charges <a href="https://chatterbug.com/community/t/what-happens-if-i-cancel-or-miss-a-live-lesson/9477" %{linkAttributes}>here.</a>',
              linkAttributes
            )}
          </Markdown>
        </ButtonBar>
      </React.Fragment>
    )
  }
}

const SC = {}
SC.LiveLesson = styled.div`
  font-weight: bold;
  margin: 10px;
  margin-bottom: 20px;
`

Cancel.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  }).isRequired,
  isStudent: PropTypes.bool.isRequired,
  tutorConfirmed: PropTypes.bool.isRequired,
  onCancelAppointment: PropTypes.func.isRequired,
  ctaButtonType: PropTypes.string,
}

export default Cancel
