import _ from 'lodash'

import { post } from 'lib/util'
import ParentScrollPreventer from 'modules/parent-scroll-preventer'

export default class WordSearch {
  constructor(element) {
    this.element = element
    const selector = WordSearch.selector
    this.emptyClass = `${WordSearch.className}_empty`
    this.loadingClass = `${WordSearch.className}_loading`
    this.formEl = element.querySelector(`${selector}__form`)
    this.resultsEl = element.querySelector(`${selector}__results`)

    this.formEl.addEventListener('submit', this.handleFormSubmit.bind(this))

    // eslint-disable-next-line
    new ParentScrollPreventer(element, {
      childSelector: `${selector}__results-body`,
    })

    this.render({})
  }

  serializeForm(form) {
    let obj = _.keyBy($(form).serializeArray(), 'name')
    for (var key in obj) obj[key] = obj[key].value
    return obj
  }

  handleResults(resultHTML) {
    // TODO: could fire off an event here
    this.render({ resultHTML })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let form = e.target
    let formData = this.serializeForm(form)
    if (formData.search.trim()) {
      this.render({ loading: true })
      post(form.action, formData).then(({ data }) => this.handleResults(data))
    } else {
      this.render({})
    }
  }

  render(state) {
    let { resultHTML, loading } = state

    if (loading) {
      this.element.classList.add(this.loadingClass)
      this.element.classList.remove(this.emptyClass)
      this.resultsEl.innerHTML = LoadingHTML
    } else if (resultHTML) {
      this.element.classList.remove(this.loadingClass)
      this.element.classList.remove(this.emptyClass)
      this.resultsEl.innerHTML = resultHTML
    } else {
      this.element.classList.add(this.emptyClass)
      this.resultsEl.innerHTML = ''
    }
  }
}
WordSearch.className = 'word-search'
WordSearch.selector = `.${WordSearch.className}`

const LoadingHTML = `<div class="flex flex_column flex_align-center flex_justify-center flex_justify-center">
  <div class="loader_spinner"></div>
</div>`
