import * as React from 'react'
import * as ReactDOM from 'react-dom'
import LRU from 'lru-cache'

const componentCache = new LRU<string, React.ComponentClass>({ max: 256 })
function classNameToCtor(className: string) {
  if (!componentCache.has(className)) {
    const wnd: any = window
    const klass = wnd.ReactRailsUJS.getConstructor(className)

    if (!klass) {
      throw new Error(`React Component ${className} not found`)
    }

    componentCache.set(className, klass)
  }

  return componentCache.get(className)
}

export class ReactElementHost<TComponentProps = any> extends HTMLElement {
  reactComponentClass: React.ComponentClass<TComponentProps>
  props: TComponentProps
  connected = false

  static create<TComponent extends React.ComponentClass<TProps>, TProps = any>(
    component: TComponent,
    props?: TProps
  ): ReactElementHost<TProps> {
    const ret = document.createElement('react-element') as ReactElementHost<
      TProps
    >

    ret.reactComponentClass = component
    ret.setProps(props)
    return ret
  }

  // Custom Elements callbacks

  static get observedAttributes() {
    return ['component', 'props']
  }

  connectedCallback() {
    this.connected = true
    this.render()
  }

  disconnectedCallback() {
    this.unmountIfMounted()
    this.connected = false
  }

  attributeChangedCallback(attrName: string, _oldVal: string, newVal: string) {
    if (attrName === 'component') {
      this.unmountIfMounted()
      this.reactComponentClass = classNameToCtor(
        newVal
      ) as React.ComponentClass<TComponentProps>

      this.render()
    }

    if (attrName === 'props') {
      this.props = JSON.parse(newVal ?? '')

      if (!this.reactComponentClass) return

      this.render()
    }
  }

  // Our own regular methods

  setProps(newProps: Partial<TComponentProps>) {
    const IAmBadAtTypescript: any = this
    IAmBadAtTypescript['props'] = IAmBadAtTypescript['props'] ?? {}

    // NB: It is more Technically Correct™ to call setAttribute here but
    // that costs a lot, so we'll let it go
    Object.assign(this.props, newProps)
    this.render()
  }

  private unmountIfMounted() {
    if (!this.reactComponentClass) {
      return
    }

    ReactDOM.unmountComponentAtNode(this)
    this.reactComponentClass = null
  }

  private render() {
    // NB: When the browser first sets this up, it could call
    // us back in any order, so if they call 'props', 'component',
    // just do nothing and we'll set it up later
    if (!this.reactComponentClass || !this.connected) {
      return
    }

    ReactDOM.render(
      React.createElement(this.reactComponentClass, this.props),
      this
    )
  }
}

// NB: We wrap this in an 'if' so that we don't break HMR
if ('customElements' in window && !window.customElements.get('react-element')) {
  window.customElements.define('react-element', ReactElementHost)
}
