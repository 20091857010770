import { renderTemplate } from 'lib/util'

// All this does is fade out the old text and fade in the new. Does not deal
// with button width, so make the button wide enough to support all messages.
//
// <button type="button" class="button button_cta text-rotate-button"></button>
//
// render() accepts: {
//   buttonText: string,
//   disabled: bool
// }

const TransitionTemplate = `
  <span class="{oldTextClass}">{oldText}</span>
  <span class="{newTextClass}">{newText}</span>
`

export default class TextRotateButton {
  constructor(element, options) {
    this.hiddenTextClass = `${TextRotateButton.class}__hidden-text`
    this.oldTextClass = `${TextRotateButton.class}__old-text`
    this.newTextClass = `${TextRotateButton.class}__new-text`
    this.hideClass = `${TextRotateButton.class}_hide-text`
    this.showClass = `${TextRotateButton.class}_show-text`

    this.options = options || {}
    this.element = element
    $(element).on('click', this.options.onClick)
  }

  // do all dom updates in here
  render(state, prevState) {
    let element = this.element
    let textChanged = !prevState || prevState.buttonText !== state.buttonText
    let disabledChanged = !prevState || prevState.disabled !== state.disabled

    if (textChanged) {
      let buttonText = state.buttonText

      element.innerHTML = renderTemplate(TransitionTemplate, {
        oldTextClass: this.oldTextClass,
        newTextClass: this.newTextClass,
        oldText: element.textContent.trim(),
        newText: buttonText,
      })

      let oldTextElement = element.querySelector('.' + this.oldTextClass)
      let newTextElement = element.querySelector('.' + this.newTextClass)

      // Need to add the classes out of band or there will be no animation...
      window.setTimeout(() => {
        window.setTimeout(() => {
          // animation is over
          element.textContent = buttonText
        }, TextRotateButton.animationDuration)

        // start the animation
        newTextElement.classList.add(this.showClass)
        oldTextElement.classList.add(this.hideClass)
      }, 0)
    }

    if (disabledChanged) {
      element.disabled = state.disabled
    }

    element.blur()
  }
}
TextRotateButton.class = 'text-rotate-button'
TextRotateButton.selector = '.text-rotate-button'
TextRotateButton.animationDuration = 300
