// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const radii = {
 "full": "100px",
 "1,5x": "24px",
 "1x": "16px",
 "0,5x": "8px",
 "none": "0px"
}

export default radii;