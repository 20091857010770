import _ from 'lodash'

// $('.rating_stars').ratingStars()
// $('.rating_emojis').ratingEmojis()

class Rating {
  constructor(element, options) {
    this.options = options || {}

    // eslint-disable-next-line
    ;(this.ratingSelector = options.ratingSelector || '.implement_it'),
      (this.descriptionSelector = '.rating__description'),
      (this.ratingValueSelector = '.rating__value_'),
      (this.onClass = 'rating__on'),
      (this.selectedClass = 'rating__selected'),
      (this.$el = $(element))
    this.input = this.$el.find('input')
    this.ratings = this.$el.find(this.ratingSelector)

    this.ratings.hover(
      (e) => {
        // mouse in
        let rating = $(e.target).closest(this.ratingSelector)
        let value = parseInt(rating.attr('ref'))
        this.highlightValue(value)
      },
      (e) => {
        // mouse out, reset to selection
        let selected = this.getSelectedRating()
        let value = parseInt(selected.attr('ref'))
        this.highlightValue(value)
      }
    )

    this.ratings.click(this.handleClick.bind(this))
  }

  reset() {
    this.selectValue(null)
  }

  getSelectedRating() {
    return this.$el.find('.' + this.selectedClass)
  }

  getRating(value) {
    return this.$el.find(`${this.ratingSelector}[ref="${value}"]`)
  }

  getRatingDescription(value) {
    return this.$el.find(this.ratingValueSelector + value)
  }

  handleClick(e) {
    let rating = $(e.target).closest(this.ratingSelector)
    let value = parseInt(rating.attr('ref'))
    this.selectValue(value)
    if (_.isFunction(this.options.onClick)) {
      this.options.onClick.call(this, value)
    }
    return false
  }
}

// Stars

$.fn.ratingStars = function(options) {
  return _.map(this, function(element) {
    element.rating = new RatingStars(element, options)
    return element.rating
  })
}

export class RatingStars extends Rating {
  constructor(element, options) {
    super(element, _.assign({ ratingSelector: '.rating__star' }, options))
  }

  highlightValue(value) {
    this.ratings.removeClass(this.onClass)
    this.$el.find(`${this.descriptionSelector} > p`).hide()
    for (let i = value; i > 0; i--) {
      this.getRating(i).addClass(this.onClass)
    }
    this.getRatingDescription(value).show()
  }

  selectValue(value) {
    this.input.val(value)
    this.ratings.removeClass(this.selectedClass)
    this.getRating(value).addClass(this.selectedClass)
    this.highlightValue(value)
  }
}

// Emojis

$.fn.ratingEmojis = function(options) {
  return _.map(this, function(element) {
    element.rating = new RatingEmojis(element, options)
    return element.rating
  })
}

export class RatingEmojis extends Rating {
  constructor(element, options) {
    super(element, _.assign({ ratingSelector: '.rating__emoji' }, options))
  }

  highlightValue(value) {
    this.ratings.removeClass(this.onClass)
    this.$el.find(`${this.descriptionSelector} > p`).hide()
    this.getRating(value).addClass(this.onClass)
    this.getRatingDescription(value).show()
  }

  selectValue(value) {
    this.input.val(value)
    this.ratings.removeClass(this.selectedClass)
    this.getRating(value).addClass(this.selectedClass)
    this.highlightValue(value)
  }
}
