import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chatterbug/aaron'

const TYPES = {
  info: {
    color: 'blue42',
    backgroundColor: 'blue90',
  },
  error: {
    color: 'ladybug4',
    backgroundColor: 'ladybug1',
  },
}

const ANIM_DURATION = 500
const VISIBLE_DURATION = 1000
const KEYFRAMES_APPEARANCE = [
  {
    transform: 'translateY(-16px)',
    opacity: 0,
  },
  {
    transform: 'translateY(0)',
    opacity: 1,
  },
  {
    transform: 'translateY(4px)',
    opacity: 1,
  },
  {
    transform: 'translateY(0)',
    opacity: 1,
  },
]
const KEYFRAMES_DISAPPEARANCE = [
  {
    opacity: 1,
  },
  {
    opacity: 0.7,
  },
  {
    opacity: 0.3,
  },
  {
    opacity: 0,
  },
]

const OPTIONS = {
  duration: ANIM_DURATION,
  easing: 'ease',
  fill: 'forwards',
}

const clearTimer = (timer) => {
  if (timer) {
    clearTimeout(timer)
  }
}

const Snackbar = ({
  show,
  animated,
  message,
  link,
  type,
  keepVisible,
  onHide,
}) => {
  const snackbarRef = useRef()
  const timer = useRef(null)
  const animationEndedTimer = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!show) {
      return
    }

    setVisible(true)

    if (animated) {
      snackbarRef?.current?.animate?.(KEYFRAMES_APPEARANCE, OPTIONS)

      clearTimer(timer.current)

      if (!keepVisible) {
        timer.current = setTimeout(() => {
          snackbarRef?.current?.animate?.(KEYFRAMES_DISAPPEARANCE, {
            ...OPTIONS,
          })

          animationEndedTimer.current = setTimeout(() => {
            onHide?.()
            setVisible(false)
          }, ANIM_DURATION)
        }, ANIM_DURATION + VISIBLE_DURATION)
      }
    }

    return () => {
      clearTimer(timer.current)
      clearTimer(animationEndedTimer.current)
    }
  }, [show, animated, setVisible, onHide, keepVisible])

  return (
    <Box
      sx={{
        position: animated ? 'sticky' : 'relative',
        top: '3x',
        left: 0,
        width: '100%',
        zIndex: 1000,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={snackbarRef}
          alignItems="center"
          bg={TYPES[type]?.backgroundColor}
          p="2x"
          color={TYPES[type]?.color}
          sx={{
            display: visible || !animated ? 'block' : 'none',
            position: animated ? 'absolute' : 'relative',
            top: 0,
            left: 0,
            width: '100%',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: TYPES[type]?.color,
            borderRadius: '0,5x',
            transition: 'opacity 0.25s ease',
            opacity: visible || !animated ? 1 : 0,
            lineHeight: 1,
          }}
        >
          {message}
          {link && (
            <Box
              as="a"
              href={link.url}
              ml="1x"
              sx={{
                color: TYPES[type]?.color,
                textDecoration: 'underline',
                ':hover, :focus': {
                  textDecoration: 'underline',
                  color: TYPES[type]?.color,
                },
              }}
            >
              {link.label}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

Snackbar.propTypes = {
  show: PropTypes.bool.isRequired,
  animated: PropTypes.bool.isRequired,
  message: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  type: PropTypes.oneOf(['info', 'error']),
  keepVisible: PropTypes.bool,
  onHide: PropTypes.func,
}

export default Snackbar
