import React from 'react'
import { ReactElementHost } from 'src/lib/react-host'

import { classString, translate } from 'src/lib/util'

export interface PageAlertProps {
  content: JSX.Element
  type?: string
  fixed?: boolean
  overlay?: boolean
  icon?: string
  onClose?: () => void
}

class PageAlert extends React.PureComponent<PageAlertProps> {
  static className = 'page-alert'
  static aside: HTMLElement | undefined
  static element: ReactElementHost<PageAlertProps>
  static _lastAlertShown: PageAlert

  static showAlert(options: PageAlertProps) {
    PageAlert.removeAlert()

    if (!PageAlert.aside) {
      PageAlert.aside = document.createElement('aside')
    }

    // NB: The legacy unit tests love to remove this element from the DOM
    // somehow, so we have to add it back
    if (!PageAlert.aside.isConnected) {
      document.body.appendChild(PageAlert.aside)
    }

    if (PageAlert.element) {
      PageAlert.element.setProps(options)
    } else {
      PageAlert.element = ReactElementHost.create(PageAlert, options)
      PageAlert.aside.appendChild(PageAlert.element)
    }

    return {
      close: () => PageAlert._lastAlertShown?.close(),
    }
  }

  constructor(props: any) {
    super(props)

    // NB: This is horrible but I don't know how else to do this
    PageAlert._lastAlertShown = this
  }

  static showSuccess(content: JSX.Element) {
    PageAlert.showAlert({
      type: 'success',
      fixed: true,
      content,
    })
  }

  static showDanger(content: JSX.Element) {
    PageAlert.showAlert({
      type: 'danger',
      fixed: true,
      content,
    })
  }

  static showInfo(content: JSX.Element) {
    PageAlert.showAlert({
      type: 'info',
      fixed: true,
      content,
    })
  }

  static removeAlert() {
    this._lastAlertShown = null

    if (PageAlert.element) {
      PageAlert.aside.removeChild(PageAlert.element)
      PageAlert.element = null
    }
  }

  close() {
    if (this.props.onClose) this.props.onClose()
    PageAlert.removeAlert()
  }

  render() {
    const { type, fixed, icon, overlay, content } = this.props

    let alertClasses = classString([
      PageAlert.className,
      'alert',
      `alert-${type ?? 'info'}`,
      fixed && `${PageAlert.className}_fixed`,
      icon && `${PageAlert.className}_has-icon`,
      'alert-dismissible',
      'fade',
      'in',
    ])

    return (
      <div
        className={classString([
          `${PageAlert.className}_container`,
          overlay && `${PageAlert.className}_overlay`,
        ])}
      >
        <div className={alertClasses} role="alert">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.close.bind(this)}
          >
            <span aria-hidden="true">{'×'}</span>
          </button>
          {icon && <div className={`activity-icon activity-icon_${icon}`} />}
          <div className={`${PageAlert.className}__content`}>
            {typeof content === 'string' ? translate(content) : content}
          </div>
        </div>
      </div>
    )
  }
}

export default PageAlert
