import _ from 'lodash'

// Renders the little step indicators in onboarding
//
// <div class="carousel-steps">
//   <span rel="0" class="carousel-steps__step carousel-steps__active-step"></span>
//   <span rel="1" class="carousel-steps__step"></span>
//   <span rel="2" class="carousel-steps__step"></span>
//   <span class="carousel-steps__message"></span>
// </div>
//
// render() accepts: {
//   stepIndex: 0,
//   visited: {0: true, ...}
// }

export default class CarouselSteps {
  constructor(element, options) {
    this.stepSelector = `${CarouselSteps.selector}__step`
    this.messageSelector = `${CarouselSteps.selector}__message`
    this.activeClass = `${CarouselSteps.class}__step_active`
    this.visitedClass = `${CarouselSteps.class}__step_visited`

    this.options = options || {}
    this.element = element
    this.steps = element.querySelectorAll(this.stepSelector)
    this.message = element.querySelector(this.messageSelector)

    $(element).on('click', this.stepSelector, this.handleStepClick.bind(this))
  }

  handleStepClick(e) {
    let index = parseInt(e.target.getAttribute('rel'))
    if (!_.isFunction(this.options.onClickStep)) return
    if (this.hasVisitedIndex(index) && !this.isActiveIndex(index)) {
      this.options.onClickStep(index)
    }
  }

  isActiveIndex(index) {
    return this.state && this.state.stepIndex === index
  }

  hasVisitedIndex(index) {
    return (
      (this.state && this.state.stepIndex === index) ||
      (this.state.visited && this.state.visited[index])
    )
  }

  // do all dom updates in here
  render(state) {
    // This is a little dirty; need it to allow clicks on only visited steps
    this.state = state
    let { stepIndex, visited } = state

    let current = this.element.querySelector('.' + this.activeClass)
    if (current) current.classList.remove(this.activeClass)
    this.steps[stepIndex].classList.add(this.activeClass)

    _.each(this.steps, (step, i) =>
      visited[i] ? null : step.classList.remove(this.visitedClass)
    )
    // eslint-disable-next-line
    for (let index in visited) {
      this.steps[stepIndex].classList.add(this.visitedClass)
    }

    this.message.textContent = `step ${stepIndex + 1} of ${this.steps.length}`
  }
}

CarouselSteps.class = 'carousel-steps'
CarouselSteps.selector = `.${CarouselSteps.class}`
