/* eslint-disable camelcase */
export default class AudioTrigger {
  static connectAudio() {
    $('.audio__trigger')
      .off()
      .on('click', AudioTrigger.toggleAudio)
  }

  // returns true if audio triggered, false otherwise
  static toggleAudio(ev) {
    let triggerEl = $(this)
    let audioEl

    let audioElId = triggerEl.data('play-id')
    if (audioElId !== '') {
      audioEl = document.getElementById(audioElId)
    } else {
      audioEl = triggerEl
        .parent()
        .find('audio')
        .get(0)
    }
    if (!audioEl) return false

    var play_cb = ev && ev.onplay ? ev.onplay : null
    var end_cb = ev && ev.onend ? ev.onend : null

    audioEl.addEventListener('play', () => {
      triggerEl
        .find('.fa')
        .removeClass('fa-volume-up')
        .addClass('fa-pause')
      if (play_cb) play_cb.call(audioEl)
    })

    audioEl.addEventListener('ended', () => {
      triggerEl
        .find('.fa')
        .removeClass('fa-pause')
        .addClass('fa-volume-up')
      if (end_cb) end_cb.call(audioEl)
    })

    let isAudioTriggered = audioEl.paused

    try {
      const audioPromise = isAudioTriggered ? audioEl.play() : audioEl.pause()

      // https://developers.google.com/web/updates/2016/03/play-returns-promise
      if (audioPromise) {
        audioPromise.catch((error) => {
          console.log(error)
          if (end_cb) end_cb.call(audioEl)
        })
      }
    } catch (error) {
      console.log(error)
      isAudioTriggered = false
    }

    return isAudioTriggered
  }
}

// This has to work on both turbolinks and non-turbolinks pages
$(document).ready(AudioTrigger.connectAudio)
document.addEventListener('turbolinks:load', AudioTrigger.connectAudio)
