// The `System UI Theme Specification` is a general purpose format for storing a
// design systems style and scales.
// https://system-ui.com/theme/ | https://styled-system.com/table

import space from './tokens/spacing'
import shadows from './tokens/shadows'
import mediaQueries from './tokens/mediaQueries'
import lineHeights from './tokens/lineHeights'
import borderWidths from './tokens/borderWidths'
import fontFamilies from './tokens/fontFamilies'
import fontWeights from './tokens/fontWeights'
import letterSpacings from './tokens/letterSpacings'
import radii from './tokens/radii'
import fontSizes from './tokens/fontSizes'
import colors from './tokens/colors'
import theme from './theme'

const transformFontFamilies = (fontFamilies) => {
  // need to add 'sans-serif' to exported token
  const transformedNames = {}
  Object.keys(fontFamilies).forEach((fontFamily) => {
    return (transformedNames[fontFamily] = fontFamilies[fontFamily]
      .match(/[A-Z][a-z]+/g)
      .join('-')
      .toLowerCase())
  })
  return transformedNames
}

const FONT_FAMILIES = transformFontFamilies(fontFamilies)

const BREAKPOINTS = []
Object.keys(mediaQueries).forEach((mediaQuery) => {
  BREAKPOINTS[mediaQuery] = mediaQueries[mediaQuery]
})

const TEXT_VARIANTS = {
  h1: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xxl'],
    color: colors['black0'],
  },
  h2: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xl'],
    color: colors['black0'],
  },
  h3: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: 600,
    fontSize: fontSizes['lg'],
    color: colors['black0'],
  },
  h4: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: 600,
    fontSize: fontSizes['md'],
    color: colors['black0'],
  },
  h5: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: 600,
    fontSize: fontSizes['sm'],
    lineHeight: lineHeights['s'],
    color: colors['black0'],
  },
  paragraph: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['sm'],
    color: colors['black0'],
    lineHeight: lineHeights['l'],
  },
  pageTitle: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['lg'], // this is 28px, but Figma says it should be 26px. Do we need another fontSize
    textTransform: 'capitalize',
    color: colors['black0'],
  },
  buttonTitle: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: 600,
    fontSize: fontSizes['xs'],
    textTransform: 'uppercase',
    letterSpacing: '0.06em', // needs to be updated once letterSpacings are correct
    color: colors['black0'],
  },
  subtitle: {
    // other subtitle styles are taking precedence
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['xs'],
    lineHeight: lineHeights['l'],
    color: colors['gray60'],
  },
  tinyTitle: {
    fontFamily: FONT_FAMILIES['body'],
    fontWeight: 600,
    fontSize: fontSizes['xxs'],
    color: colors['black0'],
  },
  monospace: {
    fontFamily:
      "Consolas, Monaco, 'Liberation Mono', 'Courier New', Courier, monospace",
    fontSize: fontSizes['xs'],
    fontWeight: fontWeights['regular'],
    color: colors['gray60'],
  },
}

const ACCESSIBILITY_VARIANTS = {
  hidden: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
}

export default {
  breakpoints: BREAKPOINTS,
  space: {
    ...space,
  },
  radii: {
    ...radii,
    full: '100%',
  },
  shadows: {
    ...shadows,
    darkFocusRing: '0px 0px 0px 2px rgba(0, 0, 0, 1)',
  },
  lineHeights: {
    ...lineHeights,
  },
  borderWidths: {
    ...borderWidths,
  },
  fontWeights: {
    ...fontWeights,
    semiBold: 600,
  },
  fontSizes: {
    ...fontSizes,
  },
  letterSpacings: {
    ...letterSpacings,
    wide: '0.06em',
    tight: '-0.04em',
  },
  fonts: {
    heading: 'sofia-pro-soft',
    monospace:
      "Consolas, Monaco, 'Liberation Mono', 'Courier New', Courier, monospace",
    ...FONT_FAMILIES,
  },
  colors: {
    ...colors,
    //Below are older color names (mapped to the new color values), for compatibility
    //with the our Aaron components in the following repo https://github.com/chatterbugapp/aaron
    //Once the Aaron repo has been updated to use the new color names
    //the values below can be removed.
    //*DO NOT USE THE COLOR NAMES BELOW*:
    //They are deprecated; only use the color names from `colors.js`
    pink40: 'rgba(230, 28, 84, 1)', //pink51 in colors.js
    pink10: '#FCC0D1',
    white1: 'rgba(255, 255, 255, 1)', //white100 in colors.js
    red4: 'rgba(204, 20, 33, 1)', //red44 in colors.js
    red5: 'rgba(102, 10, 17, 1)', //red22 in colors.js
    black1: 'rgba(48, 48, 48, 1)', //black19 in colors.js
    gray5: 'rgba(154, 155, 157, 1)', //gray60 in colors.js
    blue2: 'rgba(0, 111, 214, 1)', //blue42 in colors.js
    blue3: 'rgba(0, 80, 153, 1)', //blue30 in colors.js
    blue1: 'rgba(204, 232, 255, 1)', //blue90 in colors.js
  },
  text: {
    ...TEXT_VARIANTS,
  },
  a11y: {
    ...ACCESSIBILITY_VARIANTS,
  },
  buttonHeights: theme.buttonHeights, // to allow for backwards compatibility with buttons
  textStyles: theme.textStyles, // to allow Modal title to be styled in both themes
}

// TODO: Name the fontFamilies better
// TODO: remove all the 'new' stuff from theme.js so it only represents what's old
// TODO: figure out why fontWeight semiBold is getting exported as 400 when it should be 600
// TODO: can we give lineheight same naming as fontSizes: 'l' -> "lg"?
// TODO: pageTitle variant is 26px in Figma. Is this accurate or should it be 28?
