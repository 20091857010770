/* eslint-disable no-new */
import TermDisplay from 'modules/term-display'

export default class LayoutTermsAdmin {
  constructor() {
    $('select.language-selector').on('change', this.changeLanguage.bind(this))

    $('.js-tags').select2({ tags: true })

    new TermDisplay($('.l-terms')[0], { termLinkSelector: '#term-preview' })
  }

  changeLanguage(ev) {
    var lc = ev.target.options[ev.target.selectedIndex].value
    var url = ev.target.attributes['ref'].value.replace('__LC__', lc)
    window.location = url
  }
}
