/* global OneSignal */
import { post } from 'lib/util'
import Preferences from 'lib/preferences'

// Notifications.init({}) is called on each pageload to setup OneSignal and
// subscribe the user if they have things enabled (or havent chosen).

global.OneSignal = global.OneSignal || []

class Notifications {
  static init(options) {
    OneSignal.LOGGING = true
    OneSignal.push(() => {
      Preferences.instance().then((preferences) => {
        Notifications._instance = new Notifications(options, preferences)
      })
    })
  }

  static instance() {
    return Notifications._instance
  }

  constructor(options, preferences) {
    let { appId } = options
    let enabled = preferences.get('notifications.desktop_enabled')
    this.preferences = preferences
    if (preferences.get('impersonating')) {
      this.enabled = false
      return
    }
    OneSignal.init({
      appId,
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      persistNotification: false,
      notifyButton: { enable: false },
    })

    this.enabled = enabled

    if (OneSignal.isPushNotificationsSupported()) {
      if (enabled) this.enableIfPermission()
    } else {
      console.log('Push notifications are not supported, disable everything!')
    }

    OneSignal.on(
      'notificationPermissionChange',
      this.handlePermissionsChange.bind(this)
    )
  }

  isEnabled() {
    return this.enabled
  }

  handlePermissionsChange({ to }) {
    console.log('Permissions CHANGE', to)
    if (to === 'granted') this.enable()
    else this.disable()
    // else it is 'default', meaning they clicked the x
  }

  askForPermission() {
    // This will pop up the permission check. The result will end up in
    // handlePermissionsChange
    OneSignal.registerForPushNotifications()
  }

  enableIfPermission() {
    return OneSignal.getNotificationPermission().then((permission) => {
      if (permission === 'granted') return this.enable()
      else if (permission === 'default') return this.askForPermission()
    })
  }

  // Must already have permission to enable
  enable() {
    this.enabled = true
    OneSignal.setSubscription(true)
    return Promise.all([
      this.preferences.set('notifications.desktop_enabled', true),
      OneSignal.getUserId(),
    ]).then(([p, userId]) => {
      return post('/account/desktop_notifications', { userId }, 'json')
    })
  }

  disable() {
    this.enabled = false
    OneSignal.setSubscription(false)
    return this.preferences.set('notifications.desktop_enabled', false)
  }
}

export default Notifications
