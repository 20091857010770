import _ from 'lodash'
import moment from 'moment'

import { bindMethods, dataAttrs, redirectToHREF } from 'lib/util'

const JoinMinutesBeforeStart = 5
const ChatWaitURL = '/chat/wait'

// See l-exercise-replay.js for the exercise replay related things...
export default class LayoutTutorAccountSetup {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults({}, options, {
      joinMinutes: JoinMinutesBeforeStart,
      chatWaitURL: ChatWaitURL,
    })

    let { startTime } = dataAttrs(element)
    if (startTime) this.setupJoinTimer(startTime)
  }

  now() {
    return moment()
  }

  setupJoinTimer(startTime) {
    startTime = moment(startTime)
    let now = this.now()
    let notifyTime = startTime
      .clone()
      .subtract(this.options.joinMinutes, 'minutes')

    let notifyDelta = Math.max(0, notifyTime.diff(now))
    let startDelta = Math.max(0, startTime.diff(now))

    window.setTimeout(this.showJoinNotification, notifyDelta)
    window.setTimeout(this.redirectToChatWait, startDelta)
  }

  showJoinNotification() {
    this.element.classList.add(LayoutTutorAccountSetup.joinNowClass)
  }

  redirectToChatWait() {
    redirectToHREF(ChatWaitURL)
  }
}

LayoutTutorAccountSetup.className = 'l-tutor-account-setup'
LayoutTutorAccountSetup.selector = `.${LayoutTutorAccountSetup.className}`
LayoutTutorAccountSetup.joinNowClass = `${LayoutTutorAccountSetup.className}_join-now`
