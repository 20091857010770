import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

import { sentry } from '../config/globals'

export { default as gql } from 'graphql-tag'
export { Query, Mutation, Subscription } from 'react-apollo'

let APOLLO_CLIENT: ApolloClient<NormalizedCacheObject> | undefined

const setupClient = () => {
  APOLLO_CLIENT = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors && graphQLErrors.length > 0) {
          console.error('[GraphQL errors]', graphQLErrors)
          sentry.captureMessage(JSON.stringify(graphQLErrors))
        }
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({ uri: '/api/graphql', credentials: 'same-origin' }),
    ]),
    cache: new InMemoryCache(),
  })

  return APOLLO_CLIENT
}

/**
 * @deprecated
 * Use the `useApolloClient` hook instead
 * https://www.apollographql.com/docs/react/api/react-hooks/#useapolloclient
 */
export const getClient = () => {
  return APOLLO_CLIENT || setupClient()
}
