import _ from 'lodash'

import Module from 'lib/module'
import { on } from 'lib/util'

// Options:
// {
//   videoPublisher: Telco.Tokbox.publisher
// }
export default class VideoActions extends Module {
  constructor(element, options) {
    super(
      element,
      _.defaults({}, options, {
        videoPublisher: null,
      })
    )
    this.elements = this.findElements()

    on(
      this.element,
      'click',
      VideoActions.videoToggleSelector,
      this.handleVideoToggle
    )

    // There is no way to ask the publisher if it is publishing data. So this var
    this.isPublishingVideo = true
    this.updateUI()
  }

  handleVideoToggle(e) {
    e.preventDefault()
    this.isPublishingVideo = !this.isPublishingVideo
    this.options.videoPublisher.publishVideo(this.isPublishingVideo)
    this.updateUI()
  }

  updateUI() {
    let { videoToggle } = this.elements
    let fn = this.isPublishingVideo ? 'add' : 'remove'
    videoToggle.classList[fn](VideoActions.publishingClass)
  }
}

VideoActions.className = 'video-actions'
VideoActions.publishingClass = `${VideoActions.className}__video-toggle_publishing`
VideoActions.videoToggleSelector = `.${VideoActions.className}__video-toggle`
