import React, { useState } from 'react'
import { Image, Flex, Box, Button, Grid } from '@chatterbug/aaron'
import sadUnicorn from './assets/sadUnicorn.svg'
import musicUnicorn from './assets/musicUnicorn.svg'
import { t } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
//TODOS: move ModalPortalWrapper to its own folder in UI
import ModalPortalWrapper from 'src/ui/MainMenu/subcomponents/ModalPortalWrapper/ModalPortalWrapper'
import GiftVoucherSkeleton from './GiftVoucherSkeleton'
import { customModalStyle } from '../utils'

type GiftVoucherModalProps = {
  isSuccess?: boolean
  onHide?: () => void
  isOpen?: boolean
  liveLessonCount?: number
  voucherRedeemUrl: string
  isEligibleForLiveLessons?: boolean
}

const COPY = {
  newUser: {
    body: t(
      "Before booking your first Live Lesson you'll need to do some self-study to get the basics."
    ),
    button: t('Start Studying!'),
  },
  currentUser: {
    body: t('Get started by scheduling your first Live Lesson.'),
    button: t('Schedule a Live Lesson'),
  },
}

// TODOS: fix close x mark (override default with the one from figma style)
const GiftVoucherModal: React.FC<GiftVoucherModalProps> = ({
  isSuccess,
  onHide,
  isOpen,
  liveLessonCount,
  voucherRedeemUrl,
  isEligibleForLiveLessons,
}) => {
  const [open, setOpen] = useState(isOpen)

  const clearModalCookie = () => {
    //We use a cookie to temporarily store that a user successfully redeemed
    //or failed to redeem a gift voucher. Here we clear that cookies so that
    //it won't be shown anymore for this session.
    document.cookie =
      'show_gift_voucher_redemption_modal=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
  }

  const handleClose = () => {
    setOpen(false)
    clearModalCookie()
    onHide?.()
  }

  const handleSuccessClick = () => {
    handleClose()
    if (isEligibleForLiveLessons) {
      window.location.assign(routes.schedule_index())
    }
  }

  return (
    <ModalPortalWrapper
      isOpen={!!open}
      onRequestClose={handleClose}
      style={customModalStyle}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        p="3x"
      >
        {isSuccess ? (
          <GiftVoucherSkeleton
            imageSection={<Image src={musicUnicorn} my="4x" alt="" />}
            headingSection={t('Your Voucher is now active!')}
            bodySection={
              <Box variant="text.paragraph" data-testid="voucher-success-text">
                {liveLessonCount
                  ? t('You have %{count} Live Lessons available.', {
                      count: liveLessonCount,
                    })
                  : null}
                <br />
                {isEligibleForLiveLessons
                  ? COPY.currentUser.body
                  : COPY.newUser.body}
              </Box>
            }
            buttonSection={
              <Button
                onClick={handleSuccessClick}
                type="primaryAdvanced"
                size="large"
                data-testid="schedule-LL"
              >
                {isEligibleForLiveLessons
                  ? COPY.currentUser.button
                  : COPY.newUser.button}
              </Button>
            }
          />
        ) : (
          <GiftVoucherSkeleton
            imageSection={<Image src={sadUnicorn} my="4x" alt="" />}
            headingSection={t('Your voucher was not applied.')}
            bodySection={
              <Box
                as="p"
                variant="text.paragraph"
                mb="3x"
                data-testid="voucher-failure-text"
              >
                {t(
                  'Unfortunately your voucher was not applied due to an error.'
                )}
              </Box>
            }
            buttonSection={
              <Grid gridGap="3x" columns={{ _: '1fr', tablet: 'auto auto' }}>
                <Button
                  href={voucherRedeemUrl}
                  onClick={handleClose}
                  type="primaryAdvanced"
                  size="large"
                  data-testid="voucher-try-again-button"
                >
                  {t('try again')}
                </Button>
                <Button
                  data-testid="voucher-cux-support-button"
                  onClick={() => {
                    handleClose()
                    window.Intercom?.(
                      'showNewMessage',
                      t(
                        "Hi, I've experienced an error redeeming my gift voucher."
                      )
                    )
                  }}
                  type="primaryAdvanced"
                  size="large"
                  fill="outline"
                >
                  {t('contact customer support')}
                </Button>
              </Grid>
            }
          />
        )}
      </Flex>
    </ModalPortalWrapper>
  )
}

export default GiftVoucherModal
