import React from 'react'

import LoadingBox from '../LoadingBox/LoadingBox'

const BreadcrumbsPlaceholder = () => (
  <LoadingBox width="200px" maxWidth="100%">
    &nbsp;
  </LoadingBox>
)

export default BreadcrumbsPlaceholder
