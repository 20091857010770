import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import DateUtil from 'src/lib/date-util'
import { getLocale } from 'src/config/globals'
import { renderReactComponent } from 'src/lib/util'

export default class LiveDate extends React.Component {
  static liveFormats = ['fromNow', 'fromNowSimple', 'toNow', 'toNowSimple']

  static mount(props, element) {
    return renderReactComponent(props, element, { component: LiveDate })
  }

  static mountAll(props, container) {
    const dateElements = (container || document).querySelectorAll(
      'react-element[component="LiveDate"]'
    )
    return _.each(dateElements, (dateElement) => {
      return this.mount(props, dateElement)
    })
  }

  constructor() {
    super()
    this.state = {
      momentDate: null,
    }
  }

  cacheDate(props) {
    let momentDate = null
    const { date, locale, timezone } = props

    if (date) momentDate = DateUtil.dateFromISOString(date, locale, timezone)
    this.setState({ momentDate })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { format } = this.props
    if (LiveDate.liveFormats.indexOf(format) > -1) {
      this.timeinterval = window.setInterval(() => this.forceUpdate(), 5000)
    }

    this.cacheDate(this.props)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.date !== this.props.date ||
      newProps.format !== this.props.format ||
      newProps.timezone !== this.props.timezone ||
      newProps.locale !== this.props.locale
    ) {
      this.cacheDate(newProps)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeinterval)
  }

  render() {
    const { format, title, hasTitle, hideMeridiem, spacedMeridiem } = this.props
    const { momentDate } = this.state
    if (!momentDate) return null

    const titleStr = hasTitle
      ? title || DateUtil.format(momentDate, DateUtil.FullFormat)
      : null

    let content = DateUtil.format(momentDate, format)
    if (spacedMeridiem) content = content.replace(/([ap]m)$/i, ' $1')
    if (hideMeridiem) content = content.replace(/\s*[ap]m$/i, '')

    return (
      <span className="live-date" title={titleStr} notranslate="notranslate">
        {content}
      </span>
    )
  }
}

LiveDate.defaultProps = {
  hasTitle: true,
  locale: getLocale() || window.navigator.language,
}

LiveDate.propTypes = {
  date: PropTypes.string.isRequired, // iso9681 string
  timezone: PropTypes.string, // 'America/Los_Angeles' etc.
  format: PropTypes.string.isRequired,
  locale: PropTypes.oneOf(['en', 'de', 'es', 'fr']), // 'en', 'de', etc
  title: PropTypes.string, // title override
  hasTitle: PropTypes.bool, // can have a title...
  hideMeridiem: PropTypes.bool,
  spacedMeridiem: PropTypes.bool,
}
