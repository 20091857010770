import _ from 'lodash'

import { post, isSuccessStatus, bindMethods, serializeForm } from 'lib/util'
import FormLoader from 'modules/form-loader'
import { t } from 'src/lib/i18n'

// Utilities for nice async forms
//
// * When submitting
//   * disables buttons
//   * shows a FormLoader spinner
// * Displays any errors when the request fails
//
// options: {
//   onComplete: ({data, status}) => , // called on success and failure
//   onSuccess: ({data, status}) => ,
//   onFail: ({data, status}) => ,
//   // return data to be posted to the server; default serializes the form
//   formData: (form) => serializeForm(form)
// }
//
// Expects the form action to
// * Be POST
// * Return JSON
// * All 200 responses are success
// * All 400 responses are failures
// * Failure responses return `{error: 'some failure message'}` which will be
//   shown to the user

export default class Form {
  constructor(element, options) {
    if (!element) return
    bindMethods(this)
    this.element = element
    this.options = _.defaults(options, {
      onComplete: () => {},
      onSuccess: () => {},
      onFail: () => {},
      formData: (form) => serializeForm(form),
    })

    let loader = element.querySelector(FormLoader.selector)
    if (loader) this.formLoader = new FormLoader(loader)
    this.form = this.element
    this.form.addEventListener('submit', this.handleFormSubmit)
  }

  submit() {
    return this.handleFormSubmit()
  }

  reset() {
    this.form.reset()
  }

  handleFormSubmit(e) {
    if (e) e.preventDefault()
    let { form } = this
    let { onSuccess, onFail, onComplete, formData } = this.options
    this.render({ stage: 'loading' })
    return post(form.action, formData(form), 'json').then(
      ({ data, status }) => {
        let isSuccess = isSuccessStatus(status)
        this.render({
          stage: isSuccess ? 'idle' : 'error',
          errorMessage: isSuccess ? null : data.error,
        })
        isSuccess ? onSuccess({ data, status }) : onFail({ data, status })
        onComplete({ data, status })
      }
    )
  }

  changeButtonEnablement(enabled) {
    let buttons = this.form.querySelectorAll('button')
    _.each(buttons, (b) => (b.disabled = !enabled))
  }

  render(state) {
    let { stage, errorMessage } = state
    let isLoading = stage === 'loading'
    let isError = stage === 'error'
    this.changeButtonEnablement(!isLoading)
    if (this.formLoader) {
      this.formLoader.render({
        loading: isLoading,
        messageType: 'error',
        message: isError ? errorMessage || t('Oops, there was an error') : null,
      })
    }
  }
}

Form.className = 'form'
Form.selector = `.${Form.className}`
