import Tokbox from './tokbox'
import Openvidu from './openvidu'

export let Provider, ProviderName

// Allow overriding the telco provider via the Rails environment or the URL,
// but not in the process environment, since Docker buids now compile assets
// once for all environments

const override = window.telcoProvider || window.location.hash
if (/openvidu/.test(override)) {
  Provider = new Openvidu()
  ProviderName = 'openvidu'
} else {
  Provider = new Tokbox()
  ProviderName = 'tokbox'
}

const Telco = { Provider, ProviderName }
export default Telco
