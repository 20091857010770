/* eslint-disable camelcase */
export default {
  LEADING_NOMINATIVE_ARTICLE: new RegExp('^(der|die|das) '),

  // Expand the text to allow for various properties.
  //
  // text           - the string text to expand.
  // type           - the string type. Possible values: "word" or "phrase".
  // features       - the collection of linguistic features. Includes part of speech.
  // isNative       - whether the text is in the native language of the user
  //
  // Returns an array of possible expansions. Even if no expansions were made,
  // the return will be a one-element array with the original text.
  expand: function(text, type, features, isNative) {
    let expansions = [text]

    if (
      isNative &&
      type === 'word' &&
      features['part_of_speech'] === 'noun' &&
      this.LEADING_NOMINATIVE_ARTICLE.test(text)
    ) {
      expansions.push(text.substring(4))
    }

    return expansions
  },
}
