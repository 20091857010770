/* eslint-disable no-new */
import moment from 'moment-timezone'

import { bindMethods } from 'lib/util'
import { t } from 'src/lib/i18n'
import ScheduleUpcomingAppointments from 'modules/schedule-upcoming-appointments'
import TutorGoalsModal from 'modules/tutor-goals-modal'
import OnDemandChatSession from 'modules/on-demand-chat-session'
import Calendar from 'modules/calendar'
import NewPaymentMethod from 'modules/new-payment-method'
import DateUtil from 'lib/date-util'

export default class LayoutScheduleUpcoming {
  constructor(element) {
    bindMethods(this)
    this.element = element
    this.calendarEl = element.querySelector('.calendar')

    const newPaymentEl = document.querySelector('.new-payment-method')
    // init the payment modal
    if (newPaymentEl) {
      const props = {
        paymentSource: 'additional',
        paymentMethods:
          newPaymentEl.getAttribute('data-sepa') === '1'
            ? ['card', 'iban']
            : undefined,
        stripePublishedKey: window.stripe_public_key,
        buttonText: t('Book Appointment'),
        onStripeResponse: (paymentId) => {
          window.eventTracking.track('live lesson', 'reservation')
          document.getElementById('new-source').setAttribute('value', paymentId)
          document.getElementById('appointment-book-form').submit()
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(newPaymentEl, { props })
    }

    let tutorGoalsModalEl = element.querySelector(TutorGoalsModal.selector)
    let onDemandChatSessionEl = element.querySelector(
      OnDemandChatSession.selector
    )
    let scheduleUpcomingAppointmentsEl = element.querySelector(
      ScheduleUpcomingAppointments.selector
    )
    new ScheduleUpcomingAppointments(scheduleUpcomingAppointmentsEl)
    new TutorGoalsModal(tutorGoalsModalEl)

    if (onDemandChatSessionEl) new OnDemandChatSession(onDemandChatSessionEl)

    // load the calendar 2 days from now
    let targetDate = new Date()
    targetDate.setDate(targetDate.getDate() + 2)

    this.calendar = new Calendar(this.calendarEl, {
      onClickEvent: this.handleClickEvent.bind(this),
      onEventRender: this.handleEventRender.bind(this),
      height: 800,
      selectable: false,
      selectHelper: false,
      editable: false,
      defaultDate: targetDate,
      firstDay: 1,
      events: {
        url: '/schedule/available_bookings',
      },
    })

    this.checkTimezone()
  }

  checkTimezone() {
    const element = this.element
    const serverTimezoneEl = element.querySelector(
      `${LayoutScheduleUpcoming.selector}__server-timezone`
    )
    // If the user does not have any booked lesson then this selector doesn't exist.
    if (!serverTimezoneEl) {
      return false
    }
    const browserTimezoneEl = element.querySelector(
      `${LayoutScheduleUpcoming.selector}__browser-timezone`
    )
    const timezoneWarningEl = element.querySelector(
      `${LayoutScheduleUpcoming.selector}__timezone-warning`
    )

    const serverTimezone = serverTimezoneEl.getAttribute('data-timezone')
    const timezone = moment.tz.guess()

    if (
      moment.tz(serverTimezone).format('Z') !== moment.tz(timezone).format('Z')
    ) {
      const timezoneStr = timezone.replace(/\//g, ' - ').replace(/_/g, ' ')
      browserTimezoneEl.textContent = timezoneStr

      timezoneWarningEl.classList.add(
        `${LayoutScheduleUpcoming.className}__timezone-warning_mismatch`
      )
    }
  }

  handleClickEvent(calEvent, jsEvent, view) {
    if (calEvent.time_start) {
      let start = moment(calEvent.start)
      let end = moment(calEvent.end)

      let formattedDate = `${DateUtil.format(
        start,
        'cb_ddddMMMD'
      )} ${DateUtil.format(start, 'cb_hmma')} - ${DateUtil.format(
        end,
        'cb_hmma'
      )}`
      if (calEvent.appointment_id) {
        // TODO: Remove IDs here...
        $('#booking-appt-id').val(calEvent.appointment_id)
      }
      $('#booking-time').val(calEvent.time_start)
      $('#booking-start-time').text(formattedDate)

      let tomorrow = moment().add(1, 'day')
      if (start.isBefore(tomorrow)) {
        $('#under-24h').show()
      } else {
        $('#under-24h').hide()
      }

      if (calEvent.preferred) {
        $('#booking-preferred-id').val(calEvent.preferred[0])
        $('#booking-preferred').text(calEvent.preferred[1])
        $('#preferred-block').show()
      } else {
        $('#booking-preferred-id').val(0)
        $('#preferred-block').hide()
      }

      $('#booking-modal').modal('show')
      window.eventTracking.track('live lesson', 'planning')
    }
  }

  handleEventRender(event, element) {
    if (event.event_type) {
      element.addClass(`calendar__booking_${event.event_type}`)
    }
    element.find('.fc-title').html(event.title)
  }
}

LayoutScheduleUpcoming.className = 'l-schedule-upcoming'
LayoutScheduleUpcoming.selector = `.${LayoutScheduleUpcoming.className}`
