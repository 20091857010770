import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@chatterbug/aaron'

import separatorImage from './assets/breadcrumbs-separator.svg'

const SEPARATOR_STYLES = {
  width: '24px',
  height: '24px',
  backgroundImage: `url(${separatorImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  color: 'transparent',
}

const ACTIVE_LINK_STYLES = {
  color: 'blue30',
  textDecoration: 'none',
}

const ITEM_STYLES = {
  lineHeight: '24px',
  color: 'blue42',
  textDecoration: 'none',
  ':hover': ACTIVE_LINK_STYLES,
  ':active': ACTIVE_LINK_STYLES,
  ':focus': ACTIVE_LINK_STYLES,
}

const Breadcrumbs = ({ items }) => (
  <Flex as="nav" alignItems="flex-start" flexWrap="wrap">
    {items.map((item, index) => (
      <React.Fragment key={`breadcrumb-${item.key}`}>
        {index > 0 && (
          <Box mt="0,25x" sx={SEPARATOR_STYLES}>
            {'>'}
          </Box>
        )}
        <Box as="a" href={item.link} fontSize="sm" sx={ITEM_STYLES}>
          {item.name}
        </Box>
      </React.Fragment>
    ))}
  </Flex>
)

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
}

export default Breadcrumbs
