import React from 'react'
import PropTypes from 'prop-types'

const convert = (text) => {
  const t1 = text
    .replace(/\*\*(.+)\*\*/g, '<b>$1</b>')
    .replace(/\*(.+)\*/g, '<em>$1</em>')
  return t1
}

const Markdown = ({ children }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: convert(children),
      }}
    />
  )
}

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Markdown
