import _ from 'lodash'

import Module from 'lib/module'
import ChatChannel from 'lib/actioncable/chat-channel'
import { dataAttrs, renderTemplate } from 'lib/util'
import { t } from 'src/lib/i18n'
import Tooltip from 'modules/tooltip'

export default class ConnectionQuality extends Module {
  constructor(element, options) {
    super(
      element,
      _.defaults({}, options, {
        chatChannelReplay: null,
        tooltipTemplate: 'Connection quality is {quality}',
      })
    )
    let modifiers = this.findModifiers()

    let { sessionId, userEmail } = dataAttrs(element)
    this.sessionId = sessionId
    this.userEmail = userEmail

    this.tooltip = new Tooltip(this.element, {
      html: true,
      placement: 'bottom',
    })

    if (this.options.chatChannelReplay) {
      this.chatChannel = this.options.chatChannelReplay
    } else if (this.sessionId) {
      this.chatChannel = ChatChannel.get(this.sessionId)
    }

    if (this.chatChannel) {
      this.chatChannel.on('connectionQuality', this.handleQualityEvent)
      this.chatChannel.on('presence', this.handlePresenceEvent)
    }

    for (let qualityName of Qualities) {
      if (modifiers[qualityName]) {
        this.render({ quality: Qualities.indexOf(qualityName) })
      }
    }
  }

  handleQualityEvent(data) {
    if (data.user !== this.userEmail) return
    this.render({ quality: data.quality })
  }

  handlePresenceEvent(data) {
    if (!data[this.userEmail]) {
      this.render({ quality: 0 })
    }
  }

  render(state) {
    let { quality } = state
    let { classList } = this.element
    classList.remove(...QualityClasses)
    classList.add(ConnectionQuality.getQualityClass(quality))

    this.tooltip.setContent(
      renderTemplate(this.options.tooltipTemplate, {
        quality: `<b>${_.startCase(Qualities[quality])}</b>`,
      })
    )
  }

  static getQualityClass(quality) {
    return `${ConnectionQuality.className}_${Qualities[quality]}`
  }
}

ConnectionQuality.className = 'connection-quality'
ConnectionQuality.selector = `.${ConnectionQuality.className}`

let Qualities = [t('unknown'), t('bad'), t('ok'), t('good'), t('great')]
let QualityClasses = _.map(Qualities, (q, i) =>
  ConnectionQuality.getQualityClass(i)
)
