import { bindMethods } from 'lib/util'
import NewPaymentMethod from 'modules/new-payment-method'

export default class LayoutOrgPayments {
  constructor(element) {
    bindMethods(this)
    this.element = element

    let newMethodEl = this.element.querySelector('.new-payment-method')
    if (newMethodEl) {
      const props = {
        paymentSource: 'additional',
        paymentMethods:
          newMethodEl.getAttribute('data-sepa') === '1'
            ? ['card', 'iban']
            : undefined,
        stripePublishedKey: window.stripe_public_key,
        plan: undefined,
        onStripeResponse: (paymentId) => {
          this.handleSetupTokenResponse(paymentId)
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(newMethodEl, { props })
    }

    // This is not available to people in organisations.
    const addPaymentModal = element.querySelector('#add-payment-modal')
    if (addPaymentModal) {
      const props = {
        paymentSource: 'additional',
        paymentMethods:
          addPaymentModal.getAttribute('data-sepa') === '1'
            ? ['card', 'iban']
            : undefined,
        stripePublishedKey: window.stripe_public_key,
        onStripeResponse: (paymentId) => {
          this.handleSetupTokenResponse(paymentId)
          $(addPaymentModal).modal('hide')
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(
        element.querySelector('#add-payment-modal .modal-body'),
        { props }
      )
    }

    $('.js-member-change').click(function(e) {
      e.preventDefault()
      var userRef = $(this).attr('ref')
      // show loading thing
      $.get(userRef, function(data) {
        $('#memberChangeBody').html(data)
        $('#js-remove-user').click(function() {
          $('#confirm-removal').removeClass('hidden')
        })
        $('#updateMember').click(function() {
          $(this).addClass('disabled')
        })
      })
      $('#memberChange').modal({ show: true })
    })
  }

  handleSetupClick(event) {
    event.preventDefault()
    this.setupButton.disabled = true
    this.newPaymentMethod.save().then(this.handleSetupTokenResponse)
  }

  handleSetupTokenResponse(paymentId) {
    if ($('#new-source-form').length > 0) {
      document.getElementById('new-source').setAttribute('value', paymentId)
      $('#new-source-form').submit()
    }
  }
}

LayoutOrgPayments.className = 'l-org-payments'
LayoutOrgPayments.selector = '.l-org-payments'
