import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chatterbug/aaron'

const UserIcon = ({
  onClick,
  href = '#',
  avatarUrl,
  username,
  textStyle,
  withDropdownStyles,
  sx,
}) => {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
    return false
  }

  if (onClick) {
    return (
      <Flex
        as={'button'}
        onClick={handleClick}
        padding="1x"
        {...withDropdownStyles}
        sx={{
          ...sx,
        }}
        style={{
          textDecoration: 'none',
          color: 'black',
        }}
      >
        <Box
          sx={{
            borderRadius: '1,5x',
            backgroundImage: `url(${avatarUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            minWidth: 26,
            height: 26,
            border: '1px solid black',
          }}
        />
        <Box
          {...textStyle}
          overflow={{ _: 'initial', laptop: 'hidden' }}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {username}
        </Box>
      </Flex>
    )
  }
  return (
    <Flex
      as={'a'}
      href={href}
      padding="1x"
      {...withDropdownStyles}
      sx={{
        ...sx,
      }}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <Box
        sx={{
          borderRadius: '1,5x',
          backgroundImage: `url(${avatarUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          minWidth: 26,
          height: 26,
          border: '1px solid black',
        }}
      />
      <Box
        {...textStyle}
        overflow={{ _: 'initial', laptop: 'hidden' }}
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {username}
      </Box>
    </Flex>
  )
}

UserIcon.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  avatarUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  textStyle: PropTypes.object,
  withDropdownStyles: PropTypes.object,
}

export default UserIcon
