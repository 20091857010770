// The `System UI Theme Specification` is a general purpose format for storing a
// design systems style and scales.
// https://system-ui.com/theme/ | https://styled-system.com/table

import sv from 'src/lib/scssVarsToJson'

const fonts = {
  body: sv['font-family-body'],
  heading: sv['font-family-heading'],
  monospace: sv['font-family-monospace'],
}

const fontSizes = {
  xxs: sv['font-size-body-tiny'],
  xs: sv['font-size-body-smallest'],
  sm: sv['font-size-body-smaller'],
  md: sv['font-size-body'],
  lg: sv['font-size-body-larger'],
  xl: sv['font-size-body-largest'],
  xxl: sv['font-size-heading'],
}

const fontWeights = {
  thin: sv['font-weight-thin'], // 200
  normal: sv['font-weight-normal'], // 400
  semiBold: sv['font-weight-semi-bold'], // 500
  bold: sv['font-weight-bold'], // 600
}

const space = {
  none: 0,
  xxs: sv['space-xxs'],
  xs: sv['space-xs'],
  sm: sv['space-sm'],
  md: sv['space-md'],
  lg: sv['space-lg'],
  xl: sv['space-xl'],
  xxl: sv['space-xxl'],
}

const radii = {
  none: '0px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  full: '100%',
}

const colors = {
  white: sv['white'],
  black: sv['black'],
  blacks: [sv['black-lightest'], sv['black-lighter'], sv['black-darker']],
  gray: sv['gray'],
  grays: [
    sv['gray-lighter'],
    sv['gray-darker'],
    sv['gray-darkest'],
    sv['gray-dark'],
  ],
  blue: sv['blue'],
  blues: [
    sv['blue-lightest'],
    sv['blue-lighter'],
    sv['blue-darker'],
    sv['blue-darkest'],
  ],
  cadetBlue: sv['blue-faded'],
  cadetBlues: [sv['blue-faded-lightest'], sv['blue-faded-lighter']],

  purple: sv['purple'],
  purples: [
    sv['purple-lightest'],
    sv['purple-lighter'],
    sv['purple-darker'],
    sv['purple-darkest'],
  ],
  pink: sv['pink'],
  pinks: [sv['pink-lightest'], sv['pink-lighter'], sv['pink-darker']],
  green: sv['green'],
  greens: [
    sv['green-lightest'],
    sv['green-lighter'],
    sv['green-darker'],
    sv['green-darkest'],
  ],
  yellow: sv['yellow'],
  yellows: [sv['yellow-lighter'], sv['yellow-darker'], sv['yellow-darkest']],
  red: sv['red'],
  reds: [
    sv['red-lightest'],
    sv['red-lighter'],
    sv['red-darker'],
    sv['red-darkest'],
  ],
  backgrounds: {
    red: sv['red-bg'],
    green: sv['green-bg'],
    pink: sv['pink-bg'],
    purple: sv['purple-bg'],
    blue: sv['blue-bg'],
    yellow: sv['yellow-bg'],
    yellowOpaque: sv['yellow-bg-opaque'],
  },
  illustration: {
    jaguar: ['#e0e0e0', '#c2c2c2', '#949494', '#000000'],
    dolfin: ['#e7fdff', '#c4eef8', '#73daef', '#5ecced'],
    octopus: ['#f8f5fe', '#e2d7fc', '#ccb7f5', '#ab8aea'],
    frog: ['#ecfed7', '#d8fe85', '#95ce3f', '#50b133'],
    dragonfly: ['#dafdf6', '#b2fced', '#73f7db', '#60d0ac'],
    ladybug: ['#ffe8e8', '#ffd2d2', '#feb0b0', '#fd4242'],
    baboon: ['#fceeee', '#f8d2dc', '#f3afc2', '#ea3d6d'],
    flamingo: ['#fcedf9', '#f9dbf2', '#ef8cc6', '#ec67c9'],
    bumblebee: ['#feffd9', '#fdf1b8', '#fbe971', '#f9dc4a'],
    camel: ['#fef7e6', '#f9dec4', '#f6c494', '#f19b43'],
  },
  semantic: {
    neutrals: {
      primaryText: '#000000',
      secondaryText: '#303030',
      tertiaryText: '#979797',
      backgroundWhite: '#ffffff',
      backgroundLight: '#fafafa',
      backgroundProgress: '#c4c4c4',
      itemActive: 'rgba(151, 151, 151, 0.12)',
    },
    primary: {
      primaryButton: '#3993dd',
      primaryButtonPress: '#1d6fb3',
      primaryBackgroundBlue: '#d6ecff',
    },
  },
}

const breakpoints = [sv['screen-xs'], sv['screen-sm'], sv['screen-md']]
breakpoints.sm = sv['screen-xs']
breakpoints.md = sv['screen-sm']
breakpoints.lg = sv['screen-md']

const shadows = {
  sm: '0 2px 20px 2px rgba(134, 19, 214, 0.06)',
  default: '0px 4px 8px rgba(153, 160, 172, 0.08)',
  lg: '0 2px 35px 2px rgba(134, 19, 214, 0.14)',
  active: '0px 1px 4px rgba(153, 160, 172, 0.18)',
  elevated:
    '0px 1px 2px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 16px rgba(0, 0, 0, 0.03)',
}

const borders = {
  seperator: '1px solid #DACDF6',
}

const buttonHeights = [
  sv['button-height-small'],
  sv['button-height'],
  sv['button-height-large'],
]

const text = {
  h1: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: '42px',
    color: colors.semantic.neutrals.primaryText,
  },
  h2: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: '34px',
    color: colors.semantic.neutrals.primaryText,
  },
  h3: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.bold,
    fontSize: '28px',
    color: colors.semantic.neutrals.primaryText,
  },
  h4: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.bold,
    fontSize: '20px',
    color: colors.semantic.neutrals.primaryText,
  },
  h5: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes.md,
    color: colors.semantic.neutrals.primaryText,
  },
  pageTitle: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: '26px',
    textTransform: 'capitalize',
    color: colors.semantic.neutrals.primaryText,
  },
  subtitle: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: fontSizes.md,
    textTransform: 'capitalize',
    color: colors.semantic.neutrals.tertiaryText,
    lineHeight: 1.625,
  },
  tinyTitle: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes.xs,
    color: colors.semantic.neutrals.primaryText,
  },
  buttonTitle: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.bold,
    fontSize: '13px',
    textTransform: 'uppercase',
    letterSpacing: '0.06em', // em?
    color: colors.semantic.neutrals.secondaryText,
  },
  paragraph: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: fontSizes.md,
    color: colors.semantic.neutrals.secondaryText,
    lineHeight: 1.625,
  },
  paragraphSmall: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    fontSize: fontSizes.sm,
    color: colors.semantic.neutrals.tertiaryText,
  },
}

const textStyles = {
  normal: {
    fontSize: fontSizes.md,
    color: colors.black,
  },
  heading: {
    fontSize: '32px', // not in design system
    color: colors.black,
    fontWeight: fontWeights.normal,
    fontFamily: fonts.heading,
    marginBottom: space.xxl,
  },
  subheading: {
    fontSize: fontSizes.xxl,
    color: colors.black,
    fontWeight: fontWeights.bold,
    marginBottom: space.xl,
  },
  link: {
    color: colors.blue,
    '&:hover': {
      color: colors.blues[1],
      textDecoration: 'underline',
    },
  },
  code: {
    fontFamily: fonts.monospace,
    color: colors.purple,
    fontSize: fontSizes.xs,
  },
}

const gradients = {
  purpleToBlue: `linear-gradient(to bottom right, #AA89ED, #0CCCEF)`,
}

const theme = {
  space,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  radii,
  shadows,
  breakpoints,
  borders,
  buttonHeights, // exported for button component - will refactor into `buttonStyles`
  textStyles,
  gradients,
  variants: {
    text,
  },
}

export default theme
