import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35355 5.62496C5.15829 5.4297 4.84171 5.4297 4.64645 5.62496C4.45118 5.82022 4.45118 6.13681 4.64645 6.33207L5.35355 5.62496ZM18.6464 20.3321C18.8417 20.5273 19.1583 20.5273 19.3536 20.3321C19.5488 20.1368 19.5488 19.8202 19.3536 19.625L18.6464 20.3321ZM4.64645 6.33207L18.6464 20.3321L19.3536 19.625L5.35355 5.62496L4.64645 6.33207Z"
        fill="black"
      />
      <path
        d="M19.3536 6.33207C19.5488 6.13681 19.5488 5.82022 19.3536 5.62496C19.1583 5.4297 18.8417 5.4297 18.6464 5.62496L19.3536 6.33207ZM4.64645 19.625C4.45118 19.8202 4.45118 20.1368 4.64645 20.3321C4.84171 20.5273 5.15829 20.5273 5.35355 20.3321L4.64645 19.625ZM18.6464 5.62496L4.64645 19.625L5.35355 20.3321L19.3536 6.33207L18.6464 5.62496Z"
        fill="black"
      />
    </svg>
  )
}
