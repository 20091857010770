export default {
  chatterbug: {
    cb_MMMD: 'D. MMM', // 8. Mai
    cb_MMMDYYYY: 'D. MMM YYYY', // 8. Mai 2017
    cb_MMMYYYY: 'MMM YYYY', // Mai 2017
    cb_dddMMMD: 'ddd, D. MMM', // Mo., 8. Jan
    cb_ddddMMMD: 'dddd, D. MMM', // Montag, 8. Jan
    cb_hmma: 'HH:mm', // 15:00
    cb_hmma_alt: 'h:mma', // 3:00pm
    cb_MMMDYYYYhmma: 'D. MMM YYYY HH:mm', // 8. Jan 2017 15:00
    cb_MMMDYYYYhmma_alt: 'D. MMM YYYY h:mma', // 8. Jan 2017 3:00pm
  },
}
