import _ from 'lodash'

import PageCarousel from 'modules/page-carousel'
import CarouselSteps from 'modules/carousel-steps'
import TextRotateButton from 'modules/text-rotate-button'
import FormLoader from 'modules/form-loader'

// Handles the page carousel, steps display, and the button
// Used when onboarding a tutor
export default class OnboardingController {
  constructor(element, options) {
    this.element = element
    this.options = _.defaults(options, {
      componentState: null,
      pageClasses: [],
      onClickFinish: () => {},
    })

    // must have ComponentState obj passed in
    let { componentState } = options

    this.transitionClass = `${OnboardingController.className}_in-transition`
    const carouselEl = element.querySelector(PageCarousel.selector)
    const carouselStepsEl = element.querySelector(CarouselSteps.selector)
    const nextButtonEl = element.querySelector(TextRotateButton.selector)
    const formLoaderEl = element.querySelector(FormLoader.selector)
    this.backgroundEl = element.querySelector(
      `${OnboardingController.selector}__background`
    )

    this.formLoader = new FormLoader(formLoaderEl)
    this.carousel = new PageCarousel(carouselEl, {
      pageClasses: this.options.pageClasses,
      setState: componentState.set.bind(componentState),
      initState: componentState.init.bind(componentState),
    })
    this.carouselSteps = new CarouselSteps(carouselStepsEl, {
      onClickStep: this.handleClickStep.bind(this),
    })
    this.nextButton = new TextRotateButton(nextButtonEl, {
      onClick: this.handleClickNext.bind(this),
    })

    this.componentState = componentState
    this.componentState.init({
      stepIndex: 0,
      nextText: this.carousel.getPage(0).nextButtonText(),
      visited: { 0: true },
    })
  }

  handleClickNext() {
    let { stepIndex } = this.componentState.state
    let totalSteps = this.carousel.getNumberPages()
    if (stepIndex === totalSteps - 1) {
      this.options.onClickFinish()
    } else {
      this.next()
    }
  }

  handleClickStep(index) {
    this.setStep(index)
  }

  next() {
    let { state } = this.componentState
    this.setStep(state.stepIndex + 1)
  }

  back() {
    let { state } = this.componentState
    this.setStep(state.stepIndex - 1)
  }

  setStep(stepIndex) {
    let { state } = this.componentState
    let numberPages = this.carousel.getNumberPages()
    if (
      stepIndex >= 0 &&
      stepIndex !== state.stepIndex &&
      stepIndex < numberPages
    ) {
      let page = this.carousel.getPage(stepIndex)
      let nextText = page.nextButtonText()
      let nextVisited = {}
      nextVisited[stepIndex] = true
      this.componentState.set({
        stepIndex,
        nextText,
        visited: _.assign({}, state.visited, nextVisited),
      })
    }
  }

  // Deal with the language-specific background image in the bottom left
  // Probably should be its own component
  renderLanguageBackgroundImage(state, prevState) {
    const setBackgroundLanguage = (langCode) => {
      removeClasses(this.backgroundEl, backgroundClasses)
      this.backgroundEl.classList.add(
        `background_landmark-corner-${langCode || 'generic'}`
      )
    }

    let firstSelectedLangCode = getFirstLanguage(state.selectedLanguages)
    if (
      firstSelectedLangCode !== getFirstLanguage(prevState.selectedLanguages)
    ) {
      this.element.classList.add(this.transitionClass)
      window.setTimeout(() => {
        setBackgroundLanguage(firstSelectedLangCode)
        this.element.classList.remove(this.transitionClass)
      }, 150)
    } else if (firstSelectedLangCode === null) {
      setBackgroundLanguage(null)
    }
  }

  render(state, prevState) {
    let { nextDisabled, stepIndex } = state

    // This is kind of ugly. Would be a redux selector...
    stepIndex = stepIndex || 0
    let page = this.carousel.getPage(stepIndex)
    nextDisabled = page.nextButtonDisabled(state)
    state = _.assign({}, state, { nextDisabled })

    this.formLoader.render(state, prevState)
    this.carousel.render(state, prevState)
    this.carouselSteps.render(state, prevState)
    this.nextButton.render(
      {
        buttonText: state.nextText,
        disabled: state.nextDisabled,
      },
      {
        buttonText: prevState.nextText,
        disabled: prevState.nextDisabled,
      }
    )

    this.renderLanguageBackgroundImage(state, prevState)
  }
}

OnboardingController.className = 'l-onboarding'
OnboardingController.selector = '.l-onboarding'

const backgroundClasses = [
  'background_landmark-corner-generic',
  'background_landmark-corner-de',
  'background_landmark-corner-en',
  'background_landmark-corner-es',
  'background_landmark-corner-fr',
]

function removeClasses(el, classes) {
  // eslint-disable-next-line
  el.classList.remove.apply(el.classList, classes)
}

function getFirstLanguage(languages) {
  if (!languages) return null
  for (var langCode in languages) {
    return langCode
  }
  return null
}
