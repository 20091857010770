import { t } from 'src/lib/i18n'

document.addEventListener('turbolinks:load', function() {
  $('#show-debug-deck').click(function(ev) {
    ev.preventDefault()
    var debugDeck = $('#debug-deck')
    if (debugDeck.length) {
      debugDeck.toggle()
      if (debugDeck.is(':visible')) {
        $('#show-debug-deck').text(t('Hide Debug Deck'))
      } else {
        $('#show-debug-deck').text(t('Show Debug Deck'))
      }
    } else {
      var deckUrl = $(this).attr('ref')
      var root = $(this).closest('.debug-deck')
      $.get(deckUrl, function(data) {
        root.append(data)
        $('#debug-deck').show()
        $('#show-debug-deck').text(t('Hide Debug Deck'))
      })
    }
    return false
  })
})
