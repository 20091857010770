import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2929 19.7929L15.9389 20.146L15.9393 20.1464L16.2929 19.7929ZM14.5 17.9953L14.854 17.6422L14.6996 17.4874L14.4811 17.4956L14.5 17.9953ZM18 16.8212L17.7149 16.4105L17.5 16.5597V16.8212H18ZM9.75 4.5H14.25V3.5H9.75V4.5ZM14.25 17.5H9.75V18.5H14.25V17.5ZM9.75 17.5C6.3152 17.5 3.5 14.6071 3.5 11H2.5C2.5 15.1248 5.72895 18.5 9.75 18.5V17.5ZM14.25 4.5C17.6848 4.5 20.5 7.39285 20.5 11H21.5C21.5 6.87516 18.271 3.5 14.25 3.5V4.5ZM9.75 3.5C5.72895 3.5 2.5 6.87516 2.5 11H3.5C3.5 7.39285 6.3152 4.5 9.75 4.5V3.5ZM17.5 19.0858C17.5 19.5312 16.9614 19.7543 16.6464 19.4393L15.9393 20.1464C16.8843 21.0914 18.5 20.4221 18.5 19.0858H17.5ZM14.4811 17.4956C14.4045 17.4985 14.3274 17.5 14.25 17.5V18.5C14.34 18.5 14.4296 18.4983 14.5189 18.4949L14.4811 17.4956ZM20.5 11C20.5 13.2612 19.3909 15.2471 17.7149 16.4105L18.2851 17.232C20.2271 15.8839 21.5 13.5928 21.5 11H20.5ZM17.5 16.8212V19.0858H18.5V16.8212H17.5ZM14.146 18.3484L15.9389 20.146L16.6469 19.4398L14.854 17.6422L14.146 18.3484Z"
        fill="black"
      />
    </svg>
  )
}
