export default {
  chatterbug: {
    cb_MMMD: 'MMM D', // Jan 8
    cb_MMMDYYYY: 'MMM D, YYYY', // Jan 8, 2017
    cb_MMMYYYY: 'MMM YYYY', // Jan 2017
    cb_dddMMMD: 'ddd, MMM D', // Mon, Jan 8
    cb_ddddMMMD: 'dddd, MMM D', // Monday, Jan 8
    cb_hmma: 'h:mma', // 3:00pm
    cb_hmma_alt: 'HH:mm', // 15:00
    cb_MMMDYYYYhmma: 'MMM D, YYYY h:mma', // Jan 8, 2017 3:00pm
    cb_MMMDYYYYhmma_alt: 'MMM D, YYYY HH:mm', // Jan 8, 2017 15:00
  },
}
