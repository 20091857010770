import React from 'react'

import { t } from 'src/lib/i18n-react'
import PageAlert from 'src/components/PageAlert'

export const ALERTS = {
  BROWSER_DOES_NOT_SUPPORT_WEBRTC: 'BROWSER_DOES_NOT_SUPPORT_WEBRTC',
  GET_USER_MEDIA_FAILED: 'GET_USER_MEDIA_FAILED',
  ANOTHER_TAB_OPEN: 'ANOTHER_TAB_OPEN',
  CHROME_MICROPHONE_ERROR: 'CHROME_MICROPHONE_ERROR',
}

export const showLessonAlert = (alert) => {
  switch (alert) {
    case ALERTS.BROWSER_DOES_NOT_SUPPORT_WEBRTC:
      renderAlert(
        t('Oh no! Your browser does not support WebRTC.'),
        <span>
          {t(
            'In order to connect your webcam and take our Live Lessons, please use the latest version of Chrome or Firefox.'
          )}
        </span>
      )
      break
    case ALERTS.GET_USER_MEDIA_FAILED:
      renderAlert(
        t('Unable to access your webcam'),
        t(
          'Please check that no other programs are using your webcam. Restarting your computer will also usually fix this problem.'
        )
      )
      break
    case ALERTS.ANOTHER_TAB_OPEN:
      renderAlert(
        t('Oops!'),
        t(
          'It looks like you may have another browser tab open to this page. Please close this tab and find the other!'
        )
      )
      break
    case ALERTS.CHROME_MICROPHONE_ERROR:
      renderAlert(
        t('Oh no! Your browser needs to be restarted.'),
        t(
          'Please quit the program and start it again to get your audio working.'
        )
      )
      break
    default:
      console.error(`Don't have an alert for: ${alert}`)
  }
}

const renderAlert = (title, body) => {
  PageAlert.showAlert({
    type: 'danger',
    icon: 'chat-session',
    content: (
      <p>
        <span className="text_bold">{title}</span>
        <br />
        <br />
        {body}
      </p>
    ),
    overlay: true,
  })
}
