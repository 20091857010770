import React from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { Box, Flex, Grid } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import CountDownItem from './CountDownItem'

export type Props = {
  endAt: Date
  onComplete: () => void
  countdownMessage?: string
}

const renderer = (props: CountdownRenderProps): JSX.Element => {
  const { days, hours, minutes, seconds } = props

  const items = [
    { label: t('DAYS'), value: days },
    { label: t('HRS'), value: hours },
    { label: t('MIN'), value: minutes },
    { label: t('SEC'), value: seconds },
  ]

  return (
    <>
      {items.map((item, index) => (
        <CountDownItem
          key={`count-down-item-${index}`}
          index={index}
          label={item.label}
          value={item.value}
        />
      ))}
    </>
  )
}

const OfferCountDown: React.FC<Props> = ({
  endAt,
  onComplete,
  countdownMessage,
}) => {
  return (
    <Grid
      px="1x"
      gridTemplateColumns={{
        _: '1fr',
        tabletWide: 'minmax(min-content,160px) auto',
      }}
      alignItems="center"
      justifyItems={{ _: 'center' }}
      borderLeftWidth={{ _: 'none', tablet: 'small' }}
      borderLeftStyle="solid"
      borderLeftColor={{
        _: 'transparent',
        tablet: 'white100',
      }}
    >
      <Box as="p" variant="text.buttonTitle" color="white100" fontSize="xxs">
        {countdownMessage || t('offer ends in')}
      </Box>
      <Flex
        minHeight={32}
        alignItems="center"
        justifyContent={{
          _: 'center',
          tablet: 'flex-end',
        }}
        borderRightWidth={1}
        borderRightStyle="solid"
        borderRightColor={{
          _: 'transparent',
          tablet: 'white100',
        }}
        sx={{
          '&:last-child': { borderRightStyle: 'none' },
        }}
      >
        <Countdown
          date={endAt}
          renderer={(props) => renderer(props)}
          onComplete={onComplete}
        />
      </Flex>
    </Grid>
  )
}

export default OfferCountDown
