import React, { useState } from 'react'
import PropTypes from 'prop-types'

import routes from 'src/lib/rails-routes/rails-routes'
import { Box, Text, Image, Flex } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import ToolTip from '../ToolTip'
import { isActiveLink } from '../../util'

import livelesson from './assets/livelesson.svg'
import livelessonActive from './assets/livelessonActive.svg'

const getPillStyle = (isCreditShort) => {
  if (isCreditShort) {
    return {
      width: 20,
      height: 20,
      borderRadius: 'full',
    }
  } else {
    return {
      width: 30,
      height: 20,
      borderRadius: '1,5x',
    }
  }
}

const ToolTipContent = ({ credits, toolTipStyle, isCreditShort }) => {
  const showCredits = credits === null ? false : true
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text sx={{ marginTop: '-2px' }}>{t('Live Lessons')}</Text>
      </Box>
      <Box
        sx={{
          position: 'relative',
          borderRadius: '1,5x',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '1x',
          marginLeft: '2x',
        }}
        {...toolTipStyle}
      >
        {showCredits && (
          <Box
            sx={{
              ...getPillStyle(isCreditShort),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bg: 'yellow65',
            }}
          >
            <Text
              sx={{
                fontSize: 12,
                color: 'black0',
                marginTop: '-2px',
              }}
            >
              {credits}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

ToolTipContent.propTypes = {
  href: PropTypes.string,
  credits: PropTypes.number,
  toolTipStyle: PropTypes.object,
  isCreditShort: PropTypes.bool,
}

const LivelessonIcon = ({
  onClick,
  credits,
  textStyle,
  selectedCourse,
  showMediumNav,
}) => {
  const [showToolTip, setShowToolTip] = useState(false)
  const href = routes.advanced_schedule(selectedCourse)
  const selected = isActiveLink(href)
  const showCredits = credits !== null && !showMediumNav ? true : false
  const isCreditShort = String(credits).length <= 2

  return (
    <Flex
      as="a"
      href={href}
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1x',
        borderRadius: '1,5x',
        backgroundColor: selected ? '#F5F5F5' : undefined,
        ':hover': {
          backgroundColor: '#F3F3F3',
        },
        cursor: 'pointer',
      }}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Image src={selected ? livelessonActive : livelesson} />
        <Text {...textStyle} sx={{ marginTop: '-2px' }}>
          {t('Live Lessons')}
        </Text>
      </Box>
      <Box
        sx={{
          position: 'relative',
          borderRadius: 'full',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '2x',
        }}
        display={{
          _: 'flex',
          tablet: 'none',
          laptop: showCredits && 'flex',
        }}
      >
        {showCredits && (
          <Box
            sx={{
              ...getPillStyle(isCreditShort),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bg: 'yellow65',
            }}
          >
            <Text
              sx={{
                fontSize: 12,

                color: 'black0',
                marginTop: '-2px',
              }}
            >
              {credits}
            </Text>
          </Box>
        )}
      </Box>
      {showToolTip && (
        <ToolTip
          containerStyle={{ display: { _: 'none', tabletWide: 'block' } }}
          popupSx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
          popupStyle={{
            marginLeft: { _: '45px', laptop: showMediumNav ? '45px' : '175px' },
            display: {
              _: 'none',
              tablet: 'flex',
              laptop: showMediumNav ? 'flex' : 'none',
            },
          }}
          onCancel={() => setShowToolTip(false)}
        >
          <ToolTipContent
            href={href}
            onClick={onClick}
            credits={credits}
            selected={selected}
            textStyle={textStyle}
            isCreditShort={isCreditShort}
            toolTipStyle={{
              display: {
                _: 'none',
                tablet: 'flex',
                laptop: showMediumNav ? 'flex' : 'none',
              },
            }}
          />
        </ToolTip>
      )}
    </Flex>
  )
}

LivelessonIcon.propTypes = {
  href: PropTypes.string,
  selectedCourse: PropTypes.string,
  onClick: PropTypes.func,
  credits: PropTypes.number,
  textStyle: PropTypes.object,
  showMediumNav: PropTypes.bool,
}

export default LivelessonIcon
