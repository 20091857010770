import Plyr from 'plyr'
import LegacyPopper from 'popper.js'

import { get, post, bindMethods, on } from 'lib/util'
import { t } from 'src/lib/i18n'

const mediaWordStudySelector = '.l-media__word-add-study'

export default class LayoutMedia {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.lookupWordUrl = decodeURI(options.lookupWordUrl || '')

    this.captionsData = null
    this.currentIndex = -1
    this.startTime = null
    this.endTime = null
    this.duration = null
    this.videoPlayer = null
    this.totalSeconds = 0
    this.currentPopper = null
    this.wordsClicked = {}
    this.adminUi = $('#admin-ui').length > 0
    this.userId = parseInt($('#user-id').val()) || null
    this.isLoggedIn = this.userId !== null
    this.videoOverlayOn = true

    // get captions
    this.captionsUrl = $('.js-player').attr('data-captions-url')
    this.mediaGid = $('.js-player').attr('data-media-gid')

    // setup start and end time clipping
    this.startTime = $('.js-player').attr('data-start-time')
    if (this.startTime) {
      this.startTime = parseFloat(this.startTime)
    }
    this.endTime = $('.js-player').attr('data-end-time')
    if (this.endTime) {
      this.endTime = parseFloat(this.endTime)
    }
    if (this.startTime && this.endTime) {
      this.duration = this.endTime
    }

    // get captions data and setup transcription table
    get(this.captionsUrl).then(({ data }) => {
      this.setupTranscript(data)
    })

    // initialize video player and setup callbacks
    let youtubeAudio = $('.js-player').attr('data-youtube-audio') === '1'
    this.videoPlayer = new Plyr('.js-player', {
      hideControls: !youtubeAudio,
      duration: this.duration,
      settings: [],
      storage: { enabled: false },
      speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
    })

    this.videoPlayer.on('timeupdate', this.handlePlayerTimeUpdate)
    this.videoPlayer.on('ended', this.handlePlayerEnd)
    // connect UI event handlers
    on(element, 'click', '#js-subtitles-cover', this.toggleCaptionCover)
    on(element, 'click', '#js-show-transcript', this.showTranscript)
    on(element, 'click', '#js-hide-transcript', this.hideTranscript)
    on(element, 'change', '#js-captions-full', (e) => {
      e.target.checked ? this.captionsFull(e) : this.captionsSingle(e)
    })
    on(element, 'change', '#js-playback-rate', this.changePlaybackRate)

    if (youtubeAudio) {
      let wrapper = $('.plyr--video')
      wrapper.addClass('plyr--audio')
      wrapper.removeClass('plyr--video')
    }

    $('body').mousedown(this.handleBodyClick)
    $('body').on('click', `${mediaWordStudySelector} a`, this.handleWordStudy)

    // setup interval to check how much they're watching the video
    window.setInterval(this.incrementTime, 1000) // check every second if they're playing it

    window.eventTracking.track('activity', 'start', 'media')
  }

  clearInfo() {
    $('#info').text('')
  }

  // check to see if we're outside of the bounds and seek/pause if so
  checkStartEnd(time) {
    if (this.videoPlayer.paused) {
      return false
    }

    if (this.startTime && this.startTime > time) {
      this.videoPlayer.currentTime = this.startTime
      $('#info').text(t('automatically skipped video to beginning of the clip'))
      window.setTimeout(this.clearInfo, 5000) // clear info in a few seconds
    }
    if (this.endTime && this.endTime < time && this.endTime > 0) {
      this.videoPlayer.currentTime = this.startTime
      this.videoPlayer.stop()
      $('#js-done').prop('disabled', false)
      $('#info').text(t('automatically stopped video at the end of the clip'))
    }
  }

  updateCaptions(index) {
    let captions = this.captionsData[index][2]
    let seconds = Math.floor(this.captionsData[index][0])

    let captionText = $('<div/>')
    captionText = this.appendLookupWords(captionText, captions)

    $('#caption').html(captionText)
    $('tr.active').removeClass('active')
    $('#ts-' + seconds)
      .closest('tr')
      .addClass('active')
    $('#ts-' + seconds)
      .focus()
      .blur()
  }

  incrementTime() {
    if (!this.videoPlayer.paused) {
      this.totalSeconds += 1
      $('#playing-time').val(this.totalSeconds)
    }
  }

  setupTranscript(json) {
    this.captionsData = json

    $('#transcript').hide()

    let table = $('<table class="transcript-table"/>')
    if (!this.captionsData) {
      return false
    }

    this.captionsData.forEach((element) => {
      let row = $('<tr/>')
      let timestamp = $('<td class="cue-time"/>')
      let sec = Math.floor(element[0])

      // I18N FIXME: seconds cannot always be represented by suffixing an 's'
      timestamp.append(
        '<a href="#" class="js-skip" id="ts-' +
          sec +
          '" data-ts="' +
          element[0] +
          '">' +
          sec +
          's</a>'
      )
      row.append(timestamp)

      let wordCell = $('<td class="cue-text"/>')
      let clickable = $('<div class="cue-text-clickable"/>')
      clickable = this.appendLookupWords(clickable, element[2])
      wordCell.append(clickable)
      row.append(wordCell)

      if (this.adminUi) {
        let editCell = $('<td class="cue-cntl show-xs"/>')
        editCell.append(
          '<a ref="' +
            element[0] +
            '" class="admin-edit js-admin-edit" href="javascript:void(0)">edit</a>'
        )
        row.append(editCell)
      }

      table.append(row)
    })
    $('#transcript').append(table)
    on(this.element, 'click', '.js-skip', this.skipTo)
    on(this.element, 'click', '.js-word', this.wordLookup)
    on(this.element, 'click', '.js-admin-edit', this.adminEdit)
    on(this.element, 'click', '.js-submit-cue', this.adminEditSubmit)
    on(this.element, 'click', '.js-cancel-cue', this.adminEditCancel)

    if (!this.isLoggedIn) {
      this.showTranscript()
    }
  }

  adminEdit(ev) {
    ev.preventDefault()
    const $target = $(ev.target)
    this.adminEditCancel(ev) // close off any other edits open
    let ts = $target.attr('ref') // get the timestamp
    let tr = $target.parent().parent() // get the enclosing row
    let cueText = tr.find('.cue-text') // get the cue cell

    let clickableText = cueText.find('.cue-text-clickable')
    clickableText.hide() // hide the existing text

    let transcript = [] // get the plain text from the clickables
    cueText.find('.js-word-section').each(function(i, section) {
      transcript.push($(this).text())
    })
    let editor = $('<textarea ref="' + ts + '" id="js-cue-text"/>')
    editor.append(transcript.join('\n')) // put together a textarea / form with that text
    cueText.append(editor)
    cueText.append(
      `<button type="submit" class="js-submit-cue button button_small">${t(
        'Update'
      )}</button>`
    )
    cueText.append(
      `<button type="submit" class="js-cancel-cue button button_small">${t(
        'Cancel'
      )}</button>`
    )
  }

  adminEditSubmit(ev) {
    ev.preventDefault()
    const $target = $(ev.target)
    let textArea = $target.parent().find('#js-cue-text')
    let clickable = $target.parent().find('.cue-text-clickable')
    let cueStart = textArea.attr('ref')
    let newCue = textArea.val()
    let url = $('#admin-edit-url').attr('ref')
    post(url, { cue: newCue, start: cueStart }).then(({ status, data }) => {
      let thisData = JSON.parse(data)
      this.replaceCaptions(thisData)
      clickable.empty()
      this.appendLookupWords(clickable, thisData[2])
    })
    this.adminEditCancel(ev)
  }

  // accepts array [start_time, end_time, cue]
  // and finds the spot in captions and updates
  replaceCaptions(data) {
    for (let index = 0; index < this.captionsData.length; ++index) {
      if (data[1] === this.captionsData[index][1]) {
        this.currentIndex = index
        this.captionsData[index] = data
        this.updateCaptions(index)
        return
      }
    }
  }

  adminEditCancel(ev) {
    ev.preventDefault()
    $('.cue-text-clickable').show()
    $('#js-cue-text').remove()
    $('.js-submit-cue').remove()
    $('.js-cancel-cue').remove()
  }

  appendLookupWords(element, textString) {
    let sections = textString.split('\n')

    let onlyPunctuation = /^[/\-,.?!@#$%^&*()_=+[\]/'";:<>|…—–~]*$/
    sections.forEach(function(section) {
      let sectionDiv = $('<div class="js-word-section">')
      let words = section.split(' ')
      words.forEach(function(word) {
        if (!word.match(onlyPunctuation)) {
          sectionDiv.append('<span class="js-word">' + word + '</span> ')
        } else {
          sectionDiv.append('<span>' + word + '</span> ')
        }
      })
      element.append(sectionDiv)
    })
    return element
  }

  wordLookup(ev) {
    ev.preventDefault()
    const $target = $(ev.target)
    const lookup = $target.text().replace(/\./g, ' ')
    let word = ev.target

    if (this.hasPopperFor(word)) {
      this.killPopper()
      return
    }

    this.videoPlayer.pause() // wait while user looks up this word

    var popperContent = this.isLoggedIn
      ? `
        <div id="popper-word">
          <div class="loader_spinner loader_dark"></div>
        </div>
      `
      : `
        <div id="popper-word">
          <a data-track-click="signup click/in-page/media" href="/signup?cb_first_media=` +
        this.mediaGid +
        `">${t('Please signup to study this word')}</div>
        </div>
      `
    this.createPopper(word, popperContent)

    if (this.isLoggedIn && lookup) {
      let url = this.lookupWordUrl.replace('{word}', encodeURIComponent(lookup))
      get(url).then(({ status, data }) => {
        if (status !== 'success') {
          return
        }

        var word = $('#popper-word')
          .html(data)
          .find('a')
          .attr('ref')
        this.addLookup(word)
      })
    }
  }

  createPopper(word, content) {
    this.killPopper()

    this.currentPopper = new LegacyPopper(
      word,
      { content: content, contentType: 'html' },
      { placement: 'bottom' }
    )

    return this.currentPopper
  }

  killPopper() {
    if (this.currentPopper != null) {
      this.currentPopper.destroy()
      $(this.currentPopper._popper).remove()
      this.currentPopper = null
    }
  }

  hasPopperFor(word) {
    return this.currentPopper != null && this.currentPopper._reference === word
  }

  handleBodyClick(ev) {
    if (
      ev.target.id === 'popper-word' ||
      $(ev.target).parents('#popper-word').length > 0 ||
      this.hasPopperFor(ev.target)
    ) {
      // we're doing stuff inside the popup, so just chillax
    } else {
      this.killPopper()
    }
  }

  handleWordStudy(ev) {
    ev.preventDefault()

    let word = ev.target.getAttribute('ref')
    let url = this.lookupWordUrl.replace('{word}', word)
    post(url, { study: 1 })

    this.addStudy(word)

    $(mediaWordStudySelector).html(`<b>${t('added')}</b>`)
    return false
  }

  addLookup(word) {
    this.wordsClicked[word] = this.wordsClicked[word] || 'look'
    this.formifyWordsClicked()
  }

  addStudy(word) {
    this.wordsClicked[word] = 'study'
    this.formifyWordsClicked()
  }

  formifyWordsClicked() {
    var formData = JSON.stringify(this.wordsClicked)
    $('#studying-words').val(formData)
  }

  skipTo(ev) {
    ev.preventDefault()
    const $target = $(ev.target)
    const seconds = parseFloat($target.attr('data-ts'))
    this.videoPlayer.currentTime = seconds
  }

  stillInCaption(time) {
    return this.inCaption(time, this.currentIndex)
  }

  inCaption(time, index) {
    let data = this.captionsData[index]
    if (!data) {
      return false
    }
    if (time >= data[0] && time <= data[1]) {
      return true
    }
    return false
  }

  handlePlayerEnd(event) {
    // show done button
    $('#js-done').prop('disabled', false)
  }

  handlePlayerTimeUpdate(event) {
    let instance = event.detail.plyr
    let time = instance.currentTime
    this.checkStartEnd(time)

    if (!this.captionsData) {
      return false
    }

    if (this.stillInCaption(time)) {
      return false
    }

    for (let index = 0; index < this.captionsData.length; ++index) {
      if (this.inCaption(time, index)) {
        this.currentIndex = index
        this.updateCaptions(index)
      }
    }
  }

  changePlaybackRate(ev) {
    ev.preventDefault()
    const $target = $(ev.target)
    let pbRate = parseFloat($target.val())
    this.videoPlayer.speed = pbRate
  }

  captionsSingle(ev) {
    ev.preventDefault()
    $('#transcript').hide()
    $('#caption').show()
  }

  captionsFull(ev) {
    ev.preventDefault()
    $('#transcript').show()
    $('#caption').hide()
  }

  toggleCaptionCover(ev) {
    if (this.videoOverlayOn) {
      $('#video_overlays').hide()
      this.videoOverlayOn = false
    } else {
      $('#video_overlays').show()
      this.videoOverlayOn = true
    }
  }

  showTranscript(ev) {
    if (ev) {
      ev.preventDefault()
    }
    $('#js-show-transcript').hide()
    $('#js-hide-transcript').show()
    $('.l-media__captions').show()
    $('.caption-controls').show()
  }

  hideTranscript(ev) {
    ev.preventDefault()
    $('#js-show-transcript').show()
    $('#js-hide-transcript').hide()
    $('.l-media__captions').hide()
  }
}
