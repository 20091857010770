import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Card } from '@chatterbug/aaron'

import Filler from './subcomponents/Filler.js'
import Tickmark from './subcomponents/Tickmark.js'

const ProgressBar = ({ complete, tickmarks, onClick, ...rest }) => {
  const el = useRef()

  complete = _.clamp(complete, 0, 1)

  return (
    <Card
      ref={el}
      my="md"
      borderRadius="lg"
      boxShadow="sm"
      css={`
        height: 8px;
        cursor: ${tickmarks ? 'pointer;' : 'auto;'}
        background-image: linear-gradient(
          135deg,
          rgba(0, 230, 194, 0.2),
          rgba(37, 204, 237, 0.2)
        );
      `}
      onClick={(e) => {
        if (el.current && onClick) {
          // calculate the horizontal percentage where the click event took place.
          onClick((e.clientX - el.current.offsetLeft) / el.current.offsetWidth)
        }
      }}
      {...rest}
    >
      <Filler complete={complete} />
      {tickmarks &&
        tickmarks.map((tickPercentage) => {
          return (
            <Tickmark
              key={`tick-percentage-${tickPercentage}`}
              position={tickPercentage}
            />
          )
        })}
    </Card>
  )
}

ProgressBar.propTypes = {
  complete: PropTypes.number.isRequired,
  tickmarks: PropTypes.array,
  onClick: PropTypes.func,
}

export default ProgressBar
