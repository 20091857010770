// A range with a pointer to the value
//
// <div class="marked-range">
//   <input class="marked-range__range" type="range" name="hours" value="2" step="0.5" min="0.5" max="24">
//   <div class="marked-range__marker"></div>
// </div>
//
// render() accepts: {
//   text: string
//   value: number
// }

export default class MarkedRange {
  constructor(element, options) {
    this.rangeSelector = MarkedRange.selector + '__range'
    this.markerSelector = MarkedRange.selector + '__marker'

    this.element = element
    this.options = options || {}

    this.marker = element.querySelector(this.markerSelector)

    this.range = element.querySelector(this.rangeSelector)
    this.range.addEventListener('input', this.handleChangeRange.bind(this))
    this.range.addEventListener('change', this.handleChangeRange.bind(this))
  }

  getValue() {
    return parseFloat(this.range.value)
  }

  setValue(value) {
    this.range.value = value
  }

  handleChangeRange() {
    this.options.onChange(this.getValue())
  }

  // do all dom updates in here
  render(state) {
    let { value, text, html } = state
    let min = parseFloat(this.range.getAttribute('min'))
    let max = parseFloat(this.range.getAttribute('max'))
    this.range.value = value
    if (html) {
      this.marker.innerHTML = html
    } else {
      this.marker.textContent = text
    }
    this.marker.style.left = `${((value - min) * 100) / (max - min)}%`
  }
}
MarkedRange.selector = '.marked-range'
