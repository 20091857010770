import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'

export type Props = {
  label: string
  value: string | number
  index: number
}

const CountDownItem: React.FC<Props> = ({ label, value, index }) => {
  const isFirst = index === 0

  let textColor = 'rgb(254, 218, 50)'

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      borderLeftWidth={1}
      borderLeftStyle="solid"
      borderLeftColor={isFirst ? 'transparent' : 'white100'}
      minWidth={60}
      ml={isFirst ? 2 : 3}
      pl={isFirst ? 2 : 3}
    >
      <Box variant="text.h4" lineHeight={1} color={textColor}>
        {value}
      </Box>
      <Box variant="text.buttonTitle" color="white100" fontSize="xxs">
        {label}
      </Box>
    </Flex>
  )
}

export default CountDownItem
