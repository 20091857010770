export default class LayoutChatCompleteRoom {
  constructor(element, options) {
    $('.rating_stars').ratingStars()

    $('#thumbs button').click(function() {
      var value = parseInt($(this).attr('ref'))
      $(this)
        .parent()
        .parent()
        .find('input')
        .val(value)
      $(this)
        .parent()
        .find('button')
        .removeClass('button_success')
        .removeClass('button_danger')
      if (value < 0) {
        $(this).addClass('button_danger')
      } else {
        $(this).addClass('button_success')
      }
    })
  }
}
