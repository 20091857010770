import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectStripe } from 'react-stripe-elements'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

const SubscribeButton = ({ stripe, elements, plan, onClick, ...others }) => {
  const { name } = plan
  return (
    <SC.Actions>
      <Button
        type="success"
        size="medium"
        onClick={(e) => {
          e.preventDefault()
          onClick(stripe, elements)
        }}
        {...others}
      >
        {t('Subscribe to the %{name} plan', { name })}
      </Button>
    </SC.Actions>
  )
}

const SC = {}
SC.Actions = styled.div`
  margin-top: 32px;
  text-align: center;
`
SC.SubscriptionCost = styled.div`
  color: #b3b3b3;
  font-size: 14px;

  b {
    color: #aa8cea;
    font-size: 18px;
  }
`

SubscribeButton.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    /* The price in cents */
    price: PropTypes.number.isRequired,
    currency: PropTypes.oneOf(['eur', 'usd', 'EUR', 'USD']).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default injectStripe(SubscribeButton)
