import _ from 'lodash'

import PageAlert from 'src/components/PageAlert'
import CardUtils from 'lib/card-utils'
import { datajsJQuery, scrollMinimallyToElement } from 'lib/util'
import AudioTrigger from 'modules/audio'
import Preferences from 'lib/preferences'
import { t } from 'src/lib/i18n'

const SHOW_HINT_TIMEOUT = 4000
const KEYDOWN_TIMEOUT = 100
const FALSE_SUBMIT_TIMEOUT = 1000
const NO_AUDIO_TIMEOUT = 7500

const TAB_CODE = 9
const ENTER_CODE = 13
const PERFECT_STRINGS = {
  es: [
    'Perfect!',
    '¡Muy bien!',
    '¡Bien hecho!',
    '¡Estupendo!',
    '¡Maravilloso!',
    '¡Perfecto!',
    '¡Chévere!',
    '¡Genial!',
  ],
  de: [
    'Perfect!',
    'Sehr gut!',
    'Richtig!',
    'Perfekt!',
    'Gut gemacht!',
    'Super!',
    'Genau richtig!',
  ],
  fr: ['Perfect!', 'Parfait!', 'Bravo!', 'Super!', 'Bien joué!', 'Magnifique!'],
}

document.addEventListener('turbolinks:load', function() {
  // FIXME: This is not ideal here. This class has both page flow logic (e.g.
  // focus the card on load, next button handling) and card handling logic
  // (answer validation). This makes it difficult to use on other pages, e.g.
  // styleguide. Should we split out the page handling logic into another file?
  var isTesting = !!$('.test-page').length
  var isOnStudyCardsPage = !!$('.l-study-cards').length
  var showAnswerHint = $('.card-input').attr('data-show-answer-hint') === 'true'

  /// / Elements

  var cardForm = $('.card form')
  var nextButton = datajsJQuery('card.next-button')
  var cardRating = datajsJQuery('card.rating')
  var cardAnswer = $('.card-input').cardInput()[0]
  var cardJudgment = $('.card-judgment').cardJudgment()[0]
  var answerInput = $('.card-input span')

  // Autofocus the input element.
  if (cardAnswer && !isTesting) {
    cardAnswer.focus()
  } else {
    nextButton.focus()
  }

  if ($('.card_answer').length === 0) return

  /// / Variables

  var pageLoadTime = new Date()
  var keyPressTimes = []
  var advanceCard = true

  /// / Functions

  // Submit the form.
  function nextQuestion() {
    cardForm.submit()
  }

  // Should the user's response be considered "instant" for the given size of
  // question?
  //
  // size - the integer character count of the question.
  //
  // Returns true if the response was instant, false otherwise.
  function wasResponseInstant(size) {
    var maxInitialDelay = 2000 + 50 * size

    var initialDelay = keyPressTimes[0] - pageLoadTime

    var totalInnerTypeDelay = 0
    for (var i = 1; i < keyPressTimes.length; i++) {
      totalInnerTypeDelay += keyPressTimes[i] - keyPressTimes[i - 1]
    }

    var avgInnerTypeDelay = totalInnerTypeDelay / keyPressTimes.length - 1

    return initialDelay < maxInitialDelay && avgInnerTypeDelay < maxInitialDelay
  }

  function perfectString(language) {
    let stringArray = PERFECT_STRINGS[language]
    if (stringArray && stringArray.length > 0) {
      return stringArray[_.random(stringArray.length - 1)]
    }
    return 'Perfect!'
  }

  // A correct answer was given. Display the judgment, delay for one second,
  // then submit.
  function correctAnswer(theAnswer, description) {
    cardAnswer.disable()
    var targetLanguage = $('#study_lang').val()

    var numRating = wasResponseInstant(theAnswer.length) ? 5 : 4
    cardJudgment.showCorrectAnswer(
      4,
      4,
      description || perfectString(targetLanguage)
    )
    cardRating.val(numRating)
    afterCorrectAnswer()
  }

  function afterCorrectAnswer() {
    $('.debug-deck__prediction').removeClass('is-hidden')

    // play audio after .1s
    window.setTimeout(function() {
      if (
        $('#audio-autoplay').val() === 'true' &&
        AudioTrigger.toggleAudio.call($('.audio__trigger').last(), {
          onend: nextQuestion,
        })
      ) {
        // assume audio "onend" triggers advance, but fallback if it fails
        window.setTimeout(function() {
          nextQuestion()
        }, NO_AUDIO_TIMEOUT)
      } else {
        window.setTimeout(nextQuestion, 250)
      }
    }, 100)
  }

  // An incorrect answer was given. Display the judgment.
  function incorrectAnswer(accuracy, description, studentAnswer) {
    cardAnswer.disable()
    nextButton.focus()

    var theAnswerIs = $('#answer_is').val() + ':'
    var answerExplanation = $('#answer_explanation').val()

    var numRating = 0
    if (accuracy >= 0.9) {
      numRating = 3
      cardJudgment.showIncorrectAnswer(
        2,
        2,
        description ||
          t("Close enough! We'll give you this one, but %{theAnswerIs}", {
            theAnswerIs,
          }),
        answerExplanation
      )
    } else if (accuracy >= 0.85) {
      numRating = 2
      cardJudgment.showIncorrectAnswer(
        2,
        1,
        description ||
          t('Close! %{theAnswerIs}', {
            theAnswerIs: _.upperFirst(theAnswerIs),
          }),
        answerExplanation
      )
    } else if (accuracy >= 0.5) {
      numRating = 1
      cardJudgment.showIncorrectAnswer(
        1,
        1,
        description ||
          t('Close! %{theAnswerIs}', {
            theAnswerIs: _.upperFirst(theAnswerIs),
          }),
        answerExplanation
      )
    } else {
      numRating = 0
      cardJudgment.showIncorrectAnswer(
        0,
        0,
        description || t('Nope, %{theAnswerIs}', { theAnswerIs }),
        answerExplanation
      )
    }

    // If the student left the answer blank, then no mistake button is offered
    if (!studentAnswer || studentAnswer.trim() === '') {
      $('#mistakeButton').hide()
    }

    cardRating.val(numRating)
    afterIncorrectAnswer()
  }

  function afterIncorrectAnswer() {
    $('.debug-deck__prediction').removeClass('is-hidden')

    if ($('#audio-autoplay').val() === 'true') {
      AudioTrigger.toggleAudio.call($('.audio__trigger').last())
    }
  }

  // A tagged answer was given, show the intervention.
  function taggedAnswer(rating, prompt) {
    cardAnswer.disable()
    nextButton.focus()

    var targetLanguage = $('#study_lang').val()
    var theAnswerIs = $('#answer_is').val() + ':'
    var answerExplanation = $('#answer_explanation').val()

    if (rating >= 4) {
      if (prompt) {
        cardJudgment.showIncorrectAnswer(rating, 4, prompt, answerExplanation)
      } else {
        cardJudgment.showCorrectAnswer(4, 4, perfectString(targetLanguage))
      }
    } else if (rating === 3) {
      cardJudgment.showIncorrectAnswer(
        rating,
        2,
        prompt ||
          t("Close enough! We'll give you this one, but %{theAnswerIs}", {
            theAnswerIs,
          }),
        answerExplanation
      )
    } else if (rating === 2 || rating === 1) {
      const defaultPrompt = t('Close! %{theAnswerIs}', {
        theAnswerIs: _.upperFirst(theAnswerIs),
      })

      cardJudgment.showIncorrectAnswer(
        rating,
        rating,
        prompt || defaultPrompt,
        answerExplanation
      )
    } else {
      cardJudgment.showIncorrectAnswer(
        rating,
        rating,
        prompt || t('Nope, %{theAnswerIs}', { theAnswerIs }),
        answerExplanation
      )
    }
    cardRating.val(rating)

    if (rating >= 4 && !prompt) {
      afterCorrectAnswer()
    } else {
      afterIncorrectAnswer()
    }
  }

  const showHint = (el) => {
    // do not show hint if user has already answered
    if ($('.card-judgment').is(':visible')) {
      return
    }
    $(el)
      .tooltip({
        title: t('Hit ⏎ return to continue'),
        trigger: 'manual',
        placement: 'top',
      })
      .tooltip('show')
  }
  const showHintDelayed = _.debounce(
    () => showHint(answerInput),
    SHOW_HINT_TIMEOUT
  )

  /// / On Page Load

  // Play the audio if appropriate.
  if ($('#audio-autoplay').val() === 'true' && $('#side').val() === '1') {
    AudioTrigger.toggleAudio.call($('.audio__trigger').first())
  }

  $('#review-explanation').on('click', (ev) => {
    let url = $('#review-explanation').attr('ref')
    $.get(url, (data) => {
      $('#review-explanation-body').html(data)
      $('#review-explanation-dlg').modal('show')
    })
  })

  /// / Events

  // Show a hint user on how to proceed if the user hasn't entered text for
  // 5 seconds.
  if (showAnswerHint) {
    $(window)
      .off('keyup')
      .keyup(() => {
        answerInput.tooltip('hide')
        if (answerInput[0].textContent.length > 0) {
          showHintDelayed()
        }
      })
  }

  // Keydown - prevent multiple events from firing from submitting the form
  const keydownWait = _.debounce(
    () => {
      if (showAnswerHint) {
        // Update the user's preferences so that the hint is no longer shown
        Preferences.instance().then((prefs) => {
          prefs.set(['study', 'cards', 'show_answer_hint'], false)
        })
      }
      nextQuestion()
    },
    KEYDOWN_TIMEOUT,
    { leading: true, trailing: false }
  )
  $(window)
    .off('keydown')
    .keydown(function(e) {
      // This handler is dangerous when run on other pages (e.g. styleguide)
      if (!isOnStudyCardsPage) return

      var key = e.which
      var pressedAt = new Date()

      // Enter key and Tab key both submit the form.
      if (cardAnswer.isMultiple() && (key === TAB_CODE || key === ENTER_CODE)) {
        e.preventDefault()
        if (key === ENTER_CODE && cardAnswer.canAdvanceFocus()) {
          // submit the form since the user pressed enter on the last element
          keydownWait()
          return false
        }
        cardAnswer.advanceFocus()
        return false
      } else if (key === TAB_CODE) {
        e.preventDefault()
      } else if (key === ENTER_CODE) {
        // Give a moment after the page has loaded to prevent false submits
        if (
          pressedAt - pageLoadTime < FALSE_SUBMIT_TIMEOUT &&
          keyPressTimes.length === 0
        ) {
          e.preventDefault()
          return false
        } else if (key === TAB_CODE) {
          e.preventDefault()
        } else if (key === ENTER_CODE) {
          // Give a moment after the page has loaded to prevent false submits
          if (
            pressedAt - pageLoadTime < FALSE_SUBMIT_TIMEOUT &&
            keyPressTimes.length === 0
          ) {
            e.preventDefault()
            return false
          }

          keydownWait()
          return false
        }

        // Record every key press to determine how quickly the user responded.
        keyPressTimes.push(pressedAt)
      }
    })

  // Dont have the enter / tab keys move on when in the mistakeForm
  const mistakeModal = $('#mistake')
  const mistakeForm = $('#mistake form')

  mistakeModal.keydown(function(e) {
    const key = e.which
    if (key === TAB_CODE || key === ENTER_CODE) {
      e.stopPropagation()
    }
    if (key === ENTER_CODE) {
      mistakeForm.submit()
    }
  })

  mistakeModal.on('shown.bs.modal', (e) => {
    $('#mistake-message').focus()
  })

  mistakeForm.submit(function(e) {
    e.preventDefault()
    var href = mistakeForm.attr('action')
    var yourAnswer = cardAnswer.val()
    var originalRating = cardRating.val()
    var message = $('#mistake-message').val()
    var appealReason = $('#mistake-reason').val()
    var allAnswers = $('#answer').val()
    var theAnswer = allAnswers.split(';')[0]
    var theQuestion = $('.card_question div.word').text()
    var cardSide = $('#side').val()
    var cardEx = $('#example').val()

    cardRating.val(5)

    // change the answer score and submit the page
    var data = {
      question: theQuestion,
      answer: yourAnswer,
      expected: theAnswer,
      accepted: allAnswers,
      originalRating: originalRating,
      message: message,
      reason: appealReason,
      side: cardSide,
      example: cardEx,
    }

    $.post(href, data, function() {
      PageAlert.showAlert({
        content: t('Thanks for your feedback!'),
        fixed: true,
      })
      mistakeForm.modal('hide')
      window.setTimeout(() => nextQuestion(), 200)
    })
  })

  // Any "next" buttons submit the main form.
  nextButton.click(function() {
    nextQuestion()
  })

  function displayTag(response, yourAnswer, originalAccuracy) {
    if (response) {
      const { feedback, rating } = response
      if (rating !== null) {
        taggedAnswer(rating, feedback)
      } else {
        incorrectAnswer(originalAccuracy, feedback, yourAnswer)
      }
    } else {
      incorrectAnswer(originalAccuracy, undefined, yourAnswer)
    }
  }

  // Judge the response.
  cardForm.submit(function() {
    if (isTesting) {
      return false
    }
    if (!cardAnswer) {
      return false
    }

    if (parseInt(cardRating.val()) !== -1) {
      nextButton.prop('disabled', true)
      nextButton.data('ujs:disabled', true)

      var rv = advanceCard
      advanceCard = false // don't actually submit more than once
      return rv
    }

    var yourAnswer = cardAnswer.val()
    var allAnswers = $('#answer').val()
    var theAnswer = allAnswers.split(';')[0]
    var answerLang = $('#answer-lang').val()
    var side = parseInt($('#side').val(), 10)
    var answerType = $('#answer-type').val()
    var answerResponses = JSON.parse($('#answer-responses').val())
    var answerFeatures = JSON.parse($('#answer-features').val())

    $('#response').val(yourAnswer)

    // Get accuracy
    var accuracy = CardUtils.judgeAccuracy(
      allAnswers,
      yourAnswer,
      answerLang,
      answerType,
      answerFeatures,
      side
    )

    if (accuracy === 1.0) {
      correctAnswer(theAnswer)
    } else {
      if (answerResponses && Object.keys(answerResponses).length > 0) {
        displayTag(
          answerResponses[yourAnswer.toLowerCase()],
          yourAnswer,
          accuracy
        )
      } else {
        incorrectAnswer(accuracy, undefined, yourAnswer)
      }
    }

    scrollMinimallyToElement($('.card-judgment'))
    return false
  })
})
