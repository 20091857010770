/* eslint-disable camelcase */
export default class SyllabusView {
  constructor(element, options) {
    this.language_code = options.language_code
    this.init()
  }

  init() {
    let saved_config = JSON.parse(localStorage.getItem(this.config_name()))
    let default_config = this.default_config()

    if (saved_config && saved_config.version === default_config.version) {
      this.config = saved_config
    } else {
      this.config = default_config
      this.save()
    }

    this.render()
    this.set_behaviors()
  }

  default_config() {
    return {
      version: 1.2, // increment when config structure changes
      level_name: true,
      exercise: true,
      topic: true,
      communicative_goal: false,
      pedagogical_goal: false,
      single_level: null,
      single_lesson: null,
    }
  }

  set_behaviors() {
    this.set_parts_toggles()
    this.set_lesson_toggles()
    this.set_menu_behavior()
    this.set_view_only_level_behavior()
  }

  set_parts_toggles() {
    $('.toggler').click((ev) => {
      let toggles = ev.target.dataset.toggles
      this.config[toggles] = !this.config[toggles]
      this.render()
    })
  }

  set_lesson_toggles() {
    $('.view_only_lesson').click((ev) => {
      let lesson = ev.target.dataset.lesson
      this.config.single_lesson =
        this.config.single_lesson === lesson ? null : lesson
      this.render()
    })
  }

  set_menu_behavior() {
    let $menu = $('#syllabus_view_menu')
    let top_ = $menu.offset().top

    $(window).scroll(function() {
      var top2 = $(window).scrollTop()
      $('#main').toggleClass('vade_mecum', top2 >= top_)
    })
  }

  set_view_only_level_behavior() {
    $('.view_only_level').click((ev) => {
      let level = $(ev.target)
        .closest('.level_row')
        .data('level')
      this.config.single_level =
        this.config.single_level === level ? null : level
      this.render()
    })
  }

  config_name() {
    return `syllabus_view_config_${this.language_code}`
  }

  sync_buttons() {
    $('.toggler').each((i, el) => {
      $(el).blur()
      let is_on = this.config[el.dataset.toggles]
      $(el).toggleClass('active', is_on)
    })
  }

  sync_levels() {
    let single_level = this.config.single_level

    if (single_level === null) {
      $('.level_row').show()
    } else {
      $('.level_row').each((i, el) => {
        let level = el.dataset.level
        if (single_level !== level) $(el).hide()
      })
    }
  }

  sync_lessons() {
    let single_lesson = this.config.single_lesson

    if (single_lesson === null) {
      $('.lesson_col')
        .show()
        .removeClass('single_col')
    } else {
      $('.lesson_col').each((i, el) => {
        let lesson = el.dataset.lesson
        if (single_lesson !== lesson) {
          $(el)
            .hide()
            .removeClass('single_col')
        } else {
          $(el).addClass('single_col')
        }
      })
    }
  }

  sync_lesson_bits() {
    $('.toggler').each((i, el) => {
      let toggles = el.dataset.toggles
      let $to_toggle = $(`.${toggles}`)
      $to_toggle.toggle(this.config[toggles])
    })
  }

  render() {
    this.sync_lesson_bits()
    this.sync_lessons()
    this.sync_levels()
    this.sync_buttons()
    this.save()
  }

  save() {
    localStorage.setItem(this.config_name(), JSON.stringify(this.config))
  }
}
