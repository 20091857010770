import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '@chatterbug/aaron'

import { LEFT_POSITIONING, getTranslateTransform } from '../../StudentProgress'

const getCharacter = (l2Code) => {
  return (
    {
      de: require('./assets/character_de.svg'),
      en: require('./assets/character_en.svg'),
      fr: require('./assets/character_fr.svg'),
      es: require('./assets/character_es.svg'),
    }[l2Code] || './assets/character_de.svg'
  )
}

const ProgressCharacter = ({ l2Code, completed }) => {
  return (
    <Image
      src={getCharacter(l2Code)}
      left={LEFT_POSITIONING(completed)}
      sx={{
        position: 'absolute',
        transform: getTranslateTransform(completed),
        zIndex: '2',
        bottom: '10px',
      }}
    />
  )
}

ProgressCharacter.propTypes = {
  completed: PropTypes.number.isRequired,
  l2Code: PropTypes.string.isRequired,
}

export default ProgressCharacter
