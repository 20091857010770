import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useLazyQuery, useMutation } from 'react-apollo'

import ProductTourContext from 'src/lib/product-tour-context'
import { usePageParams } from 'src/utils/params'

export const PRODUCT_TOUR = gql`
  query PRODUCT_TOUR($pathname: String!, $pageSection: String) {
    productTour(pathname: $pathname, pageSection: $pageSection) {
      name
    }
  }
`

const MARK_TIMELINE = gql`
  mutation MARK_TIMELINE($name: String!, $code: LanguageEnum) {
    response: markTimeline(name: $name, code: $code) {
      errors {
        code
        message
      }
    }
  }
`

const LoadProductTour = ({ disabled, pageSection = 'content', children }) => {
  const [productTour, setProductTour] = useState(null)
  const [markTimeline, { loading: markTimelineLoading }] = useMutation(
    MARK_TIMELINE
  )
  const { l2Code } = usePageParams()

  const handleSeen = async (name) => {
    if (markTimelineLoading) {
      return
    }

    try {
      await markTimeline({ variables: { name, code: l2Code } })
    } catch (error) {
      console.error(error)
    }
  }

  const handleFetchCompleted = (data) => {
    const list = data?.productTour?.length > 0 ? data.productTour : null

    if (list && !productTour) {
      setProductTour({
        list,
        onSeen: handleSeen,
      })
    }
  }

  const [loadData] = useLazyQuery(PRODUCT_TOUR, {
    variables: { pathname: window.location.pathname, pageSection },
    fetchPolicy: 'network-only',
    onCompleted: handleFetchCompleted,
  })

  useEffect(() => {
    if (!disabled) {
      loadData()
    }
  }, [disabled]) // only when disabled prop changes

  return (
    <ProductTourContext.Provider value={productTour}>
      {children}
    </ProductTourContext.Provider>
  )
}

LoadProductTour.propTypes = {
  pageSection: PropTypes.string,
  disabled: PropTypes.bool,
}

export default LoadProductTour
