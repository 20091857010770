import _ from 'lodash'

import { bindMethods } from 'lib/util'
import Module from 'lib/module'

export default class ExerciseSummaryContainer {
  static find(element) {
    return (element || document).querySelector(
      ExerciseSummaryContainer.selector
    )
  }

  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.elements = Module.findElements(
      element,
      ExerciseSummaryContainer.className
    )
    this.options = _.defaults(options, {
      onViewExercise: (id) => {},
    })
    this.exercises = element.querySelectorAll(
      ExerciseSummaryContainer.itemSelector
    )
    this.videoPlayback = document.querySelector(
      ExerciseSummaryContainer.videoSelector
    )
    this.videoProgressBar = document.querySelector(
      ExerciseSummaryContainer.videoProgressSelector
    )
    $(element).on(
      'click',
      this.elements.exerciseSelector,
      this.handleClickExercise
    )
  }

  handleClickExercise(e) {
    let exercise = $(e.target).closest(ExerciseSummaryContainer.itemSelector)[0]
    let exerciseId = parseInt(exercise.getAttribute('data-id'))
    this.options.onViewExercise(exerciseId)
  }

  render({ exerciseId }) {
    if (exerciseId == null) {
      this.element.classList.remove(ExerciseSummaryContainer.hasSelectionClass)
    } else {
      this.element.classList.add(ExerciseSummaryContainer.hasSelectionClass)
    }
    _.each(this.exercises, (e) =>
      e.classList.remove(ExerciseSummaryContainer.itemSelectedClass)
    )

    let itemSelector = ExerciseSummaryContainer.itemSelector
    if (exerciseId != null) {
      let exercise = this.element.querySelector(
        `${itemSelector}[data-id="${exerciseId}"]`
      )
      if (this.videoPlayback) {
        if (exercise.getAttribute('data-video') === 'false') {
          this.videoPlayback.classList.add('hidden')
          if (this.videoProgressBar) {
            this.videoProgressBar.classList.add('hidden')
          }
        } else {
          this.videoPlayback.classList.remove('hidden')
          if (this.videoProgressBar) {
            this.videoProgressBar.classList.remove('hidden')
          }
        }
      }
      exercise.classList.add(ExerciseSummaryContainer.itemSelectedClass)
    }
  }
}

ExerciseSummaryContainer.className = 'exercise-summary__container'
ExerciseSummaryContainer.selector = `.${ExerciseSummaryContainer.className}`
ExerciseSummaryContainer.hasSelectionClass = `${ExerciseSummaryContainer.className}_has-selection`
ExerciseSummaryContainer.itemClass = 'exercise-summary'
ExerciseSummaryContainer.itemSelector = '.exercise-summary'
ExerciseSummaryContainer.videoSelector = '.video-playback'
ExerciseSummaryContainer.videoProgressSelector =
  '.chat-session-replay__progress-row'
ExerciseSummaryContainer.itemSelectedClass = 'exercise-summary_selected'
