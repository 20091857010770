/* eslint-disable */
// Run `rake generate_js:routes` to update this file.
// Please format it with Prettier
const routes = {}
;(function () {
  const exports = null /*
File generated by js-routes 1.4.4
Based on Rails routes of Chatterbug::Application
 */

  ;(function () {
    var DeprecatedBehavior,
      NodeTypes,
      ParameterMissing,
      ReservedOptions,
      SpecialOptionsKey,
      Utils,
      root,
      hasProp = {}.hasOwnProperty,
      slice = [].slice

    root = typeof exports !== 'undefined' && exports !== null ? exports : this

    function ParameterMissing(message, fileName, lineNumber) {
      var instance = new Error(message, fileName, lineNumber)
      if (Object.setPrototypeOf) {
        Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
      } else {
        instance.__proto__ = this.__proto__
      }
      if (Error.captureStackTrace) {
        Error.captureStackTrace(instance, ParameterMissing)
      }
      return instance
    }

    ParameterMissing.prototype = Object.create(Error.prototype, {
      constructor: {
        value: Error,
        enumerable: false,
        writable: true,
        configurable: true,
      },
    })

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(ParameterMissing, Error)
    } else {
      ParameterMissing.__proto__ = Error
    }

    NodeTypes = {
      GROUP: 1,
      CAT: 2,
      SYMBOL: 3,
      OR: 4,
      STAR: 5,
      LITERAL: 6,
      SLASH: 7,
      DOT: 8,
    }

    SpecialOptionsKey = '_options'

    DeprecatedBehavior = false

    ReservedOptions = [
      'anchor',
      'trailing_slash',
      'subdomain',
      'host',
      'port',
      'protocol',
    ]

    Utils = {
      configuration: {
        prefix: '',
        default_url_options: {},
        special_options_key: '_options',
        serializer: null,
      },
      default_serializer: function (object, prefix) {
        var element, i, j, key, len, prop, s
        if (prefix == null) {
          prefix = null
        }
        if (object == null) {
          return ''
        }
        if (!prefix && !(this.get_object_type(object) === 'object')) {
          throw new Error('Url parameters should be a javascript hash')
        }
        s = []
        switch (this.get_object_type(object)) {
          case 'array':
            for (i = j = 0, len = object.length; j < len; i = ++j) {
              element = object[i]
              s.push(this.default_serializer(element, prefix + '[]'))
            }
            break
          case 'object':
            for (key in object) {
              if (!hasProp.call(object, key)) continue
              prop = object[key]
              if (prop == null && prefix != null) {
                prop = ''
              }
              if (prop != null) {
                if (prefix != null) {
                  key = prefix + '[' + key + ']'
                }
                s.push(this.default_serializer(prop, key))
              }
            }
            break
          default:
            if (object != null) {
              s.push(
                encodeURIComponent(prefix.toString()) +
                  '=' +
                  encodeURIComponent(object.toString())
              )
            }
        }
        if (!s.length) {
          return ''
        }
        return s.join('&')
      },
      serialize: function (object) {
        var custom_serializer
        custom_serializer = this.configuration.serializer
        if (
          custom_serializer != null &&
          this.get_object_type(custom_serializer) === 'function'
        ) {
          return custom_serializer(object)
        } else {
          return this.default_serializer(object)
        }
      },
      clean_path: function (path) {
        var last_index
        path = path.split('://')
        last_index = path.length - 1
        path[last_index] = path[last_index].replace(/\/+/g, '/')
        return path.join('://')
      },
      extract_options: function (number_of_params, args) {
        var last_el, options
        last_el = args[args.length - 1]
        if (
          (args.length > number_of_params && last_el === void 0) ||
          (last_el != null &&
            'object' === this.get_object_type(last_el) &&
            !this.looks_like_serialized_model(last_el))
        ) {
          options = args.pop() || {}
          delete options[this.configuration.special_options_key]
          return options
        } else {
          return {}
        }
      },
      looks_like_serialized_model: function (object) {
        return (
          !object[this.configuration.special_options_key] &&
          ('id' in object || 'to_param' in object)
        )
      },
      path_identifier: function (object) {
        var property
        if (object === 0) {
          return '0'
        }
        if (!object) {
          return ''
        }
        property = object
        if (this.get_object_type(object) === 'object') {
          if ('to_param' in object) {
            if (object.to_param == null) {
              throw new ParameterMissing('Route parameter missing: to_param')
            }
            property = object.to_param
          } else if ('id' in object) {
            if (object.id == null) {
              throw new ParameterMissing('Route parameter missing: id')
            }
            property = object.id
          } else {
            property = object
          }
          if (this.get_object_type(property) === 'function') {
            property = property.call(object)
          }
        }
        return property.toString()
      },
      clone: function (obj) {
        var attr, copy, key
        if (obj == null || 'object' !== this.get_object_type(obj)) {
          return obj
        }
        copy = obj.constructor()
        for (key in obj) {
          if (!hasProp.call(obj, key)) continue
          attr = obj[key]
          copy[key] = attr
        }
        return copy
      },
      merge: function () {
        var tap, xs
        xs = 1 <= arguments.length ? slice.call(arguments, 0) : []
        tap = function (o, fn) {
          fn(o)
          return o
        }
        if ((xs != null ? xs.length : void 0) > 0) {
          return tap({}, function (m) {
            var j, k, len, results, v, x
            results = []
            for (j = 0, len = xs.length; j < len; j++) {
              x = xs[j]
              results.push(
                (function () {
                  var results1
                  results1 = []
                  for (k in x) {
                    v = x[k]
                    results1.push((m[k] = v))
                  }
                  return results1
                })()
              )
            }
            return results
          })
        }
      },
      normalize_options: function (
        parts,
        required_parts,
        default_options,
        actual_parameters
      ) {
        var i,
          j,
          key,
          len,
          options,
          part,
          parts_options,
          result,
          route_parts,
          url_parameters,
          use_all_parts,
          value
        options = this.extract_options(parts.length, actual_parameters)
        if (actual_parameters.length > parts.length) {
          throw new Error('Too many parameters provided for path')
        }
        use_all_parts =
          DeprecatedBehavior || actual_parameters.length > required_parts.length
        parts_options = {}
        for (key in options) {
          if (!hasProp.call(options, key)) continue
          use_all_parts = true
          if (this.indexOf(parts, key) >= 0) {
            parts_options[key] = value
          }
        }
        options = this.merge(
          this.configuration.default_url_options,
          default_options,
          options
        )
        result = {}
        url_parameters = {}
        result['url_parameters'] = url_parameters
        for (key in options) {
          if (!hasProp.call(options, key)) continue
          value = options[key]
          if (this.indexOf(ReservedOptions, key) >= 0) {
            result[key] = value
          } else {
            url_parameters[key] = value
          }
        }
        route_parts = use_all_parts ? parts : required_parts
        i = 0
        for (j = 0, len = route_parts.length; j < len; j++) {
          part = route_parts[j]
          if (i < actual_parameters.length) {
            if (!parts_options.hasOwnProperty(part)) {
              url_parameters[part] = actual_parameters[i]
              ++i
            }
          }
        }
        return result
      },
      build_route: function (
        parts,
        required_parts,
        default_options,
        route,
        full_url,
        args
      ) {
        var options, parameters, result, url, url_params
        args = Array.prototype.slice.call(args)
        options = this.normalize_options(
          parts,
          required_parts,
          default_options,
          args
        )
        parameters = options['url_parameters']
        result = '' + this.get_prefix() + this.visit(route, parameters)
        url = Utils.clean_path(result)
        if (options['trailing_slash'] === true) {
          url = url.replace(/(.*?)[\/]?$/, '$1/')
        }
        if ((url_params = this.serialize(parameters)).length) {
          url += '?' + url_params
        }
        url += options.anchor ? '#' + options.anchor : ''
        if (full_url) {
          url = this.route_url(options) + url
        }
        return url
      },
      visit: function (route, parameters, optional) {
        var left, left_part, right, right_part, type, value
        if (optional == null) {
          optional = false
        }
        ;(type = route[0]), (left = route[1]), (right = route[2])
        switch (type) {
          case NodeTypes.GROUP:
            return this.visit(left, parameters, true)
          case NodeTypes.STAR:
            return this.visit_globbing(left, parameters, true)
          case NodeTypes.LITERAL:
          case NodeTypes.SLASH:
          case NodeTypes.DOT:
            return left
          case NodeTypes.CAT:
            left_part = this.visit(left, parameters, optional)
            right_part = this.visit(right, parameters, optional)
            if (
              optional &&
              ((this.is_optional_node(left[0]) && !left_part) ||
                (this.is_optional_node(right[0]) && !right_part))
            ) {
              return ''
            }
            return '' + left_part + right_part
          case NodeTypes.SYMBOL:
            value = parameters[left]
            if (value != null) {
              delete parameters[left]
              return this.path_identifier(value)
            }
            if (optional) {
              return ''
            } else {
              throw new ParameterMissing('Route parameter missing: ' + left)
            }
            break
          default:
            throw new Error('Unknown Rails node type')
        }
      },
      is_optional_node: function (node) {
        return (
          this.indexOf(
            [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT],
            node
          ) >= 0
        )
      },
      build_path_spec: function (route, wildcard) {
        var left, right, type
        if (wildcard == null) {
          wildcard = false
        }
        ;(type = route[0]), (left = route[1]), (right = route[2])
        switch (type) {
          case NodeTypes.GROUP:
            return '(' + this.build_path_spec(left) + ')'
          case NodeTypes.CAT:
            return '' + this.build_path_spec(left) + this.build_path_spec(right)
          case NodeTypes.STAR:
            return this.build_path_spec(left, true)
          case NodeTypes.SYMBOL:
            if (wildcard === true) {
              return '' + (left[0] === '*' ? '' : '*') + left
            } else {
              return ':' + left
            }
            break
          case NodeTypes.SLASH:
          case NodeTypes.DOT:
          case NodeTypes.LITERAL:
            return left
          default:
            throw new Error('Unknown Rails node type')
        }
      },
      visit_globbing: function (route, parameters, optional) {
        var left, right, type, value
        ;(type = route[0]), (left = route[1]), (right = route[2])
        if (left.replace(/^\*/i, '') !== left) {
          route[1] = left = left.replace(/^\*/i, '')
        }
        value = parameters[left]
        if (value == null) {
          return this.visit(route, parameters, optional)
        }
        parameters[left] = function () {
          switch (this.get_object_type(value)) {
            case 'array':
              return value.join('/')
            default:
              return value
          }
        }.call(this)
        return this.visit(route, parameters, optional)
      },
      get_prefix: function () {
        var prefix
        prefix = this.configuration.prefix
        if (prefix !== '') {
          prefix = prefix.match('/$') ? prefix : prefix + '/'
        }
        return prefix
      },
      route: function (parts_table, default_options, route_spec, full_url) {
        var j, len, part, parts, path_fn, ref, required, required_parts
        required_parts = []
        parts = []
        for (j = 0, len = parts_table.length; j < len; j++) {
          ;(ref = parts_table[j]), (part = ref[0]), (required = ref[1])
          parts.push(part)
          if (required) {
            required_parts.push(part)
          }
        }
        path_fn = function () {
          return Utils.build_route(
            parts,
            required_parts,
            default_options,
            route_spec,
            full_url,
            arguments
          )
        }
        path_fn.required_params = required_parts
        path_fn.toString = function () {
          return Utils.build_path_spec(route_spec)
        }
        return path_fn
      },
      route_url: function (route_defaults) {
        var hostname, port, protocol, subdomain
        if (typeof route_defaults === 'string') {
          return route_defaults
        }
        hostname = route_defaults.host || Utils.current_host()
        if (!hostname) {
          return ''
        }
        subdomain = route_defaults.subdomain
          ? route_defaults.subdomain + '.'
          : ''
        protocol = route_defaults.protocol || Utils.current_protocol()
        port =
          route_defaults.port ||
          (!route_defaults.host ? Utils.current_port() : void 0)
        port = port ? ':' + port : ''
        return protocol + '://' + subdomain + hostname + port
      },
      has_location: function () {
        return (
          (typeof window !== 'undefined' && window !== null
            ? window.location
            : void 0) != null
        )
      },
      current_host: function () {
        if (this.has_location()) {
          return window.location.hostname
        } else {
          return null
        }
      },
      current_protocol: function () {
        if (this.has_location() && window.location.protocol !== '') {
          return window.location.protocol.replace(/:$/, '')
        } else {
          return 'http'
        }
      },
      current_port: function () {
        if (this.has_location() && window.location.port !== '') {
          return window.location.port
        } else {
          return ''
        }
      },
      _classToTypeCache: null,
      _classToType: function () {
        var j, len, name, ref
        if (this._classToTypeCache != null) {
          return this._classToTypeCache
        }
        this._classToTypeCache = {}
        ref = 'Boolean Number String Function Array Date RegExp Object Error'.split(
          ' '
        )
        for (j = 0, len = ref.length; j < len; j++) {
          name = ref[j]
          this._classToTypeCache['[object ' + name + ']'] = name.toLowerCase()
        }
        return this._classToTypeCache
      },
      get_object_type: function (obj) {
        if (root.jQuery && root.jQuery.type != null) {
          return root.jQuery.type(obj)
        }
        if (obj == null) {
          return '' + obj
        }
        if (typeof obj === 'object' || typeof obj === 'function') {
          return (
            this._classToType()[Object.prototype.toString.call(obj)] || 'object'
          )
        } else {
          return typeof obj
        }
      },
      indexOf: function (array, element) {
        if (Array.prototype.indexOf) {
          return array.indexOf(element)
        } else {
          return this.indexOfImplementation(array, element)
        }
      },
      indexOfImplementation: function (array, element) {
        var el, i, j, len, result
        result = -1
        for (i = j = 0, len = array.length; j < len; i = ++j) {
          el = array[i]
          if (el === element) {
            result = i
          }
        }
        return result
      },
      namespace: function (root, namespace, routes) {
        var index, j, len, part, parts
        parts = namespace.split('.')
        if (parts.length === 0) {
          return routes
        }
        for (index = j = 0, len = parts.length; j < len; index = ++j) {
          part = parts[index]
          if (index < parts.length - 1) {
            root = root[part] || (root[part] = {})
          } else {
            return (root[part] = routes)
          }
        }
      },
      configure: function (new_config) {
        return (this.configuration = this.merge(this.configuration, new_config))
      },
      config: function () {
        return this.clone(this.configuration)
      },
      make: function () {
        var routes
        routes = {
          // account => /account(.:format)
          // function(options)
          account: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // account_account => /account/account(.:format)
          // function(options)
          account_account: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'account', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_desktop_notifications => /account/desktop_notifications(.:format)
          // function(options)
          account_desktop_notifications: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'desktop_notifications', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_get_preferences => /account/get_preferences(.:format)
          // function(options)
          account_get_preferences: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'get_preferences', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_languages => /account/languages(.:format)
          // function(options)
          account_languages: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'languages', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_mobile_number => /account/mobile_numbers/:id(.:format)
          // function(id, options)
          account_mobile_number: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'mobile_numbers', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // account_mobile_numbers => /account/mobile_numbers(.:format)
          // function(options)
          account_mobile_numbers: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'mobile_numbers', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_notification => /account/notifications/:id(.:format)
          // function(id, options)
          account_notification: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'notifications', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // account_notifications => /account/notifications(.:format)
          // function(options)
          account_notifications: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'notifications', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_organization_invoices => /account/organization/:org_id/invoices(.:format)
          // function(org_id, options)
          account_organization_invoices: Utils.route(
            [
              ['org_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'organization', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'org_id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'invoices', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // account_organizations => /account/organizations(.:format)
          // function(options)
          account_organizations: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'organizations', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_password => /account/password(.:format)
          // function(options)
          account_password: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'password', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_preferences => /account/preferences(.:format)
          // function(options)
          account_preferences: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'preferences', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_privacy => /account/privacy(.:format)
          // function(options)
          account_privacy: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'privacy', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_schedule => /account/schedule(.:format)
          // function(options)
          account_schedule: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'schedule', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_study => /account/study(.:format)
          // function(options)
          account_study: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'study', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_time_settings => /account/time_settings(.:format)
          // function(options)
          account_time_settings: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'time_settings', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // account_user_token => /account/user_tokens/:id(.:format)
          // function(id, options)
          account_user_token: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'user_tokens', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // account_user_tokens => /account/user_tokens(.:format)
          // function(options)
          account_user_tokens: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'user_tokens', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // achievements => /achievements(.:format)
          // function(options)
          achievements: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'achievements', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // achievements_next => /achievements/next(.:format)
          // function(options)
          achievements_next: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'achievements', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'next', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // advanced_achievements => /advanced/language/:code/achievements(.:format)
          // function(code, options)
          advanced_achievements: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'achievements', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_achievements_live_lessons => /advanced/language/:code/achievements/livelessons(.:format)
          // function(code, options)
          advanced_achievements_live_lessons: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'achievements', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'livelessons', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_cefr_level_wrapup => /advanced/language/:code/level_wrapup/:cefr_level(.:format)
          // function(code, cefr_level, options)
          advanced_cefr_level_wrapup: Utils.route(
            [
              ['code', true],
              ['cefr_level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'level_wrapup', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'cefr_level', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_goals => /advanced/language/:code/goals(.:format)
          // function(code, options)
          advanced_goals: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'goals', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_home => /advanced/language/:code/home(.:format)
          // function(code, options)
          advanced_home: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'home', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_my_course => /advanced/language/:code/my_course(.:format)
          // function(code, options)
          advanced_my_course: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'my_course', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_schedule => /advanced/language/:code/schedule(.:format)
          // function(code, options)
          advanced_schedule: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'schedule', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_search => /advanced/language/:code/search(.:format)
          // function(code, options)
          advanced_search: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'search', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_search_show => /advanced/search(.:format)
          // function(options)
          advanced_search_show: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'advanced', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'search', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // advanced_toggle => /advanced/toggle(.:format)
          // function(options)
          advanced_toggle: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'advanced', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'toggle', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // advanced_toggle_tour => /advanced/toggle_tour(.:format)
          // function(options)
          advanced_toggle_tour: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'advanced', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'toggle_tour', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // advanced_topic => /advanced/language/:code/topics/:topic_id(.:format)
          // function(code, topic_id, options)
          advanced_topic: Utils.route(
            [
              ['code', true],
              ['topic_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'topics', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'topic_id', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_topics => /advanced/language/:code/topics(.:format)
          // function(code, options)
          advanced_topics: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'topics', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit => /advanced/language/:code/units/:unit_id(.:format)
          // function(code, unit_id, options)
          advanced_unit: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit_concepts => /advanced/language/:code/units/:unit_id/concepts(.:format)
          // function(code, unit_id, options)
          advanced_unit_concepts: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'concepts', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit_exercises => /advanced/language/:code/units/:unit_id/exercises(.:format)
          // function(code, unit_id, options)
          advanced_unit_exercises: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'exercises', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit_media => /advanced/language/:code/units/:unit_id/media(.:format)
          // function(code, unit_id, options)
          advanced_unit_media: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'media', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit_vocabulary => /advanced/language/:code/units/:unit_id/vocabulary(.:format)
          // function(code, unit_id, options)
          advanced_unit_vocabulary: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'vocabulary', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // advanced_unit_writing => /advanced/language/:code/units/:unit_id/writing(.:format)
          // function(code, unit_id, options)
          advanced_unit_writing: Utils.route(
            [
              ['code', true],
              ['unit_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'advanced', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'units', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'unit_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'writing', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // alert => /alerts/:id(.:format)
          // function(id, options)
          alert: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'alerts', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // api_graphql => /api/graphql(.:format)
          // function(options)
          api_graphql: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'graphql', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // api_v1 => /api/v1/rooms/:room_id/connection_quality_logs(.:format)
          // function(room_id, options)
          api_v1: Utils.route(
            [
              ['room_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'rooms', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'room_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'connection_quality_logs', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_archive_open_vidu => /api/v1/archive/open_vidu(.:format)
          // function(options)
          api_v1_archive_open_vidu: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'archive', false],
                      [
                        2,
                        [7, '/', false],
                        [
                          2,
                          [6, 'open_vidu', false],
                          [
                            1,
                            [2, [8, '.', false], [3, 'format', false]],
                            false,
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_archive_tokbox => /api/v1/archive/tokbox(.:format)
          // function(options)
          api_v1_archive_tokbox: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'archive', false],
                      [
                        2,
                        [7, '/', false],
                        [
                          2,
                          [6, 'tokbox', false],
                          [
                            1,
                            [2, [8, '.', false], [3, 'format', false]],
                            false,
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_archivestatus => /api/v1/archivestatus(.:format)
          // function(options)
          api_v1_archivestatus: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'archivestatus', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_auth => /api/v1/auth(.:format)
          // function(options)
          api_v1_auth: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'auth', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_gift_voucher => /api/v1/gift_vouchers/:code(.:format)
          // function(code, options)
          api_v1_gift_voucher: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'gift_vouchers', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'code', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language => /api/v1/languages/:code(.:format)
          // function(code, options)
          api_v1_language: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'code', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_concept => /api/v1/languages/:language_code/concepts/:id(.:format)
          // function(language_code, id, options)
          api_v1_language_concept: Utils.route(
            [
              ['language_code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'concepts', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'id', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_concept_clozes => /api/v1/languages/:language_code/concepts/:concept_id/clozes(.:format)
          // function(language_code, concept_id, options)
          api_v1_language_concept_clozes: Utils.route(
            [
              ['language_code', true],
              ['concept_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'concepts', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'concept_id', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'clozes', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_concepts => /api/v1/languages/:language_code/concepts(.:format)
          // function(language_code, options)
          api_v1_language_concepts: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'concepts', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_level_concepts => /api/v1/languages/:language_code/levels/:level/concepts(.:format)
          // function(language_code, level, options)
          api_v1_language_level_concepts: Utils.route(
            [
              ['language_code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'levels', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'level', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'concepts', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_level_phrases => /api/v1/languages/:language_code/levels/:level/phrases(.:format)
          // function(language_code, level, options)
          api_v1_language_level_phrases: Utils.route(
            [
              ['language_code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'levels', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'level', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'phrases', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_level_recordings => /api/v1/languages/:language_code/levels/:level/recordings(.:format)
          // function(language_code, level, options)
          api_v1_language_level_recordings: Utils.route(
            [
              ['language_code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'levels', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'level', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'recordings', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_level_words => /api/v1/languages/:language_code/levels/:level/words(.:format)
          // function(language_code, level, options)
          api_v1_language_level_words: Utils.route(
            [
              ['language_code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'levels', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'level', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'words', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_levels => /api/v1/languages/:language_code/levels(.:format)
          // function(language_code, options)
          api_v1_language_levels: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'levels', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_phrase => /api/v1/languages/:language_code/phrases/:id(.:format)
          // function(language_code, id, options)
          api_v1_language_phrase: Utils.route(
            [
              ['language_code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'phrases', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'id', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_phrase_example_recordings => /api/v1/languages/:language_code/phrases/:phrase_id/examples/:text/recordings(.:format)
          // function(language_code, phrase_id, text, options)
          api_v1_language_phrase_example_recordings: Utils.route(
            [
              ['language_code', true],
              ['phrase_id', true],
              ['text', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'phrases', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'phrase_id', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'examples', false],
                                        [
                                          2,
                                          [7, '/', false],
                                          [
                                            2,
                                            [3, 'text', false],
                                            [
                                              2,
                                              [7, '/', false],
                                              [
                                                2,
                                                [6, 'recordings', false],
                                                [
                                                  1,
                                                  [
                                                    2,
                                                    [8, '.', false],
                                                    [3, 'format', false],
                                                  ],
                                                  false,
                                                ],
                                              ],
                                            ],
                                          ],
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_phrase_examples => /api/v1/languages/:language_code/phrases/:phrase_id/examples(.:format)
          // function(language_code, phrase_id, options)
          api_v1_language_phrase_examples: Utils.route(
            [
              ['language_code', true],
              ['phrase_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'phrases', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'phrase_id', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'examples', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_phrases => /api/v1/languages/:language_code/phrases(.:format)
          // function(language_code, options)
          api_v1_language_phrases: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'phrases', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_recording => /api/v1/languages/:language_code/recordings/:id(.:format)
          // function(language_code, id, options)
          api_v1_language_recording: Utils.route(
            [
              ['language_code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'recordings', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'id', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_recordings => /api/v1/languages/:language_code/recordings(.:format)
          // function(language_code, options)
          api_v1_language_recordings: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'recordings', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_sequence => /api/v1/languages/:language_code/sequences/:id(.:format)
          // function(language_code, id, options)
          api_v1_language_sequence: Utils.route(
            [
              ['language_code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'sequences', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'id', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_sequences => /api/v1/languages/:language_code/sequences(.:format)
          // function(language_code, options)
          api_v1_language_sequences: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'sequences', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_study_time => /api/v1/languages/:language_code/study/time(.:format)
          // function(language_code, options)
          api_v1_language_study_time: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'study', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [6, 'time', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_word => /api/v1/languages/:language_code/words/:id(.:format)
          // function(language_code, id, options)
          api_v1_language_word: Utils.route(
            [
              ['language_code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'words', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'id', false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_word_recordings => /api/v1/languages/:language_code/words/:word_id/recordings(.:format)
          // function(language_code, word_id, options)
          api_v1_language_word_recordings: Utils.route(
            [
              ['language_code', true],
              ['word_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'words', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'word_id', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [6, 'recordings', false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_language_words => /api/v1/languages/:language_code/words(.:format)
          // function(language_code, options)
          api_v1_language_words: Utils.route(
            [
              ['language_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'languages', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'language_code', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'words', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_languages => /api/v1/languages(.:format)
          // function(options)
          api_v1_languages: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'languages', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_password_resets => /api/v1/password_resets(.:format)
          // function(options)
          api_v1_password_resets: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'password_resets', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_ping => /api/v1/ping(.:format)
          // function(options)
          api_v1_ping: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'ping', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_recording => /api/v1/recordings/:id(.:format)
          // function(id, options)
          api_v1_recording: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'recordings', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_signup => /api/v1/signup(.:format)
          // function(options)
          api_v1_signup: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'signup', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_translations_translate => /api/v1/translations/translate(.:format)
          // function(options)
          api_v1_translations_translate: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'api', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'v1', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'translations', false],
                      [
                        2,
                        [7, '/', false],
                        [
                          2,
                          [6, 'translate', false],
                          [
                            1,
                            [2, [8, '.', false], [3, 'format', false]],
                            false,
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // api_v1_video_m3u8_master => /api/v1/videos/:mux_public_asset_id.:format
          // function(mux_public_asset_id, format, options)
          api_v1_video_m3u8_master: Utils.route(
            [
              ['mux_public_asset_id', true],
              ['format', true],
            ],
            { format: 'm3u8' },
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'videos', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'mux_public_asset_id', false],
                            [2, [8, '.', false], [3, 'format', false]],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // api_v1_video_m3u8_rendition => /api/v1/videos/:mux_public_asset_id/rendition/:rendition.:format
          // function(mux_public_asset_id, rendition, format, options)
          api_v1_video_m3u8_rendition: Utils.route(
            [
              ['mux_public_asset_id', true],
              ['rendition', true],
              ['format', true],
            ],
            { format: 'm3u8' },
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'api', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'v1', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'videos', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'mux_public_asset_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'rendition', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'rendition', false],
                                    [2, [8, '.', false], [3, 'format', false]],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // appointment_book => /schedule/appointments/book(.:format)
          // function(options)
          appointment_book: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'appointments', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'book', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // appointment_modify => /schedule/appointments/:id/:modification(.:format)
          // function(id, modification, options)
          appointment_modify: Utils.route(
            [
              ['id', true],
              ['modification', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'appointments', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'modification', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // appointment_modify_confirm => /schedule/appointments/:id/:modification/confirm(.:format)
          // function(id, modification, options)
          appointment_modify_confirm: Utils.route(
            [
              ['id', true],
              ['modification', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'appointments', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'modification', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'confirm', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // autoplay => /autoplay(.:format)
          // function(options)
          autoplay: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'autoplay', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // blog => /blog(.:format)
          // function(options)
          blog: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'blog', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // card_create_appeal => /language/:code/card/:id/appeals(.:format)
          // function(code, id, options)
          card_create_appeal: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'card', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'appeals', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // card_intro => /language/:code/cards_intro/:study_card_id/:side(.:format)
          // function(code, study_card_id, side, options)
          card_intro: Utils.route(
            [
              ['code', true],
              ['study_card_id', true],
              ['side', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'cards_intro', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'study_card_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'side', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // card_pop_examples => /language/:code/card/:id/examples(.:format)
          // function(code, id, options)
          card_pop_examples: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'card', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'examples', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // card_pop_intro => /language/:code/card/:id/intro(.:format)
          // function(code, id, options)
          card_pop_intro: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'card', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'intro', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards => /language/:code/cards(.:format)
          // function(code, options)
          cards: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'cards', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_add_custom => /cards/custom(.:format)
          // function(options)
          cards_add_custom: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'cards', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'custom', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // cards_debug_deck => /language/:code/cards_deck(.:format)
          // function(code, options)
          cards_debug_deck: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'cards_deck', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_finish => /language/:code/cards_finish(.:format)
          // function(code, options)
          cards_finish: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'cards_finish', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_for_lesson => /language/:code/lesson_cards/:lesson_gid(.:format)
          // function(code, lesson_gid, options)
          cards_for_lesson: Utils.route(
            [
              ['code', true],
              ['lesson_gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'lesson_cards', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'lesson_gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_for_session => /language/:code/session_cards/:session_id(.:format)
          // function(code, session_id, options)
          cards_for_session: Utils.route(
            [
              ['code', true],
              ['session_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'session_cards', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'session_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_review_session => /language/:code/review_session/:session_id(.:format)
          // function(code, session_id, options)
          cards_review_session: Utils.route(
            [
              ['code', true],
              ['session_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'review_session', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'session_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_review_session_finish => /language/:code/review_session_finish/:session_id(.:format)
          // function(code, session_id, options)
          cards_review_session_finish: Utils.route(
            [
              ['code', true],
              ['session_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'review_session_finish', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'session_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_session => /language/:code/cards/:session_id(.:format)
          // function(code, session_id, options)
          cards_session: Utils.route(
            [
              ['code', true],
              ['session_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'cards', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'session_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // cards_study_custom => /language/:code/review_cards(.:format)
          // function(code, options)
          cards_study_custom: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'review_cards', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_available => /chat/available_speakers/:code(.:format)
          // function(code, options)
          chat_available: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'available_speakers', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_check => /chat/check(.:format)
          // function(options)
          chat_check: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'check', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_complete => /chat/complete/:id(.:format)
          // function(id, options)
          chat_complete: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'complete', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_current_exercise => /chat/current_exercise(.:format)
          // function(options)
          chat_current_exercise: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'current_exercise', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_demand => /chat/demand/:code(.:format)
          // function(code, options)
          chat_demand: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'demand', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_events => /chats/:id/events(.:format)
          // function(id, options)
          chat_events: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'events', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_final => /chat/final(.:format)
          // function(options)
          chat_final: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'final', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_find_teacher => /chat/looking/:id(.:format)
          // function(id, options)
          chat_find_teacher: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'looking', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_lesson => /chat/lesson(/:id)(.:format)
          // function(options)
          chat_lesson: Utils.route(
            [
              ['id', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'lesson', false],
                    [
                      2,
                      [1, [2, [7, '/', false], [3, 'id', false]], false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_list => /chats(/:code)(.:format)
          // function(options)
          chat_list: Utils.route(
            [
              ['code', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [1, [2, [7, '/', false], [3, 'code', false]], false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ]
          ),
          // chat_local => /chat/local(.:format)
          // function(options)
          chat_local: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'local', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_nevermind => /chat/nevermind/:id(.:format)
          // function(id, options)
          chat_nevermind: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'nevermind', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_next_exercise => /chat/next_exercise(.:format)
          // function(options)
          chat_next_exercise: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'next_exercise', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_nope => /chat/nope(.:format)
          // function(options)
          chat_nope: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'nope', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_preview_modify => /chats/:code/:id/modify(.:format)
          // function(code, id, options)
          chat_preview_modify: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'modify', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_preview_skip => /chats/:code/:id/skip(.:format)
          // function(code, id, options)
          chat_preview_skip: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'skip', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_rate_exercise => /chat/rate_exercise(.:format)
          // function(options)
          chat_rate_exercise: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'rate_exercise', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_rate_exercise_by_student => /chat/rate_exercise_by_student(.:format)
          // function(options)
          chat_rate_exercise_by_student: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'rate_exercise_by_student', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_searching => /chat/searching(.:format)
          // function(options)
          chat_searching: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'searching', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_show => /chats/:code/:id(.:format)
          // function(code, id, options)
          chat_show: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_show_preview => /chats/:code/:id/preview(.:format)
          // function(code, id, options)
          chat_show_preview: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'preview', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_show_preview_exercise => /chats/:code/:id/preview/:index(.:format)
          // function(code, id, index, options)
          chat_show_preview_exercise: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['index', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'preview', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'index', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_show_preview_finish => /chats/:code/:id/preview/finish(.:format)
          // function(code, id, options)
          chat_show_preview_finish: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chats', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'preview', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'finish', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_subscribe => /chat/subscribe(.:format)
          // function(options)
          chat_subscribe: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'subscribe', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_teach => /chat/teach(.:format)
          // function(options)
          chat_teach: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'teach', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_teach_session => /chat/teach/:id(.:format)
          // function(id, options)
          chat_teach_session: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'teach', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_teach_signup => /chat/teach(.:format)
          // function(options)
          chat_teach_signup: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'teach', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_teacher_search => /chat/teacher_search/:id(.:format)
          // function(id, options)
          chat_teacher_search: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'teacher_search', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // chat_translate => /chat/translate(.:format)
          // function(options)
          chat_translate: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'chat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'translate', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // chat_wait => /chat/wait(/:id)(.:format)
          // function(options)
          chat_wait: Utils.route(
            [
              ['id', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'wait', false],
                    [
                      2,
                      [1, [2, [7, '/', false], [3, 'id', false]], false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // check_lms_course_completion => /check_lms_course_completion(.:format)
          // function(options)
          check_lms_course_completion: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'check_lms_course_completion', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // clone_stream => /streams/:id/clone(.:format)
          // function(id, options)
          clone_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'clone', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // completed_stream => /streams/:id/completed(.:format)
          // function(id, options)
          completed_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'completed', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // concept => /language/:code/concepts/:gid(.:format)
          // function(code, gid, options)
          concept: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'concepts', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // concept_finish => /language/:code/concepts/:gid(.:format)
          // function(code, gid, options)
          concept_finish: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'concepts', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // concept_pop_explanation => /language/:code/concepts/:id/pop(.:format)
          // function(code, id, options)
          concept_pop_explanation: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'concepts', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'pop', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // concepts => /language/:code/concepts(.:format)
          // function(code, options)
          concepts: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'concepts', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // connect_payments_payout => /payments/payout/connect(.:format)
          // function(options)
          connect_payments_payout: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'payout', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'connect', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // customize_study_plan => /language/:code/customize_study_plan(.:format)
          // function(code, options)
          customize_study_plan: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'customize_study_plan', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_card_modify => /deck/:code/card/:gid(.:format)
          // function(code, gid, options)
          deck_card_modify: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'card', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_concept => /deck/:code/concept/:gid(.:format)
          // function(code, gid, options)
          deck_concept: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'concept', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_concepts => /deck/:code/study/concepts/:level(.:format)
          // function(code, level, options)
          deck_concepts: Utils.route(
            [
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'concepts', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'level', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_custom => /deck/:code/custom/:gid(.:format)
          // function(code, gid, options)
          deck_custom: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'custom', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_custom_update => /deck/:code/custom/:gid(.:format)
          // function(code, gid, options)
          deck_custom_update: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'custom', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_customs => /deck/:code/study/custom/:level(.:format)
          // function(code, level, options)
          deck_customs: Utils.route(
            [
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'custom', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'level', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_sequence => /deck/:code/sequence/:gid(.:format)
          // function(code, gid, options)
          deck_sequence: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'sequence', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_study_sequence => /deck/:code/sequence/:gid(.:format)
          // function(code, gid, options)
          deck_study_sequence: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'sequence', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_study_word => /deck/:code/word/:gid(.:format)
          // function(code, gid, options)
          deck_study_word: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'word', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_word => /deck/:code/word/:gid(.:format)
          // function(code, gid, options)
          deck_word: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'word', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // deck_words => /deck/:code/study/words/:level(.:format)
          // function(code, level, options)
          deck_words: Utils.route(
            [
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'words', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'level', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // destroy_all_video_plans => /video_plans/destroy_all(.:format)
          // function(options)
          destroy_all_video_plans: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'video_plans', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'destroy_all', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // destroy_all_video_quiz_questions => /streams/:id/video_quiz_questions/destroy_all(.:format)
          // function(id, options)
          destroy_all_video_quiz_questions: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'destroy_all', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // download_payments_invoices => /payments/invoices/download(.:format)
          // function(options)
          download_payments_invoices: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'invoices', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'download', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // easymon => /ping
          // function(options)
          easymon: Utils.route([], {}, [
            2,
            [7, '/', false],
            [6, 'ping', false],
          ]),
          // easymon.root => /ping/
          // function(options)
          easymon_root: Utils.route([], {}, [
            2,
            [2, [7, '/', false], [6, 'ping', false]],
            [7, '/', false],
          ]),
          // edit_account_desktop_notifications => /account/desktop_notifications/edit(.:format)
          // function(options)
          edit_account_desktop_notifications: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'desktop_notifications', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_account_mobile_number => /account/mobile_numbers/:id/edit(.:format)
          // function(id, options)
          edit_account_mobile_number: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'mobile_numbers', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_account_notification => /account/notifications/:id/edit(.:format)
          // function(id, options)
          edit_account_notification: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'notifications', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_account_preferences => /account/preferences/edit(.:format)
          // function(options)
          edit_account_preferences: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'preferences', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'edit', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // edit_account_user_token => /account/user_tokens/:id/edit(.:format)
          // function(id, options)
          edit_account_user_token: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'user_tokens', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_mailing_address => /mailing_addresses/:id/edit(.:format)
          // function(id, options)
          edit_mailing_address: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'mailing_addresses', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_organization => /organizations/:id/edit(.:format)
          // function(id, options)
          edit_organization: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'organizations', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_password_reset => /password_resets/:id/edit(.:format)
          // function(id, options)
          edit_password_reset: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'password_resets', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_payments_bundle => /payments/bundles/:id/edit(.:format)
          // function(id, options)
          edit_payments_bundle: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'bundles', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_payments_invoice => /payments/invoices/:id/edit(.:format)
          // function(id, options)
          edit_payments_invoice: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'invoices', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_payments_payout => /payments/payout/edit(.:format)
          // function(options)
          edit_payments_payout: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'payout', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'edit', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // edit_payments_subscription => /payments/subscription/edit(.:format)
          // function(options)
          edit_payments_subscription: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'subscription', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'edit', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // edit_payments_transaction => /payments/transactions/:id/edit(.:format)
          // function(id, options)
          edit_payments_transaction: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'transactions', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'edit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_potential_appointment => /potential_appointments/:id/edit(.:format)
          // function(id, options)
          edit_potential_appointment: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'potential_appointments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_quiz_question => /streams/:id/quiz_questions/:quiz_question_id/edit(.:format)
          // function(id, quiz_question_id, options)
          edit_quiz_question: Utils.route(
            [
              ['id', true],
              ['quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'quiz_question_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'edit', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_stream => /streams/:id/edit(.:format)
          // function(id, options)
          edit_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_video_plan => /video_plans/:id/edit(.:format)
          // function(id, options)
          edit_video_plan: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'video_plans', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'edit', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_video_quiz => /streams/:id/video_quizzes/:video_quiz_id/edit(.:format)
          // function(id, video_quiz_id, options)
          edit_video_quiz: Utils.route(
            [
              ['id', true],
              ['video_quiz_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quizzes', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'edit', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_video_quiz_question => /streams/:id/video_quiz_questions/:video_quiz_question_id/edit(.:format)
          // function(id, video_quiz_question_id, options)
          edit_video_quiz_question: Utils.route(
            [
              ['id', true],
              ['video_quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_question_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'edit', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // edit_video_slide => /streams/:id/video_slides/:video_slide_id/edit(.:format)
          // function(id, video_slide_id, options)
          edit_video_slide: Utils.route(
            [
              ['id', true],
              ['video_slide_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_slides', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_slide_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'edit', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // email_verify => /email/verify/:login/:code(.:format)
          // function(login, code, options)
          email_verify: Utils.route(
            [
              ['login', true],
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'email', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'verify', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'login', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'code', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // en => /en(.:format)
          // function(options)
          en: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'en', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // exercise_note => /chat/exercise_note/:id(.:format)
          // function(id, options)
          exercise_note: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'exercise_note', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // exercises_list => /exercises(/:code)(.:format)
          // function(options)
          exercises_list: Utils.route(
            [
              ['code', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'exercises', false],
                [
                  2,
                  [1, [2, [7, '/', false], [3, 'code', false]], false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ]
          ),
          // exercises_show => /exercises/:code/:id(.:format)
          // function(code, id, options)
          exercises_show: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'exercises', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // forum => /forum(.:format)
          // function(options)
          forum: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'forum', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // go_live_stream => /streams/:id/go_live(.:format)
          // function(id, options)
          go_live_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'go_live', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // graphiql => /graphiql(.:format)
          // function(options)
          graphiql: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'graphiql', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // help => /help(.:format)
          // function(options)
          help: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'help', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // help_tutor_update_how_to_be_a_successful_tutor => /help/tutor/update_how_to_be_a_successful_tutor(.:format)
          // function(options)
          help_tutor_update_how_to_be_a_successful_tutor: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'help', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'tutor', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'update_how_to_be_a_successful_tutor', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // home => /home(.:format)
          // function(options)
          home: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'home', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // home_org => /home/org(.:format)
          // function(options)
          home_org: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'home', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'org', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // home_student => /home/:code(.:format)
          // function(code, options)
          home_student: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'home', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // home_tutor => /home/tutor(.:format)
          // function(options)
          home_tutor: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'home', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // image_preview_streams => /streams/image_preview(.:format)
          // function(options)
          image_preview_streams: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'image_preview', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // journal_show => /:login/journal/:slug(.:format)
          // function(login, slug, options)
          journal_show: Utils.route(
            [
              ['login', true],
              ['slug', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [3, 'login', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'journal', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'slug', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_edit => /journals/:code/:id(.:format)
          // function(code, id, options)
          journals_edit: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_first_time_test => /journals/:code/:id/first_time_test(.:format)
          // function(code, id, options)
          journals_first_time_test: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'first_time_test', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_intro_test => /journals/:code/:id/intro_test(.:format)
          // function(code, id, options)
          journals_intro_test: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'intro_test', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_media => /journals/:code/media/:gid(.:format)
          // function(code, gid, options)
          journals_media: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'media', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_new => /journals/:code/new(.:format)
          // function(code, options)
          journals_new: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'new', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_translate => /language/translate(.:format)
          // function(options)
          journals_translate: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'language', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'translate', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // journals_update => /journals/:code/:id(.:format)
          // function(code, id, options)
          journals_update: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // journals_wrapup => /journals/:code/wrapup/:id(.:format)
          // function(code, id, options)
          journals_wrapup: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'wrapup', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_activity_redirect => /language/:code/activity/:activity(.:format)
          // function(code, activity, options)
          language_activity_redirect: Utils.route(
            [
              ['code', true],
              ['activity', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'activity', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'activity', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_add => /language/add(.:format)
          // function(options)
          language_add: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'language', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'add', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // language_autostudy => /language/study(.:format)
          // function(options)
          language_autostudy: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'language', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'study', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // language_award => /language/:code/award(.:format)
          // function(code, options)
          language_award: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'award', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_change_level => /language/:code/change_level/:level(.:format)
          // function(code, level, options)
          language_change_level: Utils.route(
            [
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'change_level', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'level', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_defer_level => /language/:code/defer_level(.:format)
          // function(code, options)
          language_defer_level: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'defer_level', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_define_word => /readings/:code/define/word/:id(.:format)
          // function(code, id, options)
          language_define_word: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'define', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'word', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'id', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_goals => /language/:code/goals(.:format)
          // function(code, options)
          language_goals: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'goals', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_journal => /journals/:code(.:format)
          // function(code, options)
          language_journal: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'journals', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // language_lesson => /language/:code/lesson/:unit(.:format)
          // function(code, unit, options)
          language_lesson: Utils.route(
            [
              ['code', true],
              ['unit', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'lesson', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'unit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_level => /language/:code/level(/:type)(.:format)
          // function(code, options)
          language_level: Utils.route(
            [
              ['code', true],
              ['type', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'level', false],
                        [
                          2,
                          [1, [2, [7, '/', false], [3, 'type', false]], false],
                          [
                            1,
                            [2, [8, '.', false], [3, 'format', false]],
                            false,
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_level_progress => /language/:code/level_progress/:level(.:format)
          // function(code, level, options)
          language_level_progress: Utils.route(
            [
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'level_progress', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'level', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media => /media/:code/:gid(.:format)
          // function(code, gid, options)
          language_media: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_define_word => /media/:code/define/word/:word(.:format)
          // function(code, word, options)
          language_media_define_word: Utils.route(
            [
              ['code', true],
              ['word', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'define', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'word', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'word', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_first_time_test => /media/:code/:gid/first_time_test(.:format)
          // function(code, gid, options)
          language_media_first_time_test: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'first_time_test', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_list => /media/:code/list(.:format)
          // function(code, options)
          language_media_list: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'list', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_study_word => /media/:code/define/word/:word(.:format)
          // function(code, word, options)
          language_media_study_word: Utils.route(
            [
              ['code', true],
              ['word', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'define', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'word', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'word', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_update => /media/:code/:gid(.:format)
          // function(code, gid, options)
          language_media_update: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_update_rating => /media/:code/rating/:gid(.:format)
          // function(code, gid, options)
          language_media_update_rating: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'rating', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_media_wrapup => /media/:code/wrapup/:gid(.:format)
          // function(code, gid, options)
          language_media_wrapup: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'wrapup', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_modify => /account/languages/:modify(.:format)
          // function(modify, options)
          language_modify: Utils.route(
            [
              ['modify', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'languages', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'modify', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_new => /language/new(.:format)
          // function(options)
          language_new: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'language', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // language_progress => /language/:code/progress(.:format)
          // function(code, options)
          language_progress: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'progress', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_reading => /readings/:code/:gid(.:format)
          // function(code, gid, options)
          language_reading: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_reading_first_time_test => /readings/:code/:gid/first_time_test(.:format)
          // function(code, gid, options)
          language_reading_first_time_test: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'first_time_test', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_reading_update => /readings/:code/:gid(.:format)
          // function(code, gid, options)
          language_reading_update: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_reading_update_rating => /readings/:code/rating/:gid(.:format)
          // function(code, gid, options)
          language_reading_update_rating: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'rating', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_reading_wrapup => /readings/:code/wrapup/:gid(.:format)
          // function(code, gid, options)
          language_reading_wrapup: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'wrapup', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'gid', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_readings => /readings/:code(.:format)
          // function(code, options)
          language_readings: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'readings', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // language_study => /language/:code/study(.:format)
          // function(code, options)
          language_study: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_switch => /language/switch(.:format)
          // function(options)
          language_switch: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'language', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'switch', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // language_test => /language/:code/test(.:format)
          // function(code, options)
          language_test: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'test', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_unit => /language/:code/unit/:unit(.:format)
          // function(code, unit, options)
          language_unit: Utils.route(
            [
              ['code', true],
              ['unit', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'unit', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'unit', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // language_unit_unlock => /language/:code/unit/:unit/unlock(.:format)
          // function(code, unit, options)
          language_unit_unlock: Utils.route(
            [
              ['code', true],
              ['unit', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'unit', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'unit', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'unlock', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // login => /login(.:format)
          // function(options)
          login: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'login', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // logout => /logout(.:format)
          // function(options)
          logout: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'logout', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // lookup_payments_offers => /payments/offers/lookup(.:format)
          // function(options)
          lookup_payments_offers: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'offers', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'lookup', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // mailing_address => /mailing_addresses/:id(.:format)
          // function(id, options)
          mailing_address: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'mailing_addresses', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // mailing_addresses => /mailing_addresses(.:format)
          // function(options)
          mailing_addresses: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'mailing_addresses', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // move_down_video_plan => /video_plans/:id/move_down(.:format)
          // function(id, options)
          move_down_video_plan: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'video_plans', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'move_down', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // move_down_video_quiz_question => /streams/:id/video_quiz_questions/:video_quiz_question_id/move_down(.:format)
          // function(id, video_quiz_question_id, options)
          move_down_video_quiz_question: Utils.route(
            [
              ['id', true],
              ['video_quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_question_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'move_down', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // move_up_video_plan => /video_plans/:id/move_up(.:format)
          // function(id, options)
          move_up_video_plan: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'video_plans', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'move_up', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // move_up_video_quiz_question => /streams/:id/video_quiz_questions/:video_quiz_question_id/move_up(.:format)
          // function(id, video_quiz_question_id, options)
          move_up_video_quiz_question: Utils.route(
            [
              ['id', true],
              ['video_quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_question_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'move_up', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // move_video_plan => /video_plans/:id/move(.:format)
          // function(id, options)
          move_video_plan: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'video_plans', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'move', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // move_video_quiz_question => /streams/:id/video_quiz_questions/:video_quiz_question_id/move(.:format)
          // function(id, video_quiz_question_id, options)
          move_video_quiz_question: Utils.route(
            [
              ['id', true],
              ['video_quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_question_id', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [6, 'move', false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // mux_hooks => /mux/hook(.:format)
          // function(options)
          mux_hooks: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'mux', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'hook', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_account_desktop_notifications => /account/desktop_notifications/new(.:format)
          // function(options)
          new_account_desktop_notifications: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'account', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'desktop_notifications', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'new', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // new_account_mobile_number => /account/mobile_numbers/new(.:format)
          // function(options)
          new_account_mobile_number: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'mobile_numbers', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_account_notification => /account/notifications/new(.:format)
          // function(options)
          new_account_notification: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'notifications', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_account_preferences => /account/preferences/new(.:format)
          // function(options)
          new_account_preferences: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'preferences', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_account_user_token => /account/user_tokens/new(.:format)
          // function(options)
          new_account_user_token: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'user_tokens', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_mailing_address => /mailing_addresses/new(.:format)
          // function(options)
          new_mailing_address: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'mailing_addresses', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_organization => /organizations/new(.:format)
          // function(options)
          new_organization: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'organizations', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_password_reset => /password_resets/new(.:format)
          // function(options)
          new_password_reset: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'password_resets', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_payments_bundle => /payments/bundles/new(.:format)
          // function(options)
          new_payments_bundle: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'bundles', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_payments_invoice => /payments/invoices/new(.:format)
          // function(options)
          new_payments_invoice: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'invoices', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_payments_payout => /payments/payout/new(.:format)
          // function(options)
          new_payments_payout: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'payout', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_payments_subscription => /payments/subscription/new(.:format)
          // function(options)
          new_payments_subscription: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'subscription', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_payments_transaction => /payments/transactions/new(.:format)
          // function(options)
          new_payments_transaction: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'transactions', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'new', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // new_potential_appointment => /potential_appointments/new(.:format)
          // function(options)
          new_potential_appointment: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'potential_appointments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_quiz_question => /streams/:id/quiz_questions/new(.:format)
          // function(id, options)
          new_quiz_question: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'new', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // new_stream => /streams/new(.:format)
          // function(options)
          new_stream: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_user_language => /user_languages(/:selected_code)(.:format)
          // function(options)
          new_user_language: Utils.route(
            [
              ['selected_code', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'user_languages', false],
                [
                  2,
                  [1, [2, [7, '/', false], [3, 'selected_code', false]], false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ]
          ),
          // new_video_plan => /video_plans/new(.:format)
          // function(options)
          new_video_plan: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'video_plans', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // new_video_quiz => /streams/:id/video_quizzes/new(.:format)
          // function(id, options)
          new_video_quiz: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quizzes', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'new', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // new_video_quiz_question => /streams/:id/video_quiz_questions/new(.:format)
          // function(id, options)
          new_video_quiz_question: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'new', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // new_video_slide => /streams/:id/video_slides/new(.:format)
          // function(id, options)
          new_video_slide: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_slides', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'new', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // not_subscriber => /not_subscriber(.:format)
          // function(options)
          not_subscriber: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'not_subscriber', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // notifications => /notifications(.:format)
          // function(options)
          notifications: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'notifications', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // organization => /organizations/:id(.:format)
          // function(id, options)
          organization: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'organizations', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // organization_new_source => /account/organizations/source(.:format)
          // function(options)
          organization_new_source: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'organizations', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'source', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // organization_registrations => /org-registration(.:format)
          // function(options)
          organization_registrations: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'org-registration', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // organizations => /organizations(.:format)
          // function(options)
          organizations: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'organizations', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // organizations_change_member => /orgs/change_member/:id/:user_id(.:format)
          // function(id, user_id, options)
          organizations_change_member: Utils.route(
            [
              ['id', true],
              ['user_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'change_member', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'user_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_invite_users => /orgs/:id/invite_users(.:format)
          // function(id, options)
          organizations_invite_users: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'invite_users', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_join => /orgs/join/:id/:code(.:format)
          // function(id, code, options)
          organizations_join: Utils.route(
            [
              ['id', true],
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'join', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'code', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_join_org => /orgs/join_org/:id/:code(.:format)
          // function(id, code, options)
          organizations_join_org: Utils.route(
            [
              ['id', true],
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'join_org', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'code', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_modify_member => /orgs/modify_member/:id/:user_id(.:format)
          // function(id, user_id, options)
          organizations_modify_member: Utils.route(
            [
              ['id', true],
              ['user_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'modify_member', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'user_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_remove_member => /orgs/remove_member/:id/:user_id(.:format)
          // function(id, user_id, options)
          organizations_remove_member: Utils.route(
            [
              ['id', true],
              ['user_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'remove_member', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'user_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_resend_invite => /orgs/resend_invite/:id(.:format)
          // function(id, options)
          organizations_resend_invite: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'resend_invite', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // organizations_revoke_invite => /orgs/revoke_invite/:id(.:format)
          // function(id, options)
          organizations_revoke_invite: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'orgs', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'revoke_invite', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // orientation_note => /chat/orientation_note/:id(.:format)
          // function(id, options)
          orientation_note: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'orientation_note', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // password_reset => /password_resets/:id(.:format)
          // function(id, options)
          password_reset: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'password_resets', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // password_resets => /password_resets(.:format)
          // function(options)
          password_resets: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'password_resets', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // pay_pending_invoice_payments_subscription => /payments/subscription/pay_pending_invoice(.:format)
          // function(options)
          pay_pending_invoice_payments_subscription: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'subscription', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'pay_pending_invoice', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payment_rails_hooks => /payment_rails/hook(.:format)
          // function(options)
          payment_rails_hooks: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payment_rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'hook', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments => /payments(.:format)
          // function(options)
          payments: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // payments_bundle => /payments/bundles/:id(.:format)
          // function(id, options)
          payments_bundle: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'bundles', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payments_bundles => /payments/bundles(.:format)
          // function(options)
          payments_bundles: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'bundles', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_invoice => /payments/invoices/:id(.:format)
          // function(id, options)
          payments_invoice: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'invoices', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payments_invoices => /payments/invoices(.:format)
          // function(options)
          payments_invoices: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'invoices', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_offer => /payments/offers/:id(.:format)
          // function(id, options)
          payments_offer: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'offers', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payments_pay_invoice => /payments/pay_invoice(.:format)
          // function(options)
          payments_pay_invoice: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'pay_invoice', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_pay_outstanding => /payments/pay_outstanding(.:format)
          // function(options)
          payments_pay_outstanding: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'pay_outstanding', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_payout => /payments/payout(.:format)
          // function(options)
          payments_payout: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'payout', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_plans => /payments/plans/:code(.:format)
          // function(code, options)
          payments_plans: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'plans', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payments_sources => /payments/sources(.:format)
          // function(options)
          payments_sources: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'sources', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_setup_intents => /payments/setup_intents(.:format)
          // function(options)
          payments_setup_intents: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'setup_intents', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_methods => /payments/methods(.:format)
          // function(options)
          payments_methods: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'methods', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_subscription => /payments/subscription(.:format)
          // function(options)
          payments_subscription: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'subscription', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_thankyou => /payments/thankyou(.:format)
          // function(options)
          payments_thankyou: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'thankyou', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_transaction => /payments/transactions/:id(.:format)
          // function(id, options)
          payments_transaction: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'transactions', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // payments_transactions => /payments/transactions(.:format)
          // function(options)
          payments_transactions: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'transactions', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // payments_tutor_pay => /payments/tutor/pay(.:format)
          // function(options)
          payments_tutor_pay: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'payments', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'pay', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // pending_payment_payments_subscription => /payments/subscription/pending_payment(.:format)
          // function(options)
          pending_payment_payments_subscription: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'payments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'subscription', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'pending_payment', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // placement => /language/:code/placement(.:format)
          // function(code, options)
          placement: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'placement', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // placement_finish => /language/:code/placement_finish(.:format)
          // function(code, options)
          placement_finish: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'placement_finish', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // placement_resolve => /language/:code/placement_resolve/:status(.:format)
          // function(code, status, options)
          placement_resolve: Utils.route(
            [
              ['code', true],
              ['status', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'placement_resolve', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'status', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // placement_review_card => /language/:code/placement_review(.:format)
          // function(code, options)
          placement_review_card: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'placement_review', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // potential_appointment => /potential_appointments/:id(.:format)
          // function(id, options)
          potential_appointment: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'potential_appointments', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // potential_appointments => /potential_appointments(.:format)
          // function(options)
          potential_appointments: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'potential_appointments', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // predictions => /language/:code/predictions(.:format)
          // function(code, options)
          predictions: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'predictions', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // profile => /:login
          // function(login, options)
          profile: Utils.route([['login', true]], {}, [
            2,
            [7, '/', false],
            [3, 'login', false],
          ]),
          // public_concept => /grammar/:language/:slug(.:format)
          // function(language, slug, options)
          public_concept: Utils.route(
            [
              ['language', true],
              ['slug', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'grammar', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'slug', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // public_concepts => /grammar/:language(.:format)
          // function(language, options)
          public_concepts: Utils.route(
            [
              ['language', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'grammar', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'language', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // public_media => /media/:language/watch/:slug(.:format)
          // function(language, slug, options)
          public_media: Utils.route(
            [
              ['language', true],
              ['slug', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'watch', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'slug', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // public_media_captions => /media/:code/:gid/captions(.:format)
          // function(code, gid, options)
          public_media_captions: Utils.route(
            [
              ['code', true],
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'captions', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // public_media_level => /media/:language/level/:cefr(.:format)
          // function(language, cefr, options)
          public_media_level: Utils.route(
            [
              ['language', true],
              ['cefr', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'language', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'level', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'cefr', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // public_media_list => /media/:language(.:format)
          // function(language, options)
          public_media_list: Utils.route(
            [
              ['language', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'media', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'language', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // publish_plan_stream => /streams/:id/publish_plan(.:format)
          // function(id, options)
          publish_plan_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'publish_plan', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // quiz_question => /streams/:id/quiz_questions/:quiz_question_id(.:format)
          // function(id, quiz_question_id, options)
          quiz_question: Utils.route(
            [
              ['id', true],
              ['quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'quiz_question_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // quiz_questions => /streams/:id/quiz_questions(.:format)
          // function(id, options)
          quiz_questions: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'quiz_questions', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // rack_headers => /rack_headers(.:format)
          // function(options)
          rack_headers: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rack_headers', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
          // function(signed_blob_id, variation_key, filename, options)
          rails_blob_representation: Utils.route(
            [
              ['signed_blob_id', true],
              ['variation_key', true],
              ['filename', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'representations', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'redirect', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'signed_blob_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'variation_key', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [5, [3, '*filename', false], false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
          // function(signed_blob_id, variation_key, filename, options)
          rails_blob_representation_proxy: Utils.route(
            [
              ['signed_blob_id', true],
              ['variation_key', true],
              ['filename', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'representations', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'proxy', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'signed_blob_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [3, 'variation_key', false],
                                    [
                                      2,
                                      [7, '/', false],
                                      [
                                        2,
                                        [5, [3, '*filename', false], false],
                                        [
                                          1,
                                          [
                                            2,
                                            [8, '.', false],
                                            [3, 'format', false],
                                          ],
                                          false,
                                        ],
                                      ],
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
          // function(options)
          rails_direct_uploads: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'active_storage', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'direct_uploads', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
          // function(encoded_key, filename, options)
          rails_disk_service: Utils.route(
            [
              ['encoded_key', true],
              ['filename', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'disk', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'encoded_key', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [5, [3, '*filename', false], false],
                                [
                                  1,
                                  [2, [8, '.', false], [3, 'format', false]],
                                  false,
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // rails_info => /rails/info(.:format)
          // function(options)
          rails_info: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'info', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // rails_info_properties => /rails/info/properties(.:format)
          // function(options)
          rails_info_properties: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'info', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'properties', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // rails_info_routes => /rails/info/routes(.:format)
          // function(options)
          rails_info_routes: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'info', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'routes', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // rails_mailers => /rails/mailers(.:format)
          // function(options)
          rails_mailers: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'rails', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'mailers', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
          // function(signed_id, filename, options)
          rails_service_blob: Utils.route(
            [
              ['signed_id', true],
              ['filename', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'blobs', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'redirect', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'signed_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [5, [3, '*filename', false], false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
          // function(signed_id, filename, options)
          rails_service_blob_proxy: Utils.route(
            [
              ['signed_id', true],
              ['filename', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'blobs', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'proxy', false],
                            [
                              2,
                              [7, '/', false],
                              [
                                2,
                                [3, 'signed_id', false],
                                [
                                  2,
                                  [7, '/', false],
                                  [
                                    2,
                                    [5, [3, '*filename', false], false],
                                    [
                                      1,
                                      [
                                        2,
                                        [8, '.', false],
                                        [3, 'format', false],
                                      ],
                                      false,
                                    ],
                                  ],
                                ],
                              ],
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // recording_privacy_preference => /recording_privacy_preference(.:format)
          // function(options)
          recording_privacy_preference: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'recording_privacy_preference', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // referral => /r/:code(.:format)
          // function(code, options)
          referral: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'r', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // referral_w_lang => /r/:code/:lang_code(.:format)
          // function(code, lang_code, options)
          referral_w_lang: Utils.route(
            [
              ['code', true],
              ['lang_code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'r', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'lang_code', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // referrals_claim => /referrals/claim(.:format)
          // function(options)
          referrals_claim: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'claim', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // referrals_claim_credits => /referrals/claim/credits(.:format)
          // function(options)
          referrals_claim_credits: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'claim', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'credits', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // referrals_claim_discount => /referrals/claim/discount(.:format)
          // function(options)
          referrals_claim_discount: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'claim', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'discount', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // referrals_claim_invoice => /referrals/claim/invoice(.:format)
          // function(options)
          referrals_claim_invoice: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'claim', false],
                  [
                    2,
                    [7, '/', false],
                    [
                      2,
                      [6, 'invoice', false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ],
          ]),
          // referrals_details => /referrals/details(.:format)
          // function(options)
          referrals_details: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'details', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // referrals_index => /referrals(.:format)
          // function(options)
          referrals_index: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'referrals', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // register => /register(.:format)
          // function(options)
          register: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'register', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // register_interest => /interest/new(.:format)
          // function(options)
          register_interest: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'interest', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'new', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // remove_organization => /account/remove_organization(.:format)
          // function(options)
          remove_organization: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'remove_organization', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // revenue_cat_hooks => /revenue_cat/hook(.:format)
          // function(options)
          revenue_cat_hooks: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'revenue_cat', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'hook', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // review_card => /language/:code/review_card(.:format)
          // function(code, options)
          review_card: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'review_card', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // root => /
          // function(options)
          root: Utils.route([], {}, [7, '/', false]),
          // schedule_availability => /schedule/availability(.:format)
          // function(options)
          schedule_availability: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'availability', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_available_bookings => /schedule/available_bookings(.:format)
          // function(options)
          schedule_available_bookings: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'available_bookings', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_events => /schedule/events(.:format)
          // function(options)
          schedule_events: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'events', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_feed => /schedule/feed(.:format)
          // function(options)
          schedule_feed: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'feed', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_ical => /schedule/ical/:token(.:format)
          // function(token, options)
          schedule_ical: Utils.route(
            [
              ['token', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'ical', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'token', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_index => /schedule(.:format)
          // function(options)
          schedule_index: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // schedule_index_student => /schedule/:code(.:format)
          // function(code, options)
          schedule_index_student: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // schedule_index_tutor => /schedule/tutor(.:format)
          // function(options)
          schedule_index_tutor: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_item_save => /schedule/item/:id(.:format)
          // function(id, options)
          schedule_item_save: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'item', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_item_update => /schedule/item(.:format)
          // function(options)
          schedule_item_update: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'item', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_orientation => /schedule/orientation(.:format)
          // function(options)
          schedule_orientation: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'orientation', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_preferred => /schedule/:code/preferred(.:format)
          // function(code, options)
          schedule_preferred: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'preferred', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_preferred_redirect => /schedule/preferred(.:format)
          // function(options)
          schedule_preferred_redirect: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'preferred', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_setup => /schedule/setup(.:format)
          // function(options)
          schedule_setup: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'setup', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_tutor_book_training => /schedule/tutor_book_training(.:format)
          // function(options)
          schedule_tutor_book_training: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor_book_training', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_tutor_info => /schedule/:code/tutor/:id(.:format)
          // function(code, id, options)
          schedule_tutor_info: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'tutor', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_tutor_manage => /schedule/:code/tutors(.:format)
          // function(code, options)
          schedule_tutor_manage: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'tutors', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_tutor_manage_redirect => /schedule/tutors(.:format)
          // function(options)
          schedule_tutor_manage_redirect: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutors', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_tutor_modify => /schedule/:code/tutor/:id(.:format)
          // function(code, id, options)
          schedule_tutor_modify: Utils.route(
            [
              ['code', true],
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'schedule', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'tutor', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // schedule_tutor_test => /schedule/tutor_test(.:format)
          // function(options)
          schedule_tutor_test: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor_test', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_tutor_test_bookings => /schedule/tutor_test_bookings(.:format)
          // function(options)
          schedule_tutor_test_bookings: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor_test_bookings', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // schedule_upcoming => /schedule/upcoming(.:format)
          // function(options)
          schedule_upcoming: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'schedule', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'upcoming', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // search => /search(.:format)
          // function(options)
          search: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'search', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // search_quiz_question => /streams/:id/quiz_questions/search(.:format)
          // function(id, options)
          search_quiz_question: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'search', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // search_video_slide => /streams/:id/video_slides/search(.:format)
          // function(id, options)
          search_video_slide: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_slides', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [6, 'search', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // search_word => /search/:code/word(.:format)
          // function(code, options)
          search_word: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'search', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'word', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // session_verify => /chat/verify/:id(.:format)
          // function(id, options)
          session_verify: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'chat', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'verify', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // set_tutor_goals => /goals/tutor(.:format)
          // function(options)
          set_tutor_goals: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'goals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // show_term => /language/:code/term/:term(.:format)
          // function(code, term, options)
          show_term: Utils.route(
            [
              ['code', true],
              ['term', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'term', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'term', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // sidekiq_web => /sidekiq
          // function(options)
          sidekiq_web: Utils.route([], {}, [
            2,
            [7, '/', false],
            [6, 'sidekiq', false],
          ]),
          // signup => /signup(/:selected_code)(.:format)
          // function(options)
          signup: Utils.route(
            [
              ['selected_code', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'signup', false],
                [
                  2,
                  [1, [2, [7, '/', false], [3, 'selected_code', false]], false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ]
          ),
          // sitemap => /sitemap-dynamic(.:format)
          // function(options)
          sitemap: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'sitemap-dynamic', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // sms_incoming => /sms/reply/:token(.:format)
          // function(token, options)
          sms_incoming: Utils.route(
            [
              ['token', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'sms', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'reply', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'token', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // split_dashboard => /split
          // function(options)
          split_dashboard: Utils.route([], {}, [
            2,
            [7, '/', false],
            [6, 'split', false],
          ]),
          // sso_discourse => /sso/discourse(.:format)
          // function(options)
          sso_discourse: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'sso', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'discourse', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // stream => /streams/:id(.:format)
          // function(id, options)
          stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // streamer => /streams/streamer(.:format)
          // function(options)
          streamer: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'streamer', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // streams => /streams(.:format)
          // function(options)
          streams: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // streams_home => /streams/home(.:format)
          // function(options)
          streams_home: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'home', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // streams_player => /streams/player/:id(.:format)
          // function(id, options)
          streams_player: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'player', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // streams_video => /streams/video/:id(.:format)
          // function(id, options)
          streams_video: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'video', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // stripe_hooks => /stripe/hook(.:format)
          // function(options)
          stripe_hooks: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'stripe', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'hook', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // study_card => /deck/card/:gid(.:format)
          // function(gid, options)
          study_card: Utils.route(
            [
              ['gid', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'deck', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'card', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'gid', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // study_plans => /language/:code/study_plans(.:format)
          // function(code, options)
          study_plans: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study_plans', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // studyable => /language/:code/studyable/:s(.:format)
          // function(code, s, options)
          studyable: Utils.route(
            [
              ['code', true],
              ['s', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'studyable', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 's', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // survey => /language/:code/survey(.:format)
          // function(code, options)
          survey: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'survey', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // suspended => /suspended(.:format)
          // function(options)
          suspended: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'suspended', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // templates => /streams/templates/:id(.:format)
          // function(id, options)
          templates: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'templates', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'id', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // test_streams_reactions => /test_streams/:id/reactions(.:format)
          // function(id, options)
          test_streams_reactions: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'test_streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'reactions', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // test_streams_streams => /test_streams/:id/streams(.:format)
          // function(id, options)
          test_streams_streams: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'test_streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'streams', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // test_streams_video_responses => /test_streams/:id/video_responses(.:format)
          // function(id, options)
          test_streams_video_responses: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'test_streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_responses', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // test_streams_viewings => /test_streams/:id/viewings(.:format)
          // function(id, options)
          test_streams_viewings: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'test_streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'viewings', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // tour_tutor => /tour/tutor(/:page)(.:format)
          // function(options)
          tour_tutor: Utils.route(
            [
              ['page', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'tour', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'tutor', false],
                    [
                      2,
                      [1, [2, [7, '/', false], [3, 'page', false]], false],
                      [1, [2, [8, '.', false], [3, 'format', false]], false],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // turbo_recede_historical_location => /recede_historical_location(.:format)
          // function(options)
          turbo_recede_historical_location: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'recede_historical_location', false],
                [1, [2, [8, '.', false], [3, 'format', false]], false],
              ],
            ]
          ),
          // turbo_refresh_historical_location => /refresh_historical_location(.:format)
          // function(options)
          turbo_refresh_historical_location: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'refresh_historical_location', false],
                [1, [2, [8, '.', false], [3, 'format', false]], false],
              ],
            ]
          ),
          // turbo_resume_historical_location => /resume_historical_location(.:format)
          // function(options)
          turbo_resume_historical_location: Utils.route(
            [['format', false]],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'resume_historical_location', false],
                [1, [2, [8, '.', false], [3, 'format', false]], false],
              ],
            ]
          ),
          // tutor_goals => /goals/tutor(.:format)
          // function(options)
          tutor_goals: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'goals', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // tutor_onboarding => /start/tutor(.:format)
          // function(options)
          tutor_onboarding: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'start', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // tutor_stats => /stats/tutor(.:format)
          // function(options)
          tutor_stats: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'stats', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // tutors_register => /tutors/register(.:format)
          // function(options)
          tutors_register: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'tutors', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'register', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // unit_selector_streams => /streams/unit_selector(.:format)
          // function(options)
          unit_selector_streams: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'streams', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'unit_selector', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // unlock_c_level => /language/:code/unlock_c_level(.:format)
          // function(code, options)
          unlock_c_level: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'unlock_c_level', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // unpublish_all_plans_stream => /streams/:id/unpublish_all_plans(.:format)
          // function(id, options)
          unpublish_all_plans_stream: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'unpublish_all_plans', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // upcoming_sessions => /upcoming_sessions(/:code)(.:format)
          // function(options)
          upcoming_sessions: Utils.route(
            [
              ['code', false],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'upcoming_sessions', false],
                [
                  2,
                  [1, [2, [7, '/', false], [3, 'code', false]], false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ]
          ),
          // update_organization_info => /account/organization_info(.:format)
          // function(options)
          update_organization_info: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'organization_info', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
          // function(encoded_token, options)
          update_rails_disk_service: Utils.route(
            [
              ['encoded_token', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'rails', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'active_storage', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'disk', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'encoded_token', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // update_study_plan => /language/:code/study_plan(.:format)
          // function(code, options)
          update_study_plan: Utils.route(
            [
              ['code', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'language', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'code', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'study_plan', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // update_time_format => /account/time_format(.:format)
          // function(options)
          update_time_format: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'account', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'time_format', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // update_tour_tutor => /tour/tutor(.:format)
          // function(options)
          update_tour_tutor: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'tour', false],
              [
                2,
                [7, '/', false],
                [
                  2,
                  [6, 'tutor', false],
                  [1, [2, [8, '.', false], [3, 'format', false]], false],
                ],
              ],
            ],
          ]),
          // user_certification => /:login/certification/:code/:level(.:format)
          // function(login, code, level, options)
          user_certification: Utils.route(
            [
              ['login', true],
              ['code', true],
              ['level', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [3, 'login', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [6, 'certification', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [3, 'code', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'level', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // user_languages => /user_languages(.:format)
          // function(options)
          user_languages: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'user_languages', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // video_plan => /video_plans/:id(.:format)
          // function(id, options)
          video_plan: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'video_plans', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [1, [2, [8, '.', false], [3, 'format', false]], false],
                  ],
                ],
              ],
            ]
          ),
          // video_plans => /video_plans(.:format)
          // function(options)
          video_plans: Utils.route([['format', false]], {}, [
            2,
            [7, '/', false],
            [
              2,
              [6, 'video_plans', false],
              [1, [2, [8, '.', false], [3, 'format', false]], false],
            ],
          ]),
          // video_quiz => /streams/:id/video_quizzes/:video_quiz_id(.:format)
          // function(id, video_quiz_id, options)
          video_quiz: Utils.route(
            [
              ['id', true],
              ['video_quiz_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quizzes', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // video_quiz_question => /streams/:id/video_quiz_questions/:video_quiz_question_id(.:format)
          // function(id, video_quiz_question_id, options)
          video_quiz_question: Utils.route(
            [
              ['id', true],
              ['video_quiz_question_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_quiz_question_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // video_quiz_questions => /streams/:id/video_quiz_questions(.:format)
          // function(id, options)
          video_quiz_questions: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quiz_questions', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // video_quizzes => /streams/:id/video_quizzes(.:format)
          // function(id, options)
          video_quizzes: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_quizzes', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // video_slide => /streams/:id/video_slides/:video_slide_id(.:format)
          // function(id, video_slide_id, options)
          video_slide: Utils.route(
            [
              ['id', true],
              ['video_slide_id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_slides', false],
                        [
                          2,
                          [7, '/', false],
                          [
                            2,
                            [3, 'video_slide_id', false],
                            [
                              1,
                              [2, [8, '.', false], [3, 'format', false]],
                              false,
                            ],
                          ],
                        ],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
          // video_slides => /streams/:id/video_slides(.:format)
          // function(id, options)
          video_slides: Utils.route(
            [
              ['id', true],
              ['format', false],
            ],
            {},
            [
              2,
              [7, '/', false],
              [
                2,
                [6, 'streams', false],
                [
                  2,
                  [7, '/', false],
                  [
                    2,
                    [3, 'id', false],
                    [
                      2,
                      [7, '/', false],
                      [
                        2,
                        [6, 'video_slides', false],
                        [1, [2, [8, '.', false], [3, 'format', false]], false],
                      ],
                    ],
                  ],
                ],
              ],
            ]
          ),
        }
        routes.configure = function (config) {
          return Utils.configure(config)
        }
        routes.config = function () {
          return Utils.config()
        }
        Object.defineProperty(routes, 'defaults', {
          get: function () {
            throw new Error(
              'Namespace' +
                '.defaults is removed. Use ' +
                'Namespace' +
                '.configure() instead.'
            )
          },
          set: function (value) {},
        })
        routes.default_serializer = function (object, prefix) {
          return Utils.default_serializer(object, prefix)
        }
        return Utils.namespace(root, 'Namespace', routes)
      },
    }

    if (typeof define === 'function' && define.amd) {
      define([], function () {
        return Utils.make()
      })
    } else {
      Utils.make()
    }
  }.call(this))
}.call(routes))
export default routes.Namespace
