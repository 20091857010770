import _ from 'lodash'

// So you have a box with scrollable content on a scrollable page. You scroll to
// the end of the scrollable content in the box and it scrolls the whole page.
// You facepalm. Your heart breaks. Suddenly the page feels like ice and you are
// disoriented. Unhappy. Generally discontent.
//
// The ParentScrollPreventer is your saviour.

export default class ParentScrollPreventer {
  constructor(element, options) {
    this.element = element
    this.options = _.defaults({}, options, {
      childSelector: null,
      preventedSelector: 'body',
    })

    this.preventedEl = document.querySelector(this.options.preventedSelector)

    let $element = $(element)
    if (this.options.childSelector) {
      $element.on(
        'mouseenter',
        this.options.childSelector,
        this.handleMouseEnter.bind(this)
      )
      $element.on(
        'mouseleave',
        this.options.childSelector,
        this.handleMouseLeave.bind(this)
      )
    } else {
      $element.on('mouseenter', this.handleMouseEnter.bind(this))
      $element.on('mouseleave', this.handleMouseLeave.bind(this))
    }
  }

  handleMouseEnter() {
    this.preventedEl.classList.add('no-scroll')
  }

  handleMouseLeave() {
    this.preventedEl.classList.remove('no-scroll')
  }
}
