import React from 'react'
import { keyframes, css } from 'styled-components'

import { Box } from '@chatterbug/aaron'

const KEYFRAMES = keyframes`
  0% { background-position: 0 0 }
  50% { background-position: 100% 0 }
  100% { background-position: 0 0 }
`

const theCss: any = css`
  background: linear-gradient(
    270deg,
    ${(props) => props.theme.colors.gray95},
    ${(props) => props.theme.colors.gray88}
  );
  background-size: 200% 100%;
  animation: ${KEYFRAMES} 1.6s ease infinite;
`

const LoadingBox: typeof Box = ({ children, ...props }) => (
  <Box
    sx={{
      borderRadius: '2px',
    }}
    css={theCss}
    {...props}
  >
    {children}
  </Box>
)

export default LoadingBox
