import PrivacySelector from 'modules/privacy-selector'
import { post, bindMethods } from 'lib/util'

import BasePage from './base-page'

export default class PrivacyPage extends BasePage {
  constructor(element, options) {
    super(element, options)
    bindMethods(this)
    let studentPrivacyEl = this.element.querySelector(PrivacySelector.selector)
    this.studentPrivacy = new PrivacySelector(studentPrivacyEl, {
      onChange: this.handleChangePrivacy,
    })
  }

  handleChangePrivacy(privacy) {
    post('/account/privacy', { recording_privacy_preference: privacy }).then(
      ({ data, status }) => {
        if (status === 'success') {
          this.options.setState({ privacy })
        } else {
          console.log('error', status, data)
        }
      }
    )
  }

  // Called by OnboardingController
  nextButtonDisabled(state) {
    let { nextDisabled, privacy } = state
    return nextDisabled || !privacy
  }

  render(state, prevState) {
    let { privacy } = state
    this.studentPrivacy.render({ value: privacy }, prevState)
  }
}
