import React from 'react'

import { Box, Text } from '@chatterbug/aaron'

import { LEFT_POSITIONING, getTranslateTransform } from '../../StudentProgress'

import tooltip from './assets/tooltip.svg'

const TOOLTIP_BOX_SHADOW = {
  boxShadow:
    '0px 1px 2px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 16px rgba(0, 0, 0, 0.03)',
}

const ProgressTooltip = ({ completed }) => {
  return (
    <Box
      // Image dimensions in Figma
      width="45px"
      height="34px"
      backgroundImage={`url(${tooltip})`}
      backgroundRepeat="no-repeat"
      left={LEFT_POSITIONING(completed)}
      sx={{
        position: 'absolute',
        zIndex: '2',
        top: '10px',
        transform: getTranslateTransform(completed),
        ...TOOLTIP_BOX_SHADOW,
      }}
    >
      <Text
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -45%)`,
        }}
      >{`${completed.toFixed(0)}%`}</Text>
    </Box>
  )
}

export default ProgressTooltip
