import { get, post } from 'lib/util'

export default class LayoutTutorsManage {
  constructor(element) {
    $('#tutor-modal').on('show.bs.modal', function(e) {
      $('#tutor-info-place').html(
        '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>'
      )

      var btn = $(e.relatedTarget)
      var tutorUrl = btn.data('tutor-url')
      if (tutorUrl) {
        get(tutorUrl).then(({ status, data }) => {
          $('#tutor-info-place').html(data)
        })
      }
    })

    $('.js-tutor-sorting').sortable({
      containerSelector: 'table',
      itemPath: '> tbody',
      itemSelector: 'tr',
      handle: '.sort-handle',
      placeholder: '<tr class="placeholder"/>',
      onDrop: function($item, container, _super) {
        // get a list of the item order, post it to reordering, notify
        var o = []
        $item
          .parent()
          .find('tr')
          .each(function() {
            o.push($(this).attr('ref'))
          })

        var form = $('#update-tutor-preferences')
        post(form.attr('action'), { order: o })

        _super($item, container)
      },
    })
  }
}
