import _ from 'lodash'

import { post, bindMethods } from 'lib/util'
import Preferences from 'lib/preferences'

export default class NotificationPreferences {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    $(element).on(
      'change',
      `input[type="checkbox"]`,
      this.handlePreferenceCheckboxChange
    )
  }

  handlePreferenceCheckboxChange(e) {
    let box = e.target
    let data = { path: box.name, value: box.checked }
    post(Preferences.updateURL, data, 'json')
  }

  render(state) {
    let { desktopEnabled, mobileNumbers } = state

    // enable and disable boxes when user enables / disables desktop notifications
    let desktopBoxes = this.element.querySelectorAll(
      'input[data-type="desktop"]'
    )
    for (let box of _.toArray(desktopBoxes)) {
      box.disabled = !desktopEnabled || box.getAttribute('data-disabled')
    }

    // enable and disable boxes when user adds / removes mobile numbers
    let mobileBoxes = this.element.querySelectorAll('input[data-type="mobile"]')
    for (let box of _.toArray(mobileBoxes)) {
      box.disabled =
        !mobileNumbers ||
        mobileNumbers.length === 0 ||
        box.getAttribute('data-disabled')
    }
  }
}

NotificationPreferences.className = 'notification-preferences'
NotificationPreferences.selector = `.${NotificationPreferences.className}`
