import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Flex } from '@chatterbug/aaron'
import { put } from 'src/lib/util'

const Alert = ({
  messageType,
  message,
  buttonLink,
  buttonName,
  hideable,
  isPreview,
  alertUrl,
}) => {
  const [hidden, setHidden] = useState(false)

  if (hidden) return null
  return (
    <div hidden={hidden}>
      {isPreview && (
        <SC.PreviewNotice>
          <i className="fa fa-eye" />
          <span>{'Preview'}</span>
        </SC.PreviewNotice>
      )}

      <div
        className={`page-alert_inline alert alert-preview alert-${messageType}`}
        role="alert"
      >
        <Flex flexDirection="row" alignItems="center">
          <div style={{ flexGrow: 1 }}>
            {message || 'Your message goes here...'}
          </div>
          {buttonLink && buttonName && (
            <button
              className={`button button_${messageType}`}
              style={{ marginLeft: 15 }}
              onClick={() => {
                window.location = buttonLink
              }}
            >
              {buttonName}
            </button>
          )}
          {hideable && (
            <SC.CloseButton
              messageType={messageType}
              className={`hide_alert_button`}
              style={{ marginLeft: 15 }}
              onClick={async () => {
                setHidden(true)
                await put(alertUrl)
              }}
            >
              <span>{'X'}</span>
            </SC.CloseButton>
          )}
        </Flex>
      </div>
    </div>
  )
}

Alert.propTypes = {
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonName: PropTypes.string,
  buttonLink: PropTypes.string,
  alertUrl: PropTypes.string,
  hideable: PropTypes.bool,
  isPreview: PropTypes.bool,
}
const SC = {}
SC.PreviewNotice = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  opacity: 0.3;
  padding: 3px 4px;
  text-align: right;
`
SC.CloseButton = styled.div`
  cursor: pointer;
  padding: 1rem;
  color: ${(props) => {
    const colors = {
      success: '#00e6c2',
      warning: '#feda32',
      info: '#25cced',
      danger: '#fd4242',
    }
    return colors[props.messageType]
  }}
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`

export default Alert
