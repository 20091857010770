// Wrapper for fullcalendar with our settings
import _ from 'lodash'

import { getLocale } from 'src/config/globals'

const agendaViewForScreenSize = () => {
  return $(window).width() < 768 ? 'agendaDay' : 'agendaWeek'
}

export default class Calendar {
  constructor(element, options) {
    this.element = element
    this.options = _.defaults({}, options, {
      height: 'auto',
      minHeight: 400,
      selectable: true,
      selectHelper: true,
      editable: true,
      firstDay: 1,
      defaultView: agendaViewForScreenSize(),
      defaultDate: new Date(),
      onClickEvent: () => {},
      onCreateEvent: () => {},
      onUpdateEvent: () => {},
      onEventRender: () => {},
      onStartFetch: () => {},
      onFinishFetch: () => {},
      events: [],
    })
    this.$element = $(this.element)
    this.init()
  }

  refetchEvents() {
    this.$element.fullCalendar('refetchEvents')
  }

  unselect() {
    this.$element.fullCalendar('unselect')
  }

  gotoDate(date) {
    this.$element.fullCalendar('gotoDate', date)
  }

  getHeight() {
    if (this.options.height === 'auto') {
      let height = window.innerHeight
      let top = this.$element.offset().top
      return Math.max(this.options.minHeight, height - top - 60)
    } else {
      return this.options.height || this.options.minHeight
    }
  }

  init() {
    let {
      events,
      onClickEvent,
      onCreateEvent,
      onUpdateEvent,
      onEventRender,
      onStartFetch,
      onFinishFetch,
    } = this.options
    this.$element.fullCalendar({
      defaultView: this.options.defaultView,
      businessHours: {
        dow: [0, 1, 2, 3, 4, 5, 6],
        start: '8:00',
        end: '18:00',
      },
      defaultDate: this.options.defaultDate,
      allDaySlot: false,
      slotDuration: '00:30:00',
      snapDuration: '00:15:00',
      firstDay: this.options.firstDay,
      height: this.getHeight.bind(this),
      locale: getLocale(),
      eventClick: onClickEvent,
      eventDrop: onUpdateEvent,
      eventResize: onUpdateEvent,
      selectable: this.options.selectable,
      selectHelper: this.options.selectHelper,
      select: onCreateEvent,
      editable: this.options.editable,
      eventRender: onEventRender,
      events: events,
      loading: (isLoading) => {
        isLoading ? onStartFetch() : onFinishFetch()
      },
      windowResize: (view) => {
        this.$element.fullCalendar('changeView', agendaViewForScreenSize())
      },
    })
  }
}
