import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chatterbug/aaron'

const CoachMarkSpotlight = ({ rect, sx }) => {
  if (!rect) {
    return null
  }

  const { top, left, width, height } = rect
  const isMixBlendModeSupported = Boolean(
    window.getComputedStyle(document.body)?.mixBlendMode
  )

  return (
    <Box
      sx={{
        borderRadius: '16px',
        ...sx,
        position: 'absolute',
        backgroundColor: isMixBlendModeSupported ? 'gray' : 'transparent',
        pointerEvents: 'none',
        top,
        left,
        width,
        height,
      }}
    />
  )
}

CoachMarkSpotlight.propTypes = {
  rect: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  sx: PropTypes.object,
}

export default CoachMarkSpotlight
