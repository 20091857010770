import React, { useContext } from 'react'

const ProductTourContext = React.createContext(null)

export const useProductTour = (name) => {
  const productTour = useContext(ProductTourContext)

  return {
    enabled: (productTour?.list || []).some((item) => item.name === name),
    onSeen: productTour?.onSeen,
  }
}

export default ProductTourContext
