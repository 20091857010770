import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Flag from 'src/components/Flag/Flag'

import defaultAvatar from './defaultAvatar.svg'

const sizes = {
  xxl: 210,
  xl: 128,
  l: 48,
  m: 28,
  s: 20,
}

const flagForAvatarSize = (avatarSize) =>
  ({
    xxl: { size: 'xxl', position: '0%' },
    xl: { size: 'xl', position: '0%' },
    l: { size: 'xs', position: '-40%' },
    m: { size: 'xxxs', position: '-40%' },
    s: { size: 'xxxs', position: '-46%' },
  }[avatarSize])

const Avatar = ({ url, size, languageCode, children, ...others }) => (
  <SC.Container size={size} url={url || defaultAvatar} {...others}>
    {languageCode && (
      <SC.FlagContainer
        style={{
          top: flagForAvatarSize(size).position,
          right: flagForAvatarSize(size).position,
        }}
      >
        <Flag
          countryCode={languageCode}
          size={flagForAvatarSize(size).size}
          borderColor="none"
        />
      </SC.FlagContainer>
    )}
    {children}
  </SC.Container>
)

const SC = {}
SC.Container = styled.div`
  display: inline-block;
  position: relative;
  ${({ size, url }) => `
    width: ${sizes[size]}px;
    height: ${sizes[size]}px;
    background: url(${url}) no-repeat;
  `};
  background-size: cover;
  background-position: center center;
  border-radius: 999px;
`
SC.FlagContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 0;
`

Avatar.defaultProps = {
  size: 'm',
}

Avatar.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizes)),
  url: PropTypes.string,
  languageCode: PropTypes.string,
  children: PropTypes.any,
}

export default Avatar
