/* eslint-disable camelcase */
export default {
  CONTRACTION_MAP: {
    '&': ['and'],
    "aren't": ['are not'],
    "can't": ['can not'],
    "could've": ['could have'],
    "couldn't": ['could not'],
    "didn't": ['did not'],
    "doesn't": ['does not'],
    "don't": ['do not'],
    gonna: ['going to'],
    "hadn't": ['had not'],
    "hasn't": ['has not'],
    "haven't": ['have not'],
    "he'd": ['he had', 'he would'],
    "he'll": ['he shall', 'he will'],
    "he's": ['he has', 'he is'],
    "how'd": ['how did', 'how would'],
    "how'll": ['how will'],
    "how's": ['how has', 'how is', 'how does'],
    "I'd": ['I had', 'I would'],
    "I'll": ['I shall', 'I will'],
    "I'm": ['I am'],
    "I've": ['I have'],
    "isn't": ['is not'],
    "it'd": ['it had', 'it would'],
    "it'll": ['it shall', 'it will'],
    "it's": ['it has', 'it is'],
    "let's": ['let us'],
    "mightn't": ['might not'],
    "might've": ['might have'],
    "mustn't": ['must not'],
    "must've": ['must have'],
    "needn't": ['need not'],
    "oughtn't": ['ought not'],
    "shan't": ['shall not'],
    "she'd": ['she had', 'she would'],
    "she'll": ['she shall', 'she will'],
    "she's": ['she has', 'she is'],
    "should've": ['should have'],
    "shouldn't": ['should not'],
    "somebody'd": ['somebody had', 'somebody would'],
    "somebody'll": ['somebody shall', 'somebody will'],
    "somebody's": ['somebody has', 'somebody is'],
    "someone'd": ['someone had', 'someone would'],
    "someone'll": ['someone shall', 'someone will'],
    "someone's": ['someone has', 'someone is'],
    "something'd": ['something had', 'something would'],
    "something'll": ['something shall', 'something will'],
    "something's": ['something has', 'something is'],
    "that'll": ['that will'],
    "that's": ['that has', 'that is'],
    "there'd": ['there had', 'there would'],
    "there're": ['there are'],
    "there's": ['there has', 'there is'],
    "they'd": ['they had', 'they would'],
    "they'll": ['they shall', 'they will'],
    "they're": ['they are'],
    "they've": ['they have'],
    wanna: ['want to'],
    "wasn't": ['was not'],
    "we'd": ['we had', 'we would'],
    "we'll": ['we will'],
    "we're": ['we are'],
    "we've": ['we have'],
    "weren't": ['were not'],
    "what'll": ['what shall', 'what will'],
    "what're": ['what are'],
    "what's": ['what has', 'what is', 'what does'],
    "what've": ['what have'],
    "when's": ['when has', 'when is'],
    "where'd": ['where did'],
    "where's": ['where has', 'where is', 'where does'],
    "where've": ['where have'],
    "who'd": ['who would', 'who had'],
    "who'll": ['who shall', 'who will'],
    "who's": ['who has', 'who is'],
    "who've": ['who have'],
    "why'll": ['why will'],
    "why're": ['why are'],
    "why's": ['why has', 'why is'],
    "won't": ['will not'],
    "would've": ['would have'],
    "wouldn't": ['would not'],
    "you'd": ['you had', 'you would'],
    "you'll": ['you shall', 'you will'],
    "you're": ['you are'],
    "you've": ['you have'],
  },

  CONTRACTION_CHARACTERS: /[\'\&]/,

  // Expand any contractions in the text into their non-contracted form.
  //
  // text - the string text to expand.
  //
  // Returns an array of possible expansions. Even if no expansions were made,
  // the return will be a one-element array with the original text.
  expandContractions: function(text) {
    let expansions = [text]

    // stop now unless contraction characters are present
    if (!this.CONTRACTION_CHARACTERS.test(text)) return expansions

    let text_lc = text.toLowerCase()

    for (const contraction in this.CONTRACTION_MAP) {
      // so long as contraction expansions don't contain contractions,
      // this is a safe check for usage of a contraction and faster
      if (text_lc.indexOf(contraction.toLowerCase()) < 0) continue

      let replacements = this.CONTRACTION_MAP[contraction]

      let replaceRe = new RegExp(contraction, 'gi')

      let new_expansions = []

      for (const expansion of expansions) {
        replacements.forEach((replacement) => {
          new_expansions.push(expansion.replace(replaceRe, replacement))
        })
      }

      expansions = new_expansions
    }

    expansions.unshift(text) // include original as first alternative

    return expansions
  },

  // Expand the text to allow for various properties.
  //
  // text           - the string text to expand.
  // type           - the string type. Possible values: "word" or "phrase".
  // features       - the collection of linguistic features. Includes part of speech.
  // isNative       - whether the text is in the native language of the user
  //
  // Returns an array of possible expansions. Even if no expansions were made,
  // the return will be a one-element array with the original text.
  expand: function(text, type, features, isNative) {
    let expansions = [text]

    if (type === 'phrase') {
      expansions = this.expandContractions(text)
    } else if (type === 'word') {
      if (
        features['part_of_speech'] === 'verb' &&
        (!features.hasOwnProperty('verbform') ||
          features['verbform'] === 'infinitive')
      ) {
        if (text.indexOf('to ') === 0) {
          expansions.push(text.substring(3))
        }
      } else if (features['part_of_speech'] === 'noun') {
        if (text.indexOf('the ') === 0) {
          expansions.push(text.substring(4))
        }
      } else {
        expansions = this.expandContractions(text)
      }
    }

    return expansions
  },
}
