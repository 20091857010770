import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '@chatterbug/aaron'
import Alert from 'src/components/Alert/Alert'
const AlertTray = ({ alerts }) => {
  return (
    <Flex flexDirection="column">
      {alerts.map((a) => (
        <Alert key={a.id} {...a} />
      ))}
    </Flex>
  )
}
AlertTray.propTypes = {
  alerts: PropTypes.array.isRequired,
}
export default AlertTray
