import _ from 'lodash'

import CardUtils from 'lib/card-utils'
import { stringToHTMLElement } from 'lib/util'

// This is an input that grows with its value's size.
function CardInput(element) {
  this.$el = $(element)

  // HACK: cursorStop is a fix for a webkit bug. This span is appended to the
  // end of the contentEditable element so it provides a 'stop' for the cursor.
  // See https://github.com/chatterbugapp/chatterbug/issues/774
  this.cursorStop = stringToHTMLElement('<span contenteditable="false"></span>')

  this.inputs = this.$el.find('.card-input__input')
  this.inputs.on('paste', (e) => false)
  this.inputs.on('input focus', this.handleInputChange.bind(this))
  this.inputCount = this.inputs.length
  this.inputActive = 0
}

$.extend(CardInput.prototype, {
  focusIndex: function(idx) {
    if (this.inputCount === 0 || idx < 0 || idx > this.inputCount) {
      return
    }
    this.inputActive = idx
    if (/^\s+$/.test(this.inputs[this.inputActive].textContent)) {
      // clear the initial text
      this.inputs[this.inputActive].textContent = ''
    }
    this.inputs[this.inputActive].focus()
  },

  focus: function() {
    this.focusIndex(this.inputActive)
  },

  val: function() {
    if (arguments.length > 0) {
      let setCount = arguments.length
      if (setCount > this.inputCount) setCount = this.inputCount
      for (var pos = 0; pos < setCount; pos++) {
        this.inputs[pos].textContent = arguments[pos]
      }
      return arguments[0]
    } else {
      return _.map(this.inputs, function(inp) {
        return CardUtils.cleanString(inp.textContent)
      }).join('|')
    }
  },

  disable: function() {
    this.inputs.blur()
    this.inputs.prop('contenteditable', false)
  },

  isMultiple: function() {
    return this.inputCount > 1
  },

  advanceFocus: function() {
    if (this.inputCount === 0) {
      return
    }
    this.focusIndex((this.inputActive + 1) % this.inputCount)
  },

  canAdvanceFocus: function() {
    if (this.inputCount === 0) {
      return false
    }
    return this.inputActive + 1 >= this.inputCount
  },

  handleInputChange: function(e) {
    // Dont run this code in IE because it isnt an issue there
    if (!this.cursorStop.remove) return

    const input = e.target
    this.inputActive = this.inputs.index(input)
    if (input.textContent.length === 0) {
      // We need to remove it when 0 length otherwise the cursor wont show :/
      this.cursorStop.remove()
    } else {
      // Always force it to be at the end of the text!
      input.appendChild(this.cursorStop)
    }
  },
})

$.fn.cardInput = function() {
  return $.map(this, function(element) {
    return new CardInput(element)
  })
}
