import ChatPresenceMessage from 'modules/chat-presence-message'

export default class ChatPresenceMarker extends ChatPresenceMessage {
  render(state) {
    let { presence } = state
    let { classList } = this.element
    classList.remove(
      ChatPresenceMarker.awayClass,
      ChatPresenceMarker.availableClass
    )
    if (presence === 'away') {
      classList.add(ChatPresenceMarker.awayClass)
    } else if (presence === 'available') {
      classList.add(ChatPresenceMarker.availableClass)
    }
  }
}

ChatPresenceMarker.className = 'chat-presence-marker'
ChatPresenceMarker.selector = `.${ChatPresenceMarker.className}`
ChatPresenceMarker.awayClass = `${ChatPresenceMarker.className}_away`
ChatPresenceMarker.availableClass = `${ChatPresenceMarker.className}_available`
