import React from 'react'

import { Image } from '@chatterbug/aaron'

import clouds from './assets/clouds.svg'

const getBackground = (l2Code) => {
  return (
    {
      de: require('./assets/background_de.svg'),
      en: require('./assets/background_en.svg'),
      fr: require('./assets/background_fr.svg'),
      es: require('./assets/background_es.svg'),
    }[l2Code] || './assets/background_de.svg'
  )
}
const TreesAndClouds = ({ l2Code }) => {
  return (
    <>
      <Image
        src={clouds}
        sx={{
          position: 'absolute',
          left: '50%',
          top: '10%',
          transform: 'translate(-50%)',
        }}
      />
      <Image
        src={getBackground(l2Code)}
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -70%)',
        }}
      />
    </>
  )
}

export default TreesAndClouds
