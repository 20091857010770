// Onboarding pages extend from this
// Each page will be created like so:
// `new NamedPage(element, {initState, setState})`
export default class BasePage {
  constructor(element, options) {
    this.element = element
    this.options = options
  }

  nextButtonText() {
    return this.element.getAttribute('data-next-text')
  }

  nextButtonDisabled(state) {
    return state.nextDisabled || false
  }

  render(state) {}
}
