/* eslint-disable react/jsx-no-literals */
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { bindMethods, dataAttrs, redirectToHREF } from 'lib/util'
import PageAlert from 'src/components/PageAlert'
import LiveDate from 'src/components/LiveDate'

const JoinMinutesBeforeStart = 2
const ChatWaitURL = '/chat/wait'

// See l-exercise-replay.js for the exercise replay related things...
export default class LayoutExercisePreview {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults({}, options, {
      joinMinutes: JoinMinutesBeforeStart,
      chatWaitURL: ChatWaitURL,
    })

    let { startTime, dontJoin } = dataAttrs(element)

    if (!dontJoin) {
      this.setupJoinTimer(startTime)
    }
  }

  now() {
    return moment()
  }

  setupJoinTimer(startTime) {
    startTime = moment(startTime)
    let now = this.now()
    let notifyTime = startTime
      .clone()
      .subtract(JoinMinutesBeforeStart, 'minutes')

    let notifyDelta = Math.max(0, notifyTime.diff(now))
    let startDelta = Math.max(0, startTime.diff(now))

    window.setTimeout(
      this.showJoinNotification.bind(this, startTime),
      notifyDelta
    )
    window.setTimeout(this.redirectToChatWait, startDelta)
  }

  showJoinNotification(startTime) {
    let dateParams = { date: startTime, format: 'fromNowSimple' }
    this.joinNotification = PageAlert.showAlert({
      type: 'info',
      fixed: true,
      content: (
        <p>
          This lesson starts in{' '}
          <b>
            {' '}
            <LiveDate {...dateParams} />
          </b>
          . <a href={ChatWaitURL}>Join now &rarr;</a>
        </p>
      ),
    })
  }

  redirectToChatWait() {
    redirectToHREF(ChatWaitURL)
  }
}
