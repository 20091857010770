import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@chatterbug/aaron'

import { isActiveLink } from '../../util'
import ToolTip from '../ToolTip'

import * as icons from './assets/icons'

const MenuItem = ({
  text,
  icon,
  href = '#',
  onClick,
  children,
  textStyle,
  noHover = false,
  sx,
  showToolTipOnTablet = true,
  // because sx doesn't accept responsiveness
  menuItemStyle,
  showMediumNav,
}) => {
  let IconSvg = icons[icon]
  if (!icons[icon]) {
    IconSvg = null
  }
  const [showToolTip, setShowToolTip] = useState(false)
  const selected = isActiveLink(href)

  return (
    <Box
      as="a"
      href={href}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      sx={{
        padding: '1x',
        borderRadius: '1,5x',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: selected ? '#F5F5F5' : undefined,
        ':hover': {
          backgroundColor: noHover ? null : '#F3F3F3',
        },
        cursor: noHover ? 'auto' : 'pointer',
        ...sx,
      }}
      style={{
        textDecoration: 'none',
        color: 'black',
      }}
      {...menuItemStyle}
      onClick={onClick}
    >
      {IconSvg && <IconSvg selected={selected} />}
      {children}
      {text && <Text {...textStyle}>{text}</Text>}
      {showToolTip && showToolTipOnTablet && (
        <ToolTip
          containerStyle={{ display: { _: 'none', tabletWide: 'block' } }}
          popupSx={{
            bottom: '3px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
          popupStyle={{
            marginLeft: { _: '45px', laptop: showMediumNav ? '45px' : '175px' },
            display: {
              _: 'none',
              tablet: 'flex',
              laptop: showMediumNav ? 'flex' : 'none',
            },
          }}
          onCancel={() => setShowToolTip(false)}
        >
          <Text>{text}</Text>
        </ToolTip>
      )}
    </Box>
  )
}

MenuItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  href: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  textStyle: PropTypes.object,
  menuItemStyle: PropTypes.object,
  noHover: PropTypes.bool,
  showToolTipOnTablet: PropTypes.bool,
}

export default MenuItem
