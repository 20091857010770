import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0002" cy="12.0215" r="9.5" stroke="black" />
      <path
        d="M10.7312 15.6355C10.7312 16.0675 11.0912 16.3795 11.5232 16.3795C11.9552 16.3795 12.3032 16.0675 12.3032 15.6355C12.3032 15.2035 11.9552 14.8795 11.5232 14.8795C11.0912 14.8795 10.7312 15.2035 10.7312 15.6355ZM10.8752 10.0915C11.0072 9.39548 11.5712 8.97548 12.1832 8.97548C12.8912 8.97548 13.5752 9.46748 13.5752 10.2475C13.5752 10.8115 13.0952 11.3395 12.5912 11.4835C11.6672 11.7715 10.9832 12.2275 10.9832 13.3915V13.5715C10.9832 13.8595 11.2232 14.1115 11.5112 14.1115C11.8112 14.1115 12.0512 13.8595 12.0512 13.5715L12.0632 13.3915C12.0992 12.8275 12.3632 12.6715 12.9152 12.5155C13.8632 12.2515 14.6432 11.4115 14.6432 10.2475C14.6432 8.80748 13.4792 7.90748 12.1832 7.90748C11.0672 7.90748 10.1072 8.63948 9.87924 9.85148C9.81924 10.1275 9.97524 10.4155 10.2512 10.4755C10.5272 10.5595 10.8152 10.3795 10.8752 10.0915Z"
        fill="black"
      />
    </svg>
  )
}
