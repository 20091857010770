import React from 'react'
import PropTypes from 'prop-types'

import Flag from 'src/components/Flag/Flag'
import { t, DateTime } from 'src/lib/i18n-react'

const LiveLesson = ({ languageCode, start, finish }) => {
  const { minutes } = DateTime.fromISO(finish)
    .diff(DateTime.fromISO(start), 'minutes')
    .toObject()

  return (
    <React.Fragment>
      {languageCode && (
        <Flag size="s" countryCode={languageCode} style={{ marginRight: 5 }} />
      )}
      {DateTime.fromISO(start).toLocaleString({
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}
      {' — '}
      {t('%{minutes} minute |||| %{minutes} minutes', {
        smart_count: minutes,
        minutes: Math.round(minutes),
      })}
    </React.Fragment>
  )
}

LiveLesson.propTypes = {
  languageCode: PropTypes.string,
  start: PropTypes.string.isRequired,
  finish: PropTypes.string.isRequired,
}

export default LiveLesson
