import React from 'react'
import PropTypes from 'prop-types'

import Flag from 'src/ui/MainMenu/subcomponents/Flag/Flag'
import { Box, Flex, Text } from '@chatterbug/aaron'

import LanguageCharacter from '../LanguageCharacter/LanguageCharacter'

class LanguagePanel extends React.Component {
  state = {
    hover: false,
  }

  mouseEnter = () => {
    this.setState({ hover: true })
  }

  mouseLeave = () => {
    this.setState({ hover: false })
  }

  render() {
    const { hover } = this.state
    const { onClick, languageCode, localizedName } = this.props

    return (
      <Box
        onClick={onClick}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        {languageCode !== 'other' && (
          <Flex justifyContent="flex-end">
            <Flag languageCode={languageCode} />
          </Flex>
        )}
        <LanguageCharacter isPlaying={hover} languageCode={languageCode} />
        <Flex justifyContent="center">
          <Text>{localizedName}</Text>
        </Flex>
      </Box>
    )
  }
}

LanguagePanel.propTypes = {
  onClick: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  localizedName: PropTypes.string.isRequired,
}

export default LanguagePanel
