import _ from 'lodash'

export default class ReplayExerciseSummary {
  constructor(element, options) {
    this.element = element
    this.options = _.defaults(options, {
      chatChannelReplay: null,
      onViewExercise: (millis) => {},
    })

    this.hasSelectionClass = ReplayExerciseSummary.hasSelectionClass
    this.itemSelectedClass = ReplayExerciseSummary.itemSelectedClass
    this.exerciseViewClass = ReplayExerciseSummary.exerciseViewClass
    this.exercises = element.querySelectorAll(
      `.${ReplayExerciseSummary.exerciseClass}`
    )

    this.chatChannelReplay = this.options.chatChannelReplay
    this.chatChannelReplay.on('play', this.handlePlay.bind(this))
    this.chatChannelReplay.on('stop', this.handleStop.bind(this))
    this.chatChannelReplay.on(
      'nextExercise',
      this.handleNextExercise.bind(this)
    )
    this.chatChannelReplay.on(
      'seek-backward',
      this.handleSeekBackward.bind(this)
    )

    $(element).on(
      'click',
      `.${this.exerciseViewClass}`,
      this.handleClickView.bind(this)
    )
  }

  handleClickView(e) {
    let exercise = $(e.target).closest('.exercise-summary')[0]
    let startTime = exercise.getAttribute('data-start-time')
    let startMillis = this.chatChannelReplay.timestampToMillis(startTime)
    this.options.onViewExercise(startMillis)
  }

  handlePlay() {
    this.showLatestExercise()
  }

  handleStop() {
    this.render({ playState: 'stopped', exerciseId: null })
  }

  handleNextExercise(data) {
    if (!data.exercise) return
    this.render({
      playState: this.chatChannelReplay.getPlayState(),
      exerciseId: data.exercise,
    })
  }

  handleSeekBackward() {
    this.showLatestExercise()
  }

  showLatestExercise() {
    let event = this.chatChannelReplay.getLastEvent({ type: 'nextExercise' })
    event ? this.handleNextExercise(event.event) : this.showExerciseIndex(0)
  }

  showExerciseIndex(index) {
    let exercise = this.exercises[index]
    let exerciseId = parseInt(exercise.getAttribute('data-id'))
    this.render({
      playState: this.chatChannelReplay.getPlayState(),
      exerciseId,
    })
  }

  render({ playState, exerciseId }) {
    if (playState === 'stopped') {
      this.element.classList.remove(this.hasSelectionClass)
    } else {
      this.element.classList.add(this.hasSelectionClass)
    }

    _.each(this.exercises, (e) => e.classList.remove(this.itemSelectedClass))

    if (exerciseId != null) {
      let exercise = this.element.querySelector(
        `.exercise-summary[data-id="${exerciseId}"]`
      )
      exercise.classList.add(this.itemSelectedClass)
    }
  }
}

ReplayExerciseSummary.className = 'replay-exercise-summary'
ReplayExerciseSummary.selector = `.${ReplayExerciseSummary.className}`

ReplayExerciseSummary.exerciseClass = 'exercise-summary'
ReplayExerciseSummary.exerciseViewClass = 'exercise-summary__view'
ReplayExerciseSummary.hasSelectionClass =
  'exercise-summary__container_has-selection'
ReplayExerciseSummary.itemSelectedClass = 'exercise-summary_selected'
