export const getAbsoluteRect = (clientRect) => {
  if (!clientRect) {
    return null
  }

  const rect = {
    top: clientRect.top,
    left: clientRect.left,
    height: clientRect.height,
    width: clientRect.width,
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
  // To get real offsets even if it was scrolled
  rect.top = rect.top + (window.scrollY || 0)
  rect.left = rect.left + (window.scrollX || 0)

  return rect
}
