import moment from 'moment'

import Calendar from 'modules/calendar'
import DateUtil from 'lib/date-util'
import { t } from 'src/lib/i18n'

export default class LayoutScheduleTutorTest {
  constructor(element) {
    this.element = element
    this.calendarEl = element.querySelector('.calendar')

    let targetDate = new Date()
    targetDate.setDate(targetDate.getDate() + 2)

    this.calendar = new Calendar(this.calendarEl, {
      onClickEvent: this.handleClickEvent.bind(this),
      onEventRender: this.handleEventRender.bind(this),
      defaultView: 'listWeek',
      height: 'auto',
      minHeight: 300,
      selectable: false,
      selectHelper: false,
      editable: false,
      defaultDate: targetDate,
      events: {
        url: '/schedule/tutor_test_bookings',
      },
    })
  }

  handleClickEvent(calEvent, jsEvent, view) {
    if (calEvent.time_start) {
      let start = moment(calEvent.start)
      let end = moment(calEvent.end)
      let date = `${DateUtil.format(start, 'cb_ddddMMMD')} ${DateUtil.format(
        start,
        'cb_hmma'
      )} - ${DateUtil.format(end, 'cb_hmma')}`
      if (calEvent.appointment_id) {
        $('#booking-appt-id').val(calEvent.appointment_id)
      }
      $('#booking-time').val(calEvent.time_start)
      $('#booking-start-time').text(
        t('Confirm appointment on %{date}', { date })
      )
      $('#booking-modal').modal('show')
    }
  }

  handleEventRender(event, element) {
    if (event.event_type) {
      element.addClass(`calendar__training_${event.event_type}`)
    }
    element.find('.fc-title').html(event.title)
  }
}
