/* eslint-disable react/prop-types */
import React from 'react'

export default class Loader extends React.Component {
  render() {
    const { type, color } = this.props
    return <span className={`loader_${type} loader_${color}`} />
  }
}

Loader.defaultProps = {
  type: 'spinner',
  color: null,
}
