import _ from 'lodash'

import Module from 'lib/module'
import { translate } from 'lib/util'

// Wraps the bootstrap tooltip
// https://github.com/twbs/bootstrap/blob/v3.3.7/js/tooltip.js

const DefaultOptions = {
  container: 'body',
}

$(document).ready(function() {
  $(Tooltip.selector).tooltip(DefaultOptions)
})

export default class Tooltip extends Module {
  constructor(element, options) {
    super(element, _.defaults({}, options, DefaultOptions))
    $(this.element).tooltip(this.options)
  }

  show() {
    this.bsFn('show')
  }
  hide() {
    this.bsFn('hide')
  }
  enable() {
    this.bsFn('enable')
  }
  disable() {
    this.bsFn('disable')
  }

  setContent(content) {
    content = translate(content)
    this.element.setAttribute('data-original-title', content)

    let tip = this.bsFn('tip')
    tip.find('.tooltip-inner')[this.options.html ? 'html' : 'text'](content)
  }

  bsFn(fnName) {
    let bsTooltip = $(this.element).data('bs.tooltip')
    return bsTooltip[fnName]()
  }
}

Tooltip.className = 'tooltip'
Tooltip.selector = '[data-toggle="tooltip"]'
