import Cookie from 'js-cookie'

export function setLandingPageCookie() {
  const { pathname, search } = window.location
  const url = `${pathname}${search}`
  Cookie.set('landing_page', url, { expires: 7 })
}

export function unsetLandingPageCookie() {
  Cookie.remove('landing_page')
}
