import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@chatterbug/aaron'

import LanguageCard from '../LanguageCard/LanguageCard'

const LanguageContainer = ({ languageCardList, selectLanguage }) => {
  return (
    <Grid
      gridGap="2x"
      m="2x"
      width="100%"
      minWidth={250}
      gridTemplateColumns={{
        _: '1fr',
        tablet: `repeat(${languageCardList.length}, 1fr)`,
      }}
    >
      {languageCardList.map((languageCard, i) => {
        return (
          <LanguageCard key={i} {...languageCard} onClick={selectLanguage} />
        )
      })}
    </Grid>
  )
}

LanguageContainer.propTypes = {
  selectLanguage: PropTypes.func,
  languageCardList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}

export default LanguageContainer
