import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import { post } from 'src/lib/util'
import ButtonBar from 'src/components/ButtonBar/ButtonBar'

class Reject extends React.Component {
  state = {
    reason: '',
  }

  render() {
    const { appointment, onSubmit } = this.props

    return (
      <React.Fragment>
        <p>
          {t(
            `We will try to find another tutor for this student. Rejecting or canceling an appointment can affect your Tutor Level. Please send us a message via chat if you think there's something we should know immediately and make sure your availability calendar is always up to date.`
          )}
        </p>
        <form>
          <p>
            <label htmlFor="reason">
              {t("Is there a reason you can't make this time?")}
            </label>
          </p>
          <p>
            <textarea
              onChange={(e) => this.setState({ reason: e.target.value })}
              rows="1"
              placeholder={t("I can't make it because...")}
              className="form-control"
              value={this.state.reason}
              style={{ margin: 8 }}
            />
          </p>
        </form>
        <ButtonBar>
          <Button
            type="cta"
            disableWith={t('Rejecting...')}
            onClick={() => {
              post(
                routes.appointment_modify(appointment.id, 'reject'),
                { reason: this.state.reason },
                'json'
              ).then(({ data }) => {
                onSubmit(
                  { ...appointment, ...data.appointment },
                  { text: data.notice }
                )
              })
            }}
          >
            {t('Reject')}
          </Button>
        </ButtonBar>
      </React.Fragment>
    )
  }
}

Reject.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Reject
