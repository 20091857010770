import React from 'react'
import { Flex, Image } from '@chatterbug/aaron'
import PropTypes from 'prop-types'

import logo from './assets/logo.svg'
import logoText from './assets/logo-text.svg'

const Logo = ({ showMediumNav }) => {
  return (
    <Flex as="a" href="/" flexDirection="row" justifyContent="baseline">
      {/* src attribute doesn't take responsive properties, so show different logos on different sizes */}
      <Image
        display={{ _: 'block', laptop: showMediumNav ? 'block' : 'none' }}
        src={logo}
      />
      <Image
        display={{ _: 'none', laptop: showMediumNav ? 'none' : 'block' }}
        src={logoText}
      />
    </Flex>
  )
}

Logo.propTypes = {
  showMediumNav: PropTypes.bool,
}

export default Logo
