import React from 'react'

export default () => {
  return (
    <svg
      style={{ borderRadius: '100px' }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37485 10.3119C2.95564 3.96225 9.41654 0.0916154 15.8045 1.66284C22.1942 3.23334 26.0896 9.65247 24.5089 16.0011C22.9278 22.3495 16.468 26.2218 10.0787 24.6503C3.69027 23.0798 -0.207203 16.6603 1.37485 10.3119Z"
        fill="#5579EF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.37485 10.3119C2.95564 3.96225 9.41654 0.0916154 15.8045 1.66284C22.1942 3.23334 26.0896 9.65247 24.5089 16.0011C22.9278 22.3495 16.468 26.2218 10.0787 24.6503C3.69027 23.0798 -0.207203 16.6603 1.37485 10.3119Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="-0.348389"
          y="0.764282"
          width="29.617"
          height="8.28095"
          fill="#434A54"
        />
        <rect
          x="-0.348389"
          y="9.04523"
          width="29.617"
          height="8.28095"
          fill="#E25A63"
        />
        <rect
          x="-0.348389"
          y="17.3262"
          width="29.617"
          height="8.28095"
          fill="#F5C055"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.8289C19.6274 24.8289 25 19.4946 25 12.9144C25 6.33427 19.6274 1 13 1C6.37258 1 1 6.33427 1 12.9144C1 19.4946 6.37258 24.8289 13 24.8289Z"
        stroke="black"
      />
    </svg>
  )
}
