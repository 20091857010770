import _ from 'lodash'

import InteractiveImage from 'modules/interactive-image'

export default class ExerciseSwitcher {
  constructor(element, options) {
    this.bindHandlers()
    this.element = element
    this.options = _.defaults(options, {
      chatChannelReplay: null,
      exercises: null, // elements
    })

    this.defaultContent = element.querySelector(
      ExerciseSwitcher.mainContentSelector
    )
    this.showingExercise = null
    this.exercises = this.options.exercises
    this.chatChannelReplay = this.options.chatChannelReplay

    this.setChatChannelReplay(this.options.chatChannelReplay)
  }

  bindHandlers() {
    this.handlePlay = this.handlePlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
    this.handleNextExercise = this.handleNextExercise.bind(this)
    this.handleSeekBackward = this.handleSeekBackward.bind(this)
  }

  setChatChannelReplay(chatChannelReplay) {
    this.chatChannelReplay = chatChannelReplay
    this.chatChannelReplay.on('play', this.handlePlay)
    this.chatChannelReplay.on('stop', this.handleStop)
    this.chatChannelReplay.on('nextExercise', this.handleNextExercise)
    this.chatChannelReplay.on('seek-backward', this.handleSeekBackward)
  }

  handlePlay() {
    if (!this.showingExercise) {
      this.defaultContent.remove()
      this.showExerciseIndex(0)
    }
  }

  handleStop() {
    if (this.showingExercise) {
      this.destroyInteractiveImage()
      this.element.innerHTML = ''
      this.element.appendChild(this.defaultContent)
      this.showingExercise = null
    }
  }

  handleNextExercise(data) {
    if (!data.exercise) return
    let exercise = this.exercises.querySelector(
      `div[data-id="${data.exercise}"]`
    )
    this.showExercise(exercise)
  }

  handleSeekBackward() {
    let event = this.chatChannelReplay.getLastEvent({ type: 'nextExercise' })
    event ? this.handleNextExercise(event.event) : this.showExerciseIndex(0)
  }

  showExerciseIndex(index) {
    console.log(
      this.exercises.querySelectorAll(ExerciseSwitcher.curriculumItemSelector)
    )
    let exercise = this.exercises.querySelectorAll(
      ExerciseSwitcher.curriculumItemSelector
    )[index]
    this.showExercise(exercise)
  }

  showExercise(exerciseDiv) {
    let newExerciseId = parseInt(exerciseDiv.getAttribute('data-id'))
    if (this.showingExercise === newExerciseId) return
    this.showingExercise = newExerciseId
    this.element.innerHTML = exerciseDiv.innerHTML
    this.initInteractiveImage()
  }

  destroyInteractiveImage() {
    if (this.interactiveImage) {
      this.interactiveImage.destroy()
      this.interactiveImage = null
    }
  }

  initInteractiveImage() {
    this.destroyInteractiveImage()

    // Create the new one
    let imageEl = this.element.querySelector(InteractiveImage.selector)
    if (imageEl) {
      this.interactiveImage = new InteractiveImage(imageEl, {
        chatChannelReplay: this.chatChannelReplay,
      })
    }
  }
}

ExerciseSwitcher.className = 'exercise-switcher'
ExerciseSwitcher.selector = `.${ExerciseSwitcher.className}`
ExerciseSwitcher.mainContentSelector = `.${ExerciseSwitcher.className}__main-content`
ExerciseSwitcher.curriculumItemSelector = `.${ExerciseSwitcher.className}__curriculum-item`
ExerciseSwitcher.curriculumItemsSelector = `.${ExerciseSwitcher.className}__curriculum-items`
