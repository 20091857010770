import _ from 'lodash'

import { bindMethods } from 'lib/util'

$(document).ready(() => {
  let elements = document.querySelectorAll(VideoThumb.selector)
  let loader = new Loader(_.map(elements, (e) => new VideoThumb(e)))
  loader.load()
})

// WELLLL, Chrome can only handle 4 parallel videos at a time, so this gives it
// 4 most of the time.
class Loader {
  constructor(thumbs) {
    this.thumbs = thumbs
  }

  load() {
    let thumbs = [].concat(this.thumbs)
    function loadNext() {
      let thumb = thumbs.shift()
      return thumb ? thumb.load().then(() => loadNext()) : null
    }
    loadNext()
    loadNext()
    loadNext()
    loadNext()
  }
}

export default class VideoThumb {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults(options, {
      seconds: 20,
    })

    this.video = document.createElement('video')
    this.video.addEventListener('loadedmetadata', this.handleLoadeddata, false)
    this.video.addEventListener('seeked', this.handleSeeked, false)
    this.video.preload = 'none'
    this.video.src = this.element.getAttribute('data-url') + '#t=20,0.5'
  }

  load() {
    let self = this
    return new Promise(function(resolve) {
      self.resolve = resolve
      self.video.load()
    })
  }

  handleLoadeddata() {
    this.video.currentTime = this.options.seconds
    this.video.pause()
  }

  handleSeeked() {
    this.generateThumb()

    // Kill the video! Otherwise will buffer the whole thing
    this.video.pause()
    this.video.src = ''
    this.video.load()
    this.video.remove()
    delete this.video
    this.resolve(this)
  }

  generateThumb() {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    canvas.width = this.video.videoWidth
    canvas.height = this.video.videoHeight
    ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height)
    this.element.innerHTML = ''
    this.element.appendChild(canvas)
  }
}

VideoThumb.className = 'video-thumb'
VideoThumb.selector = `.${VideoThumb.className}`
