import React from 'react'

export default ({ selected }) => {
  if (selected) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10.6227"
          cy="10.6012"
          r="7.26987"
          transform="rotate(-0.84529 10.6227 10.6012)"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M15.9425 15.991L20.4116 20.4602"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10.6227"
          cy="10.6011"
          r="7.26987"
          transform="rotate(-0.84529 10.6227 10.6011)"
          stroke="black"
        />
        <path
          d="M15.9426 15.991L20.4117 20.4602"
          stroke="black"
          strokeLinecap="round"
        />
      </svg>
    )
  }
}
