import TutorGoalsForm from 'modules/tutor-goals-form'

import BasePage from './base-page'

export default class TutorGoalsPage extends BasePage {
  constructor(element, options) {
    super(element, options)
    this.options.initState({
      weekMaxHours: 10,
      dayMaxHours: 4,
    })

    let tutorGoalsFormEl = element.querySelector(TutorGoalsForm.selector)
    this.tutorGoalsForm = new TutorGoalsForm(tutorGoalsFormEl, {
      onChange: (goals) => this.options.setState(goals),
    })
  }

  render(state, prevState) {
    this.tutorGoalsForm.render(state, prevState)
  }
}
