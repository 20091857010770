import React from 'react'
import { Box, Flex, Grid } from '@chatterbug/aaron'

type SkeletonProps = {
  imageSection: JSX.Element
  headingSection: string
  bodySection: JSX.Element
  buttonSection: JSX.Element
}

const GiftVoucherSkeleton: React.FC<SkeletonProps> = ({
  imageSection,
  headingSection,
  bodySection,
  buttonSection,
}) => {
  return (
    <Grid gridGap="3x" justifyItems="center" pb="3x" maxWidth={464}>
      {imageSection}
      <Box as="p" variant="text.h3">
        {headingSection}
      </Box>
      {bodySection}
      <Flex>{buttonSection}</Flex>
    </Grid>
  )
}

export default GiftVoucherSkeleton
