import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@chatterbug/aaron'

const Tickmark = ({ position }) => {
  return (
    <Card
      css={`
        position: relative;
      `}
    >
      <Card
        key={position}
        width="5px"
        bg="#00e6c2"
        borderRadius="lg"
        css={`
          height: 16px;
          top: -12px;
          position: absolute;
          left: ${position * 100 - 0.3}%;
          cursor: pointer;
          border: 1px rgba(134, 19, 214, 0.06) solid;
        `}
        aria-label="tick"
      />
    </Card>
  )
}

Tickmark.propTypes = {
  position: PropTypes.number.isRequired,
}

export default Tickmark
