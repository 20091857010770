import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'src/lib/i18n-react'
import { Box } from '@chatterbug/aaron'

import ProgressWidgetSkeleton from './ProgressWidgetSkeleton'
import StudentProgress from './subcomponents/StudentProgress/StudentProgress'
import Background from './subcomponents/Background/Background'
import TreesAndClouds from './subcomponents/TreesAndClouds/TreesAndClouds'

const ProgressIlustration = ({ goal, completed, l2Code }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Background />
      <TreesAndClouds l2Code={l2Code} />
      <StudentProgress goal={goal} completed={completed} l2Code={l2Code} />
    </Box>
  )
}

const ProgressWidget = ({ goal, completed, l2Code }) => {
  // safeguard against values over 100
  const maxCompleted = Math.min(completed, 100)
  return (
    <ProgressWidgetSkeleton
      headerSection={t('Progress to %{goal}', { goal: goal.toUpperCase() })}
      contentSection={
        <ProgressIlustration
          goal={goal}
          completed={maxCompleted}
          l2Code={l2Code}
        />
      }
    />
  )
}

ProgressWidget.propTypes = {
  goal: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  l2Code: PropTypes.string.isRequired,
}

export default ProgressWidget
