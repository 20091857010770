const moduleArgsFromProps = ({ layout, filename, selector }) => {
  if (typeof layout === 'undefined') {
    return { filename, selector }
  }
  return {
    filename: `l-${layout}.js`,
    selector: `.l-${layout}`,
  }
}

// This function creates a layout bridge component. It takes the `requires`
// result from webpack's context module api:
// https://github.com/webpack/docs/wiki/context#context-module-api
//
// You can execute an ordinary JS class with the `react_component` template tag:
//   <%= react_component 'LayoutBridge', layout: 'dashboard', options: [1, 2] %>
//
// This will look for files in `requires`, execute the default exported
// class, pass in the element associated to the selector, and the options.
//
// A helper is also available: `<%= js_layout 'dashboard' %>` does the same as
// above.
//
const createLayoutBridge = (requires) => {
  const layoutFiles = requires.keys()

  const LayoutBridge = ({ options, ...others }) => {
    const { filename, selector } = moduleArgsFromProps(others)
    const relativeFilename = filename.startsWith('./')
      ? filename
      : `./${filename}`
    if (!layoutFiles.includes(relativeFilename)) {
      console.error(
        `The file "${relativeFilename}" was not found by LayoutBridge, is it in
      the "layouts" directory?\n`,
        layoutFiles.join(',\n')
      )
      return null
    }

    const ModuleDefaultClass = requires(relativeFilename).default
    /* eslint-disable no-new */
    new ModuleDefaultClass(
      typeof selector !== 'undefined'
        ? document.querySelector(selector)
        : undefined,
      options
    )
    return null
  }

  return LayoutBridge
}

export default createLayoutBridge
