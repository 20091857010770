import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@chatterbug/aaron'

import { t } from 'src/lib/i18n-react'
import { getAbsoluteRect } from 'src/ui/CoachMark/utils'

import arrow from './assets/arrow.svg'

const GAP = 24
const SIDE_MARGIN = 24
const INITIAL_TOP = -1
const ARROW_WIDTH = 32
const ARROW_HEIGHT = 18

const getArrowLeft = (containerLeft, containerWidth, rect, customOffsetX) => {
  const minValue = ARROW_WIDTH
  const maxValue = containerWidth - 2 * ARROW_WIDTH
  let value = containerWidth / 2 - ARROW_WIDTH / 2

  const arrowLeftBasedOnSpotlightWidth = rect?.width / 2 - ARROW_WIDTH / 2
  const arrowLeftInWindow = containerLeft + arrowLeftBasedOnSpotlightWidth

  if (
    arrowLeftInWindow > rect?.left &&
    arrowLeftInWindow < rect?.left + rect?.width / 2
  ) {
    value = arrowLeftBasedOnSpotlightWidth
  }

  return Math.max(Math.min(value - customOffsetX, maxValue), minValue)
}

const setMainElementHeight = (minHeight) => {
  const mainElement = document.querySelector('.layout-with-coach-marks')

  if (mainElement) {
    mainElement.style['min-height'] = minHeight
  }
}

const CoachMarkPopup = ({ rect, title, description, align, sx, onConfirm }) => {
  const containerRef = useRef()
  const [containerTop, setContainerTop] = useState(INITIAL_TOP)
  const [containerWidth, setContainerWidth] = useState(window.innerWidth)

  const handlePosition = () => {
    if (!rect || !containerRef.current) {
      return
    }

    const containerRect = getAbsoluteRect(
      containerRef.current.getBoundingClientRect()
    )

    if (align === 'below') {
      setContainerTop(rect.top + rect.height + GAP)
    } else if (align === 'above') {
      if (containerRect?.height > 0) {
        setContainerTop(rect.top - containerRect.height - GAP)
      }
    }

    if (containerRect?.width > 0) {
      setContainerWidth(containerRect.width)
    }

    const overlay = containerRef.current.parentElement?.parentElement?.querySelector(
      '.coach-mark-overlay'
    )

    if (overlay) {
      const minOverlayHeight =
        Math.max(
          rect.top + rect.height,
          containerRect.top + containerRect.height
        ) +
        2 * GAP

      // for screens with a small height
      const minHeight = `${Math.max(
        document.body.scrollHeight,
        minOverlayHeight
      )}px`
      overlay.style['min-height'] = minHeight
      setMainElementHeight(minHeight)
    }
  }

  useEffect(() => {
    handlePosition()
  }, [rect, align, setContainerTop])

  useEffect(() => {
    setTimeout(() => handlePosition(), 0)

    return () => {
      setMainElementHeight('0')
    }
  }, [])

  const customOffsetX = parseInt(sx?.marginLeft || 0)
  const leftOffset = rect?.left + customOffsetX
  const tabletLeft = Math.max(
    Math.min(leftOffset, window.innerWidth - containerWidth),
    SIDE_MARGIN
  )
  const mobileLeft = Math.min(leftOffset, SIDE_MARGIN)

  return (
    <Box
      borderRadius="1x"
      maxWidth={{ mobile: 420 - 2 * SIDE_MARGIN }}
      left={{
        _: mobileLeft,
        tablet: tabletLeft,
      }}
      right={{
        _: mobileLeft,
        tablet: undefined,
      }}
      sx={{
        ...sx,
        position: 'absolute',
        top: containerTop,
        margin: 0, // override
        background: '#fff',
        opacity: containerTop > INITIAL_TOP ? 1 : 0,
        transition: 'opacity 0.3s ease',
        zIndex: 1001,
      }}
    >
      <Box
        ref={containerRef}
        p="2x"
        minWidth={{
          tablet: 200,
        }}
        sx={{
          position: 'relative',
        }}
      >
        <Box
          left={{
            _: getArrowLeft(mobileLeft, containerWidth, rect, customOffsetX),
            tablet: getArrowLeft(
              tabletLeft,
              containerWidth,
              rect,
              customOffsetX
            ),
          }}
          sx={{
            position: 'absolute',
            width: ARROW_WIDTH,
            height: ARROW_HEIGHT,
            background: `url(${arrow}) no-repeat`,
            backgroundSize: '100%',
            ...(align === 'below'
              ? {
                  top: -ARROW_HEIGHT / 2,
                }
              : {}),
            ...(align === 'above'
              ? {
                  bottom: -ARROW_HEIGHT / 2,
                  transform: 'rotate(180deg)',
                }
              : {}),
          }}
        />
        <Box variant="text.h4" lineHeight={1.2}>
          {title}
        </Box>
        <Box variant="text.paragraph" my="1x">
          {description}
        </Box>
        <Button type="ctaTheme2" onClick={onConfirm}>
          {t('Got it')}
        </Button>
      </Box>
    </Box>
  )
}

CoachMarkPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['above', 'below']),
  rect: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  sx: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
}

export default CoachMarkPopup
