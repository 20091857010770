// From https://github.com/cladera/videojs-offset
// Copypasta because they export in a jank way (attach to window) :/

function vjsoffset(options) {
  options != null ? enable.call(this, options) : disable.call(this)

  return this
}

function disable() {
  let Player = this.constructor
  if (Player.__super__ && Player.__super__.__offsetInit) {
    this.off('timeupdate', handleOffsetTimeUpdate)

    delete this._offsetStart
    delete this._offsetEnd
    delete this._restartBeginning
    delete Player.prototype.startOffset
    delete Player.prototype.endOffset

    Player.prototype.duration = Player.__super__.duration
    Player.prototype.currentTime = Player.__super__.currentTime
    Player.prototype.bufferedPercent = Player.__super__.bufferedPercent
    Player.prototype.remainingTime = Player.__super__.remainingTime
    delete Player.__super__
  }
}

function enable(options) {
  let Player = this.constructor
  this._offsetStart = options.start || 0
  this._offsetEnd = options.end || 0
  this._restartBeginning = options.restart_beginning || false

  if (!Player.__super__ || !Player.__super__.__offsetInit) {
    Player.__super__ = {
      __offsetInit: true,
      duration: Player.prototype.duration,
      currentTime: Player.prototype.currentTime,
      bufferedPercent: Player.prototype.bufferedPercent,
      remainingTime: Player.prototype.remainingTime,
    }

    Player.prototype.duration = function() {
      if (this._offsetEnd > 0) {
        return this._offsetEnd - this._offsetStart
      }
      return (
        Player.__super__.duration.apply(this, arguments) - this._offsetStart
      )
    }

    Player.prototype.currentTime = function(seconds) {
      if (seconds !== undefined) {
        return (
          Player.__super__.currentTime.call(this, seconds + this._offsetStart) -
          this._offsetStart
        )
      }
      return (
        Player.__super__.currentTime.apply(this, arguments) - this._offsetStart
      )
    }

    Player.prototype.remainingTime = function() {
      var curr = this.currentTime()
      if (curr < this._offsetStart) {
        curr = 0
      }
      return this.duration() - curr
    }

    Player.prototype.startOffset = function() {
      return this._offsetStart
    }

    Player.prototype.endOffset = function() {
      if (this._offsetEnd > 0) {
        return this._offsetEnd
      }
      return this.duration()
    }
  }

  this.on('timeupdate', handleOffsetTimeUpdate)
}

function handleOffsetTimeUpdate() {
  var curr = Math.round(this.currentTime())
  if (curr < 0) {
    this.currentTime(0)
  }
  if (this._offsetEnd > 0 && curr > this._offsetEnd - this._offsetStart) {
    this.pause()
    if (!this._restartBeginning) {
      this.currentTime(this._offsetEnd - this._offsetStart)
    } else {
      this.trigger('loadstart')
      this.currentTime(0)
    }
  }
}

// Version.
vjsoffset.VERSION = '1.0.1'

// Export a function cause videojs may not be available at time of require.
export default function init(videojs) {
  videojs.plugin('offset', vjsoffset)
  return vjsoffset
}
