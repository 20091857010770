import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'

import Breadcrumbs from 'src/ui/Breadcrumbs/Breadcrumbs'
import BreadcrumbsPlaceholder from 'src/ui/Breadcrumbs/BreadcrumbsPlaceholder'

export const BREADCRUMBS = gql`
  query BREADCRUMBS($pathname: String!) {
    breadcrumbs(pathname: $pathname) {
      key
      name
      link
    }
  }
`

const LoadBreadcrumbs = () => {
  const { data, loading } = useQuery(BREADCRUMBS, {
    variables: { pathname: window.location.pathname },
  })

  if (loading) {
    return <BreadcrumbsPlaceholder />
  }

  if (data?.breadcrumbs?.length) {
    return <Breadcrumbs items={data.breadcrumbs} />
  }

  return null
}

export default LoadBreadcrumbs
