/* eslint-disable react/no-children-prop */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Flex } from '@chatterbug/aaron'

import LanguageSelector from 'src/ui/LanguageSelector/LanguageSelector'
import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'

import ModalPortalWrapper from '../ModalPortalWrapper/ModalPortalWrapper'
import Flag from '../Flag/Flag'
import Course from '../Course/Course'
import MenuItem from '../MenuItem/MenuItem'

const SeparatorLine = () => {
  return (
    <Box
      display={{ _: 'none', tablet: 'flex' }}
      sx={{
        borderBottomWidth: 'small',
        borderBottomColor: 'gray88',
        borderBottomStyle: 'solid',
        width: '100%',
      }}
    />
  )
}

const OrgIcon = ({ orgName }) => {
  return (
    <Flex
      bg="black0"
      color="white100"
      height={26}
      width={26}
      borderRadius="100%"
      justifyContent="center"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="black0"
    >
      <Box>{orgName[0]}</Box>
    </Flex>
  )
}

const OptionalAdminArea = ({ title, href, children }) => {
  return (
    <MenuItem
      textStyle={{ marginLeft: '2x' }}
      text={title}
      sx={{ fontSize: 'xs', paddingLeft: '2x' }}
      showToolTipOnTablet={false}
      href={href}
    >
      {children}
    </MenuItem>
  )
}

const CourseList = ({
  selectedCourse,
  courses,
  textStyle,
  courseStyle,
  eligibleLearningLanguages,
  onClick,
  isTutor = false,
  isModalOpen,
  onCloseModal,
  orgName,
  isEmployee,
  isStreamer,
}) => {
  return (
    <Grid gap="2x" {...courseStyle}>
      {isTutor && (
        <Box borderRadius="1,5x" marginBottom="-10px">
          <OptionalAdminArea
            title={t('Tutor Dashboard')}
            href={routes.home_tutor()}
            children={<Flag languageCode={'tutor'} />}
          />
        </Box>
      )}
      {isEmployee && (
        <Box borderRadius="1,5x" marginBottom="-10px">
          <OptionalAdminArea
            title={t('Admin')}
            href='/admin'
            children={<Flag languageCode={'admin'} />}
          />
        </Box>
      )}
      {(isTutor || isEmployee || isStreamer) && (
        <SeparatorLine />
      )}
      {courses.map(({ code, label }) => {
        return (
          <Course
            key={code}
            selectedCourse={selectedCourse}
            languageCode={code}
            text={label}
            textStyle={{
              marginLeft: '2x',
              ...textStyle,
            }}
          />
        )
      })}
      {eligibleLearningLanguages.length > 0 && (
        <MenuItem
          text={t('Add language').toUpperCase()}
          icon="Add"
          textStyle={{
            display: 'flex',
            marginLeft: '2x',
            fontSize: 'xs',
          }}
          sx={{ paddingLeft: '2x' }}
          showToolTipOnTablet={false}
          onClick={() => {
            onClick()
          }}
        />
      )}
      <ModalPortalWrapper
        isOpen={isModalOpen}
        title={t('Study a New Language')}
        onRequestClose={onCloseModal}
      >
        <LanguageSelector languageCardList={eligibleLearningLanguages} />
      </ModalPortalWrapper>
      {orgName && (
        <>
          <Box borderRadius="1,5x">
            <Box mb="1x">
              <SeparatorLine />
            </Box>
            <OptionalAdminArea
              title={orgName}
              href={routes.home_org()}
              children={<OrgIcon orgName={orgName} />}
            />
          </Box>
        </>
      )}
    </Grid>
  )
}

CourseList.propTypes = {
  l1Code: PropTypes.string,
  selectedCourse: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  eligibleLearningLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  textStyle: PropTypes.object,
  courseStyle: PropTypes.object,
  onClick: PropTypes.func,
  isTutor: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  orgName: PropTypes.string,
  isEmployee: PropTypes.bool,
  isStreamer: PropTypes.bool,
}

export default CourseList
