// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontWeights = {
 "light": 300,
 "regular": 400,
 "medium": 500,
 "semibold": 400,
 "bold": 700
}

export default fontWeights;