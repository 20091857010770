import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@chatterbug/aaron'
import { IbanElement } from 'react-stripe-elements'

import { t } from 'src/lib/i18n-react'

const labelStyle = {
  marginBottom: 'sm',
  alignSelf: 'flex-start',
}

const fieldStyle = {
  flexDirection: 'column',
  marginBottom: 'md',
  borderBottom: '1px #d8d8d8 solid',
}

const inputStyle = {
  border: 0,
  width: '100%',
  outline: 'none',
  fontSize: '18px',
  fontFamily: 'sans-serif',
  ':placeholder': {
    color: '#d8d8d8',
  },
}

const IbanForm = ({ formData, onFormChange }) => {
  const { name, email } = formData
  return (
    <Flex flexDirection="column" fontSize="18px" color="black">
      <Flex {...fieldStyle}>
        <Box as="label" htmlFor="iban_name" {...labelStyle}>
          {t('Name')}
        </Box>
        <Box
          as="input"
          id="iban_name"
          type="text"
          placeholder="Maria Dolores"
          value={name}
          onChange={(e) => onFormChange({ name: e.target.value })}
          required
          {...inputStyle}
        />
      </Flex>
      <Flex {...fieldStyle}>
        <Box as="label" htmlFor="iban_email" {...labelStyle}>
          {t('E-mail address')}
        </Box>
        <Box
          as="input"
          id="iban_email"
          type="email"
          placeholder="maria@example.com"
          value={email}
          onChange={(e) => onFormChange({ email: e.target.value })}
          required
          {...inputStyle}
        />
      </Flex>
      <Flex {...fieldStyle}>
        <Box as="label" {...labelStyle}>
          {t('IBAN')}
        </Box>
        <IbanElement
          supportedCountries={['SEPA']}
          style={{
            base: {
              fontSize: '18px',
              '::placeholder': {
                color: '#D8D8D8',
              },
            },
          }}
        />
      </Flex>
    </Flex>
  )
}

IbanForm.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  onFormChange: PropTypes.func.isRequired,
}

export default IbanForm
