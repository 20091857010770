import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82575 5.26893V4.98893C9.82575 4.24893 9.22575 3.64893 8.48575 3.64893C7.74575 3.64893 7.14575 4.24893 7.14575 4.98893V5.93893"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.82575 5.26893V4.98893C9.82575 4.24893 9.22575 3.64893 8.48575 3.64893C7.74575 3.64893 7.14575 4.24893 7.14575 4.98893V6.20893"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.81579 11.5389H3.27579C2.53579 11.5389 1.93579 10.9389 1.93579 10.1989C1.93579 9.45889 2.53579 8.85889 3.27579 8.85889H4.49579"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47233 7.06534C8.29187 4.2458 12.841 4.22031 15.6919 6.98889H15.4559V8.17578C15.3266 8.03999 15.1954 7.90554 15.0623 7.77245C12.6076 5.31771 8.63417 5.31771 6.17943 7.77245C4.01972 9.93216 3.76015 13.2674 5.40073 15.7097V17.2899C2.62727 14.4394 2.65113 9.88654 5.47233 7.06534ZM7.39282 20.2176C7.39349 20.2192 7.39416 20.2208 7.39483 20.2224C7.47276 20.4081 7.65449 20.5289 7.85588 20.5289H21.8559C22.0021 20.5289 22.141 20.4649 22.236 20.3538C22.3309 20.2426 22.3725 20.0955 22.3498 19.9511C21.8028 16.4797 20.4906 13.206 18.4959 10.3276V12.1738C19.8473 14.4387 20.7824 16.9213 21.2632 19.5289H8.18222C8.16204 19.485 8.14154 19.4412 8.12073 19.3976V20.2176H7.39282Z"
        fill="black"
      />
      <path
        d="M7.40572 12.8591C7.84203 12.8591 8.19572 12.5054 8.19572 12.0691C8.19572 11.6327 7.84203 11.2791 7.40572 11.2791C6.96942 11.2791 6.61572 11.6327 6.61572 12.0691C6.61572 12.5054 6.96942 12.8591 7.40572 12.8591Z"
        fill="black"
      />
      <path
        d="M10.9658 9.29899C11.4021 9.29899 11.7558 8.9453 11.7558 8.50899C11.7558 8.07269 11.4021 7.71899 10.9658 7.71899C10.5295 7.71899 10.1758 8.07269 10.1758 8.50899C10.1758 8.9453 10.5295 9.29899 10.9658 9.29899Z"
        fill="black"
      />
      <path
        d="M11.8459 10.639C11.7559 10.749 11.7659 10.899 11.8659 10.999C12.0659 11.199 12.0459 11.539 11.8359 11.749C11.6259 11.959 11.2759 11.969 11.0759 11.769L10.8559 11.549C10.7859 11.479 10.7859 11.379 10.8259 11.299C10.9859 11.059 11.0359 10.789 10.9859 10.519C10.9259 10.199 10.5159 10.079 10.2759 10.329L9.24589 11.359C8.99589 11.599 9.12589 12.019 9.45589 12.069C9.67589 12.109 9.92589 12.059 10.1259 11.939C10.1559 11.929 10.1859 11.899 10.2259 11.879C10.3059 11.819 10.4059 11.839 10.4759 11.909L10.6959 12.129C10.8959 12.329 10.8859 12.679 10.6759 12.889C10.4659 13.099 10.1359 13.109 9.93589 12.909C9.83589 12.809 9.68589 12.799 9.57589 12.889C9.45589 12.989 9.45589 13.169 9.55589 13.269C9.96589 13.679 10.6359 13.669 11.0459 13.259C11.2759 13.029 11.3759 12.729 11.3559 12.429C11.6559 12.449 11.9559 12.349 12.1859 12.119C12.5959 11.709 12.6059 11.039 12.1959 10.629C12.1259 10.519 11.9559 10.529 11.8459 10.639Z"
        fill="black"
      />
      <path
        d="M15.4058 9.04901V6.07901C15.4058 5.19901 16.1158 4.48901 16.9958 4.48901C17.8758 4.48901 18.5858 5.19901 18.5858 6.07901V11.899"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29572 17.0589V19.0389C8.29572 19.9189 7.58572 20.6289 6.70572 20.6289C5.82572 20.6289 5.11572 19.9189 5.11572 19.0389V16.1191"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
