import React from 'react'

export default ({ selected }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={selected ? 'black' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50024 7.24268C3.50024 5.58582 4.84339 4.24268 6.50024 4.24268H17.5002C19.1571 4.24268 20.5002 5.58582 20.5002 7.24268V18.2427C20.5002 19.8995 19.1571 21.2427 17.5002 21.2427H6.50024C4.84339 21.2427 3.50024 19.8995 3.50024 18.2427V7.24268Z"
        stroke="black"
      />
      <path
        d="M3.84961 9.13257H20.3751"
        stroke={`${selected ? 'white' : 'black'}`}
      />
      <path d="M6.8227 5.39507V2.99507" stroke="black" strokeLinecap="round" />
      <path d="M16.8573 5.39507V2.99507" stroke="black" strokeLinecap="round" />
      <path
        d="M11.2679 13.612C11.2679 13.262 11.5579 12.932 12.0079 12.932C12.4579 12.932 12.7379 13.262 12.7379 13.612C12.7379 13.982 12.4779 14.322 12.0179 14.322C11.5479 14.322 11.2679 13.992 11.2679 13.612ZM10.8179 16.292C10.8179 15.742 11.2879 15.212 11.8979 15.212H12.0779C12.6879 15.212 13.1879 15.732 13.1879 16.292C13.1879 16.892 12.6479 17.392 12.0079 17.392C11.3579 17.392 10.8179 16.892 10.8179 16.292ZM10.0779 13.572C10.0779 14.022 10.2679 14.412 10.5579 14.692C9.9779 15.032 9.5979 15.612 9.5979 16.332C9.5979 17.562 10.6679 18.552 12.0079 18.552C13.3379 18.552 14.4079 17.562 14.4079 16.332C14.4079 15.622 14.0379 15.032 13.4479 14.692C13.7479 14.412 13.9379 14.032 13.9379 13.572C13.9379 12.592 13.0779 11.792 12.0079 11.792C10.9279 11.792 10.0779 12.592 10.0779 13.572Z"
        fill={`${selected ? 'white' : 'black'}`}
      />
    </svg>
  )
}
