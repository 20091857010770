import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@chatterbug/aaron'

const ProgressWidgetSkeleton = ({ headerSection, contentSection }) => {
  return (
    <Box>
      <Box mb="1x" variant="text.h4">
        {headerSection}
      </Box>
      {contentSection}
    </Box>
  )
}

ProgressWidgetSkeleton.propTypes = {
  headerSection: PropTypes.any,
  contentSection: PropTypes.any,
}

export default ProgressWidgetSkeleton
