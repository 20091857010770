/* eslint-disable react/no-render-return-value */
import qs from 'qs'
import LRU from 'lru-cache'

import { t } from 'src/lib/i18n-react'

import { $ } from 'src/config/globals'

import { camelCase, compact, each, isFunction, keyBy, set } from 'lodash'
import { ReactElementHost } from './react-host'

const SmoothScrollSpeed = 200

export function getCSRFToken() {
  const el = document.querySelector('meta[name="csrf-token"]')
  const ret = el?.getAttribute('content')
  if (!el || !ret) {
    throw new Error('CSRF Token not present!')
  }

  return ret
}

// Select elements with the given data-js value.
//
// val - the string value to select on.
//
// For example, consider the following HTML:
//
//     <input data-js="card.answer keyboard.target">
//
// You can select it with:
//
//     let cardAnswer = datajsJQuery('card.answer');
//
// Returns the jQuery elements.
export function datajsJQuery(val: string, context?: any) {
  let pattern = "[data-js~='" + val + "']"
  return context ? context.find(pattern) : $(pattern)
}

export function datajs(val: string, context?: HTMLElement) {
  let pattern = "[data-js~='" + val + "']"
  return context
    ? context.querySelectorAll(pattern)
    : document.querySelectorAll(pattern)
}

// import {dataAttrs} from 'lib/util'
// let element = <div data-cats="yep" data-multi-word="auch"></div>
// let {cats, multiWord} = dataAttrs(element)
export function dataAttrs(element: HTMLElement) {
  let attrs: { [k: string]: string } = {}
  each(element.attributes, (attr) => {
    if (/^data-/.test(attr.name)) {
      attrs[camelCase(attr.name.substr(5))] = attr.value
    }
  })

  return attrs
}

export function ensureAction(controllerName: string, actionName: string) {
  return $('#action-' + controllerName + '-' + actionName).length === 0
}

export function redirectToHREF(href: string, allowReload: boolean) {
  if (allowReload || window.location.href !== href) {
    window.location.href = href
  }
}

export function generateKey() {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export function pluralize(
  n: number,
  singular: string,
  plural?: string,
  options?: { noCount?: boolean }
) {
  if (typeof plural === 'object') {
    options = plural
    plural = undefined
  }
  options = options || {}
  plural = plural || singular + 's'
  let label = n === 1 ? singular : plural
  return options.noCount ? label : n + ' ' + label
}

export function pluralizeHTML(
  number: number,
  singular: string,
  plural?: string,
  options?: { noCount: boolean }
) {
  if (typeof plural === 'object') {
    options = plural
    plural = undefined
  }
  const opts = options || { noCount: false }

  plural = plural || singular + 's'
  let label = number === 1 ? singular : plural
  return opts.noCount
    ? label
    : '<var count pluralize="' + number + '">' + number + '</var> ' + label
}

export function levelToString(n: number) {
  return ~~(n / 100) + '.' + (n % 100)
}

export function stringToLevel(str: string) {
  const levels = str.trim().split(/.·/)
  return parseInt(levels[0]) * 100 + parseInt(levels[1])
}

// Template replace
// renderTemplate('omg {alet}', {alet: 'yeah'}) // => 'omg yeah'
export function renderTemplate(
  template: string,
  letObject: Record<string, string>
) {
  return template.replace(/{([^{}]*)}/g, function (a, b) {
    let r = letObject[b]
    return typeof r === 'string' || typeof r === 'number' ? r : a
  })
}

// Scrolling utilities

export type AnimateFn = () => void

export function scrollToPoint(scrollTop: any, fn: AnimateFn) {
  $('html, body').animate({ scrollTop }, SmoothScrollSpeed, 'swing', fn)
}

export function caseInsensitiveEquals(a: string, b: string) {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b
}

export function scrollMinimallyToElement(
  target: HTMLElement | HTMLElement[],
  options: { offset?: number },
  fn: AnimateFn
) {
  options = options || {}

  if (Array.isArray(target)) {
    let element = target[0]
    let startPos = {
      x: window.pageXOffset,
      y: window.pageYOffset,
    }

    let padding = options.offset == null ? 20 : options.offset
    let height = window.innerHeight
    let clientTop = startPos.y
    let clientBottom = clientTop + height
    let elementTop = element.offsetTop - padding
    let elementBottom = elementTop + element.offsetHeight + padding * 2

    let endY: number
    if (elementTop >= clientTop && elementBottom <= clientBottom) {
      return
    }
    if (elementTop < clientTop) {
      endY = clientTop + (elementTop - clientTop)
    } else {
      endY = clientTop + (elementBottom - clientBottom)
    }

    scrollToPoint(endY, fn)
  }
}

// FIXME: get rid of jquery. Rails has a jquery wrapper that injects a X-CSRF-Token
// header to all requests, so you dont need to send it yourself.
export function get(
  url: string,
  dataType: JQuery.jqXHR.DoneCallback<any, JQuery.jqXHR<any>>
) {
  return new Promise(function (resolve) {
    void $.get(
      url,
      (data: any, status: any) => {
        resolve({ data, status })
      },
      dataType
    )
  })
}

// FIXME: get rid of jquery. Rails has a jquery wrapper that injects a X-CSRF-Token
// header to all requests, so you dont need to send it yourself.
export function post<T = any>(
  url: string,
  data: string | Record<string, unknown>,
  dataType?: string,
  method?: string,
  timeout?: number
) {
  dataType = dataType || 'text'
  let isJSON = dataType === 'json'

  // When the 'json' dataType is specified, it will send the data as JSON, and
  // parse the response as JSON.
  data = isJSON ? JSON.stringify(data) : data
  let processData = !isJSON
  let options: Record<string, any> = {
    type: method || 'POST',
    url,
    dataType,
    data,
    processData,
    contentType: 'application/json; charset=UTF-8',
    timeout: timeout,
    complete: (...args: any) => {},
  }

  if (!isJSON) delete options.contentType
  if (!timeout) delete options.timeout

  function parseJSON<T>(data: string) {
    try {
      return JSON.parse(data) as T
    } catch (e) {
      console.error('Couldnt parse JSON', data)
    }
    return {}
  }

  return new Promise<{ data: T; status: any; statusCode: number }>(function (
    resolve
  ) {
    options.complete = (
      jqXHR: { responseText: string; status: number },
      status: any
    ) => {
      let data: any = jqXHR.responseText
      let statusCode = jqXHR.status
      if (isJSON && data) {
        data = parseJSON(data)
      }
      resolve({ data: data as T, status, statusCode })
    }

    void $.ajax(options)
  })
}

// This method creates a form, adds it to the document, and submits it.
export function postForm(url: string, data: any) {
  const form = document.createElement('form')
  const queryParams = data ? '?' + qs.stringify(data) : ''
  form.setAttribute('action', `${url}${queryParams}`)
  form.setAttribute('method', 'POST')

  const token = document.createElement('input')
  token.setAttribute('type', 'hidden')
  token.setAttribute('name', 'authenticity_token')
  token.setAttribute('value', getCSRFToken())
  form.appendChild(token)

  document.body.appendChild(form)
  form.submit()
}

// FIXME: get rid of jquery. Rails has a jquery wrapper that injects a X-CSRF-Token
// header to all requests, so you dont need to send it yourself.
export function put(
  url: string,
  data: string | Record<string, unknown>,
  dataType: string
) {
  return post(url, data, dataType, 'PUT')
}

// FIXME: get rid of jquery. Rails has a jquery wrapper that injects a X-CSRF-Token
// header to all requests, so you dont need to send it yourself.
export function destroy(
  url: string,
  data: string | Record<string, unknown>,
  dataType: string
) {
  return post(url, data, dataType, 'DELETE')
}

export function isSuccessStatus(status: string | number) {
  return typeof status === 'string'
    ? status === 'success'
    : status >= 200 && status < 400
}

export function stringToHTMLElement(str: string) {
  str = str.trim()
  if (!str) return null

  let junkEl = document.createElement('div')
  junkEl.innerHTML = str
  return junkEl.childNodes.length === 1
    ? junkEl.childNodes[0]
    : Array.from(junkEl.childNodes)
}

// Translate locally via ___?
export function translate(thing: any) {
  console.warn(`Translation missing for: ${thing}`)
  return thing
}

const translateCache = new LRU({ max: 64 })

// Returns Promise. Result of promise will be an Object
//
// {translation: 'Wie gefällt dir die lampe?'}
export function translateRemote(content: string, languageCode: string) {
  let params = { content, from_language: languageCode }
  let key = `___LANG_CODE__${languageCode}\n\n${content}`
  if (translateCache.has(key)) {
    return Promise.resolve(translateCache.get(key))
  }

  const ret = post('/language/translate', params, 'json')

  return ret.then(({ data }) => {
    translateCache.set(key, data)
    return data
  })
}

// Pass in a form, get an object with the {key: values}
export function serializeForm(form: HTMLFormElement) {
  let data = {}
  const obj = keyBy($(form).serializeArray(), 'name')
  for (const key in obj) set(data, key, obj[key].value)

  return data
}

// Bind to DOM events on children of the element. e.g. disable all link clicks:
//
// on(element, 'click', 'a', (e) => e.preventDefault)
export function on(element: any, type: any, selector: any, handler: any) {
  return $(element).on(type, selector, handler)
}

// Automatically bind all functions on the instance's proto to 'this'
// From: https://github.com/kodefox/class-autobind/blob/master/src/autobind.js
//
// instance - usually this
// proto - (optioal) Prototype of functions to bind to instance
export function bindMethods(
  instance: { [x: string]: any },
  proto: { [x: string]: any }
) {
  if (proto == null) proto = getFlattenedPrototype(instance)
  for (let name in proto) {
    let value = proto[name]
    if (isFunction(value) && name !== 'constructor') {
      instance[name] = value.bind(instance)
    }
  }
}

export function getFlattenedPrototype(obj: { [x: string]: any }) {
  const ObjectProto = Object.prototype

  let keys: string[] = []
  let proto = Object.getPrototypeOf(obj)
  while (proto !== null && proto !== ObjectProto) {
    keys.push(...Object.getOwnPropertyNames(proto))
    proto = Object.getPrototypeOf(proto)
  }

  let flattened: Record<string, unknown> = {}
  for (let key of keys) {
    flattened[key] = obj[key]
  }
  return flattened
}

// Takes an array and produces a string, removing falsy things
//
// classString([
//   'thing',
//   'thing_one',
//   isOMG && 'thing_omg'
// ])
//
// (isOMG === true) => 'thing thing_one thing_omg'
// (isOMG === false) => 'thing thing_one'
export function classString(classes: (string | null | false | undefined)[]) {
  return compact(classes).join(' ')
}

// Takes your hundred-denomination currency value, and produces
// a readable string with currency symbol included.
export function formatMoneyString(symbol: string, amount: number) {
  const amountStr = (amount / 100).toFixed(2).toString().replace(/\.00$/, '')

  return `${symbol}${amountStr}`
}

// You can rerender an element that was generated by react-rails. This is
// useful for rerendering a component in a non-react
//
// options - {
//   component: ComponentClass // - optional; if not specified, will use window.ReactRailsUJS to find
// }
export function renderReactComponent(
  props: any,
  element: string | Element,
  options: { component: any }
) {
  // NB: Yes, I know I got lazy and any'd my way through, sorryyyyy
  let el: Element | null
  if (typeof element === 'string') {
    el = document.querySelector(element)
  } else {
    el = element
  }

  if (!el) {
    throw new Error(`Cannot find element with query selector: ${element}`)
  }

  if (el.tagName === 'REACT-ELEMENT') {
    const comp = el as ReactElementHost
    comp.setProps(props)
    return
  }

  if (options?.component) {
    // NB: We do the setTimeout here to prevent reentrant renders (i.e. if we're
    // in a LayoutBridge)
    const toReplace = ReactElementHost.create(options.component, props)

    // NB: We need to re-apply our style and ID
    const klass = el.getAttribute('class')
    if (klass) toReplace.setAttribute('class', klass)
    const id = el.getAttribute('id')
    if (id) toReplace.setAttribute('id', id)

    setTimeout(() => el.replaceWith(toReplace), 0)
    return
  }

  console.error('Invalid parameters for renderReactComponent!')
}

let langTable: Record<string, string>

// converts l2Code into a language name
export const getLanguageName = (l2Code: string) => {
  langTable = langTable || {
    de: t('German'),
    en: t('English'),
    fr: t('French'),
    es: t('Spanish'),
    other: t('Other'),
  }

  return langTable[l2Code]
}
