import _ from 'lodash'

import Module from 'lib/module'

// A spinner that shows next to form buttons
//
// <span class="form-loader">
//   <span class="form-loader__message"></span>
//   <span class="loader_spinner"></span>
// </span>
//
// render() accepts: {
//   message: string,
//   messageType: string, // 'error'
//   loading: bool
// }

export default class FormLoader extends Module {
  constructor(element, options) {
    super(element, options)
    this.element = element
    this.loadingClass = FormLoader.className + '_loading'
    this.messageSelector = FormLoader.selector + '__message'
    this.loaderSelector = '.loader_spinner'

    this.loader = element.querySelector(this.loaderSelector)
    this.message = element.querySelector(this.messageSelector)
  }

  // do all dom updates in here
  render(state, prevState) {
    let { loading, message, messageType } = state
    messageType = messageType || 'error'

    if (loading) {
      this.element.classList.add(this.loadingClass)
    } else {
      this.element.classList.remove(this.loadingClass)
    }

    if (message) {
      this.message.textContent = message
      this.element.classList.add(
        `${FormLoader.className}_message-${messageType}`
      )
    } else {
      this.message.textContent = ''
      removeMessageClass(this.element)
    }
  }
}
FormLoader.className = 'form-loader'
FormLoader.selector = '.form-loader'

const MessageClassRE = new RegExp(`^${FormLoader.className}_message-`)
function removeMessageClass(element) {
  let classes = _.toArray(element.classList)
  for (let className of classes) {
    if (MessageClassRE.test(className)) {
      element.classList.remove(className)
      break
    }
  }
}
