import { post } from 'lib/util'

export default class ScheduleUpcomingAppointments {
  constructor(element) {
    if (!element) return
    const selector = ScheduleUpcomingAppointments.selector
    let confirmForms = element.querySelectorAll(`${selector}__confirm-form`)
    let confirmModal = element.querySelector(`${selector}__confirm-modal`)
    let confirmBody = element.querySelector(`${selector}__confirm-body`)

    $(confirmForms).submit(function(e) {
      e.preventDefault()
      let form = $(this)
      let data = form.serializeArray()
      post(form.attr('action'), data).then(({ data }) => {
        $(confirmBody).html(data)
        $(confirmModal).modal('show')
        $(confirmModal).on('shown.bs.modal', () => {
          $(confirmBody)
            .find('input, textarea')
            .focus()
        })
      })
    })
  }
}

ScheduleUpcomingAppointments.className = 'schedule-upcoming-appointments'
ScheduleUpcomingAppointments.selector = `.${ScheduleUpcomingAppointments.className}`
