import AudioTrigger from 'modules/audio'
import { scrollMinimallyToElement } from 'lib/util'

function initializeIntroPage() {
  var introBlock = $('.card_question-intro')
  if (introBlock.length === 0) return

  scrollMinimallyToElement(introBlock)

  if ($('#audio-autoplay').val() === 'true') {
    AudioTrigger.toggleAudio.call($('.audio__trigger').first())
  }
}

document.addEventListener('turbolinks:load', initializeIntroPage)
