import React from 'react'
import { Grid, Box, Flex } from '@chatterbug/aaron'

export type Props = {
  backgroundColor?: string
  backgroundSection?: any
  contentSection: any
  buttonSection: any
  dismissSection?: any
}

const BannerSkeleton: React.FC<Props> = ({
  backgroundColor,
  backgroundSection,
  contentSection,
  buttonSection,
  dismissSection,
}) => {
  return (
    <Box
      as="section"
      py={{
        _: '2x',
        tablet: '1x',
      }}
      px="1x"
      sx={{
        position: 'relative',
        // add a background color if it was defined
        ...(backgroundColor
          ? {
              // a linear gradient works only for `background` or `backgroundImage` props
              // in order to use our color tokens we have to specify `backgroundColor` prop
              [backgroundColor.includes('gradient')
                ? 'background'
                : 'backgroundColor']: backgroundColor,
            }
          : {}),
      }}
    >
      {backgroundSection && (
        <Box
          sx={{ position: 'absolute', left: 0, width: '100%', height: '100%' }}
        >
          {backgroundSection}
        </Box>
      )}
      <Grid
        justifyContent="center"
        textAlign="center"
        alignItems={{
          tablet: 'center',
        }}
        gridGap="3x"
        columns={{
          _: '1fr',
          tabletWide: 'auto auto auto',
        }}
        minHeight={{
          tablet: 56,
        }}
        gridTemplateAreas={{
          _: `
            "DISMISS"
            "CONTENT"
            "BUTTON"
          `,
          tablet: `
            "CONTENT BUTTON DISMISS"
          `,
        }}
      >
        {dismissSection && (
          <Flex sx={{ gridArea: 'DISMISS' }} justifyContent="center">
            {dismissSection}
          </Flex>
        )}
        {contentSection}
        {buttonSection}
      </Grid>
    </Box>
  )
}

export default BannerSkeleton
