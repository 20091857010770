import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal } from '@chatterbug/aaron/modal'
import { t } from 'src/lib/i18n-react'

import Book from './subcomponents/Book'
import Confirm from './subcomponents/Confirm'
import Cancel from './subcomponents/Cancel'
import Reject from './subcomponents/Reject'

const modalBodyForStatus = (status) =>
  ({
    book: Book,
    confirm: Confirm,
    cancel: Cancel,
    reject: Reject,
  }[status])

const AppointmentModifyConfirmation = ({
  status,
  appointment,
  advanced,
  ...others
}) => {
  const Body = modalBodyForStatus(status)

  return (
    <Modal title={t('Confirm Your Change')} {...others}>
      <SC.Body>
        <Body appointment={appointment} advanced={advanced} {...others} />
      </SC.Body>
    </Modal>
  )
}

const SC = {}
SC.Body = styled.div`
  text-align: center;
  max-width: 600px;
  p {
    margin-bottom: 15px;
  }
`

AppointmentModifyConfirmation.propTypes = {
  status: PropTypes.oneOf(['confirm', 'cancel', 'book', 'reject']).isRequired,
  appointment: PropTypes.object.isRequired,
  advanced: PropTypes.bool,
}

export default AppointmentModifyConfirmation
