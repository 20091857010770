import React from 'react'

import { Card } from '@chatterbug/aaron'

const Filler = ({ complete }) => {
  return (
    <Card
      borderRadius="lg"
      aria-label="filler"
      css={`
        background-image: linear-gradient(135deg, #00e6c2, #25cced);
        background-repeat: no-repeat;
        background-size: 100%;
        width: ${complete * 100}%;
        min-width: 6px;
        height: 100%;
        transition: all 0.15s ease-in;
        border-radius: 24px;
      `}
    />
  )
}

export default Filler
