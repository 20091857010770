// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const mediaQueries = {
 "mobile": "420px",
 "tablet": "768px",
 "tabletWide": "1024px",
 "laptop": "1200px",
 "desktop": "1440px"
}

export default mediaQueries;