import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '@chatterbug/aaron'

import deRegular from './assets/de/know.svg'
import deConfused from './assets/de/dontKnow.svg'
import enRegular from './assets/en/know.svg'
import enConfused from './assets/en/dontKnow.svg'
import frRegular from './assets/fr/know.svg'
import frConfused from './assets/fr/dontKnow.svg'
import esRegular from './assets/es/know.svg'
import esConfused from './assets/es/dontKnow.svg'

const IMAGES = {
  de: { regular: deRegular, confused: deConfused },
  fr: { regular: frRegular, confused: frConfused },
  en: { regular: enRegular, confused: enConfused },
  es: { regular: esRegular, confused: esConfused },
}

const LanguageAvatar = ({
  languageCode = 'de',
  variant = 'regular',
  altText,
}) => {
  return <Image src={IMAGES[languageCode][variant]} alt={altText || ''} />
}

LanguageAvatar.propTypes = {
  languageCode: PropTypes.oneOf(['de', 'fr', 'es', 'en']),
  variant: PropTypes.oneOf(['regular', 'confused']),
  altText: PropTypes.string,
}

export default LanguageAvatar
