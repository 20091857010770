import _ from 'lodash'
import videojs from 'video.js'

import { bindMethods } from 'lib/util'
import Module from 'lib/module'
import ChatChannelReplay from 'lib/chat-channel-replay'
import InteractiveImage from 'modules/interactive-image'
import ReplayControls from 'modules/replay-controls'
import ReplayProgressBar from 'modules/replay-progress-bar'
import ExerciseSwitcher from 'modules/exercise-switcher'
import ReplayExerciseSummary from 'modules/replay-exercise-summary'
import 'video.js/dist/video-js.min.css'
import initVideoJSOffset from 'modules/videojs-offset'

export default class ChatSessionReplay {
  constructor(element, options) {
    initVideoJSOffset(videojs)
    bindMethods(this)
    this.element = element
    this.elements = Module.findElements(element, ChatSessionReplay.className)
    this.videoEl = element.querySelector('.video-playback')
    if (this.videoEl) {
      this.video = videojs(this.videoEl)
      this.video.on('play', this.handleVideoPlay)
      this.video.on('pause', this.handleVideoPause)
      this.video.on('timeupdate', this.handleVideoTimeUpdate)
      $(element).on(
        'click',
        `.${ChatSessionReplay.className}__start`,
        this.handleClickPlay
      )
      this.setOptions(options)
    }
  }

  setOptions(options) {
    this.options = _.defaults(options, {
      videoSrc: null,
      startTime: null,
      endTime: null,
      offset: null,
      events: [],
    })

    if (!this.video) return

    let { videoSrc } = this.options
    this.video.offset(null) // disable offset before src load, will be maybe enabled in init / updateReplay
    if (videoSrc) this.video.src(videoSrc)

    // Sometimes loadedmetadata isnt called, and it's hard to tell why.
    let fallbackTimeout = window.setTimeout(() => this.initReplay(), 3000) // fallback if it isnt called.
    this.video.one('loadedmetadata', () => {
      clearTimeout(fallbackTimeout)
      this.initReplay()
    })
  }

  initReplay() {
    if (this.initialized) return this.updateReplay()
    this.initialized = true

    let exerciseSwitcher = this.element.querySelector(ExerciseSwitcher.selector)
    let replayExerciseSummary = this.element.querySelector(
      ReplayExerciseSummary.selector
    )
    let replayProgressBar = this.element.querySelector(
      ReplayProgressBar.selector
    )
    let replayControls = this.element.querySelector(ReplayControls.selector)
    let curriculumItems = this.element.querySelector(
      ExerciseSwitcher.curriculumItemsSelector
    )
    let interactiveImage = this.elements.leftColumn.querySelector(
      InteractiveImage.selector
    )

    this.updateVideoOffset(this.options)
    this.replay = this.createChatChannelReplay(this.options)

    if (replayProgressBar) {
      this.replayProgressBar = new ReplayProgressBar(replayProgressBar, {
        chatChannelReplay: this.replay,
        onScrub: this.handleScrubProgressBar,
        onChange: this.handleChangeProgressBar,
      })
    }

    if (replayControls) {
      this.replayControls = new ReplayControls(replayControls, {
        chatChannelReplay: this.replay,
        onPlay: this.handleClickPlay,
        onPause: this.handleClickPause,
        onStop: this.handleClickStop,
      })
    }

    if (interactiveImage) {
      this.interactiveImage = new InteractiveImage(interactiveImage, {
        chatChannelReplay: this.replay,
      })
    }

    if (exerciseSwitcher) {
      this.exerciseSwitcher = new ExerciseSwitcher(exerciseSwitcher, {
        chatChannelReplay: this.replay,
        exercises: curriculumItems,
      })
    }

    if (replayExerciseSummary) {
      this.replayExerciseSummary = new ReplayExerciseSummary(
        replayExerciseSummary,
        {
          chatChannelReplay: this.replay,
          onViewExercise: this.handleChangeProgressBar,
        }
      )
    }
  }

  updateReplay() {
    if (this.replay) this.replay.dispose()
    this.updateVideoOffset(this.options)
    this.replay = this.createChatChannelReplay(this.options)
    if (this.replayProgressBar) {
      this.replayProgressBar.setChatChannelReplay(this.replay)
    }
    if (this.replayControls) {
      this.replayControls.setChatChannelReplay(this.replay)
    }
    if (this.interactiveImage) {
      this.interactiveImage.setChatChannelReplay(this.replay)
    }
  }

  // options - {
  //   startTime: 'UTC string',
  //   endTime: ...,
  //   offsetStartTime: ..., // optional, if you want to slice the vid
  //   offsetEndTime: ...,   // optional, if you want to slice the vid
  // }
  //
  // Requires that the video metadata has been loaded cause it looks at the duration.
  getVideoTimeMeta(options) {
    let { startTime, endTime, offsetStartTime, offsetEndTime } = options
    startTime = millisFromString(startTime)
    endTime = millisFromString(endTime)
    offsetStartTime = millisFromString(offsetStartTime)
    offsetEndTime = millisFromString(offsetEndTime)

    let offsetStart = null

    let offsetEnd = null

    // HACK: We dont have the real beginning timestamp of the video, so we're
    // inferring it. Empirically, the end time seems more accurate, so use that.
    //
    // There is a 100ms padding here so the replay stops before the video
    let durationMillis = parseInt(this.video.duration() * 1000) - 100
    startTime = endTime - durationMillis

    if (offsetStartTime && offsetEndTime) {
      offsetStart = Math.max(offsetStartTime - startTime, 0)
      offsetEnd = Math.min(offsetEndTime, endTime) - startTime
      durationMillis = offsetEndTime - offsetStartTime
    } else if (endTime) {
      // There is a 100ms padding here so the replay stops before the video
      durationMillis = parseInt(this.video.duration() * 1000) - 100
      startTime = startTime || endTime - durationMillis
    }

    return {
      startTime,
      endTime, // abs millis
      offsetStart,
      offsetEnd, // relative millis
      offsetStartTime,
      offsetEndTime, // abs millis
      durationMillis,
    }
  }

  createChatChannelReplay(options) {
    let { events } = options
    let { startTime, offsetStartTime, durationMillis } = this.getVideoTimeMeta(
      options
    )
    let replay = new ChatChannelReplay({
      events,
      durationMillis,
      startTime: offsetStartTime || startTime,
    })
    replay.on('stop', this.handleStop)
    return replay
  }

  updateVideoOffset(options) {
    let { offsetStart, offsetEnd } = this.getVideoTimeMeta(options)
    if (offsetStart != null) {
      // HACK: Man. The re-up of the src will make the video check the offset things.
      // Once we have real start times, this can be in setOptions().
      this.video.src(this.video.src())
      this.video.offset({
        start: offsetStart / 1000,
        end: offsetEnd / 1000,
        restart_beginning: false,
      })
    } else {
      this.video.offset(null)
    }
  }

  // video event handlers

  handleVideoPlay() {
    this.replay.play()
  }

  handleVideoPause() {
    this.replay.pause()
  }

  handleVideoTimeUpdate() {
    let millis = this.video.currentTime() * 1000
    if (this.replay) this.replay.seek(millis)
  }

  // User interaction event handlers

  handleClickPlay() {
    this.video.play()
  }

  handleClickPause() {
    this.video.pause()
  }

  handleClickStop() {
    this.video.pause()
    this.replay.stop()
  }

  handleScrubProgressBar() {
    if (!this.scrubPlayState) {
      this.scrubPlayState = this.replay.getPlayState()
      if (this.scrubPlayState === 'playing') this.video.pause()
    }
  }

  handleChangeProgressBar(millis) {
    if (
      this.scrubPlayState === 'playing' ||
      this.replay.getPlayState() === 'stopped'
    ) {
      this.video.play()
      this.replay.play()
    }
    this.video.currentTime(millis / 1000)
    this.replay.seek(millis) // without setting this too, the progress bar will jump
    this.scrubPlayState = null
  }

  // Replay event handlers

  handleStop() {
    this.video.pause()
    this.video.currentTime(0)
  }
}

function millisFromString(stamp) {
  return stamp ? new Date(stamp).getTime() : null
}

ChatSessionReplay.className = 'chat-session-replay'
ChatSessionReplay.selector = `.${ChatSessionReplay.className}`
