export enum LanguageCode {
  en = 'en',
  de = 'de',
  es = 'es',
  fr = 'fr',
}

export enum CefrLevelCode {
  A1 = 'a1',
  A2 = 'a2',
  B1 = 'b1',
  B2 = 'b2',
  C1 = 'c1',
  C2 = 'c2',
}

export type Language = {
  code: LanguageCode
  name?: string
  localizedName?: string
  pairedNativeLanguages?: {
    code: LanguageCode
    name: string
    localizedName: string
  }[]
}

export type LeaderboardUser = {
  id: number
  login: string
  avatarUrl: string
}

export type LeaderboardPosition = {
  user: LeaderboardUser
  studyTime: number
  rank: number
  languages: Language[]
  isCurrentUser?: boolean
}

export type Certification = {
  kind: 'major' | 'partial_major'
  licenseNumber: string
  startedAt: string
  completedAt: string
  linkUrl: string
  selfStudyInMinutes: number
  liveLessonsInMinutes: number
}

export type Plan = {
  credits: number
  label: string
}

export enum SubscriptionCode {
  lite = 'lite',
  casual = 'casual',
  serious = 'serious',
  ultimate = 'ultimate',
}
