import _ from 'lodash'
import moment from 'moment'

import { t } from 'src/lib/i18n'

export default class ActivityTime {
  constructor(element, options) {
    this.element = element
    this.options = _.defaults({}, options)
    if (!this.options.activityTimer) throw new Error('Needs an ActivityTimer!')

    this.idleClass = `${ActivityTime.className}__idle`
    this.elapsedClass = `${ActivityTime.className}__elapsed`

    this.render = this.render.bind(this)
    this.start()
  }

  start() {
    window.setInterval(this.render, 5000)
  }

  render() {
    let { activityTimer } = this.options
    let idleSeconds = renderSeconds(activityTimer.getIdleSeconds())
    let elapsedSeconds = renderSeconds(activityTimer.getElapsedSeconds())
    this.element.innerHTML =
      (elapsedSeconds
        ? `<span class="${this.elapsedClass}">${elapsedSeconds}</span>`
        : '') +
      (idleSeconds
        ? `<span class="${this.idleClass}"> (${t('idle for %{idleSeconds}', {
            idleSeconds,
          })})</span>`
        : '')
  }
}

ActivityTime.className = 'activity-time'
ActivityTime.selector = `.${ActivityTime.className}`

const formatMinutes = (minutes) =>
  t('%{minutes} minute |||| %{minutes} minutes', {
    smart_count: minutes,
    minutes,
  })
const formatHours = (hours) =>
  t('%{hours} hour |||| %{hours} hours', { smart_count: hours, hours })

function renderSeconds(seconds) {
  let duration = moment.duration(seconds, 'seconds')
  let hours = duration.hours()
  let minutes = duration.minutes()
  if (hours) {
    return `${formatHours(hours)} ${formatMinutes(minutes)}`
  } else if (duration.minutes()) {
    return formatMinutes(minutes)
  }
  return ''
}
