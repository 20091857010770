import moment from 'moment-timezone'
import { DateTime } from 'luxon'
import { t } from 'src/lib/i18n'

import enLocale from 'src/locales/en'
import deLocale from 'src/locales/de'
import esLocale from 'src/locales/es'
import frLocale from 'src/locales/fr'

// TODO: See if we can reduce the dependency on the locales by doing this ourselves:
// https://momentjs.com/docs/#/customization/relative-time/

// Load moment-specific localized formats. 'en' is built-in.
import 'moment/locale/es'
import 'moment/locale/de'
import 'moment/locale/fr'

import { getAltTime } from 'src/config/globals'

export default class DateUtil {
  static formats = {
    en: enLocale.chatterbug,
    de: deLocale.chatterbug,
    es: esLocale.chatterbug,
    fr: frLocale.chatterbug,
  }
  static FullFormat = 'cb_MMMDYYYYhmma'

  static calendarDate(date, withTime = false) {
    const time = withTime ? ' LT' : ''
    const hours = moment(date).hours()

    const today = t('Today at |||| Today at', { smart_count: hours })

    const tomorrow = t('Tomorrow at |||| Tomorrow at', { smart_count: hours })

    return moment(date).calendar(null, {
      sameDay: `[${today}] ${time}`,
      nextDay: `[${tomorrow}] ${time}`,
    })
  }

  static dateFromISOString(date, locale, timezone) {
    locale = locale || 'en'
    const momentDate =
      timezone !== '' ? moment.tz(date, timezone) : moment(date)
    momentDate.locale(locale)
    return momentDate
  }

  static getFormats(locale) {
    let formats = DateUtil.formats[locale]

    if (!formats) {
      const baseLocale = locale.split('-')[0]
      formats = DateUtil.formats[baseLocale] || DateUtil.formats.en
    }
    return formats
  }

  static format(date, format) {
    if (format === 'fromNow') {
      return date.fromNow()
    } else if (format === 'fromNowSimple') {
      return date.fromNow(true)
    } else if (format === 'toNow') {
      return date.toNow()
    } else if (format === 'toNowSimple') {
      return date.toNow(true)
    } else if (format.match(/^cb_/)) {
      const altFormat = `${format}_alt`

      const allFormats = DateUtil.getFormats(date.locale())

      let fmt
      if (getAltTime() && allFormats[altFormat]) {
        fmt = allFormats[altFormat]
      }

      fmt = fmt ?? allFormats[format]
      return date.format(fmt)
    } else {
      return date.format(format)
    }
  }

  static DATETIME_FORMAT_RE = /^(DATE|TIME|DATETIME)_[A-Z0-9_]+$/
  static DATETIME_AGO_RE = /^(weeks|days|hours|minutes|seconds)(ago|fromnow)(short)?$/i
  static DATETIME_RELATIVE_RE = /^relative(weeks|days|hours|minutes|seconds)?$/i
  static DATETIME_AGO_LIMITS = {
    weeks: 52,
    days: 365,
    hours: 72,
    minutes: 300,
    seconds: 600,
  }

  static formatDateTime(date, format) {
    let match = null
    if (
      DateUtil.DATETIME_FORMAT_RE.test(format) &&
      DateTime.hasOwnProperty(format)
    ) {
      return date.toLocaleString(DateTime[format])
    } else if (typeof format === 'object') {
      return date.toLocaleString(format)
    } else if ((match = DateUtil.DATETIME_AGO_RE.exec(format)) !== null) {
      const units = match[1]
      const difference = date.diffNow(units)
      const value = difference[units]
      const label = /short/i.test(match[3]) ? units[0] : ` ${units}`
      const limit = DateUtil.DATETIME_AGO_LIMITS[units]
      if (Math.abs(value) > limit) {
        if (units === 'weeks' || units === 'days') {
          return date.toLocaleString(DateTime.DATE_SHORT)
        } else {
          return date.toLocaleString(DateTime.DATETIME_SHORT)
        }
      } else if (value < 0) {
        return `${-Math.round(value)}${label} ago`
      } else {
        return `${Math.round(value)}${label} from now`
      }
    } else if ((match = DateUtil.DATETIME_RELATIVE_RE.exec(format)) !== null) {
      if (match[1]) {
        return date.toRelative({ unit: match[1].toLowerCase() })
      } else {
        return date.toRelative()
      }
    } else if (format === 'date') {
      return date.toLocaleString(DateTime.DATE_MED)
    } else {
      return date.toLocaleString(DateTime.DATETIME_SHORT)
    }
  }

  static convertTimeTo12HourFormat(locale, time) {
    const date = new Date(time)
    const formattedTime = date.toLocaleTimeString(locale, {
      hour: 'numeric',
      minute: '2-digit',
    })
    const timeSections = formattedTime.split(' ')

    return timeSections
  }
}
