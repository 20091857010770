import { EventEmitter } from 'events'

import _ from 'lodash'

import AppCable from './app-cable'

// Requiring the module twice will not allow us to keep state local to this
// file. Likely this is a sprockets commoner thing. So keep the state on global.
global.__channels = global.__channels || {}

// Call `ChatChannel.get(sessionId)` to get one
// They emit events: `chatChannel.on('speak', fn...)`
class ChatChannel {
  static get(sessionId) {
    if (!global.__channels[sessionId]) {
      global.__channels[sessionId] = createChannel(sessionId)
    }
    return global.__channels[sessionId]
  }

  constructor(sessionId) {
    this.sessionId = sessionId
    this.emitter = new EventEmitter()
    this.connected = false
    this.connect()
  }

  connect() {
    var sessionId = this.sessionId
    var options = { channel: 'ChatChannel', session: sessionId }
    this.server = AppCable.createSubscription(options, {
      connected: () => {
        this.log('connected to chat')
        this.connected = true
        this.emitter.emit('connect')
      },
      disconnected: () => {
        this.log('disconnected from chat')
        this.connected = false
        removeChannel(sessionId)
        this.emitter.emit('disconnect')
      },
      rejected: () => {
        this.log('rejected from chat')
        removeChannel(sessionId)
        this.emitter.emit('reject')
      },
      received: (data) => {
        this.emitter.emit(data.type, data)
      },
      getPresence: function() {
        this.perform('getPresence')
      },
    })
  }

  send(data) {
    return this.server.send(data)
  }

  clientReady() {
    this.server.send({ type: 'clientReady' })
  }

  getClientReady() {
    this.server.send({ type: 'getClientReady' })
  }

  getPresence() {
    this.server.getPresence()
  }

  resetClientReady() {
    this.server.send({ type: 'resetClientReady' })
  }

  mouseDown(data) {
    this.server.send(_.assign({ type: 'mouseDown' }, data))
  }

  mouseMove(data) {
    this.server.send(_.assign({ type: 'mouseMove' }, data))
  }

  nextExercise(data) {
    this.server.send(_.assign({ type: 'nextExercise' }, data))
  }

  connectionQuality(data) {
    this.server.send(_.assign({ type: 'connectionQuality' }, data))
  }

  bandwidthQuality(data) {
    this.server.send(_.assign({ type: 'bandwidthQuality' }, data))
  }

  jumpToWrapup(data) {
    this.server.send(_.assign({ type: 'jumpToWrapup' }, data))
  }

  endSession() {
    this.server.send({ type: 'endSession' })
  }

  log(message) {
    this.server.send({ type: 'log', message: message })
  }

  on(event, listener) {
    if (event === 'connect' && this.connected) listener()
    this.emitter.on(event, listener)
  }

  off(event, listener) {
    this.emitter.removeListener(event, listener)
  }
}

function removeChannel(sessionId) {
  delete global.__channels[sessionId]
}

function createChannel(sessionId) {
  return new ChatChannel(sessionId)
}

export default ChatChannel
