import _ from 'lodash'

import { bindMethods } from 'lib/util'
import Form from 'modules/form'

// Handles posting to the form action of a form in a modal. Uses the Form module
// to show the loader, etc
//
// options: {
//   onComplete: ({data, status}) => , // called on success and failure
//   onSuccess: ({data, status}) => ,
//   onFail: ({data, status}) => ,
//   onShow: () => {},
//   formData: () => {} // return data to be posted to the server
// }

export default class FormModal {
  constructor(element, options) {
    if (!element) return
    bindMethods(this)
    this.element = element
    this.options = _.defaults(options, {
      onShow: () => {},
    })

    this.form = new Form(element.querySelector('form'), this.options)
    $(this.element).on('show.bs.modal', this.handleModalShow)
    $(this.element).on('shown.bs.modal', this.handleModalShown)
  }

  submit() {
    this.form.submit()
  }

  hide() {
    $(this.element).modal('hide')
  }

  show() {
    $(this.element).modal('show')
  }

  handleModalShow(e) {
    this.form.reset()
    this.options.onShow()
  }

  handleModalShown() {
    // focus first element
    $(this.element)
      .find('input:visible:first')
      .focus()
  }
}

FormModal.className = 'form-modal'
FormModal.selector = `.${FormModal.className}`
