import React from 'react'

export default ({ selected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={selected ? 'black' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16692 9.00369L10.6669 3.19193C11.426 2.51318 12.574 2.51318 13.3331 3.19193L19.8331 9.00369C20.2574 9.38312 20.5 9.92539 20.5 10.4946V19.5C20.5 20.6046 19.6046 21.5 18.5 21.5H14.5V16.5C14.5 15.1193 13.3807 14 12 14C10.6193 14 9.5 15.1193 9.5 16.5V21.5H5.5C4.39543 21.5 3.5 20.6046 3.5 19.5V10.4946C3.5 9.92539 3.74257 9.38312 4.16692 9.00369Z"
        stroke="black"
      />
    </svg>
  )
}
