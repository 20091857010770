import _ from 'lodash'
import { OpenVidu } from 'openvidu-browser'

import { post, bindMethods } from 'lib/util'
import ConnectionQuality from 'lib/connection-quality'

import TelcoBase from './base'

const AUDIO_THROTTLE = 100 // milliseconds
const QUALITY_INTERVAL = 1000
const QUALITY_TEST_LENGTH = 15000
const AUDIO_HUSHER = 0.25

export default class Openvidu extends TelcoBase {
  constructor() {
    super()
    bindMethods(this)
    this.OV = new OpenVidu()
    this.OV.enableProdMode()
    // https://openvidu.io/docs/advanced-features/speech-detection/
    this.OV.setAdvancedConfiguration({
      publisherSpeakingEventsOptions: {
        interval: 200, // Frequency of the polling of audio streams in ms
      },
    })
  }

  // Public: Set up local video and connection to chat server
  //
  //    chatOptions - Hash of options, including:
  //      'token'        - Session token for OpenVidu (required)
  //      'userToken'    - User token for OpenVidu to join session.
  //      'localVideoId' - Element ID into which local video node will
  //                       be inserted, defaults to 'ourvideo'
  //      'remoteVideoId' - Element ID into which remote video node will
  //                       be inserted, defaults to 'theirvideo'
  //      'onPublish'    - Callback function invoked on when Telco is ready to publish
  //      'onFailure'    - Callback function invoked on failures, will be
  //                       passed Hash with 'reason' & maybe other keys
  //      'onDisconnect' - Callback when disconnected from video session
  //      'onLog'        - Callback when a message should be logged
  initialize(chatOptions) {
    this.options = _.extend({}, this.defaultOptions, chatOptions)

    if (!this.options.hasOwnProperty('token')) {
      this.options.onFailure({
        reason: 'Missing required credentials for initializing video chat',
      })
      return
    }

    this.log(
      `connecting to ${this.options.token} as ${this.options.identity ||
        '<missing>'}`
    )

    this.session = this.OV.initSession()

    // calling session.disconnect() will forceably END the session for all
    window.onbeforeunload = () => {
      this.session && this.session.unpublish()
    }

    // Join session now if userToken was provided
    if (this.options.hasOwnProperty('userToken')) {
      this.join(this.options.userToken, this.options)
    }
  }

  // This is called by initialize() after a tokbox session has been created
  async join(userToken, chatOptions) {
    var options = _.extend({}, this.defaultOptions, chatOptions)

    if (!this.session) {
      options.onFailure({
        reason: 'Attempting to join session before calling initialize()',
      })
      return
    }

    if (options.remoteVideoId) {
      this.session.on('streamCreated', (e) => {
        this.session.subscribe(e.stream, options.remoteVideoId, {
          insertMode: 'APPEND',
        })
      })
    }

    await this.session.connect(options.userToken)

    const publisher = await this.OV.initPublisherAsync(
      this.options.localVideoId,
      {
        publishAudio: true,
        publishVideo: true,
        insertMode: 'APPEND',
        mirror: true,
      }
    )
    this.publisher = publisher
    if (options.testNetwork) {
      publisher.subscribeToRemote(true)
    }

    publisher.once('streamPlaying', () => {
      options.onConnect()
    })
    publisher.on(
      'streamAudioVolumeChange',
      this.createAudioHandler(this.options.onChangePublisherAudioLevel)
    )
    await this.session.publish(publisher)
    options.onPublish()
  }

  testNetwork(roomId) {
    return new Promise((resolve, reject) => {
      this.publisher.once('remoteVideoPlaying', (e) => {
        const connectionQuality = new ConnectionQuality({
          intervalMillis: QUALITY_INTERVAL,
          onUpdate: (stats) => {
            post(
              `/api/v1/rooms/${roomId}/connection_quality_logs`,
              { payload: stats },
              'json'
            )
          },
        })

        // TODO extract this so it's just handing ConnectionQuality a getStats fn
        const peerConnection = e.target.stream.getRTCPeerConnection()
        const subscriberShim = {
          getStats: (completionHandler) => {
            peerConnection.getStats(null).then((stats) => {
              completionHandler(null, stats)
            })
          },
        }
        connectionQuality.setSubscriber(subscriberShim)
        window.setTimeout(() => {
          connectionQuality.stop()
          // resolve(connectionQuality.isQualityOK())
          // stats only returns a quality of zero for self-streams? need to figure out why
          resolve(true)
        }, QUALITY_TEST_LENGTH)
      })
    })
  }

  createAudioHandler(callback) {
    return _.throttle(
      (event) => {
        // OpenVidu detects lots of noise - hush it so it appears normal on the audio meter
        const volume =
          1 - Math.abs(event.value.newValue || 0) / 100 - AUDIO_HUSHER
        if (callback) callback(volume || 0)
      },
      AUDIO_THROTTLE,
      { leading: true, trailing: false }
    )
  }
}
