/* Global Notifications */
import { bindMethods } from 'lib/util'
import Module from 'lib/module'
import Preferences from 'lib/preferences'
import { t } from 'src/lib/i18n'

export default class DesktopNotificationEnabler {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.hideWhenIgnored =
      JSON.parse(element.getAttribute('data-hide-when-ignored')) || false
    this.elements = Module.findElements(
      element,
      DesktopNotificationEnabler.className
    )
    this.elements.enable.addEventListener('click', () =>
      this.handleEnablementChange(true)
    )
    this.elements.disable.addEventListener('click', () =>
      this.handleEnablementChange(false)
    )

    Preferences.instance().then((preferences) => {
      preferences.on('change', () => this.render({ preferences }))
      this.render({ preferences })
    })
  }

  handleEnablementChange(isEnabled) {
    let notifications = window.Notifications.instance()
    if (!notifications) {
      return
    }
    isEnabled ? notifications.enableIfPermission() : notifications.disable()
  }

  render(state) {
    let { preferences } = state
    let {
      preferencePath,
      enabledClass,
      disabledClass,
      noChoiceClass,
      ignoredClass,
    } = DesktopNotificationEnabler
    let desktopEnabled = preferences.get(preferencePath)

    this.element.classList.remove(
      enabledClass,
      disabledClass,
      noChoiceClass,
      ignoredClass
    )

    if (typeof window.Notifications === 'undefined') {
      this.element.parentNode.style.display = 'none'
    } else {
      if (desktopEnabled === true) {
        this.element.classList.add(enabledClass)
        $(this.elements.disable).text(t('Disable Desktop Notifications'))
      } else if (desktopEnabled === false) {
        this.element.classList.add(disabledClass)
        if (this.hideWhenIgnored) this.element.classList.add(ignoredClass)
      } else if (desktopEnabled === null) {
        this.element.classList.add(noChoiceClass)
        $(this.elements.disable).text(t('no thanks'))
      }
    }
  }
}

DesktopNotificationEnabler.preferencePath = 'notifications.desktop_enabled'
DesktopNotificationEnabler.className = 'desktop-notification-enabler'
DesktopNotificationEnabler.ignoredClass = 'desktop-notification-enabler_ignored'
DesktopNotificationEnabler.enabledClass = 'desktop-notification-enabler_enabled'
DesktopNotificationEnabler.disabledClass =
  'desktop-notification-enabler_disabled'
DesktopNotificationEnabler.noChoiceClass =
  'desktop-notification-enabler_no-choice'
DesktopNotificationEnabler.selector = `.${DesktopNotificationEnabler.className}`
