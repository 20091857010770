// NB: This extremely Gross Hack is because we cannot set more than one context,
// so we need to decide up-front whether we're loading an admin page or a non-admin
// page
//
// NB NB: Some pages under /admin......aren't admin pages.
const FAKE_ADMIN_PAGES = [/^\/admin\/languages.*\/preview/i]

export const isAdminPage =
  !FAKE_ADMIN_PAGES.find((re) => re.test(window.location.pathname)) &&
  /^\/admin\//i.test(window.location.pathname)
