import _ from 'lodash'
import React from 'react'

import { t, tWithEscape } from 'src/lib/i18n-react'
import FormLoader from 'modules/form-loader'
import PageAlert from 'src/components/PageAlert'

export default class LayoutTimezone {
  constructor(element, options) {
    this.element = element
    const { timezone, friendlyTimezones } = options
    const form = document.querySelector(options.form)
    const $timezoneChoice = $(element).find('#timezoneChoice')
    const formLoader = FormLoader.create(element)

    $(form)
      .on('ajax:success', function () {
        $(element).modal('hide')
        formLoader.render({ loading: false, message: null })
        const msg = tWithEscape('Set your timezone to <b>%{timezone}</b>', {
          timezone: $timezoneChoice.text(),
        })
        PageAlert.showAlert({
          type: 'info',
          fixed: true,
          content: <p dangerouslySetInnerHTML={{ __html: msg }} />,
        })
      })
      .on('ajax:error', function () {
        formLoader.render({
          loading: false,
          message: t('oops there was an error'),
          messageType: 'error',
        })
      })

    $(element)
      .find('#changeTimezone')
      .click(function () {
        var friendlyTimezone = $timezoneChoice.text()

        // show the friendly timezone name on the main form + highlight it
        $(form).find('#userTimezoneShow').val(friendlyTimezone)

        // find (or use) the olsonName to remember in the DB
        $(form)
          .find('#userTimezone')
          .val(
            _.findKey(friendlyTimezones, function (olsonName) {
              return olsonName === friendlyTimezone
            }) || friendlyTimezone
          )

        // save and return to the form!
        formLoader.render({ loading: true, message: null })
        $(form).submit()
      })

    $(element).on('show.bs.modal', function () {
      formLoader.render({ loading: false, message: null })
    })

    let $zonepicker = $(element).find('#zonepicker')
    $(element).on('shown.bs.modal', function () {
      $zonepicker.timezonePicker({
        initialLat: 30,
        initialLng: 0,
        initialZoom: 2,
        onReady: function () {
          $zonepicker.timezonePicker('selectZone', timezone)
        },
        onSelected: function (olsonName, utcOffset, tzName) {
          $timezoneChoice.text(friendlyTimezones[olsonName] || olsonName)
        },
        mapOptions: {
          maxZoom: 6,
          minZoom: 2,
        },
      })
    })
  }
}
