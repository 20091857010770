/* eslint-disable new-cap */
/* eslint-disable no-new */
import _ from 'lodash'
import marked from 'marked'
import plyr from 'plyr'

import { translateRemote, stringToHTMLElement, dataAttrs } from 'lib/util'
import { t } from 'src/lib/i18n'
import WordSearch from 'modules/word-search'
import StudyProgressBar from 'modules/study-progress-bar'
import ActivityTimer from 'lib/activity-timer'
import ActivityTime from 'modules/activity-time'

export default class LayoutJournal {
  constructor(element) {
    this.element = element
    this.timer = new ActivityTimer({ maxIdleSeconds: 30 })

    const selector = LayoutJournal.selector
    const {
      languageCode,
      nativeLanguageCode,
      updatedAt,
      id,
      status,
    } = dataAttrs(element)

    const editorEl = element.querySelector('#editor')
    const formEl = element.querySelector(`${selector}__form`)
    const elapsedEl = element.querySelector(`${selector}__elapsed`)
    const titleEl = element.querySelector(`${selector}__title-input`)
    const correctionWordCountLimit = parseInt(
      element.querySelector(`${selector}__correction_word_count_limit`)
        ?.value || 100
    )
    this.submitButtonEl = element.querySelector(`${selector}__submit-button`)
    const wordSearchEl = element.querySelector(WordSearch.selector)

    const activityTimeEl = element.querySelector(ActivityTime.selector)
    if (activityTimeEl) {
      new ActivityTime(activityTimeEl, { activityTimer: this.timer })
    }

    const sentences = new Sentences({
      minRequired: 6,
      onChange: this.handleSentenceChange.bind(this),
    })

    const draftSaver = new DraftSaver({
      title: titleEl,
      journalID: id,
      journalStatus: status,
      journalUpdatedAt: updatedAt,
    })

    import(/* webpackChunkName: "simplemde" */ 'simplemde').then(
      ({ default: simplemde }) => {
        const editor = new simplemde({
          element: editorEl,
          spellChecker: false,
          status: [
            sentences.getStatusItem(),
            draftSaver.getStatusItem(),
            'lines',
            'words',
            'cursor',
          ],
          toolbar: [
            'bold',
            'italic',
            'heading',
            '|',
            'quote',
            'unordered-list',
            'ordered-list',
            '|',
            'fullscreen',
          ],
        })

        // test if it's too long to correct
        editor.codemirror.on('change', async function() {
          // async await is needed here. If the page is refreshed with existing text in the editor,
          // without await wordCount would be 0.
          // if chained like querySelector(...).innerHTML, it stopps working because of the await
          const wordCounterRawHtml = await editor?.gui?.statusbar?.querySelector(
            '.words'
          )
          const wordCount = parseInt(wordCounterRawHtml?.innerHTML) || 0

          if (wordCount > correctionWordCountLimit) {
            $('#correction-error').show()
            $('#correction-box').hide()
          } else {
            $('#correction-error').hide()
            $('#correction-box').show()
          }
        })

        const editorPreviewSplit = new EditorPreviewSplit({
          nativeLanguageCode,
          languageCode,
        })
        editorPreviewSplit.injectToolbarItem(editor)

        const cheatSheetDialogEl = document.querySelector('#cheat-sheet-modal')
        if (cheatSheetDialogEl) {
          injectToolbarItem(
            editor,
            stringToHTMLElement(`
          <i class="separator toolbar-link_right">|</i>
        `)
          )

          const cheatSheet = new CheatSheetToolbarItem()
          cheatSheet.injectToolbarItem(editor)
        }

        sentences.setEditor(editor)
        draftSaver.setEditor(editor)
        draftSaver.load()

        new WordSearch(wordSearchEl)

        formEl.addEventListener('submit', (e) => {
          draftSaver.discard()
          elapsedEl.value = this.timer.getElapsedSeconds()
          this.submitButtonEl.disabled = true
        })

        // setup audio if it's there
        plyr.setup('.js-player')
      }
    )

    window.eventTracking.track('activity', 'start', 'writing')
  }

  handleSentenceChange(metMin, num) {
    this.submitButtonEl.disabled = !metMin
  }
}
LayoutJournal.className = 'l-journal'
LayoutJournal.selector = `.${LayoutJournal.className}`

export class DraftSaver {
  constructor(options) {
    this.options = options
  }

  setEditor(editor) {
    this.editor = editor
    const { title } = this.options
    const debouncedSave = _.debounce(() => this.save(), 500)
    editor.codemirror.on('change', debouncedSave)
    title.addEventListener('keyup', debouncedSave)
  }

  getStatusItem() {
    return {
      className: 'l-journal__draft-saver',
      defaultValue: (el) => {
        const { journalStatus } = this.options

        // this is ugly ...
        this.statusElement = el

        if (journalStatus === 'new') {
          this.updateStatusItem('')
        } else {
          this.updateStatusItem('saved')
        }
      },
      onUpdate: this.handleStatusUpdate.bind(this),
    }
  }

  handleStatusUpdate(el) {
    this.updateStatusItem(t('saving...'))
  }

  updateStatusItem(text) {
    if (!this.statusElement) return
    this.statusElement.textContent = text
  }

  getKey() {
    const { journalID } = this.options
    return `journal:${journalID}`
  }

  save() {
    const { title, journalUpdatedAt } = this.options
    const titleStr = title.value
    const bodyStr = this.editor.value()

    const data = {
      title: titleStr,
      body: bodyStr,
      updatedAt: journalUpdatedAt,
    }

    window.localStorage.setItem(this.getKey(), JSON.stringify(data))
    this.updateStatusItem(t('draft saved'))
  }

  load() {
    const { title, journalUpdatedAt } = this.options

    let data = window.localStorage.getItem(this.getKey())
    if (!data) return

    data = JSON.parse(data)

    if (data.updatedAt === journalUpdatedAt) {
      title.value = data.title
      this.editor.value(data.body)
      this.updateStatusItem(t('loading...'))
    }
  }

  discard() {
    window.localStorage.removeItem(this.getKey())
  }
}

export class CheatSheetToolbarItem {
  injectToolbarItem(editor) {
    let itemEl = stringToHTMLElement(`
      <a
        href="#"
        rel="#cheat-sheet-modal"
        class="toolbar-link toolbar-link_right"
        title="${t('Show the cheat sheet')}">
        ${t('cheat sheet')}
      </a>
    `)
    itemEl.addEventListener('click', this.handleClick.bind(this))
    injectToolbarItem(editor, itemEl)
  }

  handleClick(e) {
    e.preventDefault()
    $(e.target.rel).modal({ show: true })
  }
}

export class EditorPreviewSplit {
  constructor(options) {
    this.options = options || {}
  }

  injectToolbarItem(editor) {
    let itemEl = stringToHTMLElement(`
      <a
        href="#"
        class="toolbar-link toolbar-link_right no-disable no-mobile"
        title="Toggle side-by-side translation">
        ${t('show translation')}
      </a>
    `)
    itemEl.addEventListener('click', (e) => {
      e.preventDefault()
      this.handleAction(editor, itemEl)
    })
    injectToolbarItem(editor, itemEl)
  }

  renderPreview(content, previewEl) {
    let previewContent = this.renderTranslation(
      content,
      previewEl,
      (asyncPreviewContent) => {
        this.setPreviewHTML(previewEl, asyncPreviewContent)
      }
    )
    this.setPreviewHTML(previewEl, previewContent)
  }

  renderTranslation(content, previewEl, previewCallback) {
    let { languageCode } = this.options
    if (!content) {
      // show nothing if they haven't typed anything.
      return ''
    }

    translateRemote(content, languageCode).then(({ translation }) => {
      if (translation) {
        previewCallback(marked(translation, { breaks: true, sanitize: true }))
      }
    })
    let oldHTML = previewEl.innerHTML
    return oldHTML.trim()
      ? oldHTML
      : `
      <div class="loader__overlay">
        <div class="loader_spinner"></div>
      </div>
    `
  }

  setPreviewHTML(previewEl, html) {
    let flag = /<span class="flag flag_tiny/g.test(html) ? '' : this.getFlag()
    previewEl.innerHTML = flag + html
  }

  getFlag() {
    let { nativeLanguageCode } = this.options
    if (!nativeLanguageCode) return ''
    return `
      <span class="flag flag_tiny flag_${nativeLanguageCode}"></span>
    `
  }

  handleAction(editor, itemEl) {
    // Lifted from
    // https://github.com/NextStepWebs/simplemde-markdown-editor/blob/master/src/js/simplemde.js
    // and made to fit our purposes
    let cm = editor.codemirror
    let wrapper = cm.getWrapperElement()
    let previewEl = wrapper.lastChild

    const sideBySideRenderingFunction = _.debounce(() => {
      this.renderPreview(editor.value(), previewEl)
    }, 1200)
    if (!cm.sideBySideRenderingFunction) {
      cm.sideBySideRenderingFunction = sideBySideRenderingFunction
    }

    // create previewEl if not there
    if (!previewEl || !/editor-preview/.test(previewEl.className)) {
      previewEl = document.createElement('div')
      previewEl.className = 'editor-preview'
      wrapper.appendChild(previewEl)
    }

    if (/editor-preview-active/.test(previewEl.className)) {
      previewEl.classList.remove(
        'editor-preview-active',
        'editor-preview-split'
      )
      wrapper.classList.remove('CodeMirror-preview-split')
      if (itemEl) itemEl.textContent = t('show translation')
      cm.off('update', cm.sideBySideRenderingFunction)
    } else {
      // When the preview button is clicked for the first time,
      // give some time for the transition from editor.css to fire and the view to slide from right to left,
      // instead of just appearing.
      window.setTimeout(function() {
        previewEl.classList.add('editor-preview-active', 'editor-preview-split')
        wrapper.classList.add('CodeMirror-preview-split')
      }, 0)
      if (itemEl) itemEl.textContent = t('hide translation')

      sideBySideRenderingFunction()
      cm.on('update', cm.sideBySideRenderingFunction)
    }
  }
}

export class Sentences {
  constructor(options) {
    this.options = options || {}
    this.numberSentences = null
    this.className = 'l-journal__sentences'
  }

  setEditor(editor) {
    this.editor = editor
    this.updateElement(this.el)
  }

  getStatusItem() {
    return {
      className: this.className,
      defaultValue: this.defaultValue.bind(this),
      onUpdate: this.onUpdate.bind(this),
    }
  }

  updateElement(el) {
    this.updateElementForText(el, this.editor.value())
  }

  updateElementForText(el, text) {
    if (!el) return
    let sentences = _.map(text.trim().split(/[.?!\n]/g), (s) => s.trim())
    let numberSentences = _.compact(sentences).length
    if (numberSentences === this.numberSentences) return
    this.numberSentences = numberSentences
    let percent = Math.min(
      100,
      (numberSentences * 100) / this.options.minRequired
    )

    let templateOptions = { percent, className: 'study-progress-bar_small' }
    let progressHTML = StudyProgressBar.renderHTML(templateOptions)
    el.innerHTML = `
      ${progressHTML}
      <span class="l-journal__sentences__message">
      ${t('%{numberSentences} of %{minRequired} sentences', {
        numberSentences,
        minRequired: this.options.minRequired,
      })}
      </span>
    `

    if (numberSentences >= this.options.minRequired) {
      this.options.onChange(true, numberSentences)
      el.classList.add(this.className + '_minimum-met')
    } else {
      this.options.onChange(false, numberSentences)
      el.classList.remove(this.className + '_minimum-met')
    }
  }

  defaultValue(el) {
    this.el = el
    this.updateElementForText(el, this.editor ? this.editor.value() : '')
  }

  onUpdate(el) {
    this.updateElement(el)
  }
}

function injectToolbarItem(editor, element) {
  let toolbar = getToolbarDivFromEditor(editor)
  if (toolbar) toolbar.appendChild(element)
}

function getToolbarDivFromEditor(editor) {
  let cm = editor.codemirror
  let wrapper = cm.getWrapperElement().parentNode
  return wrapper.querySelector('.editor-toolbar')
}
