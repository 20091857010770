import moment from 'moment-timezone'

import { bindMethods, on } from 'lib/util'
import Calendar from 'modules/calendar'
import DateUtil from 'lib/date-util'

export default class LayoutSchedulePreferred {
  constructor(element) {
    bindMethods(this)

    this.element = element
    this.calendarEl = element.querySelector('.calendar')

    // load the calendar 2 days from now
    let targetDate = new Date()
    targetDate.setDate(targetDate.getDate() + 2)

    this.calendar = new Calendar(this.calendarEl, {
      onClickEvent: this.handleClickEvent.bind(this),
      onEventRender: this.handleEventRender.bind(this),
      height: 800,
      selectable: false,
      selectHelper: false,
      editable: false,
      defaultDate: targetDate,
      firstDay: 1,
      events: {
        url: '/schedule/available_bookings',
        data: {
          preferred: 'all',
        },
      },
    })

    on(element, 'click', '.tutor-choice', this.selectTutor)
  }

  selectTutor() {
    let checkedVals = []
    $('.tutor-choice:checked').each(function() {
      checkedVals.push($(this).val())
    })
    this.calendar.options.events.data.tutors = checkedVals.join(',')
    this.calendar.refetchEvents()
  }

  handleClickEvent(calEvent, jsEvent, view) {
    if (calEvent.time_start) {
      let start = moment(calEvent.start)
      let end = moment(calEvent.end)
      let formattedDate = `${DateUtil.format(
        start,
        'cb_ddddMMMD'
      )} ${DateUtil.format(start, 'cb_hmma')} - ${DateUtil.format(
        end,
        'cb_hmma'
      )}`
      if (calEvent.appointment_id) {
        // TODO: Remove IDs here...
        $('#booking-appt-id').val(calEvent.appointment_id)
      }
      $('#booking-time').val(calEvent.time_start)
      $('#booking-start-time').text(formattedDate)
      if (calEvent.preferred) {
        $('#booking-preferred-id').val(calEvent.preferred[0])
        $('#booking-preferred').text(calEvent.preferred[1])
      }
      $('#booking-modal').modal('show')
    }
  }

  handleEventRender(event, element) {
    if (event.event_type) {
      element.addClass(`calendar__booking_${event.event_type}`)
    }
    element.find('.fc-title').html(event.title)
  }
}
