import SVG from 'svg.js'

import { datajsJQuery, renderTemplate } from 'src/lib/util'

$(document).ready(function() {
  gauge.initialize()
})

var gauge = (function() {
  var initialize = function() {
    datajsJQuery('gauge').each(function() {
      var complete = parseFloat($(this).data('gauge-complete'))
      drawgauge(this, complete)
    })
  }

  var drawgauge = function(element, complete) {
    var draw = SVG(element).size(120, 84)

    // default colors
    var bgColor = '#ececec'
    var fgColor = '#888888'

    // center of the circle
    var xx = 60
    var yy = 60

    // radius of circle
    var radius = 55

    // gauge start point
    var startTheta = (28 / 25) * Math.PI
    var startX = xx + radius * Math.cos(startTheta)
    var startY = yy - radius * Math.sin(startTheta)

    // gauge end point
    var endTheta = (47 / 25) * Math.PI
    var endX = xx + radius * Math.cos(endTheta)
    var endY = yy - radius * Math.sin(endTheta)

    // gauge complete point
    var completeThetaDelta = complete * (31 / 25) * Math.PI
    var completeTheta = startTheta - completeThetaDelta
    var completeX = xx + radius * Math.cos(completeTheta)
    var completeY = yy - radius * Math.sin(completeTheta)

    // arc path template
    var astring = 'M {x0} {y0} A {r} {r} 0 {laf} 1 {x1} {y1}'

    var astring1 = renderTemplate(astring, {
      x0: startX,
      y0: startY,
      r: radius,
      laf: 1,
      x1: endX,
      y1: endY,
    })

    draw.path(astring1).attr({
      fill: 'none',
      stroke: bgColor,
      'stroke-width': '6',
      'stroke-linecap': 'round',
      class: 'bg',
    })

    if (complete > 0) {
      // when the arc is over 50% of the circle, flip the LAF.
      var fgLaf = complete > 0.8 ? 1 : 0

      var astring2 = renderTemplate(astring, {
        x0: startX,
        y0: startY,
        r: radius,
        laf: fgLaf,
        x1: completeX,
        y1: completeY,
      })

      draw.path(astring2).attr({
        fill: 'none',
        stroke: fgColor,
        'stroke-width': '6.25',
        'stroke-linecap': 'round',
        class: 'fg',
      })
    }
  }

  return {
    initialize: initialize,
  }
})()
