import _ from 'lodash'

// Usage: In the component you want to be the root of the state, instantiate one:
//
// this.componentState = new ComponentState({
//   onSetState: this.render.bind(this)
// })
export default class ComponentState {
  constructor(options) {
    this.state = {}
    this.prevState = {}
    this.options = _.defaults({}, options, { onSetState: function() {} })
  }

  init(state) {
    this.state = _.assign({}, this.state, state)
  }

  set(state) {
    this.prevState = this.state
    this.state = _.assign({}, this.state, state)
    this.options.onSetState(this.state, this.prevState)
  }
}
