import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import ButtonBar from 'src/components/ButtonBar/ButtonBar'
import LiveLesson from 'src/components/LiveLesson/LiveLesson'

const Book = ({ appointment }) => {
  return (
    <React.Fragment>
      {t('Do you really want to book this appointment?')}
      <br />
      <SC.LiveLesson>
        <LiveLesson {...appointment} />
      </SC.LiveLesson>
      <ButtonBar>
        <Button
          type="primary"
          disableWith={t('Booking...')}
          onClick={() => {
            // TODO: Implement me.
          }}
        >
          {t('Book it')}
        </Button>
      </ButtonBar>
    </React.Fragment>
  )
}

const SC = {}
SC.LiveLesson = styled.div`
  font-weight: bold;
  margin: 10px;
`

Book.propTypes = {
  appointment: PropTypes.object.isRequired,
}

export default Book
