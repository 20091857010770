import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

const Portal = ({ name, children }) => {
  const ref = useRef(null)

  if (!ref.current) {
    ref.current = document.createElement('div')
    ref.current.setAttribute('id', `cb-portal-${name}`)
  }

  useEffect(() => {
    if (ref.current) {
      document.body.appendChild(ref.current)
    }

    return () => {
      if (ref.current) {
        document.body.removeChild(ref.current)
      }
    }
  }, [ref])

  return createPortal(children, ref.current)
}

Portal.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Portal
