import OT from '@opentok/client'
import _ from 'lodash'

import { post, bindMethods } from 'lib/util'
import ConnectionQuality from 'lib/connection-quality'
import { ALERTS, showLessonAlert } from 'lib/lessonAlerts'

import TelcoBase from './base'

const AUDIO_THROTTLE = 100 // milliseconds
const QUALITY_INTERVAL = 1000
const QUALITY_TEST_LENGTH = 15000

export default class Tokbox extends TelcoBase {
  constructor() {
    super()
    bindMethods(this)
    this.session = null
    this.publisher = null
    this._myConnections = 0
    this._sessionConnected = false
    this._publisherInitialized = false
  }

  // Public: Set up local video and connection to chat server
  //
  //    chatOptions - Hash of options, including:
  //      'apiKey'       - API key value for TokBox (required)
  //      'token'        - Session token for TokBox (required)
  //      'userToken'    - User token for TokBox to join session.
  //                       Optional, but if not given, you must call
  //                       join() explicitly to join the session.
  //      'identity'     - User name / identifier (optional)
  //      'localVideoId' - Element ID into which local video node will
  //                       be inserted, defaults to 'ourvideo'
  //      'remoteVideoId' - Element ID into which remote video node will
  //                       be inserted, defaults to 'theirvideo'
  //      'onPublish'    - Callback function invoked on when Telco is ready to publish
  //      'onFailure'    - Callback function invoked on failures, will be
  //                       passed Hash with 'reason' & maybe other keys
  //      'onDisconnect' - Callback when disconnected from video session
  //      'onLog'        - Callback when a message should be logged
  initialize(chatOptions) {
    this.options = _.extend({}, this.defaultOptions, chatOptions)

    if (this.options.onLog && typeof this.options.onLog === 'function') {
      this.log = this.options.onLog
    }

    if (!this.options.hasOwnProperty('token')) {
      this.options.onFailure({
        reason: 'Missing required credentials for initializing video chat',
      })
      return
    }

    this.log(
      `connecting to ${this.options.token} as ${this.options.identity ||
        '<missing>'}`
    )

    if (OT.checkSystemRequirements() === 0) {
      this.options.onFailure({ reason: 'Browser does not support WebRTC' })
      showLessonAlert(ALERTS.BROWSER_DOES_NOT_SUPPORT_WEBRTC)
      return
    }

    OT.on('exception', (event) => {
      this.options.onFailure({
        reason: 'OT exception occurred',
        event: event.message,
      })
      if (event.message === 'GetUserMedia') {
        showLessonAlert(ALERTS.GET_USER_MEDIA_FAILED)
      }
    })

    this.session = OT.initSession(window.tokboxApiKey, this.options.token)
    this.session.on({
      // `connectionCreated` is called for all connections including yours, and
      // even those created before yours.
      //
      // https://tokbox.com/developer/guides/connect-session/js/#detect_other_connections
      connectionCreated: this.handleConnectionStateChange.bind(this, 1),
      connectionDestroyed: this.handleConnectionStateChange.bind(this, -1),

      streamCreated: this.handleStreamCreated,
      sessionConnected: this.handleSessionConnected,
      sessionDisconnected: (ev) => {
        this.options.onDisconnect(ev)
      },
    })

    // Join session now if userToken was provided
    if (this.options.hasOwnProperty('userToken')) {
      this.join(this.options.userToken, this.options)
    }
  }

  // This is called by initialize() after a tokbox session has been created
  join(userToken, chatOptions) {
    var options = _.extend({}, this.defaultOptions, chatOptions)

    if (!this.session) {
      options.onFailure({
        reason: 'Attempting to join session before calling initialize()',
      })
      return
    }

    this.session.connect(userToken, (error) => {
      if (error) {
        options.onFailure({
          reason: 'connectFailed',
          code: error.code,
          name: error.name,
          message: error.message,
        })
      } else {
        this.log('Session was connected - connecting publisher')
        this._sessionConnected = true
        this._publishVideoIfReady(options.onPublish)
        options.onConnect()
      }
    })
  }

  // Private: Publish video to session when all initialization is complete
  _publishVideoIfReady(onPublish) {
    if (!this._publisherInitialized) {
      this.log('  waiting to publish because publisher not initialized')
    } else if (!this._sessionConnected) {
      this.log('  waiting to publish because session not connected')
    } else {
      this.log(
        `  current number of publisher's connections: ${this._myConnections}`
      )
      if (this._myConnections <= 1 && this.publisher) {
        this.log('Publishing local stream')
        this.session.publish(this.publisher, () => onPublish(this.publisher))
      } else {
        // This is supposed to remove the video from the HTML, but of course, it
        // doesn't. Maybe it works in the future?
        this.publisher.destroy()
        this.publisher = null
        this.log('Notifying user of multiple tabs open')
        //showLessonAlert(ALERTS.ANOTHER_TAB_OPEN) # removing this alert for now because it's firing when there is a reconnection in the same tab
      }
    }
  }

  handleConnectionStateChange(increment, event) {
    // `data` is a JSON _string_ something like "{identity: 'uname', room: 'lesson'}"
    const { data } = event.connection
    this.log(`Connection ${increment > 0 ? 'created' : 'destroyed'}:`, data)
    if (this.session.connection.data === data) {
      this._myConnections += increment
    }
  }

  handleStreamCreated(event) {
    this.log('Stream was created - connecting their video')
    let subscriber = this.session.subscribe(
      event.stream,
      this.options.remoteVideoId,
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        style: {
          nameDisplayMode: 'off',
          buttonDisplayMode: 'off',
        },
      },
      (error) => {
        if (error) {
          this.options.onFailure({
            reason: 'subscribe failed',
            code: error.code,
            name: error.name,
            message: error.message,
          })
        } else {
          this.options.onSubscribe(subscriber)
        }
      }
    )
    subscriber.on({
      audioLevelUpdated: this.createAudioHandler(
        this.options.onChangeSubscriberAudioLevel
      ),
    })
  }

  handleSessionConnected(event) {
    this.publisher = OT.initPublisher(
      this.options.localVideoId,
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        audioFallbackEnabled: true,
        showControls: false,
        name: this.options.identity,
      },
      this.handleInitPublisherSuccess
    )

    this.publisher.on({
      accessAllowed: (event) => {
        this.log('Access allowed to local video')
      },
      accessDenied: (event) => {
        this.log('Denied access to video')
        this.options.onFailure({ reason: 'PermissionDeniedError' })
      },
      streamCreated: (event) => {
        this.log('Publisher started streaming')
      },
      audioLevelUpdated: this.createAudioHandler(
        this.options.onChangePublisherAudioLevel
      ),
      streamDestroyed: (event) => {
        this.log('Local publisher video ended:', event.reason)
      },
    })
  }

  handleInitPublisherSuccess(error) {
    if (error) {
      this.options.onFailure({
        reason: 'initPublisherFailed',
        code: error.code,
        name: error.name,
        message: error.message,
      })
      this.publisher.destroy()
      this.publisher = null
      this._publisherInitialized = false
      if (error.name === 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR') {
        showLessonAlert(ALERTS.CHROME_MICROPHONE_ERROR)
      }
    } else {
      this.log('Publisher initialized successfully')
      this._publisherInitialized = true
      this._publishVideoIfReady(this.options.onPublish)
    }
  }

  createAudioHandler(callback) {
    return _.throttle(
      (event) => {
        if (callback) callback(event.audioLevel || 0)
      },
      AUDIO_THROTTLE,
      { leading: true, trailing: false }
    )
  }

  testNetwork(roomId) {
    return new Promise((resolve, reject) => {
      this.session.subscribe(
        this.publisher.stream,
        document.createElement('div'), // don't show the remote video (our own!)
        {
          audioVolume: 0,
          testNetwork: true,
        },
        (error, subscriber) => {
          if (error) {
            console.error(error)
            reject(error)
          }
          const connectionQuality = new ConnectionQuality({
            intervalMillis: QUALITY_INTERVAL,
            onUpdate: (stats) => {
              post(
                `/api/v1/rooms/${roomId}/connection_quality_logs`,
                { payload: stats },
                'json'
              )
            },
          })
          connectionQuality.setSubscriber(subscriber)
          window.setTimeout(() => {
            connectionQuality.stop()
            resolve(connectionQuality.isQualityOK())
          }, QUALITY_TEST_LENGTH)
        }
      )
    })
  }
}
