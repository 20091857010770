import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider as RealApolloProvider } from 'react-apollo'
import { ThemeProvider as RealThemeProvider } from 'styled-components'

import { getClient } from 'src/lib/graphql'
import theme from 'src/lib/theme'
import theme2 from 'src/lib/theme2'

export const ApolloProvider = (props) => (
  <RealApolloProvider client={getClient()} {...props} />
)

export const ThemeProvider = ({ version = 1, ...rest }) => {
  return (
    <RealThemeProvider
      theme={Number(version) === 2 ? theme2 : theme}
      {...rest}
    />
  )
}
ThemeProvider.propTypes = {
  version: PropTypes.oneOf([1, 2]),
}

const Providers = ({ children, version }) => {
  return (
    <RealApolloProvider client={getClient()}>
      <RealThemeProvider theme={Number(version) === 2 ? theme2 : theme}>
        {children}
      </RealThemeProvider>
    </RealApolloProvider>
  )
}

export default Providers
