import React from 'react'

export default ({ selected }) => {
  if (!selected) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66667 3C6.02233 3 5.5 3.52234 5.5 4.16667V5.5H3.5C3.22386 5.5 3 5.72386 3 6V8.5C3 10.0936 4.24252 11.397 5.81162 11.4942C6.50325 13.6421 8.28214 15.3022 10.5 15.8261V16V17.5625H9.99805C8.61733 17.5625 7.49805 18.6818 7.49805 20.0625V20.5C7.49805 20.7761 7.7219 21 7.99805 21H15.998C16.2742 21 16.498 20.7761 16.498 20.5V20.0625C16.498 18.6818 15.3788 17.5625 13.998 17.5625H13.5V16V15.8261C15.7179 15.3022 17.4967 13.6421 18.1884 11.4942C19.7575 11.397 21 10.0936 21 8.5V6C21 5.72386 20.7761 5.5 20.5 5.5H18.5V4.16667C18.5 3.52233 17.9777 3 17.3333 3H6.66667ZM18.5 6.5V9.5V10.437C19.3626 10.215 20 9.43192 20 8.5V6.5H18.5ZM17.5 6V4.16667C17.5 4.07462 17.4254 4 17.3333 4H6.66667C6.57462 4 6.5 4.07462 6.5 4.16667V6V9.5C6.5 12.5376 8.96243 15 12 15C15.0376 15 17.5 12.5376 17.5 9.5V6ZM4 6.5H5.5V9.5V10.437C4.63739 10.215 4 9.43192 4 8.5V6.5ZM12.5 15.9811C12.335 15.9936 12.1682 16 12 16C11.8318 16 11.665 15.9936 11.5 15.9811V16V17.5H12.5V16V15.9811ZM15.4968 20C15.464 19.2006 14.8055 18.5625 13.998 18.5625H9.99805C9.19056 18.5625 8.53209 19.2006 8.49933 20H15.4968Z"
          fill="black"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.998 18.5C15.1026 18.5 15.998 19.3954 15.998 20.5L7.99805 20.5C7.99805 19.3954 8.89348 18.5 9.99805 18.5L13.998 18.5Z"
          fill="black"
        />
        <path
          d="M6 4.16667C6 3.79848 6.29848 3.5 6.66667 3.5H17.3333C17.7015 3.5 18 3.79848 18 4.16667V9.5C18 12.8137 15.3137 15.5 12 15.5C8.68629 15.5 6 12.8137 6 9.5V4.16667Z"
          fill="black"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 15C10.7239 15 10.5 15.2878 10.5 15.6429V15.8261C10.9815 15.9398 11.4837 16 12 16C12.5162 16 13.0185 15.9398 13.5 15.8261V15.6429C13.5 15.2878 13.2761 15 13 15H11ZM13.5 16.85C13.0153 16.9483 12.5137 17 12 17C11.4863 17 10.9847 16.9484 10.5 16.85V18.8571C10.5 19.2122 10.7239 19.5 11 19.5H13C13.2761 19.5 13.5 19.2122 13.5 18.8571V16.85Z"
          fill="black"
        />
        <path
          d="M3.5 6H6V11V11C4.61929 11 3.5 9.88071 3.5 8.5V6Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 6H18V11V11C19.3807 11 20.5 9.88071 20.5 8.5V6Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
}
