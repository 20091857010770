import { post, bindMethods } from 'lib/util'
import { RatingStars } from 'modules/rating'

export default class LayoutReadingWrapup {
  constructor(element) {
    bindMethods(this)
    this.element = element
    this.formEl = $(element).find('form')

    let ratingEl = $(element).find('.rating_stars')
    this.ratingStars = new RatingStars(ratingEl, { onClick: this.submitRating })
  }

  submitRating(value) {
    let postParams = this.formEl.serializeArray().reduce((a, x) => {
      a[x.name] = x.value
      return a
    }, {})
    post(this.formEl.attr('action'), postParams, 'json')
    window.eventTracking.track('rating', postParams.rating)
  }
}

LayoutReadingWrapup.selector = '.l-reading-wrapup'
