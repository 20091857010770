import React from 'react'

import { Box } from '@chatterbug/aaron'

const ContentCard = (props) => (
  <Box
    css={`
      ${(props) => props.theme.textStyles.text}
      background-color: white;
      text-align: left;
      border-radius: 3px;
      box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.06);
      padding: 20px;
    `}
    {...props}
  />
)

export default ContentCard
