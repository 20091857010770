import React, { lazy } from 'react'
import TutorProfilePagePlaceholder from 'src/pages/TutorProfile/TutorProfilePagePlaceholder'
import LoadPage from 'src/ui/LoadPage/LoadPage'

const TutorProfilePageLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "TutorProfilePageLazy" */ './TutorProfilePageLazy'
    )
)

const LoadPageTutorProfile: React.FC = (props: any) => {
  return (
    <LoadPage
      LazyPage={TutorProfilePageLazy}
      Placeholder={TutorProfilePagePlaceholder}
      showBreadcrumbs={true}
      {...props}
    />
  )
}

export default LoadPageTutorProfile
