import _ from 'lodash'

import Module from 'lib/module'
import { on, dataAttrs } from 'lib/util'

export default class CurrencyPicker extends Module {
  constructor(element, options) {
    super(element, options)
    this.options = _.defaults(options, {
      onChange: (selected) => {},
    })

    const { selected } = dataAttrs(element)
    on(CurrencyPicker.inputSelector, 'change', this.handleInputChange)
    this.render({ selected })
  }

  handleInputChange(e) {
    const selected = e.target.value
    this.render({ selected })
    this.options.onChange(selected)
  }

  render(state) {
    const { selected } = state
    const $el = $(this.element)
    $el
      .find(CurrencyPicker.currencySelector)
      .removeClass(CurrencyPicker.currencySelectedClass)
    $el
      .find(`${CurrencyPicker.currencySelector}[data-code="${selected}"]`)
      .addClass(CurrencyPicker.currencySelectedClass)
  }
}

CurrencyPicker.className = 'currency-picker'
CurrencyPicker.selector = `.${CurrencyPicker.className}`
CurrencyPicker.inputSelector = `input`
CurrencyPicker.currencySelector = `${CurrencyPicker.selector}__currency`
CurrencyPicker.currencySelectedClass = `${CurrencyPicker.className}__currency_selected`
