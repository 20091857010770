import _ from 'lodash'
const OneSecond = 1000

// Tracks time spent activity on a page + idle time. When idle, the time
// tracking pauses. Any idle mouse or keyboard movement will pull it out of
// idle. Useful for things like chat status or tracking time spent in an
// activity.

export default class ActivityTimer {
  constructor(options) {
    this.options = _.defaults({}, options, {
      maxIdleSeconds: 60,
      mouseTracking: true,
      keyboardTracking: true,
    })

    this.seconds = 0
    this.idleSeconds = 0
    this.running = false
    this.lastIdleTime = null

    this.pauseTimer = this.pauseTimer.bind(this)
    this.updateIdleWatchog = _.debounce(this.updateIdleWatchog.bind(this), 50)

    if (this.options.mouseTracking) this.installMouseMoveTracker()
    if (this.options.keyboardTracking) this.installKeyDownTracker()
    this.startTimer()
    this.updateIdleWatchog()
  }

  getIdleSeconds() {
    return this.idleSeconds
  }

  getElapsedSeconds() {
    return this.seconds
  }

  isIdle() {
    return !this.running
  }

  installMouseMoveTracker() {
    document.body.addEventListener('mousemove', this.updateIdleWatchog)
  }

  installKeyDownTracker() {
    document.body.addEventListener('keydown', this.updateIdleWatchog)
  }

  updateIdleWatchog() {
    this.startTimer()
    clearTimeout(this.watchdog)
    this.watchdog = window.setTimeout(
      this.pauseTimer,
      this.options.maxIdleSeconds * OneSecond
    )
  }

  startTimer() {
    this.running = true
    if (!this.interval) {
      this.interval = window.setInterval(() => {
        this.running ? this.seconds++ : this.idleSeconds++
      }, OneSecond)
    }
  }

  pauseTimer() {
    this.running = false
  }
}
