import React from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'clipboard'

import clippyImage from 'images/common/icons/clippy.svg'
import { Box, Flex } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

export default class CopyToClipboard extends React.Component {
  static propTypes = {
    copyId: PropTypes.string.isRequired,
    copyValue: PropTypes.string.isRequired,
  }

  static defaultProps = {
    copyValue: '',
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.copyButton)
    this.clipboard.on('success', this.handleSuccess.bind(this))
  }

  // Can you handle success? This function sure can.
  handleSuccess() {
    let title = this.copyButton.getAttribute('data-original-title')
    this.copyButton.setAttribute('data-original-title', t('Copied!'))

    let $button = $(this.copyButton)
    $button.tooltip('show')

    let resetTip = () => {
      this.copyButton.setAttribute('data-original-title', title)
      $button.off('hidden.bs.tooltip', resetTip)
    }
    $button.on('hidden.bs.tooltip', resetTip)
  }

  render() {
    const { copyId, copyValue } = this.props

    let inputId = 'input-' + copyId
    let inputIdHash = '#' + inputId

    return (
      <div className="copy-to-clipboard" id={copyId}>
        <Flex as="form">
          <Box
            sx={{
              borderRightWidth: '0 !important',
              borderRadius: '8px 0 0 8px',
            }}
            as="input"
            type="text"
            id={inputId}
            value={copyValue}
            className="form-control"
            readOnly
          />
          <Flex
            bg="black"
            sx={{
              borderTopRightRadius: '8px !important',
              borderBottomRightRadius: '8px !important',
              borderTopWidth: '0',
              borderLeftWidth: '0',
            }}
            minWidth="44px"
            alignItems="center"
            justifyContent="center"
            as="button"
            ref={(button) => {
              this.copyButton = button
            }}
            type="button"
            className="button"
            data-clipboard-target={inputIdHash}
            data-toggle="tooltip"
            data-placement="bottom"
            data-original-title={t('Copy to the clipboard')}
          >
            <img src={clippyImage} />
          </Flex>
        </Flex>
      </div>
    )
  }
}
