import React, { useEffect, useState } from 'react'
import { calcTimeDelta } from 'react-countdown'
import { Image, Flex, Button, Grid } from '@chatterbug/aaron'
import { IconButton } from '@chatterbug/theme'
import BannerSkeleton from '../BannerSkeleton'
import Offer from './subcomponents/Offer'
import OfferCountDown from './subcomponents/OfferCountDown'
import close from './assets/close-on-dark.svg'
import { ThemeProvider } from 'src/config/Providers'

import { t } from 'src/lib/i18n'

export type Props = {
  messageText: string
  buttonUrl: string
  buttonText: string
  dismissCookieName: string
  emoji?: string
  startAt?: Date
  endAt?: Date
  background?: string
  countdownMessage?: string
}

const BannerOfferCountdown: React.FC<Props> = ({
  startAt,
  endAt,
  messageText,
  emoji,
  buttonUrl,
  buttonText,
  dismissCookieName,
  countdownMessage,
  background = null,
}): JSX.Element => {
  const [visible, setVisible] = useState(true)
  const [seen, setSeen] = useState(false)

  useEffect(() => {
    // execute only in a browser, not during a build
    if (typeof window !== 'undefined' && startAt && endAt) {
      setVisible(
        calcTimeDelta(startAt)?.completed && !calcTimeDelta(endAt)?.completed
      )
    }
  }, [setVisible, startAt, endAt])

  if (!visible) {
    return null
  }
  if (seen) {
    return null
  }

  if (!background) {
    background = 'linear-gradient(90.07deg, #302551 1.05%, #37296F 89.43%)'
  }
  let buttonColor = 'rgb(254, 218, 50)'

  // We wrap the BannerSkeleton in ThemeProvider because it is used in the oldUI,
  // where theme2 values are not understood. Please remove the ThemeProvider from
  // here if this banner (and its children) are no longer used in the old UI.
  return (
    <ThemeProvider version={2}>
      <BannerSkeleton
        backgroundColor={background}
        contentSection={
          <Grid
            gridGap="2x"
            columns={{ _: '1fr', tablet: '1fr 1fr', tabletWide: 'auto auto' }}
            alignItems="center"
          >
            <Offer salesMessage={messageText} emoji={emoji} />
            {endAt && (
              <OfferCountDown
                endAt={endAt}
                onComplete={() => setVisible(false)}
                countdownMessage={countdownMessage}
              />
            )}
          </Grid>
        }
        buttonSection={
          <>
            {buttonUrl && (
              <Button
                href={buttonUrl}
                style={{
                  border: `1px solid ${buttonColor}`,
                  color: buttonColor,
                  backgroundColor: 'transparent',
                  overflowY: 'hidden',
                  width: '100%',
                }}
              >
                <Flex alignItems="center">{buttonText}</Flex>
              </Button>
            )}
          </>
        }
        dismissSection={
          <IconButton
            as="submit"
            kind="tertiary"
            icon={<Image src={close} alt={t('dismiss message')} />}
            data-testid="close-button"
            onClick={() => {
              document.cookie = `${dismissCookieName}=true; path=/`
              setSeen(true)
            }}
          />
        }
      />
    </ThemeProvider>
  )
}

export default BannerOfferCountdown
