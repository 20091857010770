import React from 'react'
import PropTypes from 'prop-types'

import { Box, Flex } from '@chatterbug/aaron'

import CEFRBadge from './subcomponents/CEFRBadge/CEFRBadge'
import AchievementsProgressBar from './subcomponents/AchievementsProgressBar/AchievementsProgressBar'
import ProgressCharacter from './subcomponents/ProgressCharacter/ProgressCharacter'
import ProgressTooltip from './subcomponents/ProgressTooltip/ProgressTooltip'

// at 90 (mobile)/97 (up from tablet) percent character's stick hits the edge of the PB
// and it should not move outside of it.
export const LEFT_POSITIONING = (completed) => {
  return {
    _: `${completed < 90 ? completed + '%' : '90%'}`,
    tablet: `${completed < 97 ? completed + '%' : '97%'}`,
  }
}

export const getTranslateTransform = (completed) => {
  if (completed === 0) {
    return 'translateX(-50%)'
  } else if (completed < 10) {
    return 'translateX(-60%)'
  } else if (completed > 60) {
    return 'translateX(-80%)'
  } else {
    return 'translateX(-70%)'
  }
}

const StudentProgress = ({ goal, completed, l2Code }) => {
  return (
    <Flex
      // according to Figma
      pl={{ _: '36px', mobile: '40px', tablet: '54px' }}
      width="100%"
      sx={{ position: 'absolute', top: '70%', zIndex: 2 }}
    >
      <Box
        pr="2x"
        width={{ _: '80%', tablet: '90%' }}
        sx={{ position: 'relative' }}
      >
        <AchievementsProgressBar completed={completed} />
        <ProgressCharacter completed={completed} l2Code={l2Code} />
        <ProgressTooltip completed={completed} />
      </Box>
      <Box pr="2x" sx={{ position: 'absolute', right: 0, bottom: '-5px' }}>
        <CEFRBadge completed={completed} cefrLevel={goal} />
      </Box>
    </Flex>
  )
}

StudentProgress.propTypes = {
  completed: PropTypes.number.isRequired,
  l2Code: PropTypes.string.isRequired,
  goal: PropTypes.string.isRequired,
}

export default StudentProgress
