// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const spacing = {
 "6x": "4.800000000000001rem",
 "5x": "4rem",
 "4x": "3.2rem",
 "3x": "2.4000000000000004rem",
 "2,5x": "2rem",
 "2x": "1.6rem",
 "1x": "0.8rem",
 "0,5x": "0.4rem",
 "0,25x": "0.2rem"
}

export default spacing;