/* eslint-disable space-before-function-paren */
import React from 'react'

import DataFetchError, {
  ERROR_CODE_500,
} from 'src/ui/DataFetchError/DataFetchError'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    console.error(error)
    window.Sentry?.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <DataFetchError errorCode={ERROR_CODE_500} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
