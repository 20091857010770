import { EventEmitter } from 'events'

import _ from 'lodash'

// Emits events: `chatChannel.on('speak', fn...)`
//
// Pass in {events} like this:
//
// events: [
//   {
//     event: {type: 'speak', ...},
//     timestamp: '2017-03-16T14:46:45.658-07:00'
//   }, ...
// ]
export default class ChatChannelReplay {
  constructor(options) {
    this.emitter = new EventEmitter()
    this.options = _.defaults(options, {
      startTime: null,
      durationMillis: null,
      events: [],
    })
    this.events = this.options.events
    this.startMillis = millis(this.options.startTime)
    this.durationMillis =
      this.options.durationMillis ||
      millis(_.last(this.events).timestamp) - this.startMillis
    this.handleNextFrame = this.handleNextFrame.bind(this)
    this.reset()
  }

  dispose() {
    this.stop()
  }

  on(event, listener) {
    this.emitter.on(event, listener)
  }

  off(event, listener) {
    this.emitter.removeListener(event, listener)
  }

  millisToPercent(millis) {
    return _.clamp((millis / this.durationMillis) * 100, 0, 100)
  }

  percentToMillis(percent) {
    return (percent * this.durationMillis) / 100
  }

  timestampToMillis(timestampString) {
    return millisSinceStart(this.startMillis, timestampString)
  }

  getFilteredEvents(eventFilter) {
    if (!_.isFunction(eventFilter)) eventFilter = { event: eventFilter }
    return this.events ? _.filter(this.events, eventFilter) : []
  }

  getEventsOfType(type) {
    return _.filter(this.events, (e) =>
      e.event ? e.event.type === type : false
    )
  }

  reset() {
    this.currentMillis = 0
    this.eventIndex = 0
    this.running = false
    this.stopped = true
  }

  play() {
    if (this.running) return
    let wasStopped = this.stopped
    this.stopped = false
    this.running = true
    this.lastMillis = performance.now()
    this.requestNextFrame()
    this.emitter.emit('play', { wasStopped, currentMillis: this.currentMillis })
  }

  pause() {
    if (!this.running) return
    this.running = false
    this.emitter.emit('pause', { currentMillis: this.currentMillis })
  }

  stop() {
    if (this.stopped) return
    this.reset()
    this.emitter.emit('stop')
  }

  seek(timeInMillis) {
    let deltaMillis = timeInMillis - this.currentMillis

    // 40ms ahead or behind is fine....
    if (Math.abs(deltaMillis) < 40) return

    if (deltaMillis > 0) {
      // forward! einfach.
      this.moveForward(deltaMillis)
      this.emitter.emit('seek-forward', { currentMillis: this.currentMillis })
    } else {
      this.moveBackward(deltaMillis)
      this.emitter.emit('seek-backward', { currentMillis: this.currentMillis })
    }
  }

  getPlayState() {
    let { running, stopped } = this
    return stopped ? 'stopped' : running ? 'playing' : 'paused'
  }

  // When seeking backward, some of the consumers will need to know the state
  // they should be in. e.g. where should the mouse be? what exercise are we on?
  // getLastEvent() will return the most recent event from the time we are at.
  getLastEvent(eventMatch) {
    let matches = _.matches(eventMatch)
    for (let i = this.eventIndex - 1; i >= 0; i--) {
      let event = this.events[i]
      if (matches(event.event)) return event
    }
    return null
  }

  //
  // Private
  //

  getEvents(startIndex, endMillis) {
    let i

    let event

    let eventT

    let events = []
    for (i = startIndex; i < this.events.length; i++) {
      event = this.events[i]
      eventT = this.timestampToMillis(event.timestamp)
      if (eventT <= endMillis) {
        events.push(event)
      } else {
        break
      }
    }

    let atEnd = endMillis >= this.durationMillis
    return { lastIndex: i - 1, atEnd, events }
  }

  getRemoveEvents(startIndex, endMillis) {
    let i

    let event

    let eventT

    let events = []
    for (i = startIndex; i >= 0; i--) {
      event = this.events[i]
      eventT = this.timestampToMillis(event.timestamp)
      if (eventT > endMillis) {
        events.push(event)
      } else {
        break
      }
    }
    return { lastIndex: i + 1, atEnd: false, events }
  }

  requestNextFrame() {
    if (this.running) this.requestAnimationFrame(this.handleNextFrame)
  }

  requestAnimationFrame(fn) {
    requestAnimationFrame(fn)
  }

  handleNextFrame(timestamp) {
    if (this.stopped || !this.running) return
    let deltaMillis = Math.max(0, timestamp - this.lastMillis)
    let { atEnd } = this.moveForward(deltaMillis)
    if (atEnd) {
      this.stop()
    } else {
      this.lastMillis = timestamp
      this.requestNextFrame()
    }
  }

  moveForward(deltaMillis) {
    if (deltaMillis < 0) return
    let newMillis = this.currentMillis + deltaMillis
    let { atEnd, lastIndex, events } = this.getEvents(
      this.eventIndex,
      newMillis
    )

    // console.log('moveForward', this.currentMillis, timestamp - this.lastMillis, events.length);
    for (let event of collapseEvents(events)) this.handleEvent(event)

    this.currentMillis = newMillis
    this.eventIndex = lastIndex + 1
    this.emitter.emit('change-time', { currentMillis: this.currentMillis })
    return { atEnd }
  }

  moveBackward(deltaMillis) {
    if (deltaMillis > 0) return
    let newMillis = Math.max(0, this.currentMillis + deltaMillis)
    let { lastIndex, events } = this.getRemoveEvents(
      this.eventIndex - 1,
      newMillis
    )

    // console.log('moveBackward', this.currentMillis, timestamp - this.lastMillis, events.length);
    for (let event of collapseEvents(events)) this.handleRemoveEvent(event)

    this.currentMillis = newMillis
    this.eventIndex = lastIndex
    this.emitter.emit('change-time', { currentMillis: this.currentMillis })
  }

  handleEvent(event) {
    this.emitter.emit(event.event.type, event.event, event.timestamp)
  }

  handleRemoveEvent(event) {
    this.emitter.emit(
      `remove-${event.event.type}`,
      event.event,
      event.timestamp
    )
  }
}

function millisSinceStart(startMillis, timestampString) {
  return new Date(timestampString) - startMillis
}

function millis(timestampString) {
  return new Date(timestampString).getTime()
}

// For some events, we only care about the last state in a given animation
// frame. e.g. mouseMove, nextExercise. Also helpful for big seeks, like seeking
// from 0 to near the end of the video there's no reason to render 2000
// mouseMove events.
export function collapseEvents(events) {
  let i, event, sig
  let eventSigs = {}
  let collapsedEvents = []

  for (i = events.length - 1; i >= 0; i--) {
    event = events[i]
    if (!event.event) continue
    sig = getEventSig(event)
    if (eventSigs[sig]) continue
    if (sig) eventSigs[sig] = true
    collapsedEvents.unshift(event)
  }
  return collapsedEvents
}

// Return text representation of events that need to be collapsed
function getEventSig(event) {
  let { type, user } = event.event

  switch (type) {
    case 'nextExercise':
      return type
    case 'mouseMove':
    case 'mouseDown':
      return type + user
  }
  return null
}
