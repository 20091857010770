import { DateTime, Settings } from 'luxon'
import * as SentryType from '@sentry/browser'

import * as JQueryType from 'jquery'

export const isInJestRunner = 'JEST_WORKER_ID' in process.env
const jestDefaultTz = 'Europe/Berlin'
const jestDefaultLocale = 'en'

export const setUserEnv = () => {
  setTimezone()
  setLocale()
}

// By default, Luxon creates DateTimes in the system's local zone. However, you
// can override this behavior globally
// https://github.com/moment/luxon/blob/master/docs/zones.md#changing-the-default-zone
const setTimezone = () => {
  const timezoneEl = document.querySelector<HTMLMetaElement>(
    'meta[name="userTimezone"]'
  )
  const timezone =
    timezoneEl?.content ?? (isInJestRunner ? jestDefaultTz : undefined)

  if (!timezoneEl || !timezone) {
    if (!document.querySelector('meta[name="loggedOut"]')) {
      console.warn('Could not find userTimezone')
    }
    return
  }

  const test = DateTime.local().setZone(timezone)
  if (!test.isValid) {
    console.warn('The userTimezone "' + timezone + '" is not valid')
  } else {
    Settings.defaultZoneName = timezone
  }
}

export const getTimezone = () => {
  return Settings.defaultZoneName
}

const cache: Record<string, any> = {
  usesAltTime: undefined,
  locale: undefined,
}

export const getLocale = () => {
  if (isInJestRunner) return jestDefaultLocale
  if (cache.locale) return cache.locale

  const localeEl = document.querySelector<HTMLMetaElement>(
    'meta[name="userLocale"]'
  )

  const altTime = document.querySelector<HTMLMetaElement>(
    'meta[name="userAlternateTime"]'
  )

  const forceAltTime = new URL(window.location.href).searchParams?.has(
    'forceAltTime'
  )

  const isAltTime = altTime?.content === 'true'
  cache.usesAltTime = forceAltTime || isAltTime

  if (!localeEl) {
    if (!document.querySelector('meta[name="loggedOut"]')) {
      console.warn('Could not find userLocale')
    }

    return 'en'
  }

  return (cache.locale = localeEl.content)
}

export const getAltTime = () => cache.usesAltTime

const locale24HrTimeTable: Record<string, boolean> = {
  en: false,
  de: true,
  es: true,
  fr: true,
}

export function shouldTimeBeIn24HourTime() {
  const defaultTime = locale24HrTimeTable[getLocale()] ?? true

  return getAltTime() ? !defaultTime : defaultTime
}

const wnd: any = global

export const setLocale = (forceLocale?: string) => {
  const locale = forceLocale || getLocale()
  if (forceLocale) {
    cache.locale = locale
  }

  Settings.defaultLocale = locale
  wnd.Polyglot.locale(locale)
}

// NB: We do this so that we can grab the *instance* of this global variable,
// but give it the types as if we had imported it

export const $ = wnd.$ as typeof JQueryType.default
export const sentry: typeof SentryType = wnd.Sentry
export const stripePublicKey = wnd.stripe_public_key
