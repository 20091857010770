import React from 'react'

import { Box } from '@chatterbug/aaron'
import { colors } from '@chatterbug/theme/tokens'

const Background = () => {
  return (
    <>
      <Box
        width="100%"
        height="96px"
        backgroundColor={colors.dolphin2}
        borderTopLeftRadius="1x"
        borderTopRightRadius="1x"
      />
      <Box
        width="100%"
        height="96px"
        bg="#63CE6D"
        borderBottomLeftRadius="1x"
        borderBottomRightRadius="1x"
      />
    </>
  )
}

export default Background
