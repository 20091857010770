import _ from 'lodash'

// Slides between different pages
//
// <div class="page-carousel">
//   <div class="page-carousel__screen" data-next-text="Next: Pick a language">
//     screen1
//   </div>
//   <div class="page-carousel__screen" data-next-text="Next: Set your goals">
//     screen2
//   </div>
//   <div class="page-carousel__screen" data-next-text="Finish up!">
//     screen3
//   </div>
// </div>
//
// render() accepts: {
//   stepIndex: integer
// }
//

export default class PageCarousel {
  constructor(element, options) {
    this.screenSelector = `${PageCarousel.selector}__screen`
    this.options = _.defaults(options || {}, {
      pageClasses: [
        // Classes that map onto `&__screen` elements
        // e.g. `[IntroPage, PrivacyPage]`
      ],
    })
    this.element = element
    this.screens = element.querySelectorAll(this.screenSelector)

    this.pages = []
    let { pageClasses } = this.options
    for (var i = 0; i < pageClasses.length; i++) {
      let pageClass = pageClasses[i]
      // eslint-disable-next-line
      this.pages.push(new pageClass(this.screens[i], this.options))
    }

    if (this.pages.length !== this.screens.length) {
      throw new Error('Pass pageClasses array in for each screen')
    }
  }

  getNumberPages() {
    return this.pages.length
  }

  getPage(index) {
    return this.pages[index]
  }

  // do all dom updates in here
  render(state, prevState) {
    let { stepIndex } = state
    for (let screenEl of _.toArray(this.screens)) {
      screenEl.style.left = -100 * stepIndex + '%'
    }
    this.getPage(stepIndex).render(state, prevState)
  }
}

PageCarousel.class = 'page-carousel'
PageCarousel.selector = '.page-carousel'
