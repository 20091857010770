$.fn.activityGraph = function() {
  return $.map(this, function(element) {
    return new ActivityGraph(element)
  })
}

function ActivityGraph(element) {
  this.$el = $(element)

  this.$el.find('.activity-graph__day_goal').mouseover(function() {
    var classes = $(this)
      .attr('class')
      .split(' ')
    var weekClass = null
    for (var i = 0; i < classes.length; i++) {
      var cls = classes[i]
      if (cls.substr(0, 26) === 'activity-graph__day_wsdate') {
        weekClass = cls
      }
    }
    if (weekClass != null) {
      $('.' + weekClass).addClass('activity-graph__day_goal_off')
    }
  })

  this.$el.find('.activity-graph__day_goal').mouseout(function() {
    var classes = $(this)
      .attr('class')
      .split(' ')
    var weekClass = null
    for (var i = 0; i < classes.length; i++) {
      var cls = classes[i]
      if (cls.substr(0, 26) === 'activity-graph__day_wsdate') {
        weekClass = cls
      }
    }
    if (weekClass != null) {
      $('.' + weekClass).removeClass('activity-graph__day_goal_off')
    }
  })
}
