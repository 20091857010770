import Preferences from 'lib/preferences'

export default class LayoutAlertUseChrome {
  constructor(element) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      $(element)
        .show()
        .on('closed.bs.alert', function() {
          Preferences.instance().then((prefs) => {
            prefs.set(['alerts', 'usechrome'], true)
          })
        })
    }
  }
}
