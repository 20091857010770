import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.049 15.271L20.9617 14.6068C21.8954 13.9274 22.124 12.6437 21.4698 11.7433L15.2224 3.1572C14.9068 2.72277 14.4322 2.44129 13.8864 2.36608C13.3407 2.29088 12.7923 2.4303 12.3403 2.75871L8.00456 5.91387"
        stroke="black"
      />
      <path
        d="M17.1565 11.1885L8.48496 6.19008C8.01993 5.92159 7.47186 5.85749 6.94272 6.01075C6.41357 6.16402 5.96931 6.51444 5.68996 6.9983L2.55167 12.4435C1.97502 13.4439 2.28798 14.7103 3.25117 15.2664L3.25255 15.2672L5.54692 16.5897"
        stroke="black"
      />
      <path
        d="M7.45536 21.5841C6.34318 21.5841 5.43893 20.6438 5.43813 19.4891L5.43335 13.2043C5.43335 12.6455 5.64288 12.1199 6.0245 11.7226C6.40612 11.3253 6.91281 11.1068 7.44979 11.1068L18.0682 11.0994C19.1811 11.0994 20.0854 12.0388 20.0862 13.1935L20.091 19.4792C20.091 20.0371 19.8806 20.5635 19.499 20.96C19.1174 21.3573 18.6115 21.5758 18.0745 21.5766L7.45696 21.5841H7.45536"
        stroke="black"
      />
    </svg>
  )
}
