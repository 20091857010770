import React from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements'
import { Flex, Box } from '@chatterbug/aaron'
import { css } from 'styled-components'

import { t } from 'src/lib/i18n-react'

const stripeStyles = {
  base: {
    fontSize: '18px',
    '::placeholder': {
      color: '#D8D8D8',
    },
  },
}

const fieldStyle = {
  flexDirection: 'column',
  marginBottom: 'md',
  borderBottom: '1px #d8d8d8 solid',
}

const labelStyle = {
  marginBottom: 'sm',
  alignSelf: 'flex-start',
}

const CreditCardForm = () => {
  return (
    <Flex
      fontSize="18px"
      color="black"
      flexDirection="column"
      css={css`
        @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.lg}) {
          flex-direction: row;
        }
      `}
    >
      <Flex
        {...fieldStyle}
        flex="auto"
        mr="0"
        css={css`
          @media screen and (min-width: ${(props) =>
              props.theme.breakpoints.lg}) {
            flex: 3;
            margin-right: 48px;
          }
        `}
      >
        <Box as="label" htmlFor="credit_card_form_cc" {...labelStyle}>
          {t('Card number')}
        </Box>
        <CardNumberElement
          id="credit_card_form_cc"
          style={stripeStyles}
          placeholder="0000 0000 0000 0000"
        />
      </Flex>
      <Flex
        {...fieldStyle}
        flex="auto"
        mr="0"
        css={css`
          @media screen and (min-width: ${(props) =>
              props.theme.breakpoints.lg}) {
            flex: 1;
            margin-right: 48px;
          }
        `}
      >
        <Box as="label" htmlFor="credit_card_form_exp" {...labelStyle}>
          {t('Month / Year')}
        </Box>
        <CardExpiryElement
          id="credit_card_form_exp"
          style={stripeStyles}
          placeholder="03/18"
        />
      </Flex>
      <Flex
        {...fieldStyle}
        flex="auto"
        mr="0"
        css={css`
          @media screen and (min-width: ${(props) =>
              props.theme.breakpoints.lg}) {
            flex: 1;
            margin-right: 48px;
          }
        `}
      >
        <Box as="label" htmlFor="credit_card_form_cvc" {...labelStyle}>
          {t('CVC')}
        </Box>
        <CardCVCElement
          id="credit_card_form_cvc"
          style={stripeStyles}
          placeholder="000"
        />
      </Flex>
    </Flex>
  )
}

export default CreditCardForm
