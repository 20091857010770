import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Grid, Box } from '@chatterbug/aaron'

const LanguageCardWithAvatarSkeleton = ({
  open,
  headerSection,
  avatarSection,
  onClick,
  children,
}) => {
  return (
    <Flex
      pt="6x"
      position="relative"
      onClick={onClick}
      justifyItems="center"
      width={{ _: '100%', tablet: '570px' }}
      maxWidth="570px"
    >
      <Grid
        flex="1"
        gridGap="3x"
        bg="white100"
        borderRadius="1x"
        px="3x"
        pb="3x"
        pt="5x"
        boxShadow="raised"
        width="100%"
        textAlign="center"
        sx={{
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        <Flex
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          width="100%"
        >
          {avatarSection}
        </Flex>
        {headerSection}
        <Box
          sx={{
            cursor: 'auto',
          }}
          width="100%"
        >
          <Box
            sx={{
              maxHeight: open ? '1000px' : '0',
              opacity: open ? '1' : '0',
              visibility: open ? 'visible' : 'hidden ',
              overflow: 'hidden',
              transition: 'opacity 0.5s ease 0.25s, max-height 1s ease',
            }}
          >
            {children}
          </Box>
        </Box>
      </Grid>
    </Flex>
  )
}

LanguageCardWithAvatarSkeleton.propTypes = {
  open: PropTypes.bool,
  headerSection: PropTypes.element,
  avatarSection: PropTypes.element,
  onClick: PropTypes.func,
}
export default LanguageCardWithAvatarSkeleton
