import _ from 'lodash'

import { on } from 'lib/util'
import ChatChannel from 'lib/actioncable/chat-channel'
import FormLoader from 'modules/form-loader'
import WordSearch from 'modules/word-search'
import Form from 'modules/form'
import StudyProgressBar from 'modules/study-progress-bar'
import ChatReadyHandshake from 'modules/chat-ready-handshake'
import PageAlert from 'src/components/PageAlert'
import ConnectionQuality from 'modules/connection-quality'

export default class LayoutStyleguide {
  constructor(element) {
    // Only show the section that matches the hash
    // e.g. /admin/styleguide#typography shows the typography section only
    // e.g. /admin/styleguide shows all sections
    window.onpopstate = () => {
      this.showStyleguideSection(document.location.hash)
    }
    this.showStyleguideSection(document.location.hash)

    this.sessionId = element.getAttribute('data-session-id')
    this.chatChannel = ChatChannel.get(this.sessionId)

    this.cardsSection()
    this.interactiveImageSection()
    this.chatReadyHandshakeSection()
    this.ratingsSection()
    this.studyProgressBarSection()
    this.loadersSection()
    this.wordSearchSection()
    this.dashboardTodaySection()
    this.formsSection()
    this.pageAlertsSection()
    this.connectionQualitySection()
  }

  showStyleguideSection(sectionHash) {
    let section = sectionHash ? document.querySelector(sectionHash) : null
    if (section) {
      $('.content-section').hide()
      $(section).show()
    } else {
      $('.content-section').show()
    }
  }

  cardsSection() {
    let cardJudgmentPerfect = $(
      '.card-judgement__perfect-container .card-judgment'
    ).cardJudgment()[0]
    cardJudgmentPerfect.showCorrectAnswer(4, 4, 'Perfect!')

    let cardJudgmentSuccess = $(
      '.card-judgement__success-container .card-judgment'
    ).cardJudgment()[0]
    cardJudgmentSuccess.showCorrectAnswer(3, 3, 'Yay, on to the next one!')

    let cardJudgmentOk = $(
      '.card-judgement__ok-container .card-judgment'
    ).cardJudgment()[0]
    cardJudgmentOk.showIncorrectAnswer(
      2,
      2,
      "Close enough! We'll give you this one, but the correct answer is:"
    )

    let cardJudgmentWarning = $(
      '.card-judgement__warning-container .card-judgment'
    ).cardJudgment()[0]
    cardJudgmentWarning.showIncorrectAnswer(
      2,
      1,
      'Close! The correct answer is:'
    )

    let cardJudgmentFail = $(
      '.card-judgement__fail-container .card-judgment'
    ).cardJudgment()[0]
    cardJudgmentFail.showIncorrectAnswer(0, 0, 'Nope, the correct answer is:')
  }

  interactiveImageSection() {
    let interactiveImage = $('.interactive-image').interactiveImage()[0]

    let currentOther = { x: 0, y: 0 }
    let abuseElement = $('<div/>')

    $('#interactive-image__move-mouse').click(() => {
      let newOther = {
        x: Math.random() * interactiveImage.stageWidth,
        y: Math.random() * interactiveImage.stageHeight,
      }

      let loljquery = 0
      let inProgressOther = { ...currentOther }
      abuseElement
        .stop()
        .css(currentOther)
        .animate(newOther, {
          duration: 400,
          easing: 'swing',
          step: (current) => {
            let key = ['x', 'y'][loljquery % 2]
            inProgressOther[key] = current
            this.chatChannel.server.received({
              type: 'mouseMove',
              user: 0,
              ...inProgressOther,
            })
            currentOther = inProgressOther
            loljquery++
          },
        })
      return false
    })

    $('#interactive-image__click-mouse').click(() => {
      this.chatChannel.server.received({
        type: 'mouseDown',
        user: 0,
        ...currentOther,
      })
      return false
    })
  }

  chatReadyHandshakeSection() {
    let chatReadyHandshake = new ChatReadyHandshake(
      document.querySelector('.chat-ready-handshake')
    )
    $('#chat-ready-handshake__reset').click(() => {
      this.chatChannel.resetClientReady()
      return false
    })

    $('.chat-ready-handshake__state').click((e) => {
      let state = $(e.target).attr('data-state')
      chatReadyHandshake.setState(state)
      return false
    })
  }

  ratingsSection() {
    $('.rating_stars').ratingStars({
      onClick: function () {
        console.log('Clicked stars')
      },
    })
    $('.rating_emojis').ratingEmojis({
      onClick: function () {
        console.log('Clicked emoji')
      },
    })
  }

  studyProgressBarSection() {
    let els = document.querySelectorAll(
      '#study-progress-bar .study-progress-bar'
    )
    let studyProgressBars = _.map(els, (el) => {
      let update = (percent) => bar.render({ percent })
      let bar = new StudyProgressBar(el, {
        onScrub: update,
        onChange: update,
      })
      return bar
    })
    let tickBar = studyProgressBars.filter((b) =>
      b.element.classList.contains('study-progress-bar__has-ticks')
    )[0]
    tickBar.addTick(23)
    tickBar.addTick(40)
    tickBar.addTick(82)

    $('#study-progress-bar__advance').click(function () {
      for (let bar of studyProgressBars) {
        bar.render({ percent: (bar.getPercent() + 10) % 100 })
      }
      return false
    })
  }

  loadersSection() {
    let loader = new FormLoader(document.querySelector('#loaders .form-loader'))
    $('#loaders .loaders__load').click(() =>
      loader.render({ loading: true, message: null })
    )
    $('#loaders .loaders__message').click(() =>
      loader.render({ loading: false, message: 'Oops an error' })
    )
    $('#loaders .loaders__clear').click(() =>
      loader.render({ loading: false, message: null })
    )
  }

  wordSearchSection() {
    let elements = document.querySelectorAll('.word-search')
    _.map(elements, (e) => new WordSearch(e))
  }

  formsSection() {
    let elements = document.querySelectorAll('#form-module ' + Form.selector)
    _.map(elements, (e) => new Form(e))
  }

  pageAlertsSection() {
    let element = document.querySelector('#page-alerts')
    console.log(element)
    on(element, 'click', '.page-alerts__open-alert', (e) => {
      PageAlert.showAlert({
        type: 'info',
        content: 'this is content, things and stuff',
        onClose: () => console.log('normal alert closed'),
      })
    })
    on(element, 'click', '.page-alerts__open-alert-icon', (e) => {
      PageAlert.showAlert({
        type: 'danger',
        icon: 'chat-session',
        content:
          'this is content, things and stuff lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
        onClose: () => console.log('icon alert closed'),
      })
    })
    on(element, 'click', '.page-alerts__open-fixed-alert', (e) => {
      PageAlert.showAlert({
        type: 'warning',
        fixed: true,
        content: 'This thing is fixed to the top of the browser window',
        onClose: () => console.log('fixed alert closed'),
      })
    })
  }

  connectionQualitySection() {
    let parentElement = document.querySelector('#connection-quality')
    ConnectionQuality.createAll(parentElement)
  }
}
