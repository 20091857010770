import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'
import { Flex, Button, Box } from '@chatterbug/aaron'
import { put } from 'src/lib/util'

import Ticker from './subcomponents/Ticker'
class Banner extends React.Component {
  state = { visible: true }

  hideBanner = () => {
    const { offerId } = this.props
    if (offerId) {
      this.setState({ visible: false })
      // Set this banner to permanently hidden
      put(routes.payments_offer(offerId), {}, '')
    }
  }

  render() {
    const {
      children,
      cta,
      href,
      hideButton,
      countdown,
      hideable,
      offerId,
      // props from c1-transition banner parent
      onAccept,
      onClose,
      disabled,
    } = this.props
    const { visible } = this.state

    if (!visible) return null

    return (
      <Flex
        bg="#F3EDFF"
        color="#4C2799"
        width="100%"
        css="border-bottom: 1.5px solid #b8a2ee;"
        data-testid="banner"
      >
        <Flex
          className="container"
          width="100%"
          justifyContent="center"
          alignItems="center"
          py="4px"
          px="24px"
          flexDirection="column"
          css={css`
            @media screen and (min-width: 768px) {
              flex-direction: row;
            }
            @media screen and (min-width: 992px) {
              padding-left: 16px;
              padding-right: 16px;
            }
          `}
        >
          <Flex py="16px">{children}</Flex>
          <Flex
            alignItems="center"
            css={css`
              align-self: flex-start;
              @media screen and (min-width: 768px) {
                align-self: auto;
              }
            `}
          >
            {countdown && (
              <Box
                ml="32px"
                display="none"
                css={css`
                  @media screen and (min-width: 992px) {
                    display: block;
                  }
                `}
              >
                <Ticker
                  timeTo={countdown}
                  onExpire={() => this.setState({ visible: false })}
                />
              </Box>
            )}
            {cta && !hideButton && (
              <Box
                mb="16px"
                css={css`
                  @media screen and (min-width: 768px) {
                    margin-left: 16px;
                    margin-bottom: 0;
                  }
                  @media screen and (min-width: 992px) {
                    margin-left: 32px;
                  }
                `}
              >
                <Button
                  onClick={() => {
                    onAccept?.()
                    window.location.href = href
                    // hide it if we provided a c-level accept function, so it's not shown in c-level
                    if (onAccept) {
                      this.hideBanner()
                    }
                  }}
                  disabled={disabled}
                  disableWith={t('Loading...')}
                  data-testid="accept"
                  css={`
                    background-color: #fd426e;
                    color: #ffffff;
                    &_focus {
                      background-color: #fc7f9c;
                    }
                  `}
                >
                  {cta}
                </Button>
              </Box>
            )}
          </Flex>
          {hideable && offerId && (
            <Box
              onClick={async () => {
                onClose?.()
                this.hideBanner()
              }}
              ml="16px"
              data-testid="close"
              className="fa fa-remove"
              css={css`
                cursor: pointer;
                @media screen and (max-width: 767px) {
                  order: -1;
                  align-self: flex-end;
                  padding-top: 16px;
                }
              `}
            />
          )}
        </Flex>
      </Flex>
    )
  }
}

Banner.propTypes = {
  offerId: PropTypes.number,
  cta: PropTypes.string,
  hideButton: PropTypes.bool,
  href: PropTypes.string,
  countdown: PropTypes.number,
  hideable: PropTypes.bool,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
}

Banner.defaultProps = {
  hideable: true,
  hideButton: false,
}

export default Banner
