import { createConsumer } from '@rails/actioncable'

global.__cable = null

class AppCable {
  static createSubscription(options, definition) {
    if (!global.__cable) global.__cable = createConsumer()
    return global.__cable.subscriptions.create(options, definition)
  }
}

export default AppCable
