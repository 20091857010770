import { get } from 'lib/util'

export default class LayoutNavbar {
  constructor(element, options) {
    this.element = element

    get('/notifications')
      .then(({ data }) => {
        if (!data) {
          return
        }
        var sessions = data['open_sessions']
        if (sessions > 0) {
          $('#alert-count').text(sessions)
          $('.menu-alerts').show()
        } else {
          $('.menu-alerts').hide()
        }
      })
      .catch((e) => {
        console.warn(e)
        window.Sentry?.captureException(e)
      })
  }
}
