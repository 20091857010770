import ComponentState from 'lib/component-state'
import TutorGoalsForm from 'modules/tutor-goals-form'
import FormModal from 'modules/form-modal'
import { redirectToHREF } from 'lib/util'

// A modal to edit tutor goals

export default class TutorGoalsModal {
  constructor(element, options) {
    if (!element) return
    this.element = element
    this.componentState = new ComponentState({
      onSetState: this.render.bind(this),
    })

    let tutorGoalsFormEl = element.querySelector(TutorGoalsForm.selector)
    this.tutorGoalsForm = new TutorGoalsForm(tutorGoalsFormEl, {
      onChange: (state) => {
        this.prevState = this.componentState.state
        this.componentState.set(state)
        if (this.prevState.active !== state.active) this.formModal.submit()
      },
    })

    this.formModal = new FormModal(element, {
      formData: () => this.componentState.state,
      onShow: () => this.componentState.set(this.tutorGoalsForm.getValue()),
      onSuccess: () => redirectToHREF(this.getCurrentURL(), true),
    })
  }

  getCurrentURL() {
    return window.location
  }

  render(state, prevState) {
    this.tutorGoalsForm.render(state, prevState)
  }
}
TutorGoalsModal.className = 'tutor-goals-modal'
TutorGoalsModal.selector = `.${TutorGoalsModal.className}`
