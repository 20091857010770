import _ from 'lodash'

import ComponentState from 'lib/component-state'
import { post, redirectToHREF, bindMethods } from 'lib/util'
import OnboardingController from 'modules/onboarding-controller'
import { t } from 'src/lib/i18n'

import IntroPage from './l-onboarding/intro-page'
import PrivacyPage from './l-onboarding/privacy-page'
import TutorGoalsPage from './l-onboarding/tutor-goals-page'

export default class LayoutOnboardingTutor {
  constructor(element) {
    bindMethods(this)
    this.element = element
    this.componentState = new ComponentState({ onSetState: this.render })
    this.onboarding = new OnboardingController(element, {
      componentState: this.componentState,
      onClickFinish: this.handleClickFinish,
      pageClasses: [IntroPage, PrivacyPage, TutorGoalsPage],
    })
    this.componentState.init({
      loading: false,
      message: null,
    })
    this.initialRender()
  }

  initialRender() {
    this.render(this.componentState.state, {})
  }

  handleClickFinish() {
    let { state } = this.componentState
    let selectedLanguages = _.keys(state.selectedLanguages)
    let params = _.assign(
      { selectedLanguages },
      _.pick(state, ['dayMaxHours', 'weekMaxHours'])
    )

    this.componentState.set({
      message: null,
      loading: true,
      nextDisabled: true,
    })
    post('/goals/tutor', params).then(({ data, status }) => {
      if (status === 'success') {
        this.componentState.set({ message: null, loading: false })
        redirectToHREF('/')
      } else {
        this.componentState.set({
          message: t('Oops there was an error'),
          loading: false,
        })
        console.log('error', status, data)
      }
    })
  }

  render(state, prevState) {
    this.onboarding.render(state, prevState)
  }
}

LayoutOnboardingTutor.selector = '.l-onboarding_tutor'
