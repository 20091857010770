import React from 'react'
import PropTypes from 'prop-types'

const ButtonBar = ({ children }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'row',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  )
}

ButtonBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonBar
