import Module from 'src/lib/module'
import { renderReactComponent } from 'src/lib/util'
import PaymentForm from 'src/components/PaymentForm/PaymentForm'

export default class NewPaymentMethod extends Module {
  constructor(containerEl, { props }) {
    super(containerEl)

    const ret = document.createElement('react-element')
    ret.setAttribute('component', 'PaymentForm/PaymentForm')
    ret.setProps(props)

    // NB: We do this because at the time this is called, we are already in a
    // React render call, transformed to a legacy component via LayoutBridge.
    // If we try to create this directly we will end up having reentrancy
    // issues
    setTimeout(() => containerEl.replaceWith(ret), 0)
  }
}
NewPaymentMethod.className = 'new-payment-method'
