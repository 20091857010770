import { getLocale, sentry } from 'src/config/globals'

// fetch a url, bring locale along too!
// returns an object like { status: 200, data: { (parsed json data) } }
async function request(
  url: string,
  body: BodyInit | undefined,
  dataType: string,
  method: string
) {
  const headers = new Headers()
  headers.append('X-Locale', getLocale())

  const options: RequestInit = {
    headers,
    method,
    credentials: 'same-origin',
  }

  if (dataType === 'json') {
    headers.append('Content-Type', 'application/json; charset=UTF-8')
    headers.append('Accept', 'application/json; charset=UTF-8')

    new Headers()

    if (options.method !== 'GET') {
      options.body = JSON.stringify(body)
    }
  }

  if (options.method !== 'GET') {
    let token = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]'
    )
    if (!token) {
      throw new Error("Can't find CSRF token!")
    }

    headers.append('X-CSRF-Token', token.content)
  }

  let response
  try {
    response = await window.fetch(url, options)
  } catch (error) {
    // fetch throws when something _really_ bad, like a loss of connection, happens
    console.error(error)
    sentry.captureException(error)
    return { status: 0, data: undefined }
  }
  const status = response.status

  if (response.ok) {
    let data
    const contentType = response.headers.get('Content-Type') || ''
    if (contentType.includes('application/json')) {
      data = await response.json()
    } else {
      data = await response.text()
    }
    return { status, data }
  } else {
    console.error(response)
    sentry.captureException(
      new Error(`${response.status} ${response.statusText}`)
    )
  }

  return { status, data: undefined }
}

export function get(url: string, dataType: string) {
  return request(url, undefined, dataType, 'GET')
}

export function post(url: string, body: BodyInit, dataType: string) {
  return request(url, body, dataType, 'POST')
}

export function put(url: string, body: BodyInit, dataType: string) {
  return request(url, body, dataType, 'PUT')
}

export function destroy(url: string, body: BodyInit, dataType: string) {
  return request(url, body, dataType, 'DELETE')
}
