import React from 'react'
import PropTypes from 'prop-types'

import { Box, Image } from '@chatterbug/aaron'
import Badge from 'src/components/CEFRSeal/CEFRSeal.js'

import shadow from './assets/badge_shadow_de.svg'

const CEFRBadge = ({ cefrLevel, completed }) => {
  return (
    <Box width={{ _: '48px', tablet: '64px' }} textAlign="center">
      <Box
        width="inherit"
        bottom={{ _: '80%', tablet: '50%' }}
        sx={{ position: 'absolute' }}
      >
        <Badge cefrLevel={cefrLevel} progress={completed / 100} />
      </Box>
      <Image src={shadow} />
    </Box>
  )
}

CEFRBadge.propTypes = {
  completed: PropTypes.number.isRequired,
  cefrLevel: PropTypes.string.isRequired,
}

export default CEFRBadge
