import React from 'react'
import PropTypes from 'prop-types'

import routes from 'src/lib/rails-routes/rails-routes'
import { Box } from '@chatterbug/aaron'

import Flag from '../Flag/Flag'
import MenuItem from '../MenuItem/MenuItem'

const Course = ({ selectedCourse, languageCode, text }) => {
  return (
    <Box
      bg={selectedCourse === languageCode ? '#F3F3F3' : null}
      sx={{ zIndex: 99 }}
      borderRadius="1,5x"
    >
      <MenuItem
        textStyle={{ marginLeft: '2x' }}
        text={text}
        sx={{ fontSize: 'xs', paddingLeft: '2x' }}
        showToolTipOnTablet={false}
        href={routes.advanced_home(languageCode)}
      >
        <Flag languageCode={languageCode} />
      </MenuItem>
    </Box>
  )
}

Course.propTypes = {
  selectedCourse: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Course
