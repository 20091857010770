import _ from 'lodash'

export default class TelcoBase {
  constructor() {
    this.defaultOptions = {
      localVideoId: 'ourvideo',
      remoteVideoId: 'theirvideo',
      onFailure: (err) => {
        this.log(err)
      },
      onConnect: () => {},
      onSubscribe: (subscriber) => {},
      onDisconnect: (ev) => {
        this.log('Disconnected from session', ev.reason)
      },
      onLog: () => {},
    }
  }

  log() {
    if (this.onLog) {
      this.onLog(...arguments)
    }
  }

  // Public API
  initialize(chatOptions) {
    throw new Error('Should kick off a new session')
  }
  join(userToken, chatOptions) {
    throw new Error('Should join a session')
  }

  // Public: Set up local video only
  //
  //    previewOptions - Hash of options, including:
  //      'localVideoId' - Element ID into which local video node will
  //                       be inserted, defaults to 'ourvideo'
  //      'onFailure'    - Callback function invoked on failures, will be
  //                       passed Hash with 'reason' & maybe other keys
  initializePreview(previewOptions) {
    var options = _.extend({}, this.defaultOptions, previewOptions)

    var parentNode = document.getElementById(options.localVideoId)
    if (typeof parentNode === 'undefined') {
      options.onFailure({
        reason: 'invalid localVideoId',
        description: 'Unable to find parent node for video element',
      })
      return
    }

    var videoNode = document.createElement('video')
    videoNode.setAttribute('autoplay', 'true')
    videoNode.setAttribute('defaultMuted', 'true')
    videoNode.setAttribute('muted', 'true')
    parentNode.appendChild(videoNode)

    var handleUserMediaError = function(error) {
      if (error.name === 'PermissionDeniedError') {
        console.log('Permission denied to access camera and microphone.')
      } else if (error.name === 'ConstraintNotSatisfiedError') {
        console.log('Requested video resolution is not available.')
      } else {
        console.log('GetUserMedia error: ' + error.name + ' (' + error + ')')
      }
      options.onFailure({ reason: error.name, error: error })
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function(stream) {
        videoNode.srcObject = stream
        videoNode.setAttribute('muted', 'true')
        options.onConnect()
      })
      .catch(handleUserMediaError)
  }
}
