import { get, post, bindMethods, on } from 'lib/util'
import CurrencyPicker from 'modules/currency-picker'
import NewPaymentMethod from 'modules/new-payment-method'

export default class LayoutPayments {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = options
    const { name, currency, price } = this.options

    // This is not available to people in organisations.
    const addPaymentModal = element.querySelector('#add-payment-modal')
    if (addPaymentModal) {
      const props = {
        paymentSource: 'additional',
        paymentMethods:
          addPaymentModal.getAttribute('data-sepa') === '1'
            ? ['card', 'iban']
            : undefined,
        stripePublishedKey: window.stripe_public_key,
        plan: {
          name,
          currency,
          price,
        },
        onStripeResponse: (paymentId, response) => {
          this.handleSetupTokenResponse(paymentId, response)
          $(addPaymentModal).modal('hide')
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(
        element.querySelector('#add-payment-modal .modal-body'),
        { props }
      )
    }

    CurrencyPicker.create(element, { onChange: this.handlePlanChange })

    on(
      element,
      'click',
      LayoutPayments.changeSubscriptionLinkSelector,
      this.handleChangeSubscription
    )

    on(element, 'click', '.js-switch-default', this.handleUpdatePayment)
    on(element, 'click', '.js-delete-source', this.handleUpdatePayment)
    on(element, 'click', '.js-choose-plan', this.handleShowPlanDetails)
    on(
      element,
      'click',
      '.js-cancel-subscription',
      this.handleCancelSubscription
    )
  }

  handleCancelSubscription(e) {
    e.preventDefault()
    $('.js-cancel-subscription').hide()
    $('.cancel-subscription').show()
  }

  // When a user "chooses" a plan we get a reference from the target of one of the
  // following
  // casual-details | serious-details | unlimited-details
  getPlan = (ref) => {
    const details = document.getElementById(ref)
    const currency = details
      .querySelector('input[name=currency_code]')
      .getAttribute('value')
      .toLowerCase()
    const name = details
      .querySelector('input[name=subscription_code]')
      .getAttribute('value')
    const price = parseInt(
      details.querySelector('#plan_subscription_total').getAttribute('value')
    )
    return {
      name,
      price,
      currency,
    }
  }

  handleShowPlanDetails(e) {
    e.preventDefault()
    let newMethodEl = this.element.querySelector(
      '#existing-payments .new-payment-method'
    )
    if (newMethodEl) {
      const props = {
        paymentSource: 'new',
        stripePublishedKey: window.stripe_public_key,
        plan: this.getPlan(e.target.getAttribute('ref')),
        onStripeResponse: (paymentId, response) => {
          this.handleSetupTokenResponse(paymentId, response)
        },
      }
      this.newPaymentMethod = new NewPaymentMethod(newMethodEl, { props })
    }
    $('#payment-go').show()

    // Update the plan's summary
    const $target = $(e.target)
    const target = $target.attr('ref')

    $('.payment-details').hide()
    $('#' + target).show()
    $('.plan-summary').addClass('plan-summary_unselected')
    $target.closest('.plan-summary').removeClass('plan-summary_unselected')
    // set selected form active for new plan submitting
    $('.active-form').removeClass('active-form')
    $('#' + target + ' form').addClass('active-form')
    // Show payment tooling and update button text
    let purchaseText = $target.attr('data-purchase-text')
    $('#setup-plan').text(purchaseText)
    $('#payment-go').show()
  }

  handleUpdatePayment(e) {
    e.preventDefault()
    let target = $(e.target).closest('a')[0]
    let token = target.getAttribute('ref')
    let href = target.getAttribute('href')
    post(href, { token }).then(({ data }) => this.resetPaymentsArea(data))
  }

  handlePaymentSave(result) {
    let token = null
    if (result.token) {
      token = result.token.id
    } else if (result.source) {
      token = result.source.id
    }
    post('/payments/payment', { token }).then(({ data }) =>
      this.resetPaymentsArea(data)
    )
  }

  resetPaymentsArea(result) {
    $('#existing-payments').html(result)
  }

  // Setup-only things
  // e.g. when user does not have a payment method or plan

  handleSetupTokenResponse(paymentId, result) {
    if (result.error) {
      this.setupButton.disabled = false
    } else {
      if ($('.active-form').length > 0) {
        $('#new-source').val(paymentId)
        $('.active-form').append($('#new-source'))
        $('.active-form').submit()
      } else {
        // FIXME: I think this will never get called. This code path _should_ be setup only?
        this.handlePaymentSave(result)
      }
    }
  }

  handleChangeSubscription(e) {
    get(`/payments/plans/${this.options.currency}`).then(({ data }) => {
      $('#change-plan').html(data)
      $('#change-subscription-modal').modal('show')
    })
  }

  handlePlanChange(currencyCode) {
    get(`/payments/plans/${currencyCode}`).then(({ data }) => {
      $('#plan-choice').html(data)
      $('#payment-go').hide()
    })
  }
}

LayoutPayments.className = 'l-payments'
LayoutPayments.selector = '.l-payments'
LayoutPayments.changeSubscriptionLinkSelector = `${LayoutPayments.selector}__change-subscription-link`
