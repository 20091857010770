import _ from 'lodash'

import { post, bindMethods, dataAttrs } from 'lib/util'
import ActivityTimer from 'lib/activity-timer'
import ChatSessionReplay from 'modules/chat-session-replay'
import ExerciseSummaryContainer from 'modules/exercise-summary-container'
import ActivityTime from 'modules/activity-time'

export default class LayoutExerciseReplay {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = options
    this.timer = new ActivityTimer({ maxIdleSeconds: 30 })
    let activityTimeEl = element.querySelector(ActivityTime.selector)
    if (activityTimeEl) {
      this.activityTime = new ActivityTime(activityTimeEl, {
        activityTimer: this.timer,
      })
    }

    this.initStudyTimeTracking()

    // stuff to replay?
    let exercise = _.find(options.exercises, (e) => e.videoSrc)
    if (exercise) {
      // Some exercises might not have a legit video. e.g. vids in dev
      this.currentExerciseId = exercise.id
      this.chatSessionReplay = new ChatSessionReplay(element, exercise)
      this.exerciseList = new ExerciseSummaryContainer(
        ExerciseSummaryContainer.find(element),
        {
          onViewExercise: this.handleViewExercise,
        }
      )
      this.exerciseList.render({ exerciseId: this.currentExerciseId })
    } else {
      // if there is no video source due to "dont-record-me" settings, init the replay anyhow because that loads the image
      this.chatSessionReplay = new ChatSessionReplay(
        element,
        options.exercises[0]
      )
    }
  }

  initStudyTimeTracking() {
    let { role, language } = dataAttrs(this.element)
    if (role === 'student') {
      global.addEventListener('beforeunload', () => {
        let studyType = 'exercise_review'
        let studySeconds = this.timer.getElapsedSeconds()
        post(`/api/v1/languages/${language}/study/time`, {
          study_seconds: studySeconds,
          study_type: studyType,
        })
      })
    }
  }

  handleViewExercise(chatSessionExerciseId) {
    this.render({ exerciseId: chatSessionExerciseId })
  }

  setExerciseIndex(index) {
    this.chatSessionReplay.setOptions(this.options.exercises[index])
  }

  render({ exerciseId }) {
    if (exerciseId !== this.currentExerciseId) {
      let exercise = findExercise(this.options.exercises, exerciseId)
      if (exercise) {
        if (this.chatSessionReplay.replay) {
          this.chatSessionReplay.replay.pause()
        }
        this.chatSessionReplay.setOptions(exercise)
        this.currentExerciseId = exerciseId
      }
    }
    this.exerciseList.render({ exerciseId })
  }
}

function findExercise(exercises, id) {
  for (let exercise of _.toArray(exercises)) {
    if (exercise.id === id) return exercise
  }
  return null
}
