import React from 'react'

import { Grid, GridProps } from '@chatterbug/aaron'

export const PAGE_VERTICAL_PADDING = '4x'
export const PAGE_HORIZONTAL_PADDING = { _: '2x', mobile: '5x', tablet: '3x' }
export const PAGE_GAP = { _: '3x', mobile: '2x', tabletWide: '3x' }
export const MAX_WIDTHS_FOR_BREAKPOINTS = { tabletWide: 768, laptop: 976 }

export const PageLayout: React.FC<GridProps> = (props) => {
  return (
    <Grid
      mx="auto"
      pt={PAGE_VERTICAL_PADDING} // `py` would not allow to easily override `pt` or `pb`
      pb={PAGE_VERTICAL_PADDING}
      px={PAGE_HORIZONTAL_PADDING}
      maxWidth={MAX_WIDTHS_FOR_BREAKPOINTS}
      {...props}
    />
  )
}

export default PageLayout
