import { assign } from 'lodash'

import Notifications from 'src/lib/notifications'
import * as Util from 'src/lib/util'
import { setUserEnv } from 'src/config/globals'
import Popper from 'popper.js'

// CSS
import 'assets/stylesheets/application.scss'

// Import all legacy images for Rails to use them with image_bundle_tag
const importAll = (r) => r.keys().forEach(r)
importAll(
  require.context('../../../../app/assets/images', true, /\.(png|jpe?g|svg)$/)
)
importAll(require.context('../../../../app/assets/audio', true, /\.(mp3|ogg)$/))
importAll(
  require.context('../../../../public/images', true, /\.(png|jpe?g|svg)$/)
)

setUserEnv()

// FIXME: We should only require these when necessary. No globals!
// TODO: remove these from the global space at some point
// TODO: This is just insane. Bad! Bad!
const {
  datajsJQuery,
  datajs,
  dataAttrs,
  ensureAction,
  redirectToHREF,
  pluralize,
  pluralizeHTML,
  renderTemplate,
  scrollToPoint,
  scrollMinimallyToElement,
} = Util

assign(global, {
  Notifications,
  datajsJQuery,
  datajs,
  dataAttrs,
  ensureAction,
  redirectToHREF,
  pluralize,
  pluralizeHTML,
  renderTemplate,
  scrollToPoint,
  scrollMinimallyToElement,
  LegacyPopper: Popper,
})

require('lib/actioncable/presence-channel')

// FIXME: We should only require these when necessary. No globals!
require('modules/activity-graph')
require('modules/audio')
require('modules/card-input')
require('modules/card-judgment')
require('modules/card')
require('modules/card-intro')
require('modules/chart')
require('modules/chat-ready-handshake')
require('modules/debug-deck')
require('modules/gauge')
require('modules/interactive-image')
require('modules/pie')
require('modules/rating')
require('modules/study-progress-bar')
require('modules/tabs')
require('modules/tooltip')
require('modules/video-actions')
require('modules/video-thumb')

require('telco')
