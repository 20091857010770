import React from 'react'
import MainMenu from 'src/ui/MainMenu/MainMenu'
import LoadProductTour from 'src/ui/LoadPage/LoadProductTour'

const LoadMainMenu = (props) => {
  return (
    <LoadProductTour disabled={false} pageSection="main_menu">
      <MainMenu {...props} />
    </LoadProductTour>
  )
}

export default LoadMainMenu
