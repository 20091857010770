import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'

export type Props = {
  salesMessage: string
  color?: string
  emoji: string
}

const Offer: React.FC<Props> = ({
  salesMessage,
  emoji,
  color = 'white100',
}) => {
  return (
    <Grid
      as="p"
      gridGap="2x"
      gridTemplateColumns={{ _: '1fr', tabletWide: 'auto auto' }}
      variant="text.paragraph"
      alignItems="center"
      justifyContent={{ _: 'center', tablet: 'flex-start' }}
      color={color}
    >
      <Box as="span" fontSize="sm" display={{ _: 'none', tabletWide: 'block' }}>
        {emoji}
      </Box>
      <Box as="span" dangerouslySetInnerHTML={{ __html: salesMessage }} />
    </Grid>
  )
}

export default Offer
