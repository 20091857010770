import _ from 'lodash'

import Module from 'lib/module'
import { bindMethods } from 'lib/util'

export default class ReplayControls {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults(options, {
      chatChannelReplay: null,
      onPlay: () => {},
      onPause: () => {},
      onStop: () => {},
    })

    this.elements = Module.findElements(element, ReplayControls.className)
    this.elements.stop.addEventListener('click', this.handleClickStop)
    this.elements.playPause.addEventListener('click', this.handleClickPlayPause)

    if (this.options.chatChannelReplay) {
      this.setChatChannelReplay(this.options.chatChannelReplay)
    }
  }

  setChatChannelReplay(chatChannelReplay) {
    if (this.chatChannelReplay) {
      this.chatChannelReplay.off('play', this.renderReplayState)
      this.chatChannelReplay.off('pause', this.renderReplayState)
      this.chatChannelReplay.off('stop', this.renderReplayState)
    }

    this.chatChannelReplay = chatChannelReplay
    this.chatChannelReplay.on('play', this.renderReplayState)
    this.chatChannelReplay.on('pause', this.renderReplayState)
    this.chatChannelReplay.on('stop', this.renderReplayState)
    this.renderReplayState()
  }

  handleClickStop() {
    this.options.onStop()
  }

  handleClickPlayPause() {
    let playState = this.chatChannelReplay.getPlayState()
    playState === 'playing' ? this.options.onPause() : this.options.onPlay()
  }

  renderReplayState() {
    this.render({ playState: this.chatChannelReplay.getPlayState() })
  }

  render({ playState }) {
    this.elements.playPause.disabled = false
    if (playState === 'stopped') {
      this.elements.stop.disabled = true
      this.elements.playPause.innerHTML = ReplayControls.playTemplate
    } else if (playState === 'playing') {
      this.elements.stop.disabled = false
      this.elements.playPause.innerHTML = ReplayControls.pauseTemplate
    } else if (playState === 'paused') {
      this.elements.stop.disabled = false
      this.elements.playPause.innerHTML = ReplayControls.playTemplate
    }
  }
}

ReplayControls.className = 'replay-controls'
ReplayControls.selector = `.${ReplayControls.className}`
ReplayControls.playTemplate = '<i class="fa fa-play"></i>'
ReplayControls.pauseTemplate = '<i class="fa fa-pause"></i>'
