import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Box, Text } from '@chatterbug/aaron'

const padString = (value, padder, places) => {
  return `${padder.repeat(places)}${value}`
}

const DigitBox = ({ value, label }) => {
  const stringVal = value.toString()
  const paddedValue =
    stringVal.length > 1
      ? stringVal
      : padString(stringVal, '0', 2 - stringVal.length)
  const digits = paddedValue.split('').map((d, i) => (
    <Box
      bg="white"
      textAlign="center"
      borderRadius="6px"
      key={i}
      pb="xxs"
      pt="xxs"
      ml="xxs"
      width="32px"
      border="2.1px solid #d4cae8"
      color="black"
      css="user-select: none;"
    >
      <Text fontSize="lg" fontWeight="bold">
        {d}
      </Text>
    </Box>
  ))
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex>{digits}</Flex>
      <Text mt="xxs" fontSize="xs" css="text-transform: uppercase;">
        {label}
      </Text>
    </Flex>
  )
}

DigitBox.propTypes = {
  value: PropTypes.number.isRequired,
}

export default DigitBox
