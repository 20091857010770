import _ from 'lodash'

import ProgressBar from 'src/pages/LiveLesson/components/ProgressBar/ProgressBar'
import {
  bindMethods,
  redirectToHREF,
  post,
  renderReactComponent,
} from 'lib/util'
import Module from 'lib/module'
import ChatChannel from 'lib/actioncable/chat-channel'
import ConnectionQuality from 'lib/connection-quality'
import WordSearch from 'modules/word-search'
import ChatPresenceMarker from 'modules/chat-presence-marker'
import VideoActions from 'modules/video-actions'
import ConnectionQualityView from 'modules/connection-quality'
import { t } from 'src/lib/i18n-react'
import { Provider } from 'telco'
export default class LayoutChatLessonRoom {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = options
    let overtime = false
    // set in curriculum post callback
    let exerciseId = null
    let exerciseType = null
    let exerciseIndex = null
    let exerciseCount = null
    let lastExerciseId = null
    let completeSessionCalled = false
    let minutesLeft = 100
    let graceSecondsLeft = 1000
    let timeAtPageLoadInMs = Date.now()
    let timerElapsedInSeconds = Number.parseInt($('#timer-elapsed').val())
    let timerDurationInSeconds = Number.parseInt($('#timer-duration').val())
    let exerciseWarningTime = false
    let sessionId = $('#session-id').val()
    let sessionType = $('#session-type').val()
    let chatRole = $('#chat-role').val()
    let partnerLogin = $('#partner-login').val()
    let elements = Module.findElements(element, 'l-chat-lesson-room')
    const envMeta = document.querySelector('meta[name="railsEnv"]')
    const devMode = envMeta && envMeta.content === 'development'
    let chatChannel = ChatChannel.get(sessionId)
    chatChannel.on('endSession', endSession)
    chatChannel.on('jumpToWrapup', function (data) {
      handleJumpToWrapup(chatChannel)
    })
    chatChannel.on('nextExercise', function (data) {
      handleStartNextExercise(chatChannel)
    })
    // Setup video
    Provider.initialize(
      _.assign(options.telcoOptions, {
        sessionId: sessionId,
        onSubscribe: (sub) => this.connectionQuality.setSubscriber(sub),
        onPublish: (publisher) => {
          VideoActions.create(element, {
            videoPublisher: publisher,
          })
        },
        onChangePublisherAudioLevel: (audioLevel) => {
          renderReactComponent({ audioLevel }, this.options.localAudioMeter)
        },
        onChangeSubscriberAudioLevel: (audioLevel) => {
          renderReactComponent({ audioLevel }, this.options.remoteAudioMeter)
        },
        onLog: (...params) => {
          chatChannel.log(params.join(' '))
          console.log(...params)
        },
        onFailure: (error) => {
          chatChannel.log(`Failure: ${JSON.stringify(error)}`)
          console.error(error)
        },
      })
    )
    this.connectionQuality = new ConnectionQuality({
      onUpdate: (stats) => {
        if (devMode) {
          return
        }
        post(
          `/api/v1/rooms/${this.options.telcoOptions.roomId}/connection_quality_logs`,
          { payload: stats },
          'json'
        )
        chatChannel.connectionQuality(stats)
      },
    })
    // setup the sound that plays when the other person joins
    if (document.hidden === true || document.hidden == null) {
      elements.startSound.play()
    }
    let localParent = element.querySelector('.l-chat-lesson-room__video_local')
    let partnerParent = element.querySelector('.l-chat-lesson-room__partner')
    ConnectionQualityView.create(localParent, {
      tooltipTemplate: t(`Your connection quality is {quality}`),
    })
    ConnectionQualityView.create(partnerParent, {
      tooltipTemplate: t("%{partnerLogin}'s connection quality is {quality}", {
        partnerLogin,
      }),
    })
    ChatPresenceMarker.createAll(element)
    let wordSearchEl = document.querySelector(WordSearch.selector)
    this.wordSearch = new WordSearch(wordSearchEl)
    let exerciseRating = null
    let studentRating = null
    let tutorRateStudent = null
    let tutorRateExercise = null
    if (chatRole === 'teacher') {
      studentRating = $('.rating_emojis').ratingEmojis({
        onClick: handleTeacherStudentRating,
      })[0]
      exerciseRating = $('.rating_stars').ratingStars({
        onClick: handleTeacherExerciseRating,
      })[0]
    } else {
      exerciseRating = $('.rating_student').ratingStars({
        onClick: handleStudentRating,
      })[0]
    }
    // Sets the width so when the right column is fixed, the video and chat maintains the correct width
    var rightColumn = $('.l-chat-lesson-room__right-column')
    var affixedColumn = $('.l-chat-lesson-room__affixed-column')
    affixedColumn.css({ width: rightColumn.width() })
    // after 5 minutes in, refreshes don't go to welcome screen
    if (timerElapsedInSeconds > 480 || sessionType === 'orientation') {
      $('#firstExerciseButton').hide()
      $('#nextExerciseButton').show()
      toggleTutorControls(true)
      handleStartNextExercise(chatChannel, exerciseId, lastExerciseId)
    }
    $('#firstExerciseButton').click(function () {
      $('#firstExerciseButton').hide()
      $('#nextExerciseButton').show()
      toggleTutorControls(true)
      chatChannel.nextExercise()
      handleStartNextExercise(chatChannel, exerciseId, lastExerciseId)
    })
    // Tutor controls for orientation
    $('#tutorNextExerciseButton').click(function () {
      triggerNextExercise(5)
    })
    $('#jumpToWrapupButton').click(function () {
      chatChannel.jumpToWrapup()
      handleJumpToWrapup(chatChannel)
    })
    $('a[dataConfirm]').click(function (ev) {
      var href = $(this).attr('href')
      $('#dataConfirmOK').attr('href', href)
      $('#dataConfirmModal').modal({ show: true })
      return false
    })
    $('#dataConfirmOK').on('confirm:complete', function (ev) {
      if ($(this).data('confirmed') === true) {
        chatChannel.endSession()
      } else {
        $('#dataConfirmModal').modal('hide')
      }
    })
    // show cheat sheet
    $('#note-submit').click(function () {
      var href = $('#noteForm').attr('href')
      var message = $('#note-message').val()
      var exerciseId = $('#exercise-id').val()
      var data = { message: message, exercise_id: exerciseId }
      $.post(href, data)
      $('#note-message').val('')
      $('#noteForm').modal('hide')
    })
    // -- TIMER BAR -- //
    function updateElapsedTime() {
      var elapsed = (Date.now() - timeAtPageLoadInMs) / 1000
      var totalTimeInSeconds = timerElapsedInSeconds + elapsed
      minutesLeft = Math.ceil(
        (timerDurationInSeconds - totalTimeInSeconds) / 60.0
      )
      graceSecondsLeft = Math.ceil(timerDurationInSeconds - totalTimeInSeconds)

      var percent = (totalTimeInSeconds / timerDurationInSeconds) * 100
      if (exerciseWarningTime && options.showFiveMinuteWarning) {
        if (Date.now() > exerciseWarningTime) {
          $('#runningLong').show()
        } else {
          $('#runningLong').hide()
        }
      }
      // when 45 minutes are over, minutesLeft becomes -0 and 0 === -0.
      if (minutesLeft <= 0) {
        if (updateElapsedTimeInterval) {
          // We no longer want to update the UI because the session is completed.
          window.clearInterval(updateElapsedTimeInterval)
        }
        percent = 100
        completeSession()
        return false
      }
      // Show red alert already when the 45th minute runs
      if (minutesLeft <= 1) {
        // only set once when the 44 minutes are over and the last minute runs, prevents from re-rendering every second
        if (!overtime) {
          overtime = true
          // Prompt users to rate the last exercise if they went overtime AND are
          // still not on the finish page AND are not on the starting page either.
          // Prevents chatty users from letting the lesson run out without giving a rating
          if (
            !document.querySelector('#curriculum .curriculum_finish-button') &&
            $('#firstExerciseButton').is(':hidden')
          ) {
            $('#nextExercise').modal('show')
          }
        }
        /* -- they're out of time, danger zone -- */
        $('#stopAlert').show()
        $('#wrapupAlert').hide()
        $('#gettingCloseAlert').hide()
        $('#orientationWrapupAlert').hide()
        $('#orientationGettingCloseAlert').hide()
      } else {
        if (sessionType === 'orientation') {
          // Orientations notification schedule
          if (
            chatRole === 'tutor' &&
            minutesLeft < 10 &&
            ['orientation-warmup', 'orientation-lesson'].includes(exerciseType)
          ) {
            // Firstly, a tutor-only prompt to ask the tutor to move on to the wrapup
            $('#orientationWrapupAlert').show()
          } else if (minutesLeft < 3) {
            // Finally, a prompt that both participants see, saying that time is nearly up
            $('#orientationWrapupAlert').hide()
            $('#orientationGettingCloseAlert').show()
          }
        } else {
          // General notification schedule
          if (minutesLeft < 5) {
            // Firstly, a prompt that time is nearly up
            $('#gettingCloseAlert').hide()
            $('#wrapupAlert').show()
          } else if (minutesLeft < 6) {
            // Finally, a prompt to wrapup their conversation
            $('#gettingCloseAlert').show()
          }
        }
      }
      $('.time-left').text(
        t('%{smart_count} minute |||| %{smart_count} minutes', {
          smart_count: minutesLeft,
        })
      )
      $('.seconds-left').text(
        t('%{smart_count} second |||| %{smart_count} seconds', {
          smart_count: graceSecondsLeft,
        })
      )

      renderReactComponent(
        { complete: percent / 100, my: 0 },
        '.study-nav__study-progress-bar-container',
        { component: ProgressBar }
      )
    }
    updateElapsedTime()
    const updateElapsedTimeInterval = window.setInterval(
      updateElapsedTime,
      1000
    ) // update timer bar every second
    // -- MESSAGES -- //
    // report a note for an exercise
    $('#note-submit').click(function () {
      var href = $('#noteForm').attr('href')
      var message = $('#note-message').val()
      var exerciseId = $('#exercise-id').val()
      var data = { message: message, exercise_id: exerciseId }
      $.post(href, data)
      $('#note-message').val('')
      $('#noteForm').modal('hide')
    })
    // -- NEXT EPISODE -- //
    $('#nextExercise').on('show.bs.modal', function (event) {
      exerciseRating.reset()
      if (studentRating) {
        studentRating.reset()
      }
      tutorRateStudent = null
      tutorRateExercise = null
    })
    function handleTeacherStudentRating(ratingValue) {
      tutorRateStudent = ratingValue
    }
    function handleTeacherExerciseRating(ratingValue) {
      tutorRateExercise = ratingValue
    }
    function handleTeacherRating() {
      if (tutorRateStudent !== null) {
        sendTeacherRatings(tutorRateStudent, tutorRateExercise)
        $('#nextExercise').modal('hide')
      }
    }
    $('#nextExercise-done').click(handleTeacherRating)
    function handleStudentRating(ratingValue) {
      triggerNextExercise(ratingValue)
      $('#nextExercise').modal('hide')
    }
    function triggerNextExercise(ratingValue) {
      showExerciseLoading()
      var info = {
        stars: ratingValue,
        exercise: exerciseId,
        session: sessionId,
      }
      if (overtime) {
        $.post('/chat/rate_exercise_by_student', info)
      }
      chatChannel.nextExercise(info)
    }
    function sendTeacherRatings(studentRating, exerciseRating) {
      // The student advances the exercise, so here we will be on the next
      // exercise: sooo use lastExerciseId
      var info = {
        rating: studentRating,
        exerciseRating: exerciseRating,
        exerciseId: lastExerciseId,
        session: sessionId,
      }
      $.post('/chat/rate_exercise', info)
    }
    function showExerciseLoading() {
      $('#curriculum')
        .addClass('curriculum_loading')
        .html(`<h2>${t('Loading next exercise...')}</h2>`)
    }
    function showExerciseError() {
      $('#curriculum').html(`<h2>${t("Uh-oh, we've had an error...")}</h2>`)
    }
    // -- Helper functions -- //
    function handleStartNextExercise(chatChannel) {
      lastExerciseId = exerciseId
      if (
        lastExerciseId &&
        chatRole === 'teacher' &&
        sessionType !== 'orientation'
      ) {
        $('#nextExercise').modal('show')
      }
      if (minutesLeft < 5) {
        var finished = $('#finished').html()
        $('#curriculum').html(finished)
        $('#curriculum .curriculum_finish-button').click(function () {
          chatChannel.endSession()
          completeSession()
        })
        $('.l-chat-lesson-room__actions').hide()
        return false
      }
      showExerciseLoading()
      $.post('/chat/next_exercise', { sessionId: sessionId }, function (data) {
        $('#curriculum').html(data)
        let averageDuration =
          Number.parseFloat($('#average-duration').val()) + 2.0 // 2 minutes longer than the average
        exerciseWarningTime = Date.now() + averageDuration * 1000 * 60 // convert duration into ms
        $('#runningLong').hide()
        exerciseId = $('#exercise-id').val()
        exerciseType = $('#exercise-type').val()
        exerciseIndex = parseInt($('#exercise-index').val())
        exerciseCount = parseInt($('#exercise-count').val())
        let exerciseName = $('#exerciseName').text()
        $('#preExerciseName').text(exerciseName)
        if (sessionType === 'orientation' && chatRole === 'teacher') {
          $('#tutorNextExerciseButton').toggle(
            exerciseIndex !== exerciseCount - 1
          )
          $('#jumpToWrapupButton').toggle(exerciseType !== 'orientation-wrapup')
        }
        $('#curriculum .interactive-image').interactiveImage()
        watchExamples()
      }).fail(showExerciseError)
    }
    function handleJumpToWrapup(chatChannel) {
      showExerciseLoading()
      $.post(
        '/chat/next_exercise',
        {
          exerciseType: 'orientation-wrapup',
          sessionId: sessionId,
        },
        function (data) {
          $('#curriculum').html(data)
          exerciseId = $('#exercise-id').val()
          exerciseType = $('#exercise-type').val()
          let exerciseName = $('#exerciseName').text()
          $('#preExerciseName').text(exerciseName)
          $('#jumpToWrapupButton').hide()
          $('#curriculum .interactive-image').interactiveImage()
          watchExamples()
        }
      ).fail(showExerciseError)
    }
    function endSession() {
      $('#wrapupAlert').text(
        t('The other party has ended the session. One moment while we wrap up.')
      )
      $('#wrapupAlert').show()
      window.setTimeout(completeSession, 3000)
    }
    function toggleTutorControls(visible) {
      const canShow =
        visible && sessionType === 'orientation' && chatRole === 'teacher'
      $('#jumpToWrapupButton').toggle(canShow)
      $('#tutorNextExerciseButton').toggle(canShow)
    }
    function completeSession() {
      if (completeSessionCalled) {
        window.Sentry.captureMessage(
          `completeSession has been called more than once`
        )
      } else {
        completeSessionCalled = true
        redirectToHREF('/chat/complete/' + sessionId)
      }
    }
    // -- CHEAT SHEET -- //
    function watchExamples() {
      let sheetHidden = true
      $('#view-cheat-sheet').click(function () {
        if (sheetHidden) {
          $('#view-cheat-sheet').text(t('Hide Examples'))
          sheetHidden = false
        } else {
          $('#view-cheat-sheet').text(t('View Examples'))
          sheetHidden = true
        }
        $('#cheat-examples').toggle()
      })
    }
  }
}
