import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'
import { Text } from '@chatterbug/aaron'

import LanguageContainer from './subcomponents/LanguageContainer/LanguageContainer'

const ADD_LEARNING_LANGUAGE = gql`
  mutation ADD_LEARNING_LANGUAGE(
    $languageCode: LanguageEnum!
  ) {
    response: updateUserLearningLanguage(
      languageCode: $languageCode
    ) {
      errors {
        code
        message
      }
    }
  }
`

const LanguageSelector = ({ languageCardList }) => {
  const [
    addUserLearningLanguage,
    { loading: addUserLearningLanguageLoading },
  ] = useMutation(ADD_LEARNING_LANGUAGE)

  const [errorMessage, setErrorMessage] = useState(null)

  const selectLanguage = async (languageCode) => {
    if (addUserLearningLanguageLoading) {
      return
    }

    try {
      const options = {
        variables: {
          languageCode: languageCode
        }
      }

      const mutationResponse = await addUserLearningLanguage(options)

      if (
        !mutationResponse.data?.response ||
        mutationResponse.data?.response?.errors?.length
      ) {
        setErrorMessage(t(
          'Error while adding new language. Please refresh and try again later.'
        ))
      } else {
        window.eventTracking.track('language', 'add language', languageCode)
        window.location = routes.home_student({ code: languageCode })
      }
    } catch (error) {
      console.log(error)
      setErrorMessage(t(
        'Error while adding new language. Please refresh and try again later.'
      ))
    }
  }

  if (errorMessage) {
    return <Text>{errorMessage}</Text>
  }

  return (
    <LanguageContainer
      languageCardList={languageCardList}
      selectLanguage={selectLanguage}
    />
  )
}

LanguageSelector.propTypes = {
  languageCardList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
}

export default LanguageSelector
