import _ from 'lodash'

import { bindMethods } from 'lib/util'

const IntervalMillis = 10000

export default class ConnectionQuality {
  constructor(options) {
    bindMethods(this)
    this.options = _.defaults({}, options, {
      subscriber: null,
      intervalMillis: IntervalMillis,
      onUpdate: (stats) => {},
    })
    this.setSubscriber(options.subscriber)
    this.start()
  }

  setSubscriber(subscriber) {
    this.lastStatsEvent = null
    this.subscriber = subscriber
    this.fetchStats()
  }

  stop() {
    clearInterval(this.interval)
  }

  start() {
    this.interval = window.setInterval(
      this.fetchStats,
      this.options.intervalMillis
    )
    this.fetchStats()
  }

  fetchStats() {
    if (this.subscriber) {
      try {
        this.subscriber.getStats((error, stats) => {
          if (error) {
            console.error('getStats error', error.message)
          } else {
            this.handleNewStats(stats)
          }
        })
      } catch (err) {
        console.error('fetchStats exception', err)
      }
    }
  }

  handleNewStats(statsEvent) {
    // https://tokbox.com/developer/sdks/js/reference/Subscriber.html#getStats
    // An event; bytes, packets, packetsLost are total for the subscriber session:
    //
    // timestamp:1497303282552
    // audio:
    //   bytesReceived:29013
    //   packetsLost:0
    //   packetsReceived:300
    // video:
    //   bytesReceived:435848
    //   frameRate:30
    //   packetsLost:0
    //   packetsReceived:450
    //
    // This will derive some stats based on the current event and the last one.
    // The result will look like this:
    //
    // {
    //   audio: {
    //     bytesReceived: 234543,  // Total number of audio bytes received by the subscriber since connection
    //     packetsReceived: 9828,  // Total number of audio packets received by the subscriber
    //     packetsLost: 100,       // Total audio packets that did not reach the subscriber since connection
    //     bytesPerSecond: 4321.5, // Based on bytesReceived from last and current event
    //     packetLossPercent: 1    // packetsLostDelta / packetsReceivedDelta; deltas calculated from last and current event
    //   },
    //   video: {
    //     frameRate: 30,
    //     packetsLost: 208,
    //     packetsReceived: 91134,
    //     bytesReceived: 89780470,
    //     bytesPerSecond: 65881.5,
    //     packetLossPercent: 10.1
    //   },
    //   timestamp: 123123...,
    //   quality: 4
    // }

    if (this.lastStatsEvent) {
      const secondsDiff =
        (statsEvent.timestamp - this.lastStatsEvent.timestamp) / 1000
      _.assign(
        statsEvent.audio,
        calculateDerivativeStats(
          secondsDiff,
          this.lastStatsEvent.audio,
          statsEvent.audio
        )
      )
      _.assign(
        statsEvent.video,
        calculateDerivativeStats(
          secondsDiff,
          this.lastStatsEvent.video,
          statsEvent.video
        )
      )
    }

    this.lastStatsEvent = statsEvent
    statsEvent.quality = calculateQuality(statsEvent)
    this.options.onUpdate(statsEvent)
  }

  isQualityOK() {
    return (
      this.lastStatsEvent && this.lastStatsEvent.quality > ConnectionQuality.BAD
    )
  }
}

ConnectionQuality.UNKNOWN = 0
ConnectionQuality.BAD = 1
ConnectionQuality.OK = 2
ConnectionQuality.GOOD = 3
ConnectionQuality.GREAT = 4

// These calculations are based on video _download_ quality, and with them we
// are trying to infer upload quality. It is not perfect as one person's crappy
// upload connection can make the other person's download connection look poor.
//
// It seems the bytesPerSecond is the best proxy for quality.
//
// frameRate: Often when there one side has poor bytesPerSecond download, it
// will reduce the framerate of the _other_ side, so framerate isnt super
// reliable. E.g. in this, the student has decent internet while the tutor's is
// terrible, but it is reducing the frameRate of the student's
//
// {video: {frameRate: 24, bytesPerSecond: 4376.662333766623, packetLossPercent:0}, timestamp:1498673269192, user: 'tutor'}
// {video: {frameRate: 10, bytesPerSecond: 44138.15526210484, packetLossPercent:0}, timestamp:1498673270383, user: 'student'}
//
// packetLossPercent: There doesnt seem to be much of a correlation between
// packets lost and either frameRate or bytesPerSecond. But it does seem that if
// there is prolonged packet loss on one side, that side is having issues.
export function calculateQuality(stats) {
  let downloadQuality = ConnectionQuality.UNKNOWN
  let packetQuality = ConnectionQuality.UNKNOWN

  if (stats.video && stats.video.bytesPerSecond) {
    const { bytesPerSecond, packetLossPercent } = stats.video

    // They say you need 300kbps for a 'stable' stream:
    // https://tokbox.com/developer/requirements/
    // Based on testing, it gets fuzzy around 70kbps, and maxes out around 720kbps
    const min = 7000 // 56kbps
    const max = 50000 // 400kbps
    downloadQuality = downloadSpeedQuality(bytesPerSecond, min, max)
    packetQuality = packetLossQuality(packetLossPercent)
  } else if (stats.audio && stats.audio.bytesPerSecond) {
    const { bytesPerSecond, packetLossPercent } = stats.audio
    // This is harder to determine from just audio stats. We need to rely on
    // audio stats if a user has video turned off.
    const min = 1000 // 8kbps
    const max = 5000 // 40kbps
    downloadQuality = downloadSpeedQuality(bytesPerSecond, min, max)
    packetQuality = packetLossQuality(packetLossPercent)
  }

  return Math.min(downloadQuality, packetQuality)
}

export function downloadSpeedQuality(bytesPerSecond, min, max) {
  const quality = linearMap(
    bytesPerSecond,
    min,
    max,
    ConnectionQuality.BAD,
    ConnectionQuality.GREAT
  )
  return Math.round(quality)
}

export function packetLossQuality(packetLossPercent) {
  if (packetLossPercent < 1) return ConnectionQuality.GREAT

  const minPercent = 0
  const maxPercent = 50
  const q =
    ConnectionQuality.GREAT -
    linearMap(
      packetLossPercent,
      minPercent,
      maxPercent,
      ConnectionQuality.BAD,
      ConnectionQuality.GOOD
    )
  return Math.round(q)
}

export function linearMap(value, fromMin, fromMax, toMin, toMax) {
  const newValue = toMin + ((value - fromMin) / fromMax) * toMax
  return _.clamp(newValue, toMin, toMax)
}

function calculateDerivativeStats(secondsDiff, lastStats, stats) {
  const dStats = {}

  // Sometimes the stats won't come through. Just ignore if that's the case.
  if (stats && lastStats) {
    dStats.bytesPerSecond = _.round(
      (stats.bytesReceived - lastStats.bytesReceived) / secondsDiff,
      1
    )

    const deltaPackets = stats.packetsReceived - lastStats.packetsReceived
    dStats.packetLossPercent = _.round(
      deltaPackets > 0
        ? ((stats.packetsLost - lastStats.packetsLost) / deltaPackets) * 100
        : 0,
      1
    )
  }

  return dStats
}
