import React from 'react'

export default () => {
  return (
    <svg
      style={{ borderRadius: '100px' }}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6881 1.37491C22.0378 2.9557 25.9084 9.4166 24.3372 15.8046C22.7667 22.1943 16.3475 26.0897 9.99893 24.509C3.65052 22.9279 -0.221792 16.468 1.34973 10.0788C2.92024 3.69033 9.33972 -0.207142 15.6881 1.37491Z"
        fill="#5579EF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6881 1.37491C22.0378 2.9557 25.9084 9.4166 24.3372 15.8046C22.7667 22.1943 16.3475 26.0897 9.99893 24.509C3.65052 22.9279 -0.221792 16.468 1.34973 10.0788C2.92024 3.69033 9.33972 -0.207142 15.6881 1.37491Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="28.2777"
          y="-0.348389"
          width="29.617"
          height="9.96339"
          transform="rotate(90 28.2777 -0.348389)"
          fill="#E25A63"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5131 -0.348389L18.5131 29.2686L7.23164 29.2686L7.23164 -0.348389L18.5131 -0.348389Z"
          fill="white"
        />
        <rect
          x="7.82129"
          y="-0.348389"
          width="29.617"
          height="9.96339"
          transform="rotate(90 7.82129 -0.348389)"
          fill="#4A58A4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.17115 13C1.17115 19.6274 6.50542 25 13.0856 25C19.6657 25 25 19.6274 25 13C25 6.37258 19.6657 1 13.0856 1C6.50542 1 1.17115 6.37258 1.17115 13Z"
        stroke="black"
      />
    </svg>
  )
}
