import _ from 'lodash'

import Module from 'lib/module'
import ChatChannel from 'lib/actioncable/chat-channel'
import { dataAttrs } from 'lib/util'
import { t } from 'src/lib/i18n'

export default class ChatPresenceMessage extends Module {
  constructor(element, options) {
    super(element, options)
    this.options = _.defaults({}, options, {
      chatChannelReplay: null,
    })

    let { sessionId, userEmail } = dataAttrs(element)
    this.sessionId = sessionId
    this.userEmail = userEmail

    if (this.options.chatChannelReplay) {
      this.chatChannel = this.options.chatChannelReplay
      this.handlePresenceEvent({})
    } else if (this.sessionId) {
      this.chatChannel = ChatChannel.get(this.sessionId)
      this.chatChannel.on('connect', () => this.chatChannel.getPresence())
    }

    this.chatChannel.on('presence', this.handlePresenceEvent)
  }

  handlePresenceEvent(data) {
    let presence = data[this.userEmail] ? 'available' : 'away'
    this.render({ presence })
  }

  render(state) {
    let { presence } = state
    if (presence === 'away') {
      this.element.textContent = t('partner is offline')
    } else if (presence === 'available') {
      this.element.textContent = t('partner is online')
    }
  }
}

ChatPresenceMessage.className = 'chat-presence-message'
ChatPresenceMessage.selector = `.${ChatPresenceMessage.className}`
