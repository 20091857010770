export default {
  chatterbug: {
    cb_MMMD: 'D MMM', // 8 Mai
    cb_MMMDYYYY: 'D MMM YYYY', // 8 Mai 2017
    cb_MMMYYYY: 'MMM YYYY', // Mai 2017
    cb_dddMMMD: 'ddd D MMM', // lu 8 jan
    cb_ddddMMMD: 'dddd D MMM', // lundi 8 jan
    cb_hmma: 'HH[h]mm', // 15h00
    cb_hmma_alt: 'h:mma', // 3:00pm
    cb_MMMDYYYYhmma: 'D MMM YYYY HH[h]mm', // 8 jan 2017 15h00
    cb_MMMDYYYYhmma_alt: 'D MMM YYYY h:mma', // 8 jan 2017 3:00pm
  },
}
