import React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="12"
        y1="5.5"
        x2="12"
        y2="18.5"
        stroke="#303030"
        strokeLinecap="round"
      />
      <path d="M18.5 12L5.5 12" stroke="#303030" strokeLinecap="round" />
    </svg>
  )
}
