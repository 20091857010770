import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chatterbug/aaron'

const PopupContainer = ({ containerStyle, popupStyle, onCancel, children }) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        position: 'absolute',
        height: 80,
        width: 220,
        bottom: 10,
        zIndex: 99,
        // Box doesn't accept all css props
        ...containerStyle,
      }}
      // now overwrite with what Box accepts
      {...containerStyle}
      onMouseLeave={onCancel}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 230,
          left: 0,
          bottom: 0,
          paddingX: '1x',
          display: 'flex',
          borderRadius: '0,5x',
          boxShadow: '0 4px 8px rgba(153, 160, 172, 0.18)',
          backgroundColor: 'white100',
        }}
        {...popupStyle}
      >
        {children}
      </Box>
    </Box>
  )
}

PopupContainer.propTypes = {
  onCancel: PropTypes.func,
  containerStyle: PropTypes.object,
  popupStyle: PropTypes.object,
  children: PropTypes.object,
}

export default PopupContainer
