import { Provider } from 'telco'

export default class LayoutChatLocalRoom {
  constructor(element, options) {
    Provider.initializePreview(options.telcoOptions)

    $('.js-goodbye-button').click(() => {
      $.post('/chat/next_exercise', (data) => {
        $('#curriculum').html(data)
      })
      return false
    })
  }
}
