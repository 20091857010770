/* eslint-disable no-new */
import LayoutExercisePreview from './l-exercise-preview'

export default class LayoutChatSessionPreview {
  constructor(element, options) {
    new LayoutExercisePreview(element, options)
    $(element)
      .find('#customize-plan')
      .on('click', function(e) {
        e.preventDefault()
        $(this).hide()
        $('.l-chat-session-preview__change-plan').show()
      })
  }
}
