import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@chatterbug/aaron'

const Filler = ({ completed }) => {
  return (
    <Box
      borderRadius="0,5x"
      bg="#2D7341"
      width={`${completed}%`}
      height="100%"
    />
  )
}
const AchievementProgressBar = ({ completed }) => {
  return (
    <Box height="8px" bg="#40A45D" borderRadius="0,5x">
      <Filler completed={completed} />
    </Box>
  )
}

AchievementProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
}

export default AchievementProgressBar
