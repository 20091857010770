import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'

const SECONDS_TO_LL = 5
const POLL_INTERVAL_SECONDS = 5 * 60 // every 5 minutes
const POLL_INTERVAL = POLL_INTERVAL_SECONDS * 1000

export const SESSIONS_FOR_TODAY = gql`
  query SESSIONS_FOR_TODAY {
    currentUser {
      upcomingAppointments(
        status: confirmed
        startedSinceMinutes: 40
        startsInMinutes: 1440
      ) {
        id
        chatSessionId
        startsInSeconds
      }
    }
  }
`

const LoadLiveLessonNotification = React.memo(({ onShowSnackbar }) => {
  const snackbarTimer = useRef()
  const redirectTimer = useRef()

  const response = useQuery(SESSIONS_FOR_TODAY, {
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
  })

  const { data } = response
  const appointments = data?.currentUser?.upcomingAppointments || []

  useEffect(() => {
    clearTimeout(snackbarTimer.current)
    clearTimeout(redirectTimer.current)

    if (appointments?.length) {
      let upcomingAppointment = appointments[0]

      const MS_TO_SNACKBAR = Math.max(
        (upcomingAppointment.startsInSeconds - SECONDS_TO_LL) * 1000,
        0
      )
      const MS_TO_REDIRECT = SECONDS_TO_LL * 1000

      snackbarTimer.current = setTimeout(() => {
        const url = routes.chat_wait({ id: upcomingAppointment.chatSessionId })

        onShowSnackbar({
          type: 'info',
          keepVisible: true,
          message: t(
            'Your Lesson starts now! We will redirect you in 5 seconds.'
          ),
          link: {
            label: t('Join now'),
            url,
          },
        })
        redirectTimer.current = setTimeout(() => {
          if (!document.hidden) {
            // redirect only in the visible browser tab
            window.location.href = url
          }
        }, MS_TO_REDIRECT)
      }, MS_TO_SNACKBAR)
    }

    return () => {
      clearTimeout(snackbarTimer.current)
      clearTimeout(redirectTimer.current)
    }
  }, [appointments, onShowSnackbar])

  return null
})

LoadLiveLessonNotification.propTypes = {
  onShowSnackbar: PropTypes.func.isRequired,
}

export default LoadLiveLessonNotification
