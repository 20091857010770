import LiveDate from 'src/components/LiveDate'

export default class LayoutLevelProgress {
  constructor(element) {
    this.element = element

    $('.level-progress__vocab').each(function(i) {
      var url = $(this).data('url')
      $(this).load(url, () => {
        LiveDate.mountAll({}, $(this)[0])
        $('[data-toggle="tooltip"]').tooltip({ container: 'body' })
      })
    })
  }
}
