/* eslint-disable no-new */
import { bindMethods } from 'lib/util'
import Preferences from 'lib/preferences'
import ComponentState from 'lib/component-state'
import MobileNumbers from 'modules/mobile-numbers'
import NotificationPreferences from 'modules/notification-preferences'
import DesktopNotificationEnabler from 'modules/desktop-notification-enabler'

export default class LayoutNotifications {
  constructor(element) {
    bindMethods(this)
    this.element = element
    this.componentState = new ComponentState({
      onSetState: this.render.bind(this),
    })
    this.notificationPreferences = new NotificationPreferences(
      element.querySelector(NotificationPreferences.selector)
    )

    new DesktopNotificationEnabler(
      element.querySelector(DesktopNotificationEnabler.selector)
    )
    new MobileNumbers(element.querySelector(MobileNumbers.selector), {
      onUpdateItems: this.handleUpdateMobileNumbers,
    })

    let setPreferences = (preferences) => this.setPreferences(preferences)
    Preferences.instance().then(setPreferences)
    Preferences.on('change', setPreferences)
  }

  handleUpdateMobileNumbers(items) {
    this.componentState.set({ mobileNumbers: items })
  }

  setPreferences(preferences) {
    let desktopEnabled = preferences.get('notifications.desktop_enabled')
    this.componentState.set({ desktopEnabled })
  }

  render(state) {
    this.notificationPreferences.render(state)
  }
}
