import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'src/lib/i18n-react'
import { Flex, Box, Image, Button } from '@chatterbug/aaron'

import illustration404 from './assets/404.svg'
import illustration500 from './assets/500.svg'

export const ERROR_CODE_404 = 404
export const ERROR_CODE_400 = 400 // errors in GraphQL request
export const ERROR_CODE_500 = 500 // js errors

const redirectToHome = () => {
  window.location.href = '/'
}

const ERRORS = {
  [ERROR_CODE_404]: {
    image: illustration404,
    title: t('404'),
    message: t('This page isn’t available.'),
    button: t('Back home'),
    onClick: redirectToHome,
  },
  [ERROR_CODE_400]: {
    image: illustration500,
    title: t("Oops, we can't seem to fetch that."),
    message: t(
      'Our engineers are looking into it, but in the meantime try again.'
    ),
    button: t('Try again'),
  },
  [ERROR_CODE_500]: {
    image: illustration500,
    title: t('You have encountered an unexpected error.'),
    message: t(
      'We have been notified about and will fix it shortly. If you need help, please contact support.'
    ),
    button: t('Contact'),
    onClick: () => {
      window.Intercom?.(
        'showNewMessage',
        t(
          "Hey there, I've encountered an unexpected error, the development team was notified, but I need some immediate help."
        )
      )
    },
  },
}

const CONTAINER_STYLES = {
  minHeight: '90vh',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3x',
  textAlign: 'center',
  maxWidth: '66%',
  marginLeft: 'auto',
  marginRight: 'auto',
}

const DataFetchError = ({ errorCode, graphQLResponse, onClick }) => {
  let code = errorCode
  if (!code) {
    code = graphQLResponse?.error ? ERROR_CODE_400 : ERROR_CODE_404
  }
  const error = ERRORS[code]
  let handleClick = onClick || error.onClick

  if (!onClick && code === ERROR_CODE_400) {
    handleClick = graphQLResponse?.refetch
  }

  if (!handleClick) {
    // fallback
    handleClick = redirectToHome
  }

  return (
    <Flex sx={CONTAINER_STYLES}>
      <Image src={error.image} mb="3x" />
      <Box variant="text.h4" mb="1x">
        {error.title}
      </Box>
      <Box variant="text.paragraph">{error.message}</Box>
      <Box mt="3x">
        <Button type="primaryAdvanced" onClick={() => handleClick()}>
          {error.button}
        </Button>
      </Box>
    </Flex>
  )
}

DataFetchError.propTypes = {
  errorCode: PropTypes.oneOf([ERROR_CODE_400, ERROR_CODE_404, ERROR_CODE_500]),
  graphQLResponse: PropTypes.shape({
    data: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
  }),
  onClick: PropTypes.func,
}

export default DataFetchError
