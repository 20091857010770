/* global ReactRailsUJS */
export default class LayoutStudyCards {
  constructor(element) {
    this.element = element
    const Turbolinks = require('turbolinks')
    Turbolinks.start()

    document.addEventListener('turbolinks:load', (e) => {
      // `e.data.timing` is empty when it's the first `load` and we need this
      // tracking event to only fire once.
      if (Object.keys(e.data.timing).length === 0) {
        window.eventTracking.track('activity', 'start', 'cards')
      }

      // make sure the "next" button is focused so users can just hit "enter".
      let x = window.scrollX

      let y = window.scrollY
      this.element.querySelector('button').focus()
      window.scrollTo(x, y)
    })

    document.addEventListener('turbolinks:render', () => {
      ReactRailsUJS.detectEvents() // https://github.com/reactjs/react-rails#event-handling
    })
  }
}
