import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { Modal } from '@chatterbug/aaron/modal'

// this wrapper takes care of creating a portal
// this comp could also be in the ui folder, but
// if we want, we can move this logic to the Modal in '@chatterbug/aaron'
const ModalPortalWrapper = ({ children, ...rest }) => {
  const [node, setNode] = useState(null)
  useEffect(() => {
    const node = document.createElement('modal-portal')
    document.body.appendChild(node)
    setNode(node)

    return () => document.body.removeChild(node)
  }, [])
  if (!node) {
    return null
  }
  return ReactDOM.createPortal(<Modal {...rest}>{children}</Modal>, node)
}

export default ModalPortalWrapper
