import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@chatterbug/aaron'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'

import CoachMark from 'src/ui/CoachMark/CoachMark'
import MenuItem from './MenuItem/MenuItem'
import LivelessonIcon from './LivelessonIcon/LivelessonIcon'

const MenuItemGroup = ({
  isMobileView,
  sx,
  credits,
  textStyle,
  selectedCourse,
  showMediumNav,
  isStreamer,
  hideFlashcards,
}) => {
  return (
    <Grid
      columns={1}
      gap="2x"
      sx={sx}
      justifyItems={showMediumNav ? 'center' : 'initial'}
    >
      <MenuItem
        text={t('Home')}
        icon="Home"
        textStyle={textStyle}
        href={routes.advanced_home(selectedCourse)}
        showMediumNav={showMediumNav}
      />
      <MenuItem
        text={t('My Course')}
        icon="Progress"
        textStyle={textStyle}
        href={routes.advanced_my_course(selectedCourse)}
        showMediumNav={showMediumNav}
      />
      <LivelessonIcon
        credits={credits}
        textStyle={textStyle}
        selectedCourse={selectedCourse}
        showMediumNav={showMediumNav}
      />
      <MenuItem
        text={t('Achievements')}
        icon="Achievements"
        textStyle={textStyle}
        href={routes.advanced_achievements(selectedCourse)}
        showMediumNav={showMediumNav}
      />
      {hideFlashcards || (
        <CoachMark
          disabled={isMobileView}
          name="coach_mark:find_flashcards"
          title={t('Quick-access to Flashcards')}
          description={t(
            'Memorize words and grammar concepts with our spaced repetition flashcards.'
          )}
          align="above"
          spotlightSx={{
            borderRadius: '40px',
          }}
        >
          <MenuItem
            text={t('Flashcards')}
            icon="Cards"
            textStyle={textStyle}
            href={routes.cards(selectedCourse)}
            showMediumNav={showMediumNav}
          />
        </CoachMark>
      )}

      {isStreamer &&
      <>
        <box>
          <box
            width={{ _: '100%', laptop: '40px' }}
            borderwidth={1}
            bordercolor="gray88"
            borderstyle="solid"
          />
        </box>

        <MenuItem
          text={t('Streams')}
          icon="Livelesson"
          textStyle={textStyle}
          href={"/streams"}
          showMediumNav={showMediumNav}
        />
      </>
      }

      <box>
        <box
          width={{ _: '100%', laptop: '40px' }}
          borderwidth={1}
          bordercolor="gray88"
          borderstyle="solid"
        />
      </box>

      <MenuItem
        text={t('Search')}
        icon="Search"
        textStyle={textStyle}
        href={routes.advanced_search(selectedCourse)}
        showMediumNav={showMediumNav}
      />
      <MenuItem
        text={t('Community')}
        icon="Community"
        textStyle={textStyle}
        href="https://chatterbug.com/community/"
        showMediumNav={showMediumNav}
      />
      <MenuItem
        text={t('Help')}
        icon="Help"
        textStyle={textStyle}
        href="https://chatterbug.com/community/c/support/34"
        showMediumNav={showMediumNav}
      />
    </Grid>
  )
}

MenuItemGroup.propTypes = {
  isMobileView: PropTypes.bool,
  credits: PropTypes.number,
  textStyle: PropTypes.object,
  selectedCourse: PropTypes.string,
  showMediumNav: PropTypes.bool,
  isStreamer: PropTypes.bool,
  hideFlashcards: PropTypes.bool,
}

export default MenuItemGroup
