import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '@chatterbug/aaron'

import LanguagePanel from './subcomponents/LanguagePanel'

const LanguageCard = ({ code, label, onClick }) => {
  return (
    <Flex
      display="flex"
      justifyContent="center"
      boxShadow="overlay"
      p="1x"
      m="1x"
      borderRadius="0,5x"
      sx={{
        cursor: 'pointer',
      }}
    >
      <LanguagePanel
        key={code}
        onClick={() => {
          onClick(code)
        }}
        languageCode={code}
        localizedName={label}
      />
    </Flex>
  )
}

LanguageCard.propTypes = {
  code: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default LanguageCard
