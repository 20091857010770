import MarkedRange from 'modules/marked-range'
import { pluralizeHTML } from 'lib/util'

// Shows the goals form where they can choose hours per week and date fluent by.
//
// render() accepts: {
//   weekMaxHours: number,
//   dayMaxHours: number
// }

export default class TutorGoalsForm {
  constructor(element, options) {
    if (!element) return
    this.element = element
    this.options = options || {}

    this.activeEl = element.querySelector(`input[name="active"]`)
    this.pauseButtonEl = element.querySelector(
      `${TutorGoalsForm.selector}__pause-button`
    )
    if (this.activeEl && this.pauseButtonEl) {
      this.pauseButtonEl.addEventListener(
        'click',
        this.handleToggleActive.bind(this)
      )
    }

    this.weekRangeEl = element.querySelector(
      `${MarkedRange.selector}[data-name='week-max-hours']`
    )
    this.weekHoursRange = new MarkedRange(this.weekRangeEl, {
      onChange: this.handleChangeWeekRange.bind(this),
    })

    this.dayRangeEl = element.querySelector(
      `${MarkedRange.selector}[data-name='day-max-hours']`
    )
    this.dayHoursRange = new MarkedRange(this.dayRangeEl, {
      onChange: this.handleChangeDayRange.bind(this),
    })
  }

  getActiveValue() {
    return this.activeEl ? this.activeEl.value === 'true' : true
  }

  getValue() {
    let active = this.getActiveValue()
    let dayMaxHours = this.dayHoursRange.getValue()
    let weekMaxHours = this.weekHoursRange.getValue()
    return { weekMaxHours, dayMaxHours, active }
  }

  handleToggleActive() {
    let value = this.getValue()
    value.active = !value.active
    this.options.onChange(value)
  }

  handleChangeWeekRange(weekMaxHours) {
    let active = this.getActiveValue()
    let dayMaxHours = this.dayHoursRange.getValue()
    dayMaxHours = Math.min(dayMaxHours, weekMaxHours)
    this.options.onChange({ weekMaxHours, dayMaxHours, active })
  }

  handleChangeDayRange(dayMaxHours) {
    let active = this.getActiveValue()
    let weekMaxHours = this.weekHoursRange.getValue()
    weekMaxHours = Math.max(dayMaxHours, weekMaxHours)
    this.options.onChange({ weekMaxHours, dayMaxHours, active })
  }

  // do all dom updates in here
  render(state, prevState) {
    let { weekMaxHours, dayMaxHours, active } = state
    this.weekHoursRange.render(
      {
        value: weekMaxHours,
        html: renderHoursHTML(weekMaxHours),
      },
      {
        value: prevState.weekMaxHours,
        html: renderHoursHTML(prevState.weekMaxHours),
      }
    )
    this.dayHoursRange.render(
      {
        value: dayMaxHours,
        html: renderHoursHTML(dayMaxHours),
      },
      {
        value: prevState.dayMaxHours,
        html: renderHoursHTML(prevState.dayMaxHours),
      }
    )

    if (this.pauseButtonEl) {
      let newTitle = this.pauseButtonEl.getAttribute(`data-title-${active}`)
      if ($.fn.tooltip) $(this.pauseButtonEl).tooltip('hide')
      this.pauseButtonEl.setAttribute('data-original-title', newTitle)
      this.pauseButtonEl.textContent = this.pauseButtonEl.getAttribute(
        `data-text-${active}`
      )
      this.activeEl.value = active
    }
  }
}
TutorGoalsForm.className = 'tutor-goals-form'
TutorGoalsForm.selector = `.${TutorGoalsForm.className}`

function renderHoursHTML(hours) {
  if (hours == null) return null
  return pluralizeHTML(Math.round(hours), 'hour')
}
