import React from 'react'

import { t } from 'src/lib/i18n-react'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error) {
    console.error(error)
    window.Sentry?.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          <span>
            {t(
              'You have encountered an unexpected error. We have been notified about and will fix it shortly. If you need help, please'
            )}{' '}
            <a
              href="javascript:void(0)"
              onClick={() => {
                window.Intercom(
                  'showNewMessage',
                  t(
                    "Hey there, I've encountered an unexpected error, the development team was notified, but I need some immediate help."
                  )
                )
              }}
            >
              {t('contact support.')}
            </a>
          </span>
        </p>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
