import React from 'react'
import PropTypes from 'prop-types'
import { PaymentRequestButtonElement } from 'react-stripe-elements'

const PaymentRequestButton = ({ paymentRequest, onStripeResponse }) => {
  paymentRequest.on('token', onStripeResponse)

  return (
    <div>
      <PaymentRequestButtonElement
        paymentRequest={paymentRequest}
        style={{
          // For more details on how to style the Payment Request Button, see:
          // https://stripe.com/docs/elements/payment-request-button#styling-the-element
          paymentRequestButton: {
            theme: 'dark',
            height: '64px',
          },
        }}
      />
      <br />
    </div>
  )
}

PaymentRequestButton.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
  onStripeResponse: PropTypes.func.isRequired,
}

export default PaymentRequestButton
