import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { redirectToHREF, bindMethods, renderReactComponent } from 'lib/util'
import { Provider } from 'src/telco'
import ChatChannel from 'lib/actioncable/chat-channel'
import { t } from 'src/lib/i18n-react'
import LiveDate from 'src/components/LiveDate'
import PageAlert from 'src/components/PageAlert'
import ChatReadyHandshake from 'modules/chat-ready-handshake'
import ChatPresenceAvatar from 'modules/chat-presence-avatar'
import ChatPresenceMessage from 'modules/chat-presence-message'

// There is a 1 minute preroll before the official start time
const AudioLevelThreshold = 0.1
const NotifyTime = 5 // minutes
const RedirectTime = 10 // minutes
const ChatReadyState = {
  both: 'both-ready',
  other: 'other-ready',
}

export default class LayoutChatWaitingRoom {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.state = {}
    this.options = _.defaults({}, options, {
      notifyTime: NotifyTime,
      redirectTime: RedirectTime,
    })

    this.readyState = null
    this.partnerAvailable = null
    this.startTime = moment(this.options.startTime)

    ChatPresenceAvatar.createAll(element)
    ChatPresenceMessage.createAll(element)

    this.chatReadyHandshake = ChatReadyHandshake.create(element, {
      enabled: false,
      onChangeState: this.handleChatReadyStateChange,
    })

    this.initWaitingRoomTest()
    this.initChatChannel().then(() => {
      // We can only init the notifications after the chat channel has been
      // totally initialized. Otherwise, it could think no one is there, and
      // redirect to the no-show page if they enter after 10 minutes
      this.initLateNotification()
    })
  }

  setState(newState) {
    this.state = { ...this.state, ...newState }
    this.chatReadyHandshake.setEnabled(this.state.video && this.state.audio)
    renderReactComponent(this.state, '#wait-checklist')
  }

  initChatChannel() {
    let self = this
    let channel = (this.chatChannel = ChatChannel.get(this.options.sessionId))
    channel.on('presence', this.handlePresenceEvent)

    return new Promise(function(resolve) {
      function checkIfDone() {
        if (self.readyState != null && self.partnerAvailable != null) {
          channel.off('presence', checkIfDone)
          channel.off('getClientReady', checkIfDone)
          resolve()
        }
      }

      channel.on('connect', () => {
        channel.getPresence()
        channel.getClientReady()
        channel.on('presence', checkIfDone)
        channel.on('getClientReady', checkIfDone)
      })
    })
  }

  initWaitingRoomTest() {
    Provider.initialize(
      _.assign(this.options.telcoOptions, {
        sessionId: this.options.sessionId,
        testNetwork: true,
        onPublish: this.onPublish,
        onConnect: () => {
          this.log('Video connected')
          this.setState({ video: true })
        },
        onFailure: (error) => {
          this.log('Initializer failure: ' + JSON.stringify(error))
          this.setState({ video: false })
        },
        onChangePublisherAudioLevel: this.handleChangePublisherAudioLevel,
        onLog: this.log.bind(this),
      })
    )
  }

  handleChangePublisherAudioLevel = (audioLevel) => {
    renderReactComponent({ audioLevel }, this.options.localAudioMeter)
    if (this.state.audio || audioLevel < AudioLevelThreshold) {
      return
    }
    this.log('Audio connected')
    this.setState({ audio: true })
  }

  log() {
    if (this.chatChannel) {
      this.chatChannel.log(Array.prototype.slice.call(arguments).join(' '))
    }
    console.log(...arguments)
  }

  onPublish() {
    if (this.readyState === ChatReadyState.both) {
      this.log('Skipping bandwidth testing since both ready')
      this.setState({ bandwidth: true })
    } else {
      this.log('Beginning bandwidth testing')
      try {
        Provider.testNetwork(this.options.telcoOptions.roomId).then(
          (isQualityOK) => {
            this.setState({
              networkStatus: isQualityOK ? 'success' : 'failure',
            })
          }
        )
      } catch (err) {
        console.error('error in testNetwork (waiting-room)', err)
      }
    }
  }

  initLateNotification() {
    let now = this.now()
    let notifyTime = this.startTime
      .clone()
      .add(this.options.notifyTime, 'minutes')
    let redirectTime = this.startTime
      .clone()
      .add(this.options.redirectTime, 'minutes')

    window.setTimeout(this.handleLateNotify, Math.max(0, notifyTime.diff(now)))
    window.setTimeout(
      this.handleLateRedirect,
      Math.max(0, redirectTime.diff(now))
    )
  }

  now() {
    return moment()
  }

  isOtherUserReady() {
    let { readyState, partnerAvailable } = this
    readyState = readyState || this.chatReadyHandshake.getState()
    return (
      partnerAvailable ||
      readyState === ChatReadyState.both ||
      readyState === ChatReadyState.other
    )
  }

  hideLateNotification() {
    if (this.lateNotification) this.lateNotification.close()
    this.lateNotification = null
  }

  handleLateNotify() {
    if (this.isOtherUserReady()) return
    let redirectTime = this.startTime
      .clone()
      .add(this.options.redirectTime, 'minutes')
    let dateParams = {
      date: redirectTime.toISOString(),
      format: 'fromNowSimple',
      timezone: '',
    }
    let chargeText =
      this.options.role === 'student'
        ? t('you will not be charged for this lesson')
        : t('you will still be paid for this lesson')

    this.lateNotification = PageAlert.showAlert({
      type: 'warning',
      fixed: true,
      icon: 'chat-session',
      content: (
        <p>
          {t('It looks like your partner isn’t here yet.')}{' '}
          {t('Hang around for ')}
          <b>
            <LiveDate {...dateParams} />
          </b>
          {'. '}
          {t('If they don’t show up in this time, %{chargeText}', {
            chargeText,
          })}
        </p>
      ),
    })
  }

  handleLateRedirect() {
    if (this.isOtherUserReady()) return
    redirectToHREF(this.options.failUrl)
  }

  handlePresenceEvent(data) {
    this.partnerAvailable = !!data[this.options.partnerEmail]
    if (this.isOtherUserReady()) this.hideLateNotification()
  }

  handleChatReadyStateChange(newState) {
    this.readyState = newState
    if (this.isOtherUserReady()) this.hideLateNotification()

    if (newState === ChatReadyState.both) {
      redirectToHREF(this.options.nextUrl)
    }
  }
}

LayoutChatWaitingRoom.selector = '.l-chat-waiting-room'
