import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { Box } from '@chatterbug/aaron'

import { useProductTour } from 'src/lib/product-tour-context'
import { getAbsoluteRect } from 'src/ui/CoachMark/utils'

import Portal from './subcomponents/Portal/Portal'
import CoachMarkOverlay from './subcomponents/CoachMarkOverlay/CoachMarkOverlay'
import CoachMarkSpotlight from './subcomponents/CoachMarkSpotlight/CoachMarkSpotlight'
import CoachMarkPopup from './subcomponents/CoachMarkPopup/CoachMarkPopup'

const ALREADY_SEEN_IN_THIS_SESSION = {}

const CoachMark = ({
  disabled,
  name,
  title,
  description,
  align,
  trackIfInSpotlight,
  sx,
  spotlightSx,
  popupSx,
  children,
}) => {
  const context = useProductTour(name)
  const containerRef = useRef()
  const [rect, setRect] = useState()
  const [visible, setVisible] = useState(false)

  const handleSeen = () => {
    if (ALREADY_SEEN_IN_THIS_SESSION[name]) {
      return
    }
    // keep a cache inside the component to avoid rerenderings if we would have updated it in the context
    ALREADY_SEEN_IN_THIS_SESSION[name] = true
    context?.onSeen?.(name)
  }

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    setRect(getAbsoluteRect(containerRef.current.getBoundingClientRect()))
  }

  const handleConfirm = () => {
    setVisible(false)
  }

  const handleInteraction = visible ? handleConfirm : undefined

  useEffect(() => {
    setTimeout(() => handleResize(), 300)
    // in case if some just-loaded images push the content on the page
    setTimeout(() => handleResize(), 2000)
  }, [setRect, children])

  useEffect(() => {
    const isVisible = !ALREADY_SEEN_IN_THIS_SESSION[name] && context?.enabled

    if (isVisible && visible !== isVisible) {
      setVisible(isVisible)
    }
  }, [visible, setVisible, context])

  useEffect(() => {
    if (!visible || !containerRef.current) {
      return
    }

    const debounceHandleResize = debounce(handleResize, 300)
    handleResize()

    const observer = window.IntersectionObserver
      ? new window.IntersectionObserver(debounceHandleResize)
      : null
    observer?.observe(containerRef.current)
    window.addEventListener('resize', debounceHandleResize)

    return () => {
      observer?.unobserve(containerRef.current)
      window.removeEventListener('resize', debounceHandleResize)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      // mark as seen immediately after we show it, no clicks are required for it
      handleSeen()
    }
  }, [visible])

  if (disabled) {
    return children
  }

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          display: 'inline-block',
          ...sx,
        }}
        onClick={handleInteraction}
        onWheel={handleInteraction}
      >
        {trackIfInSpotlight && React.isValidElement(children)
          ? React.cloneElement(children, { inCoachMarkSpotlight: visible })
          : children}
      </Box>
      {visible && rect && (
        <Portal name={`coach-mark-${name}`}>
          <CoachMarkOverlay rect={rect} onHide={handleConfirm}>
            <CoachMarkSpotlight rect={rect} sx={spotlightSx} />
          </CoachMarkOverlay>
          <CoachMarkPopup
            rect={rect}
            title={title}
            description={description}
            align={align}
            sx={popupSx}
            onConfirm={handleConfirm}
          />
        </Portal>
      )}
    </>
  )
}

CoachMark.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['above', 'below']),
  sx: PropTypes.object,
  trackIfInSpotlight: PropTypes.bool,
  spotlightSx: PropTypes.object,
  popupSx: PropTypes.object,
}

export default CoachMark
