import _ from 'lodash'

import { renderTemplate, stringToHTMLElement } from 'lib/util'
import { t } from 'src/lib/i18n'

// You really only need to invoke this js if you want to update the progress bar
// in JS.

$.fn.studyProgressBar = function(options) {
  return $.map(this, function(element) {
    element.studyProgressBar = new StudyProgressBar(element, options)
    return element.studyProgressBar
  })
}

export default class StudyProgressBar {
  constructor(element, options) {
    this.bindHandlers()
    this.element = element
    this.options = _.defaults(options, {
      onScrub: () => {},
      onChange: () => {},
    })

    this.draggingClass = `${StudyProgressBar.className}_dragging`
    this.editableClass = `${StudyProgressBar.className}_editable`
    this.tickClass = `${StudyProgressBar.className}__tick`
    this.ticks = []

    let selector = StudyProgressBar.selector
    this.thumb = element.querySelector(`${selector}__thumb`)
    this.completed = element.querySelector(`${selector}__completed`)

    this.isEditable = element.classList.contains(this.editableClass)
    if (this.isEditable) {
      this.thumb.addEventListener('mousedown', this.handleThumbMouseDown)
      this.element.addEventListener('mousedown', this.handleBarMouseDown)
    }

    let state = _.pick(this.options, ['className', 'percent', 'title'])
    this.render(state)
  }

  render(state) {
    let { percent, title, className } = state
    if (className != null) this.setClassName(className)
    if (percent != null) this.setPercent(percent)
    if (title != null) this.setTitle(title)
  }

  addTick(percent) {
    let tick = renderTemplate(StudyProgressBar.tickTemplate, { percent })
    let tickEl = stringToHTMLElement(tick)
    this.element.appendChild(tickEl)
    this.ticks.push(tickEl)
    return tickEl
  }

  removeTicks() {
    for (let tick of _.toArray(this.ticks)) {
      tick.remove()
    }
    this.ticks = []
  }

  // private

  handleBarMouseDown(e) {
    let target = e.target
    let percent
    if (target === this.thumb) return

    if (target.classList.contains(this.tickClass)) {
      percent = parseFloat(target.getAttribute('data-percent'))
    } else {
      this.cacheBarDimensions()
      percent = this.percentFromEvent(e)
    }

    this.options.onChange(percent)
  }

  handleThumbMouseDown() {
    this.cacheBarDimensions()
    this.element.classList.add(this.draggingClass)
    window.addEventListener('mousemove', this.handleThumbMouseMove)
    window.addEventListener('mouseup', this.handleThumbMouseUp)
  }

  handleThumbMouseMove(e) {
    let percent = this.percentFromEvent(e)
    this.options.onScrub(percent)
  }

  handleThumbMouseUp(e) {
    this.element.classList.remove(this.draggingClass)
    window.removeEventListener('mousemove', this.handleThumbMouseMove)
    window.removeEventListener('mouseup', this.handleThumbMouseUp)
    let percent = this.percentFromEvent(e)
    this.options.onChange(percent)
  }

  bindHandlers() {
    this.handleThumbMouseDown = this.handleThumbMouseDown.bind(this)
    this.handleThumbMouseMove = this.handleThumbMouseMove.bind(this)
    this.handleThumbMouseUp = this.handleThumbMouseUp.bind(this)
    this.handleBarMouseDown = this.handleBarMouseDown.bind(this)
  }

  percentFromEvent(event) {
    return _.clamp(((event.clientX - this.left) / this.width) * 100.0, 0, 100)
  }

  cacheBarDimensions() {
    this.left = $(this.element).offset().left
    this.width = this.element.offsetWidth
  }

  setTitle(title) {
    this.element.setAttribute('title', title)
  }

  getTitle() {
    return this.element.getAttribute('title')
  }

  getPercent() {
    return parseFloat(this.element.getAttribute('data-percent'))
  }

  setPercent(percent) {
    percent = Math.min(percent, 100)
    this.element.setAttribute('data-percent', percent)
    this.completed.style.width = `${percent}%`
    if (this.thumb) this.thumb.style.left = `${percent}%`
    this.setTitle(
      t(`You are %{percent} through this study session`, {
        percent: Math.round(percent),
      })
    )
  }

  setClassName(className) {
    this.element.className = `${StudyProgressBar.className} ${className || ''}`
  }

  static renderHTML(templateOptions) {
    templateOptions = _.defaults(templateOptions, {
      title: '',
      percent: 0,
      className: '',
    })
    return renderTemplate(StudyProgressBar.template, templateOptions)
  }
}

StudyProgressBar.className = 'study-progress-bar'
StudyProgressBar.selector = `.${StudyProgressBar.className}`
StudyProgressBar.tickTemplate = `<div class="study-progress-bar__tick" style="left: {percent}%" data-percent="{percent}"></div>`
StudyProgressBar.template = `
<div class="study-progress-bar {className}"
  notranslate
  title="{title}"
  data-percent="{percent}">
  <div class="study-progress-bar__completed" style="width: {percent}%">
    <span class="sr-only">${t('Completed')}</span>
  </div>
  <div class="study-progress-bar__remaining">
    <span class="sr-only">${t('Remaining')}</span>
  </div>
</div>
`
