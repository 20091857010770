import React from 'react'
import PropTypes from 'prop-types'

import {
  EsFlag,
  DeFlag,
  EnFlag,
  FrFlag,
  TutorFlag,
  Progress,
  Livelesson,
} from '../MenuItem/assets/icons'

const Flag = ({ languageCode }) => {
  if (languageCode === 'de') {
    return <DeFlag />
  }
  if (languageCode === 'en') {
    return <EnFlag />
  }
  if (languageCode === 'es') {
    return <EsFlag />
  }
  if (languageCode === 'fr') {
    return <FrFlag />
  }
  if (languageCode === 'tutor') {
    return <TutorFlag />
  }
  if (languageCode === 'admin') {
    return <Progress />
  }
  if (languageCode === 'streamer') {
    return <Livelesson />
  }
  return null
}

Flag.propTypes = {
  languageCode: PropTypes.string,
}

export default Flag
