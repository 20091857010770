import _ from 'lodash'

import StudyProgressBar from 'modules/study-progress-bar'

export default class ReplayProgressBar {
  constructor(element, options) {
    this.bindHandlers()
    this.element = element
    this.options = _.defaults(options, {
      chatChannelReplay: null,
      onScrub: () => {},
      onChange: () => {},
    })

    let progressBarEl = element.querySelector(StudyProgressBar.selector)
    this.studyProgressBar = new StudyProgressBar(progressBarEl, {
      title: '0:00',
      onChange: this.handleProgressBarChange,
      onScrub: this.handleProgressBarScrub,
    })
    this.setChatChannelReplay(this.options.chatChannelReplay)
  }

  setChatChannelReplay(chatChannelReplay) {
    if (this.chatChannelReplay) {
      this.studyProgressBar.removeTicks()
      this.chatChannelReplay.off('change-time', this.handleChangeTime)
      this.chatChannelReplay.off('stop', this.handleStop)
    }

    this.chatChannelReplay = chatChannelReplay
    chatChannelReplay.on('change-time', this.handleChangeTime)
    chatChannelReplay.on('stop', this.handleStop)

    let nextExerciseEvents = chatChannelReplay.getFilteredEvents((e) => {
      return (
        e.event && e.event.type === 'nextExercise' && e.event.exercise != null
      )
    })
    for (let event of nextExerciseEvents) {
      let percent = chatChannelReplay.millisToPercent(
        chatChannelReplay.timestampToMillis(event.timestamp)
      )
      this.studyProgressBar.addTick(percent)
    }
  }

  bindHandlers() {
    this.handleStop = this.handleStop.bind(this)
    this.handleChangeTime = _.throttle(this.handleChangeTime.bind(this), 250, {
      trailing: true,
    })
    this.handleProgressBarScrub = this.handleProgressBarScrub.bind(this)
    this.handleProgressBarChange = this.handleProgressBarChange.bind(this)
  }

  handleStop() {
    this.handleChangeTime.cancel() // important or will call after the 0 time render
    this.render({ currentMillis: this.chatChannelReplay.currentMillis })
  }

  // The user moved the progress bar and stopped scrubbing
  handleProgressBarScrub(percent) {
    this.scrubbing = true
    this.emitChange(percent, this.options.onScrub)
  }

  handleProgressBarChange(percent) {
    this.scrubbing = false
    this.emitChange(percent, this.options.onChange)
  }

  emitChange(percent, callback) {
    let currentMillis = this.chatChannelReplay.percentToMillis(percent)
    this.render({ currentMillis })
    callback(currentMillis)
  }

  handleChangeTime({ currentMillis }) {
    if (this.scrubbing) return
    this.render({ currentMillis })
  }

  render({ currentMillis }) {
    let percent = this.chatChannelReplay.millisToPercent(currentMillis)

    let seconds = Math.round(currentMillis / 1000)
    let minutes = parseInt(seconds / 60)
    let displaySeconds = seconds % 60
    if (displaySeconds < 10) displaySeconds = '0' + displaySeconds
    this.studyProgressBar.render({
      percent,
      title: `${minutes}:${displaySeconds}`,
    })
  }
}

ReplayProgressBar.className = 'replay-progress-bar'
ReplayProgressBar.selector = `.${ReplayProgressBar.className}`
