import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Grid } from '@chatterbug/aaron'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'

import UserIcon from '../UserIcon/UserIcon'
import MenuItem from '../MenuItem/MenuItem'
import CourseList from '../CourseList/CourseList'

const UserTool = ({
  isModalOpen,
  onCloseModal,
  handleOpenLanguageModal,
  avatarUrl,
  user: { login },
  courses,
  selectedCourse,
  textStyle,
  eligibleLearningLanguages,
  isTutor,
  orgName,
}) => {
  return (
    <Grid
      columns={1}
      gap={{ _: '3x', tabletWide: '1x' }}
      fontSize={{ _: 'md', tabletWide: 'sm' }}
      sx={{
        width: '100%',
        marginY: '1x',
      }}
    >
      <Flex
        height="6x"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="1,5x"
        sx={{
          textAlign: 'center',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: '#F3F3F3',
          },
          paddingX: '1x',
        }}
      >
        <UserIcon
          href={`/${login}`}
          avatarUrl={avatarUrl}
          username={login}
          textStyle={{
            marginLeft: '2x',
            maxWidth: '130px',
            ...textStyle,
          }}
        />
      </Flex>
      <MenuItem
        text={t('Settings')}
        textStyle={textStyle}
        showToolTipOnTablet={false}
        href={routes.account()}
        sx={{
          paddingX: '2x',
        }}
      />
      <MenuItem
        text={t('Referrals')}
        textStyle={textStyle}
        showToolTipOnTablet={false}
        href={routes.referrals_index()}
        sx={{
          paddingX: '2x',
        }}
      />
      <MenuItem
        text={t('Payments')}
        textStyle={textStyle}
        showToolTipOnTablet={false}
        href={routes.payments()}
        sx={{
          paddingX: '2x',
        }}
      />
      <Box
        sx={{
          borderBottom: '1px #E0E0E0 solid',
          width: '100%',
        }}
        display={{ _: 'flex', tabletWide: 'none' }}
      />
      <CourseList
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        onClick={handleOpenLanguageModal}
        selectedCourse={selectedCourse}
        courses={courses}
        textStyle={textStyle}
        eligibleLearningLanguages={eligibleLearningLanguages}
        courseStyle={{ display: { _: 'grid', tabletWide: 'none' } }}
        isTutor={isTutor}
        orgName={orgName}
      />
      <Box
        sx={{
          borderBottom: '1px #E0E0E0 solid',
          width: '100%',
        }}
      />
      <MenuItem
        sx={{ paddingX: '2x' }}
        text={t('Logout')}
        textStyle={textStyle}
        showToolTipOnTablet={false}
        href={routes.logout()}
      />
    </Grid>
  )
}

UserTool.defaultProps = {
  avatarUrl:
    'https://secure.gravatar.com/avatar/68ed22595ebf8782807a529c87727e3a?default=retro&secure=true&size=210',
  user: {
    login: 'coolUser123',
  },
}

UserTool.propTypes = {
  avatarUrl: PropTypes.string,
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  eligibleLearningLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedCourse: PropTypes.string.isRequired,
  textStyle: PropTypes.object,
  handleOpenLanguageModal: PropTypes.func,
  isTutor: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  orgName: PropTypes.string,
}

export default UserTool
