import { get } from 'lib/util'
import CurrencyPicker from 'modules/currency-picker'

export default class LayoutTutorPaymentSetup {
  constructor(element) {
    this.element = element
    CurrencyPicker.create(element, {
      onChange: this.handleCurrencyChange.bind(this),
    })
  }

  handleCurrencyChange(currencyCode) {
    get(`/payments/tutor/rates/${currencyCode}`).then(({ data }) => {
      $('#payment-rates').html(data)
    })
  }
}
