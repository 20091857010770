import React, { Suspense, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chatterbug/aaron'

import LoadBreadcrumbs from 'src/ui/Breadcrumbs/LoadBreadcrumbs'
import PageLayout, { PAGE_GAP } from 'src/ui/PageLayout/PageLayout'
import Snackbar from 'src/ui/Snackbar/Snackbar'
import { storePageParams } from 'src/utils/params'

import LoadPageErrorBoundary from './LoadPageErrorBoundary'
import LoadLiveLessonNotification from './LoadLiveLessonNotification'
import LoadProductTour from './LoadProductTour'
const HIDDEN_SNACKBAR = { show: false }

const LAYOUT_WITH_HEADER = {
  pt: 0, // it's defined in the sticky header instead `PageStickyHeader.js`
}

const LoadPage = ({
  LazyPage,
  Placeholder,
  headerSection,
  showBreadcrumbs,
  showProductTour,
  showLiveLessonNotification = true,
  layoutProps,
  ...params
}) => {
  storePageParams(params)
  const [header, setHeader] = useState(headerSection)
  const [snackbarProps, setSnackbar] = useState(HIDDEN_SNACKBAR)
  const handleHideSnackbar = () =>
    setSnackbar({ ...snackbarProps, ...HIDDEN_SNACKBAR })
  const handleShowSnackbar = useCallback(
    ({ type = 'info', ...rest }) => {
      setSnackbar({
        type,
        ...rest,
        show: true,
      })
    },
    [setSnackbar]
  )

  return (
    <LoadProductTour disabled={!showProductTour}>
      {header}
      <PageLayout {...(header ? LAYOUT_WITH_HEADER : {})} {...layoutProps}>
        <Snackbar {...snackbarProps} animated onHide={handleHideSnackbar} />
        {showBreadcrumbs && (
          <Box mb={PAGE_GAP}>
            <LoadBreadcrumbs />
          </Box>
        )}
        <LoadPageErrorBoundary>
          <Suspense fallback={<Placeholder />}>
            <LazyPage
              onShowSnackbar={handleShowSnackbar}
              onRenderHeader={setHeader}
              {...params}
            />
          </Suspense>
        </LoadPageErrorBoundary>
        {showLiveLessonNotification && (
          <LoadLiveLessonNotification onShowSnackbar={handleShowSnackbar} />
        )}
      </PageLayout>
    </LoadProductTour>
  )
}

LoadPage.propTypes = {
  LazyPage: PropTypes.object.isRequired,
  Placeholder: PropTypes.func.isRequired,
  headerSection: PropTypes.any,
  showBreadcrumbs: PropTypes.bool,
  showProductTour: PropTypes.bool,
  showLiveLessonNotification: PropTypes.bool,
  layoutProps: PropTypes.object,
}

export default LoadPage
