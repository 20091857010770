import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { Box } from '@chatterbug/aaron'

const CoachMarkOverlay = ({ rect, onHide, children }) => {
  const [opacity, setOpacity] = useState(0)
  const [hovered, setHovered] = useState(false)

  const handleMouseMove = (event) => {
    if (!rect) {
      return
    }

    let isHovered
    const { top, left, width, height } = rect

    const offsetX = event.pageX
    const offsetY = event.pageY

    isHovered =
      offsetX >= left &&
      offsetX < left + width &&
      offsetY >= top &&
      offsetY < top + height

    if (hovered !== isHovered) {
      setHovered(isHovered)
    }
  }

  useEffect(() => {
    setTimeout(() => setOpacity(1), 0)
  }, [])

  useEffect(() => {
    const debouncedHandleMouseMove = debounce(handleMouseMove, 100)

    window.addEventListener('mousemove', debouncedHandleMouseMove)

    return () => {
      window.removeEventListener('mousemove', debouncedHandleMouseMove)
    }
  }, [rect, hovered, setHovered])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onHide?.()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onHide])

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        mixBlendMode: 'hard-light',
        transition: 'opacity 0.3s ease',
        opacity,
        pointerEvents: hovered ? 'none' : 'auto', // to make the spotlight area clickable
      }}
      // coach mark popup can affect the height of the overlay on small screens
      className="coach-mark-overlay"
      onClick={onHide}
    >
      {children}
    </Box>
  )
}

CoachMarkOverlay.propTypes = {
  rect: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onHide: PropTypes.func,
}

export default CoachMarkOverlay
