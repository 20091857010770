import _ from 'lodash'

import { get } from 'lib/util'

export default class OnDemandChatSession {
  constructor(element, options) {
    this.element = element
    let selector = OnDemandChatSession.selector
    this.available = this.element.querySelector(`${selector}__available`)
    this.createButtons = this.element.querySelectorAll(
      `${selector}__create-button`
    )
    this.languageCode = this.element.getAttribute('data-language')
    this.startTime = new Date()
    this.waitSeconds = 10
    this.backoffAfter = 300 // 5 minutes

    this.checkForSpeakers()
  }

  checkForSpeakers() {
    let now = new Date()

    let elapsedSeconds = (now - this.startTime) / 1000
    if (elapsedSeconds >= this.backoffAfter && this.waitSeconds < 300) {
      this.waitSeconds = this.waitSeconds * 2
      this.startTime = now
    }
    window.setTimeout(this.checkForSpeakers.bind(this), this.waitSeconds * 1000)

    get(`/chat/available_speakers/${this.languageCode}`).then(({ data }) => {
      let { available } = data
      this.available.textContent = available
      _.each(this.createButtons, (b) => {
        b.disabled = available < 1
      })
    })
  }
}

OnDemandChatSession.className = 'on-demand-chat-session'
OnDemandChatSession.selector = `.${OnDemandChatSession.className}`
