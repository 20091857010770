/* eslint-disable no-control-regex */

import Levenshtein from 'levenshtein'
import { flatten } from 'lodash'

import transliterate from 'lib/transliterate'
import GermanLanguage from 'lib/language/german-language'
import EnglishLanguage from 'lib/language/english-language'
import SpanishLanguage from 'lib/language/spanish-language'

// characters ignored in study card input
const IGNORED_RE = /[.,¿\?#¡!$:%\x00-\x08\x0E-\x1F]/g

// characters normalized to single quote in study card input
// codepoints: x0060, x2018, x2019, x201a, x201b, x2032, x2035, x275b, x275c,
//             xc2b4, xcab9, xcabc
const SINGLE_QUOTES_RE = /[`‘’‚‛′‵❛❜´ʹʼ]/g

// characters normalized to double quote in study card input
// codepoints: x201c, x201d, x201e, x201f, x275d, x275e, x301d, x201f, xff02
const DOUBLE_QUOTES_RE = /[“”„‟❝❞〝〞〟＂]/g

export default class CardUtils {
  // Determine if the first and last characters of two strings are equal.
  //
  // value1 - the first string value.
  // value2 - the second string value.
  //
  // Returns true if they are not empty and share the same leading and trailing characters, false otherwise.
  static firstAndLastCharacterEqual(value1, value2) {
    return (
      value1.length &&
      value2.length &&
      value1.substr(-1) === value2.substr(-1) &&
      value1[0] === value2[0]
    )
  }

  // Adjust the accuracy for the specific language and translation direction.
  //
  // correctAnswer    - the string of a correct answer
  // userInput        - the string of the user's submitted answer.
  // answerLanguage   - the 2-letter code of the answer language
  //
  // Returns a multiplier for the current accuracy, as a float.
  static calculateCompensation(correctAnswer, userInput, answerLanguage) {
    // Higher tolerance for native English mistakes, where single word.
    if (
      answerLanguage === 'en' &&
      correctAnswer.length < 15 &&
      correctAnswer.indexOf(' ') < 0 &&
      CardUtils.firstAndLastCharacterEqual(userInput, correctAnswer)
    ) {
      return 0.25
    }
    return 1.0
  }

  // clean question and answer strings for comparison
  static cleanString(string) {
    return string
      .normalize()
      .toLowerCase()
      .replace(IGNORED_RE, '')
      .replace(SINGLE_QUOTES_RE, "'") // replace with x0027
      .replace(DOUBLE_QUOTES_RE, '"') // replace with x0022
      .trim()
      .replace(/\s+/g, ' ')
  }

  static getLanguageModel(languageCode) {
    switch (languageCode) {
      case 'en':
        return EnglishLanguage
      case 'es':
        return SpanishLanguage
      case 'de':
        return GermanLanguage
      default:
        return null
    }
  }

  static findSmallestLevenshteinDistance(
    correctAnswers,
    userInputs,
    answerLanguage
  ) {
    const smallestPass = 0.9
    let bestAccuracy = 0

    for (let i = 0; i < correctAnswers.length; i++) {
      for (let j = 0; j < userInputs.length; j++) {
        const lev = new Levenshtein(correctAnswers[i], userInputs[j])

        // We can provide some adjustment of the scoring in this auxiliary function, for example:
        // being more lenient when a user is writing in their native language
        const compensation = CardUtils.calculateCompensation(
          correctAnswers[i],
          userInputs[j],
          answerLanguage
        )

        let accuracy =
          1.0 - (lev.distance * compensation) / correctAnswers[i].length

        // Allow for non-standard character usage, with a minor penalty
        if (accuracy < smallestPass) {
          const transliteratedAnswer = transliterate(correctAnswers[i])
          const transLev = new Levenshtein(transliteratedAnswer, userInputs[j])
          accuracy =
            0.95 - (transLev.distance * compensation) / correctAnswers[i].length
        }

        bestAccuracy = Math.max(bestAccuracy, accuracy)

        // There's no point continuing to compare answers if they've got a perfect match
        if (bestAccuracy === 1.0) {
          return bestAccuracy
        }
      }
    }

    // Never return a sub-zero score
    return Math.max(0.0, bestAccuracy)
  }

  // Calculate the accuracy of the user's answer based on Levenshtein distance.
  //
  // correctAnswerString - the actual possible answers as a semicolon delimited string.
  // userInput      - the string user answer.
  // answerLanguage - the 2-letter code of the answer language
  // type           - the string type. Possible values: "word" or "phrase".
  // features       - the collection of linguistic features. Includes part of speech.
  // side           - the card side: 1 = answer is in native language, 2 = answer is in learning language
  //
  // Returns the accuracy as a float. A value of 1.0 means a perfect match. As
  // accuracy decreases, the value approaches 0.0.
  static judgeAccuracy(
    correctAnswerString,
    userInput,
    answerLanguage,
    type,
    features,
    side
  ) {
    const initialCorrectAnswers = CardUtils.cleanString(
      correctAnswerString
    ).split(/\s*;\s*/)
    const cleanedUserInput = CardUtils.cleanString(userInput)
    const languageModel = CardUtils.getLanguageModel(answerLanguage)
    const isNative = side === 1

    // By default, we just compare the user's input against the set of possible answers
    let expandedUserInputs = [cleanedUserInput]
    let correctAnswers = initialCorrectAnswers

    // But when there's an available language model, we can expand the inputs
    // and correct answers into more permutations...
    if (languageModel) {
      expandedUserInputs = languageModel
        .expand(cleanedUserInput, type, features, isNative)
        .map((answer) => CardUtils.cleanString(answer))

      const spreadAnswers = initialCorrectAnswers.map((answer) =>
        languageModel.expand(answer, type, features, isNative)
      )

      const flattenedAnswers = flatten(spreadAnswers)

      correctAnswers = flattenedAnswers.map((answer) =>
        CardUtils.cleanString(answer)
      )
    }

    return CardUtils.findSmallestLevenshteinDistance(
      correctAnswers,
      expandedUserInputs,
      answerLanguage
    )
  }
}
