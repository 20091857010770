// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontSizes = {
 "xxl": "4.2rem",
 "xl": "3.4rem",
 "lg": "2.8rem",
 "md": "2rem",
 "sm": "1.6rem",
 "xs": "1.4rem",
 "xxs": "1.2rem"
}

export default fontSizes;