import _ from 'lodash'

import { bindMethods } from 'lib/util'

// Module is a base class for a module class. Subclass this, and you get:
// * Easier module creation
// * Auto-binding methods to `this`
// * Some BEM helpers

// ## A little about BEM
// We use BEM (https://en.bem.info/methodology/key-concepts/) which leads to
// kind of crazy classes like `.replay-controls__play-button_blue`. They have a
// name for each part of the class. In this case they are:
//
// replay-controls__play-button_blue
// ^ block          ^ element   ^ modifier
//
// So it's a play button which is a child of the replay-controls block that is
// blue.

export default class Module {
  // Will create JS module object that wraps some markup. Module classes need a
  // className, and accept an html element + options. e.g.
  //
  // class ReplayControls extends Module {
  //   constructor (element, options) {
  //     super(element, options)
  //   }
  //   doStuff () {}
  // }
  // ReplayControls.className = 'replay-controls'
  //
  // --- In the markup
  //
  // <div class="parent-element">
  //   <div class="replay-controls">
  //     ...
  //   </div>
  // </div>
  //
  // --- Then to invoke
  //
  // let replayControls = ReplayControls.create(parentElement, {some: 'options'})
  // replayControls.doStuff()
  static create(parentElement, options) {
    return this.createAll(parentElement, options)[0] || null
  }

  // Same as `.create`, but will find and return all modules with the selector
  static createAll(parentElement, options) {
    let selector = this.selector || `.${this.className}`
    let els = parentElement.querySelectorAll(selector)
    return _.map(els, (el) => new this(el, options))
  }

  // Extracts 'elements' from a 'block' into an object. Camel cases the names.
  // e.g.
  //
  // returns {
  //   playButton: <Element.replay-controls__play-button>,
  //   playButtonSelector: '.replay-controls__play-button',
  //   ... more elements ...
  // }
  static findElements(element, blockClassName) {
    let elements = {}
    let nodes = [].concat(
      _.toArray(element.querySelectorAll(`[class^='${blockClassName}__']`)),
      _.toArray(element.querySelectorAll(`[class*=' ${blockClassName}__']`))
    )
    for (let node of nodes) {
      let { name, selector } = this.getElementName(
        blockClassName,
        node.className
      )
      if (name) {
        if (_.isArray(elements[name])) {
          elements[name].push(node)
        } else if (elements[name] != null && elements[name] !== node) {
          elements[name] = [elements[name], node]
        } else {
          elements[name] = node
        }
      }
      if (selector) elements[name + 'Selector'] = selector
    }
    return elements
  }

  // Returns a hash of modifiers based on the root element className:
  //
  // <div class="replay-controls replay-controls_red replay-controls_big-size"></div>
  // returns {
  //   red: true,
  //   bigSize: true
  // }
  static findModifiers(element, blockClassName) {
    const regex = new RegExp(`${blockClassName}_([^\\s_]+)`, 'g')
    let match

    let modifiers = {}
    while ((match = regex.exec(element.className))) {
      modifiers[_.camelCase(match[1])] = true
    }
    return modifiers
  }

  static getElementName(blockClassName, className) {
    const regex = new RegExp(`${blockClassName}__([^\\s_]+)`)
    let match = regex.exec(className)
    if (match) {
      return {
        selector: `.${match[0]}`,
        name: _.camelCase(match[1]),
      }
    }
    return {}
  }

  constructor(element, options) {
    this.element = element
    this.options = options
    let { className, name } = this.constructor
    if (!className) {
      console.warn(
        `You should set a className on ${name}. e.g. ${name}.className = '${_.kebabCase(
          name
        )}'`
      )
    }
    bindMethods(this)
  }

  findElements() {
    return this.constructor.findElements(
      this.element,
      this.constructor.className
    )
  }

  findModifiers() {
    return this.constructor.findModifiers(
      this.element,
      this.constructor.className
    )
  }
}
