import _ from 'lodash'

import ChatChannel from 'lib/actioncable/chat-channel'
import { bindMethods } from 'lib/util'

// FIXME: not ideal! At some point, they will have an npm version:
// https://github.com/CreateJS/EaselJS/issues/713
// But really, long term, this should just be using the canvas API
import 'vendor_js/common/easeljs-0.8.2.min'

$.fn.interactiveImage = function() {
  return $.map(this, function(element) {
    return new InteractiveImage(element)
  })
}

export default class InteractiveImage {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults(options, {
      width: 700,
      chatChannelReplay: null,
    })

    this.handleMouseMove = _.throttle(this.handleMouseMove, 16, {
      leading: true,
    })
    this.sendMouseMoveEventToPeer = _.throttle(
      this.sendMouseMoveEventToPeer,
      50,
      { leading: true }
    )

    // scale for retina screens; things will be drawn at 2x then scaled in css
    this.scale = 2

    this.imageURL = element.querySelector('input').value
    this.canvas = element.querySelector('canvas')
    this.stage = new global.createjs.Stage(this.canvas)

    this.currentUserId = parseInt(element.getAttribute('data-user-id'))
    this.otherUserId = parseInt(element.getAttribute('data-other-user-id'))

    this.isReplay = false
    if (this.options.chatChannelReplay) {
      this.isReplay = true
      this.setChatChannelReplay(this.options.chatChannelReplay)
    } else {
      this.sessionId = element.getAttribute('data-session-id')
      if (this.sessionId) {
        this.chatChannel = ChatChannel.get(this.sessionId)
        this.chatChannel.on('mouseMove', this.handleRemoteMouseMove)
        this.chatChannel.on('mouseDown', this.handleRemoteMouseDown)
      } else {
        console.error('No session id for interactive-image!')
      }
    }

    this.start()
  }

  destroy() {
    if (this.chatChannelReplay) {
      this.chatChannelReplay.off('mouseMove', this.handleReplayMouseMove)
      this.chatChannelReplay.off('mouseDown', this.handleReplayMouseDown)
      this.chatChannelReplay.off('seek-backward', this.handleReplaySeekBackward)
    } else if (this.chatChannel) {
      this.chatChannel.off('mouseMove', this.handleRemoteMouseMove)
      this.chatChannel.off('mouseDown', this.handleRemoteMouseDown)
      this.stage.removeEventListener('stagemousedown', this.handleMouseDown)
      this.stage.removeEventListener('stagemousemove', this.handleMouseMove)
    }
  }

  setChatChannelReplay(chatChannelReplay) {
    if (this.chatChannelReplay) {
      this.chatChannelReplay.off('mouseMove', this.handleReplayMouseMove)
      this.chatChannelReplay.off('mouseDown', this.handleReplayMouseDown)
      this.chatChannelReplay.off('seek-backward', this.handleReplaySeekBackward)
    }
    this.chatChannelReplay = chatChannelReplay
    this.chatChannelReplay.on('mouseMove', this.handleReplayMouseMove)
    this.chatChannelReplay.on('mouseDown', this.handleReplayMouseDown)
    this.chatChannelReplay.on('seek-backward', this.handleReplaySeekBackward)
  }

  start() {
    this.otherCursor = new global.createjs.Shape()
    this.otherLastPt = new global.createjs.Shape()
    this.myCursor = new global.createjs.Shape()
    this.myLastPt = new global.createjs.Shape()

    this.stageWidth = this.canvas.width
    this.stageHeight = this.canvas.height
    this.canvas.width = this.stageWidth * this.scale
    this.canvas.height = this.stageHeight * this.scale
    this.canvas.style.width = this.stageWidth + 'px'

    let image = new Image()
    let handleLoad = this.handleImageLoad.bind(this)
    image.src = this.imageURL

    return new Promise(function(resolve) {
      image.onload = (e) => {
        handleLoad(image, e)
        resolve(image, e)
      }
    })
  }

  handleImageLoad(image, evt) {
    if (!this.isReplay) {
      this.stage.enableMouseOver()
      this.stage.addEventListener('stagemousedown', this.handleMouseDown)
      this.stage.addEventListener('stagemousemove', this.handleMouseMove)
    }

    let imageURL = evt.target

    let imageWidth = image.width

    let imageHeight = image.height
    let imageScale = this.options.width / imageWidth
    let newImgWidth = imageWidth * imageScale
    let newImgHeight = imageHeight * imageScale

    let offsetX = 0
    let offsetY = 0
    if (newImgWidth < this.stageWidth) {
      offsetX = (this.stageWidth - newImgWidth) / 2
    }
    if (newImgHeight < this.stageHeight) {
      offsetY = (this.stageHeight - newImgHeight) / 2
    }

    this.stage.setTransform(0, 0, this.scale, this.scale)
    let bitmap = new global.createjs.Bitmap(imageURL).set({
      scaleX: imageScale,
      scaleY: imageScale,
    })
    bitmap.x = offsetX
    bitmap.y = offsetY
    this.stage.addChild(bitmap)

    this.drawCursor(this.otherCursor, '#FD426E')
    this.stage.addChild(this.otherCursor)
    this.otherCursor.visible = false

    this.drawDot(this.otherLastPt, '#FD426E')
    this.stage.addChild(this.otherLastPt)
    this.otherLastPt.visible = false

    this.drawCursor(this.myCursor, '#25CCED')
    this.stage.addChild(this.myCursor)
    this.myCursor.visible = false

    this.drawDot(this.myLastPt, '#25CCED')
    this.stage.addChild(this.myLastPt)
    this.myLastPt.visible = false

    this.stage.update()
    if (this.isReplay) this.updateForCurrentReplayState()
  }

  drawCursor(shape, color) {
    shape.graphics
      .beginFill(color)
      .setStrokeStyle(2, 'round', 'round')
      .beginStroke('#FFFFFF')
      .moveTo(0, 0)
      .lineTo(0, 24)
      .lineTo(7, 16.5)
      .lineTo(18, 16.5)
      .closePath()
    shape.shadow = new global.createjs.Shadow('rgba(0,0,0,0.3)', 0, 1, 6)
  }

  drawDot(shape, color) {
    shape.graphics
      .beginFill(color)
      .setStrokeStyle(2)
      .beginStroke('#FFFFFF')
      .drawCircle(0, 0, 5)
    shape.shadow = new global.createjs.Shadow('rgba(0,0,0,0.3)', 0, 1, 6)
  }

  handleReplayMouseMove(data) {
    this.moveUserCursor(data.user, data)
  }

  handleReplayMouseDown(data) {
    this.mouseDownUserCursor(data.user, data)
  }

  handleReplaySeekBackward() {
    this.updateForCurrentReplayState()
  }

  // Live mode, current user moves mouse
  handleMouseMove(event) {
    let info = {
      x: this.stage.mouseX / this.scale,
      y: this.stage.mouseY / this.scale,
    }
    this.sendMouseMoveEventToPeer(info)
    this.moveUserCursor(this.currentUserId, info)
  }

  // This function is throttled to 50ms
  sendMouseMoveEventToPeer(info) {
    this.chatChannel.mouseMove(info)
  }

  // Live mode, current user clicks mouse
  handleMouseDown(event) {
    let info = {
      x: this.stage.mouseX / this.scale,
      y: this.stage.mouseY / this.scale,
    }
    this.chatChannel.mouseDown(info)
    this.mouseDownUserCursor(this.currentUserId, info)
  }

  // Live mode, remote user moves mouse
  handleRemoteMouseMove(data) {
    if (data.user !== this.currentUserId) {
      this.moveUserCursor(data.user, data)
    }
  }

  // Live mode, remote user clicks mouse
  handleRemoteMouseDown(data) {
    if (data.user !== this.currentUserId) {
      this.mouseDownUserCursor(data.user, data)
    }
  }

  moveUserCursor(user, point) {
    let cursor = user === this.currentUserId ? this.myCursor : this.otherCursor
    this.moveCursor(cursor, point)
  }

  mouseDownUserCursor(user, point) {
    let cursor = user === this.currentUserId ? this.myLastPt : this.otherLastPt
    this.moveCursor(cursor, point)
  }

  moveCursor(cursor, point) {
    if (!point) {
      cursor.visible = false
    } else {
      cursor.visible = true
      cursor.x = parseFloat(point.x)
      cursor.y = parseFloat(point.y)
    }
    this.stage.update()
  }

  updateForCurrentReplayState() {
    let userMove = this.chatChannelReplay.getLastEvent({
      type: 'mouseMove',
      user: this.currentUserId,
    })
    let userDown = this.chatChannelReplay.getLastEvent({
      type: 'mouseDown',
      user: this.currentUserId,
    })
    let otherMove = this.chatChannelReplay.getLastEvent({
      type: 'mouseMove',
      user: this.otherUserId,
    })
    let otherDown = this.chatChannelReplay.getLastEvent({
      type: 'mouseDown',
      user: this.otherUserId,
    })

    this.moveUserCursor(this.currentUserId, userMove ? userMove.event : null)
    this.mouseDownUserCursor(
      this.currentUserId,
      userDown ? userDown.event : null
    )
    this.moveUserCursor(this.otherUserId, otherMove ? otherMove.event : null)
    this.mouseDownUserCursor(
      this.otherUserId,
      otherDown ? otherDown.event : null
    )
  }
}

InteractiveImage.className = 'interactive-image'
InteractiveImage.selector = `.${InteractiveImage.className}`
