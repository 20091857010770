// Create a chart like this:
//
// $('.chart').chart({
//   data: {
//     labels: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n'],
//     datasets: [{
//       data: [200,250,220,180,290,300,370,350,200,280,260,190,210,200],
//       label: 'Students',
//     }]
//   }
// })
//
// All this code looks pretty complicated. Basically it sets all the Chart.js
// options like we want them, and has workarounds where Chart.js sucks.

import _ from 'lodash'
import moment from 'moment'
import Chart from 'chart.js'

import { renderTemplate } from 'src/lib/util'
import { get } from 'lib/util'

class CBChart {
  constructor(element, options) {
    this.$el = $(element)
    this.options = _.defaults({}, options, {
      type: 'line',
      data: { labels: [], datasets: [] },
      chartOptions: {},
      labelsAreDates: true,
      updateUrl: false,
      updateLive: false,
    })

    this.chartOptions = _.defaultsDeep(
      {},
      this.options.chartOptions,
      DefaultChartOptions
    )
    this.chartOptions.tooltips.custom = customTooltipHandler.bind(this, element)
    this.chartOptions.tooltips.callbacks = {
      title: this.getTitleText.bind(this),
      labelColor: this.getLabelColor.bind(this),
    }

    this.labels = this.options.data.labels
    this.data = this.generateData(this.options.data)

    if (this.options.labelsAreDates) {
      // eslint-disable-next-line
      var today = new moment()
      this.labels = this.labels.map(function(label) {
        var d = moment(label)
        return d.format(d.year() === today.year() ? 'MMMM Do' : 'MMMM Do YYYY')
      })
    }

    this.canvas = element.querySelector('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.canvas.width = this.$el.width()
    this.chartObject = new Chart(this.ctx, {
      type: this.options.type,
      data: this.data,
      options: this.chartOptions,
    })

    if (this.options.updateUrl) {
      this.getUpdateData()
    }

    if (this.options.updateLive) {
      window.setInterval(() => {
        this.getUpdateData()
      }, 3000)
    }
  }

  getUpdateData() {
    get(this.options.updateUrl).then(({ data }) => {
      this.labels = data.labels
      this.data = this.generateData(data)
      this.chartObject.data = this.data
      this.chartObject.update()
    })
  }

  getTitleText(tooltipItems, data) {
    return this.labels[tooltipItems[0].index]
  }

  getLabelColor(tooltipItem, data) {
    var color = DatasetColors[tooltipItem.datasetIndex].baseColor
    return {
      backgroundColor: color,
      borderColor: color,
    }
  }

  generateData(data) {
    return {
      // we dont show them, but need placeholders for the chart to render the correct num points
      labels: _.times(data.labels.length, _.constant('')),
      // reverse so later datasets render on top
      datasets: _.map(data.datasets, this.generateDataSet.bind(this)).reverse(),
    }
  }

  generateDataSet(dataset, index) {
    var len = dataset.data.length

    // Make the first and last points not display otherwise wont render properly
    var pointRadius = _.times(len, _.constant(PointRadius))
    var pointHoverRadius = _.times(len, _.constant(PointHoverRadius))
    pointRadius[0] = pointRadius[len - 1] = 0
    pointHoverRadius[0] = pointHoverRadius[len - 1] = 0
    var colors = _.assign({}, DatasetColors[index % DatasetColors.length])

    if (this.options.type === 'bar') {
      colors.backgroundColor = colors.barBackgroundColor
    } else if (this.options.type === 'line') {
      if (this.$el.width() / this.labels.length < 10) {
        pointRadius = _.times(len, _.constant(0))
      }
    }
    if (this.options.updateUrl) {
      pointRadius = 1
    }

    var defaults = _.assign(
      {
        lineTension: LineTension,
        pointBorderWidth: PointBorderWidth,
        pointHoverBorderWidth: PointHoverBorderWidth,
        pointHitRadius: PointHitRadius,
        pointRadius: pointRadius,
        pointHoverRadius: pointHoverRadius,
      },
      colors
    )

    return _.defaults({}, dataset, defaults)
  }
}

$.fn.chart = function(options) {
  return $.map(this, function(element) {
    element.chart = new CBChart(element, options)
    return element.chart
  })
}

var PointRadius = 4
var PointHoverRadius = 4
var PointBorderWidth = 3
var PointHoverBorderWidth = 3
var PointHitRadius = 8
var LineTension = 0.3

var blue = '#25CCED'
var purple = '#AA8CEA'
var green = '#00E6C2'
var pink = '#FD426E'
var yellow = '#FEDA32'
var red = '#FD4242'

var DatasetColors = [
  {
    baseColor: blue,
    borderColor: RGBA(blue, 0.8), // the line stroke
    highlightStroke: RGBA(blue, 1), // the line stroke when hovered
    pointBorderColor: RGBA(blue, 0.8), // the line stroke around the point
    backgroundColor: RGBA(blue, 0.1), // area under the curve
    barBackgroundColor: RGBA(blue, 0.4), // area under the curve
    highlightFill: RGBA(blue, 0.5), // area under the curve wheh hovered
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
  {
    baseColor: purple,
    borderColor: RGBA(purple, 0.8),
    highlightStroke: RGBA(purple, 1),
    pointBorderColor: RGBA(purple, 0.8),
    backgroundColor: RGBA(purple, 0.1),
    barBackgroundColor: RGBA(purple, 0.4),
    highlightFill: RGBA(purple, 0.5),
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
  {
    baseColor: green,
    borderColor: RGBA(green, 0.8),
    highlightStroke: RGBA(green, 1),
    pointBorderColor: RGBA(green, 0.8),
    backgroundColor: RGBA(green, 0.1),
    barBackgroundColor: RGBA(green, 0.4),
    highlightFill: RGBA(green, 0.5),
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
  {
    baseColor: pink,
    borderColor: RGBA(pink, 0.8),
    highlightStroke: RGBA(pink, 1),
    pointBorderColor: RGBA(pink, 0.8),
    backgroundColor: RGBA(pink, 0.1),
    barBackgroundColor: RGBA(pink, 0.4),
    highlightFill: RGBA(pink, 0.5),
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
  {
    baseColor: yellow,
    borderColor: RGBA(yellow, 0.8),
    highlightStroke: RGBA(yellow, 1),
    pointBorderColor: RGBA(yellow, 0.8),
    backgroundColor: RGBA(yellow, 0.1),
    barBackgroundColor: RGBA(yellow, 0.4),
    highlightFill: RGBA(yellow, 0.5),
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
  {
    baseColor: red,
    borderColor: RGBA(red, 0.8),
    highlightStroke: RGBA(red, 1),
    pointBorderColor: RGBA(red, 0.8),
    backgroundColor: RGBA(red, 0.1),
    barBackgroundColor: RGBA(red, 0.4),
    highlightFill: RGBA(red, 0.5),
    pointBackgroundColor: '#ffffff',
    pointHoverBackgroundColor: '#ffffff',
  },
]

// Yay programming, nay CSS
function RGBA(hexColor, alpha) {
  hexColor = hexColor.replace('#', '')
  return (
    'rgba(' +
    parseInt(hexColor.slice(0, 2), 16) +
    ',' +
    parseInt(hexColor.slice(2, 4), 16) +
    ',' +
    parseInt(hexColor.slice(4, 6), 16) +
    ',' +
    alpha +
    ')'
  )
}

var DefaultChartOptions = {
  responsive: true,
  showLines: true,
  layout: {
    padding: { top: 10 },
  },
  legend: { display: false },
  animation: {
    duration: 0,
  },
  tooltips: {
    enabled: false,
    mode: 'index',
    intersect: false,
  },
  title: {
    display: false,
    text: 'Students',
  },
  scales: {
    yAxes: [
      {
        id: 'y-axis-0',
        display: false,
        gridLines: {
          display: false,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.85)',
        },
        ticks: {
          mirror: true,
          fontColor: 'rgba(0,0,0,0.8)',
          callback: function(value) {
            if (value !== 0) return '' + value
          },
        },
      },
    ],
    xAxes: [
      {
        id: 'x-axis-0',
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
      },
    ],
  },
}

var TooltipTemplate =
  '<div class="chart__tooltip__title"><var>{title}</var></div>' +
  '<div class="chart__tooltip__datasets">{body}</div>'

var TooltipBodyTemplate =
  '<div class="chart__tooltip__dataset">' +
  '<div class="chart__tooltip__dataset__swatch" style="background-color: {bgColor}"></div>' +
  '<div class="chart__tooltip__dataset__text">{label}: <span class="chart__tooltip__dataset__value"><var>{value}</var></span></div>' +
  '</div>'

function customTooltipHandler(parent, tooltip) {
  // Tooltip Element
  var tooltipEl = parent.querySelector('.chart__tooltip')
  var hoverLineEl = parent.querySelector('.chart__hover-line')
  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.classList.add('chart__tooltip')
    hoverLineEl = document.createElement('div')
    hoverLineEl.classList.add('chart__hover-line')
    parent.appendChild(tooltipEl)
    parent.appendChild(hoverLineEl)

    // This is a more reliable way to hide the tip
    $(parent).mouseleave(function() {
      tooltipEl.style.opacity = 0
      hoverLineEl.style.opacity = 0
    })
  }

  // Wellll this is the way you're supposed to hide the tip, but it flickers
  // and sucks, so i'm hiding it with mouseleave
  if (tooltip.opacity === 0) {
    // tooltipEl.style.opacity = 0
    // hoverLineEl.style.opacity = 0
    // return here cause when opacity is 0, it doesnt send any dataPoints so
    // causes a flash. This lib is dumb
    return
  }

  tooltipEl.classList.remove('chart__tooltip_left', 'chart__tooltip_right')
  if (tooltip.xAlign) {
    tooltipEl.classList.add(
      'chart__tooltip_' + (tooltip.xAlign === 'right' ? 'right' : 'left')
    )
  }

  var title = (tooltip.title || []).join(' ')
  var body = ''

  body = _.map(
    tooltip.dataPoints || [],
    function(dataPoint) {
      var meta = this.data.datasets[dataPoint.datasetIndex]
      var colorIndex = tooltip.dataPoints.length - 1 - dataPoint.datasetIndex // datasets are reversed
      var bgColor = tooltip.labelColors[colorIndex].backgroundColor
      return renderTemplate(TooltipBodyTemplate, {
        label: meta.label,
        value: dataPoint.yLabel,
        bgColor: bgColor,
      })
    }.bind(this)
  )
    .reverse()
    .join('')

  tooltipEl.innerHTML = renderTemplate(TooltipTemplate, {
    title: title,
    body: body,
  })

  var width = 0
  var height = tooltipEl.offsetHeight
  if (tooltip.xAlign === 'right') {
    width = tooltipEl.offsetWidth
  }

  var parentHeight = parent.offsetHeight

  // Display & position
  tooltipEl.style.opacity = 1
  tooltipEl.style.left = tooltip.caretX - width + 'px'
  tooltipEl.style.top =
    _.clamp(tooltip.caretY - height / 2, 0, parentHeight - height - 10) + 'px'

  hoverLineEl.style.opacity = 1
  hoverLineEl.style.left = tooltip.caretX - 1 + 'px'
}

// Plugin to draw the y grid lines within the chart data bounds.
// Modified from http://jsbin.com/parucayara/1/edit?js,output
Chart.pluginService.register({
  afterDraw: function(chart, easingDecimal) {
    var yScale = chart.scales['y-axis-0']
    var helpers = Chart.helpers
    var chartArea = chart.chartArea

    yScale.ctx.save()
    yScale.ctx.globalCompositeOperation = 'destination-over'
    helpers.each(
      yScale.ticks,
      function(label, index) {
        if (label === undefined || label === null) return
        if (index === 0) return // dont draw the top line

        var yLineValue = this.getPixelForTick(index)
        yLineValue += helpers.aliasPixel(this.ctx.lineWidth)

        // Draw gridlines
        this.ctx.save()
        this.ctx.lineWidth = this.options.gridLines.lineWidth
        this.ctx.strokeStyle = 'rgba(100, 100, 100, 0.15)'

        this.ctx.beginPath()
        this.ctx.moveTo(chartArea.left, yLineValue)
        this.ctx.lineTo(chartArea.right, yLineValue)
        this.ctx.stroke()
        this.ctx.restore()

        // Draw labels
        this.ctx.save()
        this.ctx.translate(20, yLineValue)
        this.ctx.font = '12px sofia-pro, sans-serif'
        this.ctx.textBaseline = 'bottom'
        this.ctx.textAlign = 'left'
        this.ctx.fillStyle = 'rgba(100, 100, 100, 0.4)'
        this.ctx.fillText(Number(label).toFixed(2), 0, 0)
        this.ctx.restore()
      },
      yScale
    )

    yScale.ctx.restore()
    yScale.ctx.save()
    yScale.ctx.fillStyle = 'white'
    yScale.ctx.globalCompositeOperation = 'destination-over'
    yScale.ctx.fillRect(0, yScale.bottom, chartArea.right, chartArea.bottom)
    yScale.ctx.restore()
  },
})
