import _ from 'lodash'
import Mustache from 'mustache'
import { get, put, destroy, bindMethods } from 'lib/util'
import { t } from 'src/lib/i18n'

import Module from 'lib/module'
import Form from 'modules/form'
import FormModal from 'modules/form-modal'
import Preferences from 'lib/preferences'
import AppCable from 'lib/actioncable/app-cable'

export default class MobileNumbers {
  constructor(element, options) {
    bindMethods(this)
    this.element = element
    this.options = _.defaults({}, options, {
      onUpdateItems: () => {},
    })

    this.items = JSON.parse(element.getAttribute('data-items')) || []
    this.hideWhenIgnored =
      JSON.parse(element.getAttribute('data-hide-when-ignored')) || false
    this.elements = Module.findElements(element, MobileNumbers.className)
    this.formModal = new FormModal(this.elements.modal, {
      onSuccess: this.handleAdd,
    })
    this.form = new Form(this.elements.form, {
      onSuccess: this.handleAdd,
    })

    $(this.element).on(
      'click',
      `${MobileNumbers.selector}__delete`,
      this.handleDelete
    )
    $(this.element).on(
      'click',
      `${MobileNumbers.selector}__activate`,
      this.handleActivate
    )
    $(this.element).on(
      'click',
      `${MobileNumbers.selector}__no-thanks`,
      this.handleNoThanks
    )

    Preferences.on('change', this.handlePreferenceChange)
    this.options.onUpdateItems(this.items)
    this.render({ items: this.items }, {})

    AppCable.createSubscription('NotificationsChannel', {
      received: (data) => {
        if (data.type === 'verified') {
          this.refresh()
        }
      },
    })
  }

  refresh() {
    return get('/account/mobile_numbers', 'json').then(({ data, _status }) => {
      this.render({ items: data.mobile_numbers }, { items: this.items })
      this.items = data
      this.options.onUpdateItems(this.items)
    })
  }

  handleAdd({ _data, _status }) {
    this.formModal.hide()
    this.refresh()
  }

  handleActivate(e) {
    let item = $(e.target).closest(`${MobileNumbers.selector}__item`)[0]
    let id = item.getAttribute('data-id')
    put(`/account/mobile_numbers/${id}`, { activate: true }, 'json').then(
      this.refresh
    )
  }

  handleDelete(e) {
    let item = $(e.target).closest(`${MobileNumbers.selector}__item`)[0]
    let id = item.getAttribute('data-id')
    if (
      window.confirm(t('Are you sure you want to delete this phone number?'))
    ) {
      destroy(`/account/mobile_numbers/${id}`, {}, 'json').then(this.refresh)
    }
  }

  handleNoThanks(_e) {
    let { ignorePreferencePath } = MobileNumbers
    Preferences.instance().then((preferences) => {
      preferences.set(ignorePreferencePath, true)
    })
  }

  handlePreferenceChange() {
    if (!this.hideWhenIgnored) return

    let { ignorePreferencePath, ignoredClass } = MobileNumbers
    Preferences.instance().then((preferences) => {
      let ignore = preferences.get(ignorePreferencePath)
      let classList = this.element.classList
      ignore ? classList.add(ignoredClass) : classList.remove(ignoredClass)
    })
  }

  render(state, prevState) {
    let { items } = state
    let prevItems = prevState.items
    if (!_.isEqual(items, prevItems)) {
      let itemsHTML = items
        .map((item) => Mustache.render(MobileNumbers.itemTemplate, item))
        .join('')
      this.elements.items.innerHTML = itemsHTML
    }

    let emptyClass = `${MobileNumbers.className}_empty`
    items.length
      ? this.element.classList.remove(emptyClass)
      : this.element.classList.add(emptyClass)

    let hasUnverified = !!_.filter(items, (i) => !i.verified).length
    let hasUnverifiedClass = `${MobileNumbers.className}_has-unverified`

    hasUnverified
      ? this.element.classList.add(hasUnverifiedClass)
      : this.element.classList.remove(hasUnverifiedClass)
  }
}

MobileNumbers.ignorePreferencePath = 'notifications.ignore_add_mobile'
MobileNumbers.className = 'mobile-numbers'
MobileNumbers.ignoredClass = `${MobileNumbers.className}_ignored`
MobileNumbers.selector = `.${MobileNumbers.className}`
MobileNumbers.itemTemplate = `
  <tr class="mobile-numbers__item
    {{#active}}mobile-numbers__item_active{{/active}}
    {{#verified}}mobile-numbers__item_verified{{/verified}}
    {{^verified}}mobile-numbers__item_verifying{{/verified}}"
    {{#active}}title="${t('Default mobile number')}"{{/active}}
    data-id="{{id}}"
    >
    <td>
      <span class="mobile-numbers__number">
        <var>{{formatted_number}}</var>
      </span>
      {{#verified}}
        <span class="mobile-numbers__verified-text">${t('verified')}</span>
      {{/verified}}
      {{^verified}}
        <span class="mobile-numbers__waiting-text">${t(
          'waiting for verification...'
        )}</span>
      {{/verified}}
      {{#active}}
        <span class="mobile-numbers__default-text">${t('default')}</span>
      {{/active}}
    </td>
    <td class="mobile-numbers__actions">
      {{#verified}}{{^active}}
        <a href="javascript:void(0)" class="mobile-numbers__activate">${t(
          'set as default'
        )}</a>
      {{/active}}{{/verified}}
      <a href="javascript:void(0)" class="mobile-numbers__delete" title="${t(
        'delete number'
      )}"><i class="fa fa-close"></i></a>
    </td>
  </tr>
`
