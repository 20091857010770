import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { useMediaQuery } from '@react-hook/media-query'
import { Box, Flex } from '@chatterbug/aaron'
import CoachMark from 'src/ui/CoachMark/CoachMark'
import { t } from 'lib/i18n-react'

import Flag from './subcomponents/Flag/Flag'
import Logo from './subcomponents/Logo/Logo'
import MenuItem from './subcomponents/MenuItem/MenuItem'
import UserIcon from './subcomponents/UserIcon/UserIcon'
import MenuItemGroup from './subcomponents/MenuItemGroup'
import UserTools from './subcomponents/UserTools/UserTools'
import PopupContainer from './subcomponents/PopupContainer'
import CourseList from './subcomponents/CourseList/CourseList'
import downArrow from 'src/ui/assets/icons/down-arrow.svg'

const MENUS = {
  USER: 'user',
  COURSES: 'courses',
  MORE: 'more',
  MODAL: 'modal',
  NONE: 'none',
}

const MENU_GROUP_TEXT_STYLE = {
  marginLeft: '2x',
  fontSize: 'sm',
}

const USER_TOOLS_TEXT_STYLE = {
  fontSize: { _: 'sm', tabletWide: 'xs' },
}

const MainMenu = ({
  user,
  avatarUrl,
  credits,
  showRemainingCredits,
  courses = [],
  selectedCourse,
  eligibleLearningLanguages = [],
  orgName,
  theme,
  // this decides if the menu should be collapsed/medium even on llarge screens
  showMediumNav,
  hideFlashcards,
}) => {
  const MENU_TEXT_STYLE = {
    display: { _: 'none', laptop: showMediumNav ? 'none' : 'block' },
    marginLeft: { laptop: '2x' },
    fontSize: { _: 'sm', tabletWide: 'xs' },
  }

  const [activeMenu, setActiveMenu] = useState(MENUS.NONE)
  const isMobileView = useMediaQuery(
    `(max-width: ${parseInt(theme.breakpoints.tabletWide) - 1}px)`
  )
  const handleToggleActiveMenu = (nextActiveMenu) => {
    setActiveMenu(activeMenu === nextActiveMenu ? MENUS.NONE : nextActiveMenu)
  }

  const handleOpenLanguageModal = () => {
    setActiveMenu(MENUS.MODAL)
  }

  const { isTutor, isEmployee, isStreamer } = user

  const calcHeight = () => {
    if (activeMenu !== MENUS.NONE) {
      return '100vh'
    } else {
      // 64px is from figma
      return { _: '64px', tabletWide: '100vh' }
    }
  }

  return (
    <Box
      minWidth={{ laptop: showMediumNav ? 'auto' : '214px' }}
      height={calcHeight()}
      minHeight={calcHeight()}
      bg="white100"
      sx={{
        boxShadow:
          '0px 8px 32px rgba(0, 0, 0, 0.03), 0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 8px rgba(0, 0, 0, 0.03), 0px 1px 4px rgba(0, 0, 0, 0.03)',
        overflowY: 'auto',
      }}
    >
      <Flex
        height={{ _: '64px', tabletWide: '100%' }}
        flexDirection={{ _: 'row', tabletWide: 'column' }}
        alignItems={{ _: 'center', laptop: 'stretch' }}
        bg="white100"
        px={{ _: '2x', tablet: '3x' }}
        py={{ _: '1x', tabletWide: '4x' }}
      >
        <Logo showMediumNav={showMediumNav} />

        <Box
          display={{
            _: 'none',
            tabletWide: 'block',
          }}
          mt={{ tabletWide: '3x' }}
        >
          <MenuItemGroup
            isMobileView={isMobileView}
            textStyle={MENU_TEXT_STYLE}
            credits={showRemainingCredits ? credits : null}
            selectedCourse={selectedCourse}
            showMediumNav={showMediumNav}
            isStreamer={isStreamer}
            hideFlashcards={hideFlashcards}
          />
        </Box>

        <Box flex={1} />

        <Box
          display={{ _: 'none', tabletWide: 'flex' }}
          flexDirection="column"
          alignSelf={showMediumNav && 'center'}
        >
          {activeMenu === MENUS.COURSES && (
            <PopupContainer
              containerStyle={{
                display: { _: 'none', tabletWide: 'block' },
                bottom: '70px',
              }}
              popupStyle={{
                marginLeft: {
                  _: '52px',
                  laptop: showMediumNav ? '52px' : '175px',
                },
                marginBottom: '14px',
              }}
              onCancel={() => setActiveMenu(MENUS.NONE)}
            >
              <Box sx={{ paddingY: '1x', width: '100%' }}>
                {/* warning: changes here also need to be made to
                UserTools (end of file), which takes care of the mobile courseList View.
                UserTools than has to pass props to CourseList. */}
                <CourseList
                  onClick={handleOpenLanguageModal}
                  courses={courses}
                  selectedCourse={selectedCourse}
                  eligibleLearningLanguages={eligibleLearningLanguages}
                  textStyle={MENU_TEXT_STYLE}
                  isTutor={isTutor}
                  isEmployee={isEmployee}
                  isStreamer={isStreamer}
                  isModalOpen={activeMenu === MENUS.MODAL}
                  orgName={orgName}
                  onCloseModal={() => {
                    setActiveMenu(MENUS.NONE)
                  }}
                />
              </Box>
            </PopupContainer>
          )}
          <Flex
            mt={{ tabletWide: '2x' }}
            width={showMediumNav && 'fit-content'}
            onMouseEnter={() => handleToggleActiveMenu(MENUS.COURSES)}
            sx={{
              borderRadius: '1,5x',
              backgroundColor: activeMenu === MENUS.COURSES ? '#F3F3F3' : null,
              ':hover': {
                backgroundColor: '#F3F3F3',
              },
            }}
          >
            <MenuItem
              showToolTipOnTablet={false}
              text={courses.find((c) => c.code === selectedCourse)?.label}
              textStyle={MENU_TEXT_STYLE}
            >
              <Flag languageCode={selectedCourse} />
            </MenuItem>
          </Flex>
          {activeMenu === MENUS.USER && (
            <PopupContainer
              containerStyle={{
                display: { _: 'none', tabletWide: 'block' },
              }}
              popupStyle={{
                marginLeft: {
                  _: '52px',
                  laptop: showMediumNav ? '52px' : '175px',
                },
                marginBottom: '14px',
              }}
              onCancel={() => setActiveMenu(MENUS.NONE)}
            >
              <UserTools
                user={user}
                avatarUrl={avatarUrl}
                courses={courses}
                isModalOpen={activeMenu === MENUS.MODAL}
                eligibleLearningLanguages={eligibleLearningLanguages}
                selectedCourse={selectedCourse}
                textStyle={USER_TOOLS_TEXT_STYLE}
                isTutor={isTutor}
              />
            </PopupContainer>
          )}
          <Flex
            mt={{ tabletWide: '2x' }}
            width={showMediumNav && 'fit-content'}
            onMouseEnter={() => handleToggleActiveMenu(MENUS.USER)}
            sx={{
              borderRadius: '1,5x',
              backgroundColor: activeMenu === MENUS.USER ? '#F3F3F3' : null,
              ':hover': {
                backgroundColor: '#F3F3F3',
              },
            }}
          >
            <UserIcon
              textStyle={{
                maxWidth: '100px',
                ...MENU_TEXT_STYLE,
              }}
              avatarUrl={avatarUrl}
              username={user.login}
            />
          </Flex>
        </Box>

        <Flex
          display={{ _: 'flex', tabletWide: 'none' }}
          flexDirection="row"
          alignItems="center"
        >
          {activeMenu !== MENUS.USER && activeMenu !== MENUS.MORE && (
            <UserIcon
              textStyle={MENU_TEXT_STYLE}
              avatarUrl={avatarUrl}
              username={user.login}
              onClick={() => handleToggleActiveMenu(MENUS.USER)}
              withDropdownStyles={{
                borderColor: { _: 'gray80', tabletWide: 'none' },
                borderWidth: { _: 'small', tabletWide: 0 },
                borderStyle: { _: 'solid', tabletWide: 'none' },
                backgroundImage: { _: `url(${downArrow})`, tabletWide: null },
                justifyContent: { _: 'center', laptop: 'stretch' },
                alignItems: 'center',
                borderRadius: '1,5x',
                padding: { _: '0,5x', tabletWide: '1x' },
                paddingRight: { _: '3x', tabletWide: 0 },
                bg: 'white100',
                mr: '1x',
              }}
              sx={{
                ':focus': {
                  boxShadow: 'darkFocusRing',
                  outline: 'none',
                },
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right .4em center',
              }}
            />
          )}
          <CoachMark
            disabled={!isMobileView}
            name="coach_mark:find_flashcards"
            title={t('Quick-access to Flashcards')}
            description={t(
              'Memorize words and grammar concepts with our spaced repetition flashcards.'
            )}
            align="below"
            spotlightSx={{
              borderRadius: '100%',
            }}
          >
            <MenuItem
              onClick={() =>
                // todo: simplify logic
                handleToggleActiveMenu(
                  activeMenu === MENUS.USER ? MENUS.NONE : MENUS.MORE
                )
              }
              icon={activeMenu === MENUS.NONE ? 'HamburgerMenu' : 'Close'}
            />
          </CoachMark>
        </Flex>
      </Flex>
      {activeMenu === MENUS.MORE && (
        <Box px="3x" mt="2x" display={{ tabletWide: 'none' }}>
          <MenuItemGroup
            isMobileView={isMobileView}
            credits={showRemainingCredits ? credits : null}
            textStyle={MENU_GROUP_TEXT_STYLE}
            selectedCourse={selectedCourse}
          />
        </Box>
      )}
      {(activeMenu === MENUS.MODAL || activeMenu === MENUS.USER) && (
        <Box px="3x" mt="2x" display={{ tabletWide: 'none' }}>
          <UserTools
            handleOpenLanguageModal={handleOpenLanguageModal}
            isModalOpen={activeMenu === MENUS.MODAL}
            onCloseModal={() => {
              setActiveMenu(MENUS.NONE)
            }}
            user={user}
            avatarUrl={avatarUrl}
            courses={courses}
            selectedCourse={selectedCourse}
            eligibleLearningLanguages={eligibleLearningLanguages}
            textStyle={USER_TOOLS_TEXT_STYLE}
            isTutor={isTutor}
            orgName={orgName}
          />
        </Box>
      )}
    </Box>
  )
}

MainMenu.propTypes = {
  credits: PropTypes.number,
  showRemainingCredits: PropTypes.bool,
  avatarUrl: PropTypes.string,
  user: PropTypes.shape({
    login: PropTypes.string,
    isTutor: PropTypes.bool,
    isEmployee: PropTypes.bool,
    isStreamer: PropTypes.bool,
  }),
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  eligibleLearningLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedCourse: PropTypes.string.isRequired,
  orgName: PropTypes.string,
  showMediumNav: PropTypes.bool,
  hideFlashcards: PropTypes.bool,
}

export default withTheme(MainMenu)
